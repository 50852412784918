require './smart-groups-filter-items-directive.coffee'
require './toggle-sidebar-directive.coffee'
require './ui-directive.coffee'
require './widgets-directive.coffee'
require './charts/charts.directive.module.coffee'
require './visual/visual.directive.module.coffee'
{ onSrcDirectiveInstance } = require './on-src-error.directive.ts'

module = angular.module '42.directives', [
    '42.directives.smart-groups'
    '42.directives.ui'
    '42.directives.widgets'
    '42.directives.charts'
    '42.directives.visual'
]

module.directive 'toggleSidebar', require './toggle-sidebar-directive.coffee'
module.directive 'onErrorSrc', onSrcDirectiveInstance()
