module.exports = (NRFCalendarMonthSelectViewModel) ->
    restrict: 'E'
    scope:
        model:    '='
    replace: true
    template: \
    """
    <article class="calendar-month-select">
        <button class="select-prev" ng-click="model.prev()" ng-disabled="!model.month.prev">
            <i class="icon-left-open-mini"></i>
        </button>

        <div class="selects">
            <select class="select-year" ng-model="model.selected.year"
                ng-options="model.getYearLabel(index) for index in model.ranges.year"></select>

            <select class="select-month" ng-model="model.selected.month"
                ng-options="model.getMonthLabel(index) for index in model.ranges.month"></select>
        </div>

        <button class="select-next" ng-click="model.next()" ng-disabled="!model.month.next">
            <i class="icon-right-open-mini"></i>
        </button>
    </article>
    """
    link: (scope) ->
        update = ->
            return if not scope.model
            scope.model.update()

        scope.$watch 'model.selected.month', update
        scope.$watch 'model.selected.year', update
