import axios from 'axios';
import { v1 as ServiceV1 } from '@42technologies/service-client';
import { AppConfigService, AppConfig } from '../config-app';
import { AuthService, logout } from '../auth';

class ServiceAPIError extends Error {
    code: number;
    type: string;
    constructor(reason: { code: number; type: string; message: string }) {
        super(reason.message);
        this.code = reason.code;
        this.type = reason.type;
    }
}

const authenticatedClient = (options: any) => {
    return AuthService.get().then(auth => {
        options.headers = { ...auth.toHeaders(), ...options.headers };
        return axios(options).catch(error => {
            console.error('Service API Error:', error);
            if (error.response?.status === 401) return logout();
            if (error.response?.data) throw new ServiceAPIError(error.response.data);
            throw error;
        });
    });
};

export class ServiceAPIFactory<T extends ServiceV1.ServiceAPI = ServiceV1.ServiceAPI> {
    private promise: null | Promise<any> = null;

    constructor(private serviceName: keyof AppConfig['services'], private authenticatedSpecFetch = true) {}

    public get(override?: { host?: string }) {
        override = { ...override };
        this.promise ??= this.init();
        return this.promise.then(({ host, spec }) => {
            host = override?.host ?? host;
            const createApi = ServiceV1.createApiClass<T>(spec);
            const api = createApi({ host, client: authenticatedClient, ...override });
            return api;
        });
    }

    private init(): Promise<{ host: string; spec: any }> {
        return AppConfigService.get().then(config => {
            const serviceConfig = config.services[this.serviceName];
            if (!serviceConfig) throw new Error("No config found for service '" + this.serviceName + "'");
            const host = serviceConfig.base;
            return ServiceV1.fetchSpec({
                host,
                client: this.authenticatedSpecFetch ? authenticatedClient : undefined,
            }).then(spec => {
                return { host, spec };
            });
        });
    }
}
