require './grid/items-controller.coffee'
require './main-controller.coffee'
require './metrics/metrics-controller.coffee'
require './sales/sales-controller.coffee'
require './scheduling/scheduling-controller.module.coffee'
require './store-controller.coffee'
require './transactions/transactions-controller.coffee'
require './customer/customer-module.ts'
require './counter/counter-controller.coffee'
require './store-status-controller.coffee'
require './map/index.ts'

angular.module '42.controllers', [
    '42.controllers.items'
    '42.controllers.main'
    '42.controllers.map'
    '42.controllers.metrics'
    '42.controllers.sales'
    '42.controllers.scheduling'
    '42.controllers.store'
    '42.controllers.transactions'
    '42.controllers.customer'
    '42.controllers.counter'
    '42.controllers.store-status'
]
