_ = require('../../../lib/lodash-helper.ts')

module.exports = ($rootScope, $timeout, $log, $window, CONFIG, Utils, OutsideElementClick, DateWrapper, NRFYears, DateSelectModel, DateSelectActions, DatepickerPanelViewModel, NRFCalendar, ComparisonModeSelectModel, StorageAPI) ->
    restrict: 'E'
    scope:
        selection:  "=filter"
        comparison: "="
        comparisonMode: '='
        output: "=model"
        timeRange:  "="
        autoLoad:   "@"
        isOpen:     "=?"
    replace: true
    template: \
    """
    <article class="smart-groups-filter-time">

        <button ng-click="panelModel.toggle()" ng-class="{selected:panelModel.isOpen()}">
            <div class="filter-display-date-container">
                <i class="icon-calendar"></i>
                <div class="filter-display-date-description">
                    <div class="filter-display-date">{{ filterDisplay.start }}</div>
                    <div class="filter-display-separator">-</div>
                    <div class="filter-display-date">{{ filterDisplay.end }}</div>
                </div>
            </div>
        </button>

        <article class="date-select-container" ng-class="{'date-select-container-gregorian' : isGregorian}" ng-show="panelModel.isOpen()">
            <header class="hide-small">
                <div class="display" ng-if="models.comparison.selected.start && models.comparison.selected.end">
                    <span class="timerange">
                    <span class="selection-label">TY</span>
                    <span class="time selection-start">{{ models.selection.selected.start.format("MMM DD, YYYY") }}</span>
                    to
                    <span class="time selection-end">{{ models.selection.selected.end.format("MMM DD, YYYY") }}</span>
                    </span>
                    <span class="separator">versus</span>
                    <span class="timerange">
                    <span class="selection-label">LY</span>
                    <span class="time comparison-start">{{ models.comparison.selected.start.format("MMM DD, YYYY") }}</span>
                    to
                    <span class="time comparison-end">{{ models.comparison.selected.end.format("MMM DD, YYYY") }}</span>
                    </span>
                </div>
                <div class="display" ng-if="!models.comparison.selected.start || !models.comparison.selected.end">
                    Selected
                    <span class="selection-start">{{ models.selection.selected.start.format("MMM DD, YYYY") }}</span>
                    to
                    <span class="selection-end">{{ models.selection.selected.end.format("MMM DD, YYYY") }}</span>
                </div>
                <div class="buttons">
                    <button class="button-cancel" ng-click="cancel()">cancel</button>
                    <button class="button-save" ng-click="save()">save</button>
                </div>
            </header>

            <section class="panel-left">
                <div class="comparison-mode-selector">
                    <div>
                        <span>Compare to</span>
                        <select class="compare-selector" ng-model="models.comparisonMode.mode" ng-options="x.id as x.label for x in models.comparisonMode.modes"></select>
                    </div>
                </div>
                <ul class="predefined-dates">
                    <li ng-repeat="action in actions track by $index" ng-if="!action.hide" ng-class="{disabled:action.disabled, section:action.section}">
                        <span class="predefined-date"
                              ng-click="onPresetClick(action)"
                              ng-class="{active:action.active}"
                              ng-bind-html="'{{ action.label }}'"></span>
                    </li>
                </ul>
            </section>

            <section class="panel-right hide-small">
                <nrf-datepickers ng-if="calendar" class="datepickers-selection"  title="current"    handlers="handlers.selection"  selected="models.selection.selected"  calendar="calendar" bounds="bounds"></nrf-datepickers>
                <nrf-datepickers ng-if="calendar" class="datepickers-comparison" title="compare to" handlers="handlers.comparison" selected="models.comparison.selected" calendar="calendar" bounds="bounds"></nrf-datepickers>
            </section>
        </article>

    </article>
    """
    link: (scope, element) ->
        moment = require 'moment'
        calendarType = CONFIG.datepicker?.type or 'nrf'
        scope.isGregorian = calendarType is 'gregorian'

        getDataBounds = ->
            scope.timeRange.fetch().then (bounds) ->
                hardStart = DateWrapper.CreateFromDate NRFYears[0].start
                hardEnd = DateWrapper.CreateFromDate do ->
                    [..., date, lastYear] = NRFYears
                    return date.end
                start  = try DateWrapper.CreateFromDate(bounds.start)
                end    = try DateWrapper.CreateFromDate(bounds.end)
                start ?= hardStart
                end   ?= hardEnd
                start = start.clamp(hardStart)
                if end.gt(hardEnd)
                    throw new Error("End Date `#{end.format()}` is outside supported calendar #{hardEnd.format()}.")
                return {start, end}

        updateComparisonDaterangeFromSelection = (mode) ->
            mode ?= 'lastYear'
            bounds = scope.bounds
            selectionRange  = scope.models.selection.selected
            comparisonRange = scope.models.comparisonMode.getComparisonTimerange(selectionRange, bounds)
            scope.models.comparison.select(comparisonRange)

        scope.onPresetClick = (action) ->
            action.onClick?()
            scope.save() if $window.innerWidth <= 768 # FIXME: this is a hack for when the widget is small on phones...

        scope.save = ->
            scope.selection.timestamp  = Utils.copy(scope.models.selection.filter)
            scope.comparison.timestamp = Utils.copy(scope.models.comparison.filter)
            scope.panelModel.close()

        scope.cancel = ->
            bounds = scope.bounds
            filters =
                selection:  scope.selection.timestamp
                comparison: scope.comparison.timestamp or scope.selection.timestamp
            scope.models.selection = new DateSelectModel({bounds, filter:filters.selection})
            scope.models.comparison = new DateSelectModel({bounds, filter:filters.comparison})
            scope.actions = new DateSelectActions(scope.models, scope.bounds)
            scope.panelModel.close()

        scope.handlers =

            selection:
                start: (date, shouldUpdateComparison) ->
                    {end} = scope.models.selection.selected
                    scope.models.selection.select(end:date) if not end or (date and end.lt(date))
                    scope.models.selection.select(start:date)
                    updateComparisonDaterangeFromSelection(scope.models.comparisonMode.mode) if shouldUpdateComparison
                    scope.actions = new DateSelectActions(scope.models, scope.bounds)
                end: (date, shouldUpdateComparison) ->
                    {start} = scope.models.selection.selected
                    scope.models.selection.select(start:date) if not start or (date and start.gt(date))
                    scope.models.selection.select(end:date)
                    updateComparisonDaterangeFromSelection(scope.models.comparisonMode.mode) if shouldUpdateComparison
                    scope.actions = new DateSelectActions(scope.models, scope.bounds)

            comparison:
                start: (date) ->
                    {end} = scope.models.comparison.selected
                    scope.models.comparison.select(end:date) if not end or (date and end.lt(date))
                    scope.models.comparison.select(start:date)
                    scope.actions = new DateSelectActions(scope.models, scope.bounds)
                end: (date) ->
                    {start} = scope.models.comparison.selected
                    scope.models.comparison.select(start:date) if not start or (date and start.gt(date))
                    scope.models.comparison.select(end:date)
                    scope.actions = new DateSelectActions(scope.models, scope.bounds)


        scope.panelModel = new DatepickerPanelViewModel()

        elementOutsideClick = null
        createOutsideClickAction = () -> new OutsideElementClick scope, element, ->
            return if scope.panelModel.isClosed()
            scope.cancel()

        scope.$watch 'selection.timestamp', (filter) ->
            return if not filter
            range = DateSelectModel.FilterToRange(filter)
            scope.filterDisplay =
                start: range.start.format('MMM DD YYYY')
                end:   range.end.format('MMM DD YYYY')

        scope.$watch 'panelModel.isOpen()', (isOpen) ->
            if isOpen

                if !elementOutsideClick
                    elementOutsideClick = createOutsideClickAction()
            else
                if elementOutsideClick
                    elementOutsideClick()
                    elementOutsideClick = null

            scope.isOpen = isOpen

        getInitialAction = ->
            DEFAULT_ACTION_ID = "last-complete-month"
            findActionById = (id) ->
                return _.find scope.actions, (action) -> action.id is id
            (new StorageAPI 'timeRange').then((api) -> api.get()).then (userActionId) ->
                configActionId = CONFIG.defaults?.timeRange
                actionId = do ->
                    result = userActionId or configActionId or DEFAULT_ACTION_ID
                    # FIXME: Probably need a better name for this option...
                    realTimeStartTime = CONFIG.defaults?.realTimeStartTime or 11
                    status = $rootScope.StatusMonitor.getStatus()
                    latestTransactionTimestamp = moment.utc(status?.latestTransactionTimestamp)
                    return 'last-complete-day' if result is 'dtd' and (moment().hour() < realTimeStartTime or moment().dayOfYear() < latestTransactionTimestamp.dayOfYear())
                    return result
                action = findActionById(actionId) or do ->
                    console.warn "Selected time range action `#{actionId}` was not found."
                    return scope.actions[0]
                return action

        initialize = (bounds) ->
            filter = scope.selection?.timestamp
            comparisonFilter = scope.comparison?.timestamp or filter
            scope.initialized = true
            scope.models = {}
            scope.actions = new DateSelectActions(scope.models, scope.bounds)
            scope.models.selection  = new DateSelectModel({bounds, filter})
            scope.models.comparison = new DateSelectModel({bounds, filter: comparisonFilter})
            scope.models.comparisonMode = scope.comparisonMode or new ComparisonModeSelectModel()
            scope.$watch 'models.comparisonMode.mode', updateComparisonDaterangeFromSelection

            return if scope.autoLoad is "false" or not scope.autoLoad
            getInitialAction().then (action) ->
                action?.onClick()
                scope.save()

        scope.$on '$destroy', \
            ($rootScope.$on 'query.refresh', ->
                scope.selection  ?= {}
                scope.comparison ?= {}
                initialize(scope.bounds) if not scope.initialized
            )

        scope.$watch 'timeRange', (TimeRange) ->
            return if not TimeRange
            getDataBounds().then (bounds) -> scope.bounds = bounds

        scope.$watch 'bounds', (bounds) ->
            return if not bounds or not (bounds.start and bounds.end)
            scope.calendar = new NRFCalendar(bounds.start, bounds.end)
            initialize(bounds) if scope.selection and scope.comparison

        scope.$watch 'models', -> scope.output?.models = scope.models
        scope.$watch 'actions', -> scope.output?.actions = scope.actions
        scope.$watch 'bounds', -> scope.output?.bounds = scope.bounds
