import _ from 'lodash';

import { CustomerAPIFactory } from '../customer.service';
import { ScrollToSelectedTransactionDirective } from './scroll-to-selected-transaction.directive';
import { CustomerDetailsControllerInstance } from './customer-details.controller';

const module = angular.module('42.controllers.customer.details', []);

module.config(function CustomerDetailsConfig($routeProvider: angular.route.IRouteProvider, ROUTES: any, CONFIG: any) {
    const routeId = 'customerDetails';
    const routes = CONFIG.routes ? CONFIG.routes[routeId] : {};
    const route = _.extend({}, ROUTES[routeId], _.pick(routes, 'label', 'url'));
    $routeProvider.when(route.url, route).otherwise({ redirectTo: '/' });
});

module.factory('CustomerAPI', CustomerAPIFactory());

module.controller('CustomerDetailsController', CustomerDetailsControllerInstance());

module.directive('scrollToSelectedTransaction', ScrollToSelectedTransactionDirective());
