# Assign the label titles to be the series names concatenated

do (Highcharts) ->
    labelYAxes = (chart, axisFormatter) ->
        for a in (chart.axes.filter (x) -> x.col != 'xAxis')
            if true in (a.series.map (x) -> x.visible)
                a.axisTitle?.element.innerHTML = "<tspan> #{axisFormatter(a)} </tspan>"
            else
                a.axisTitle?.element.innerHTML = ""

    # edit page source because Highcharts' setTitle is too slow, since it destroys the axes each time
    labelYAxesFromSeries = (chart) ->
        labelYAxes chart, (axis) ->
            titleArray = []
            for s in chart.series
                if s.visible and s.yAxis == axis
                    titleArray.push s.name
            return titleArray.join(', ')

    Highcharts.wrap Highcharts.Series.prototype, 'setVisible', (proceed) ->
        proceed.apply(this, Array.prototype.slice.call(arguments, 1))
        if @chart.labelFormatterOn
            labelYAxesFromSeries(@chart)
        else
            labelYAxes(@chart, (axis) -> axis.axisTitle.textStr)

    # relabel axes on init
    Highcharts.Chart.prototype.callbacks.push (chart) ->
        labelYAxes(chart, (axis) -> axis.axisTitle.textStr)

