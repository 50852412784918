import _ from 'lodash';
import * as echarts from 'echarts';
import { getSeriesMaxMinValue, getStateFullName, STATES } from './chart-echarts.helper';
import { ISeriesData, ISeriesMetricData } from './chart-echarts-wrapper.directive';
import { EChartOption } from 'echarts';

export type IMapSeries = EChartOption<echarts.EChartOption.SeriesMap> & { data: ISeriesData[] };
export interface ITooltipParams extends echarts.EChartOption.Tooltip.Format {
    metric: string;
    color: string;
    name: string;
}

export const EchartsMapChartModelInstance = () => [
    'ECHARTS_THEME',
    '$filter',
    function EchartsLineChartModelMethod(ECHARTS_THEME: any, $filter: angular.FilterFactory) {
        const ECHARTS_DEFAULT_MAP_OPTIONS = {
            scaleLimit: {
                min: 1,
                max: 1,
            } as EChartOption.SeriesCandlestick,
            textStyle: {
                fontFamily: ECHARTS_THEME.fontFamily,
            } as EChartOption.TextStyle,
            series: {} as IMapSeries,
            legend: {
                show: false,
                height: 0,
                inactiveColor: ECHARTS_THEME.axisLineColor,
                textStyle: {
                    color: ECHARTS_THEME.axisLabelColor,
                    fontSize: 10,
                },
            } as EChartOption.Legend,
            visualMap: {
                orient: 'horizontal',
                inRange: {
                    color: ['#aaa', '#40e0d0', '#7b68ee'],
                },
                text: ['High', 'Low'],
                textStyle: {
                    color: ECHARTS_THEME.axisLabelColor,
                    fontSize: 12,
                },
            },
            tooltip: {
                trigger: 'item',
                transitionDuration: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                axisPointer: {
                    type: 'shadow',
                    shadowStyle: {
                        color: 'rgba(0,0,0,0.03)',
                    },
                },
                textStyle: {
                    fontFamily: ECHARTS_THEME.fontFamily,
                    fontSize: 12,
                },
                formatter: undefined,
            } as EChartOption.Tooltip,
            emphasis: {
                label: {
                    show: true,
                },
            },
        };

        const defaultSeriesObj = {
            type: 'map',
            roam: true,
            map: 'USA',
            showLegendSymbol: false,
            emphasis: {
                label: {
                    show: false,
                },
            },
            textFixed: {
                Alaska: [20, -20],
            },
            data: [],
        } as EChartOption.SeriesMap;

        class EchartsMapChartModel {
            chartOptions: any;

            constructor(metrics: ISeriesMetricData[], data: Record<string, string | number>[]) {
                const chartOptions = _.cloneDeep(ECHARTS_DEFAULT_MAP_OPTIONS);

                chartOptions.visualMap.formatter = (valueParam: number) => {
                    const value = _.isNumber(valueParam) && !_.isNaN(valueParam) ? valueParam : 0;
                    return $filter('metric')(value ? value : 0, metrics[0]);
                };

                chartOptions.tooltip.formatter = params => {
                    const renderSeries = params => {
                        const value = _.isNumber(params.value) && !_.isNaN(params.value) ? params.value : 0;
                        const percent = params.data?.percent || '0%';

                        const templateValue = $filter('metric')(value ? value : 0, metrics[0]);

                        return `
                            <div class='tooltip'>
                                <h1 class='tooltip-header' style='color:${params.color}'>${params.name}</h1>
                                ${renderSeries(params)}
                            </div>
                        `;
                    };
                };

                const seriesObj = _.cloneDeep(defaultSeriesObj) as IMapSeries;

                let totalValue = 0;
                const dataStates: string[] = [];

                data.forEach(item => {
                    totalValue += item[metrics[0].field];
                    dataStates.push(getStateFullName(item['property0']) || '');
                });

                const statesWithoutValues = _.without(Object.keys(STATES), dataStates);
                const statesWithoutValuesData = statesWithoutValues.map(stateName => {
                    return {
                        metric: metrics[0],
                        name: stateName,
                        percent: 0,
                        value: 0,
                    };
                });

                seriesObj.data = data.map(item => {
                    const metricValue = item[metrics[0].field];
                    const value = _.isNumber(metricValue) && !_.isNaN(metricValue) ? metricValue : 0;

                    return {
                        metric: metrics[0],
                        name: getStateFullName(item['property0'] as string),
                        percent: $filter('percent')(value / totalValue),
                        value,
                    };
                }) as ISeriesData[];

                seriesObj.data = seriesObj.data.concat(statesWithoutValuesData);

                const { max, min } = getSeriesMaxMinValue(seriesObj);

                chartOptions.visualMap.min = min;
                chartOptions.visualMap.max = max;

                chartOptions.series = seriesObj;

                this.chartOptions = chartOptions;
            }
        }

        return EchartsMapChartModel;
    },
];
