angular.module('42.modules.datepicker.nrf', ['42.modules.libs.utils'])
    .constant 'Months',                         require './constants/months.constant.coffee'
    .constant 'NRFCounts',                      require './constants/nrfcounts.constant.coffee'
    .directive 'nrfDatepickers',                require './directives/nrfDatepickers.directive.coffee'
    .directive 'nrfDatepickerMonth',            require './directives/nrfDatepickerMonth.directive.coffee'
    .directive 'nrfDateInput',                  require './directives/nrfDateInput.directive.coffee'
    .directive 'smartGroupsFilterTime',         require './directives/smartGroupsFilterTime.directive.coffee'
    .directive 'nrfCalendarMonthSelect',        require './directives/nrfCalendarMonthSelect.directive.coffee'
    .factory 'DatepickerPanelViewModel',        require './factories/datepickerpanelviewmodel.factory.coffee'
    .factory 'DateSelectAction',                require './factories/dateselectaction.factory.coffee'
    .factory 'DateSelectActions',               require './factories/dateselectactions.factory.coffee'
    .factory 'DateSelectModel',                 require './factories/dateselectmodel.factory.coffee'
    .factory 'NRFCalendar',                     require './factories/nrfcalendar.factory.coffee'
    .factory 'NRFCalendarMonthSelectViewModel', require './factories/nrfcalendarmonthselectviewmodel.factory.coffee'
    .factory 'NRFCalendarViewModel',            require './factories/nrfcalendarviewmodel.factory.coffee'
    .factory 'NRFDate',                         require './factories/nrfdate.factory.coffee'
    .factory 'CalDate',                         require './factories/caldate.factory.coffee'
    .factory 'ComparisonModeSelectModel',       require './factories/comparisonmodeselectmodel.factory.coffee'
    .factory 'DateWrapper',                     require './factories/datewrapper.factory.coffee'
    .service 'DateSelectActionsOverrides',      require './services/dateselectactionsoverrides.service.coffee'
    .service 'NRFDateUtils',                    require './services/nrfdateutils.service.coffee'
    .service 'NRFMonths',                       require './services/nrfmonths.service.coffee'
    .service 'NRFYears',                        require './services/nrfyears.service.coffee'
