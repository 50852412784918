import _ from 'lodash';
import moment from 'moment';
import { IQuery, IQueryFilterItem, IQueryComparison, IQueryFiltersKeys } from '../../lib/types';
import { IHierarchyDict, IHierarchyItem } from '../../services/hierarchy.service';

export type TCollapseTypes = 'stores' | 'items' | 'acquisitions' | 'warehouses' | 'accounts';
export type ICollapse = {
    stores?: boolean;
    items?: boolean;
    acquisitions?: boolean;
    warehouses?: boolean;
    accounts?: boolean;
};

export interface IMappedFiltersItem {
    propertyName: string;
    filters: string[];
    included: boolean;
    propertyKey: string;
    filterType: string;
    isCollapsed: boolean;
    onlyPropertyName: boolean;
    isLastFilter: boolean;
}

export type IMappedFiltersItemDict = Record<string, IMappedFiltersItem>;

interface IDisplayDict {
    [key: string]: {
        includedFilters: string[];
        excludedFilters: string[];
        propertyKey: string;
        table: string;
    };
}

export interface IQueryTableProperty {
    propertyKey: string;
    table: string;
    includedFilters: string[];
    excludedFilters: string[];
}
export type IQueryTableProperties = Record<string, IQueryTableProperty>;

const buildHierarchySubqueryKeyList = (
    hierarchy: IHierarchyDict,
    hierarchyKey: string,
    subquery: IQueryFilterItem,
): IQueryTableProperties | null => {
    subquery.$and = subquery.$and || [];

    const result = subquery.$and.reduce((result, property: { [index: string]: any }) => {
        const column = Object.keys(property)[0];
        const key = `${hierarchyKey}.${column}`;
        const hierarchyItem = hierarchy[key] as IHierarchyItem;
        const name = hierarchyItem && hierarchyItem.label ? hierarchyItem.label : column;

        result[name] = {
            propertyKey: column,
            table: hierarchyKey,
            includedFilters: property[column].$in,
            excludedFilters: property[column].$nin,
        };

        return result;
    }, {} as IQueryTableProperties);

    return Object.keys(result).length === 0 ? null : result;
};

export interface ITransactionTimestamp {
    end: Date;
    start: string;
}
export type IQueryTablePropertiesToBeDisplayed = null | ITransactionTimestamp | IQueryTableProperties;

// Collect the data to be displayed
const buildKeyDataListToBeDisplayed = (
    key: string,
    subquery: IQueryFilterItem | IQueryComparison | null = null,
    hierarchy: IHierarchyDict,
): IQueryTablePropertiesToBeDisplayed => {
    if (!subquery) {
        return null;
    }

    if (key === 'transactions') {
        const { $gte: start, $lt: end } = (subquery as IQueryComparison).timestamp || {};

        if (!start || !end) {
            return null;
        }

        return {
            start,
            end: moment(end).subtract(1, 'minute').toDate(),
        };
    }

    return buildHierarchySubqueryKeyList(hierarchy, key, subquery as IQueryFilterItem);
};

export interface IQueryTables {
    [key: string]: IQueryTablePropertiesToBeDisplayed;
}

export const mapQueryByTables = (queryObj: IQuery, hierarchy: IHierarchyDict): IQueryTables => {
    return Object.keys(queryObj.filters || {}).reduce((propertyFilters, key) => {
        const filter = queryObj.filters && queryObj.filters[key as IQueryFiltersKeys];

        const keyDataList = buildKeyDataListToBeDisplayed(key, filter, hierarchy);

        if (keyDataList) {
            propertyFilters[key] = keyDataList;
        }

        return propertyFilters;
    }, {} as IQueryTables);
};

export interface IRendererFilter {
    collapsed: boolean;
    onlyPropertyName: boolean;
    size: number;
}
export interface IRenderedQuery {
    propertiesFiltersItems?: IMappedFiltersItem[];
    propertiesAdjustement?: Record<string, IRendererFilter>;
}

const getPropertyFilterSize = (filterRepresentation: IMappedFiltersItem) => {
    const filtersStringSize = filterRepresentation.filters.reduce((acc, filterName) => acc + filterName, '');
    return `${filterRepresentation.propertyName}${filtersStringSize}`.length;
};

const renderQueryAsHtml = (
    data: Record<string, IDisplayDict>,
    propertiesRendered: Record<string, IRendererFilter> = {},
    onlyPropertyName: boolean,
): IRenderedQuery => {
    const propertiesFiltersItems: IMappedFiltersItem[] = [];

    const propertiesAdjustement = Object.keys(data).reduce((acc, key, index, list) => {
        const propertyRendered = propertiesRendered[key] || { collapsed: false };
        const filterData = data[key];

        const filterRepresentation = {
            propertyName: key,
            filters: filterData.includedFilters || filterData.excludedFilters,
            included: !!filterData.includedFilters,
            propertyKey: filterData.propertyKey,
            filterType: filterData.table,
            isCollapsed: propertyRendered.collapsed,
            isLastFilter: index === list.length - 1,
            onlyPropertyName,
        } as unknown as IMappedFiltersItem;

        propertiesFiltersItems.push(filterRepresentation);

        acc[key] = {
            collapsed: propertyRendered.collapsed,
            size: getPropertyFilterSize(filterRepresentation),
            onlyPropertyName,
        };

        return acc;
    }, {} as Record<string, any>);

    return {
        propertiesFiltersItems,
        propertiesAdjustement,
    };
};

export const hasEnabledTables = (data: IQueryTables): boolean => {
    const EXCLUDED_TABLES = ['transactions'];

    return Object.keys(data).findIndex(property => !(EXCLUDED_TABLES.indexOf(property) > -1)) > -1;
};

const createPropertiesList = (data: Record<string, any>) => {
    const EXCLUDED_TABLES = ['transactions'];
    const filteredProperties = Object.keys(data).filter(key => EXCLUDED_TABLES.indexOf(key) === -1);

    return filteredProperties.reduce((acc, key) => {
        return {
            ...acc,
            ...data[key],
        };
    }, {} as Record<string, any>);
};

const findNextPropertyToCollapse = (propertiesRendered: Record<string, IRendererFilter>) => {
    return Object.keys(propertiesRendered).reduce(
        (acc, propertyName) => {
            const renderedProperty = propertiesRendered[propertyName];
            return !renderedProperty.collapsed && acc.size < renderedProperty.size
                ? {
                      propertyName,
                      size: renderedProperty.size,
                  }
                : acc;
        },
        { propertyName: undefined, size: -1 } as { propertyName: string | undefined; size: number },
    );
};

const findNextPropertyToExpand = (propertiesRendered: Record<string, IRendererFilter>) => {
    return Object.keys(propertiesRendered).reduce(
        (acc, propertyName) => {
            const renderedProperty = propertiesRendered[propertyName];

            return renderedProperty.collapsed && (acc.size === undefined || acc.size >= renderedProperty.size)
                ? {
                      propertyName,
                      size: renderedProperty.size,
                  }
                : acc;
        },
        { propertyName: undefined, size: undefined } as { propertyName: string | undefined; size: number | undefined },
    );
};

export const buildRenderedQuery = (
    data: IQueryTables,
    propertiesRendered: Record<string, IRendererFilter>,
    shouldDecreaseSize: boolean | null,
): IRenderedQuery | undefined => {
    let onlyPropertyName = false;

    if (_.isEmpty(data)) {
        return {
            propertiesFiltersItems: undefined,
            propertiesAdjustement: undefined,
        };
    }

    if (propertiesRendered && !_.isEmpty(propertiesRendered)) {
        if (!shouldDecreaseSize) {
            const propertyToExpand = findNextPropertyToExpand(propertiesRendered);

            if (!propertyToExpand.propertyName) {
                return undefined;
            }

            propertiesRendered[propertyToExpand.propertyName as string].collapsed = false;
        } else {
            const allPropertiesInOnlyPropertyName = Object.keys(propertiesRendered).find(
                propertyName => propertiesRendered[propertyName].onlyPropertyName,
            );

            if (allPropertiesInOnlyPropertyName) {
                return undefined;
            }

            const propertyToCollapsed = findNextPropertyToCollapse(propertiesRendered);
            if (propertyToCollapsed.propertyName) {
                propertiesRendered[propertyToCollapsed.propertyName as string].collapsed = true;
            } else {
                onlyPropertyName = true;
            }
        }
    }

    const propertiesToPresent = createPropertiesList(data);
    return renderQueryAsHtml(propertiesToPresent, propertiesRendered, onlyPropertyName);
};

export const buildTimestampModel = (tablesProperties: IQueryTables) => {
    if (!tablesProperties.transactions) {
        return null;
    }

    const { start, end } = tablesProperties.transactions;
    const [startDate, endDate] = [start, end].map(date => moment(date as string).format('MMM DD yyyy'));

    return {
        start: startDate,
        end: endDate,
    };
};
