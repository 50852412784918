export function HierarchySelectDirective() {
    return {
        restrict: 'E',
        scope: {
            model: '=',
        },
        replace: true,
        template: `
            <article class="hierarchy-select">
                <div class="select-title">
                    Hierarchy
                </div>
                <div class="hierarchy-select-dropdown">
                    <i class="icon-down-open-mini"></i>
                    <select ng-options="x.label for x in model.available" ng-model="model.selected">
                </div>
            </article>
        `,
    };
}

const module = angular.module('42.modules.hierarchy', []);
module.directive('hierarchySelect', [HierarchySelectDirective]);
