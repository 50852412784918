import { cloneDeep } from 'lodash';
import { deepStripAngularProperties } from '../../lib/utils-helper';
import { SchedulingServiceAPI } from '../../lib/api';
import { IQueryFilterItem } from '../../lib/types';

const module = angular.module('42.modules.services.scheduling', []);

export interface Schedule {
    templateId: string;
    expiresAfter: number;
    expression: string;
    id: string;
    jobId: string;
    label: string;
    namespace: string;
    objectVersion: string;
    schemaVersion: number;
    timezone: string;
    updatedAt: string;
    data: {
        organizationId: string;
        userId: string;
        reportId: string[];
        target: {
            bcc: string;
            body: string;
            filename: string;
            recipients: string[];
        };
    };
}

export interface IReportMetrics {
    category: string;
    cellFilter: string;
    field: string;
    headerGroup: string;
    headerName: string;
    queryName: string;
}

export interface IReportSort {
    field: string;
    limit: number;
    order: number;
    property: string;
}

export interface IReport {
    templateId: string;
    description: string;
    id: string;
    label: string;
    updatedAt: number;
    data: {
        columnStyle: string;
        filterItems: { items: IQueryFilterItem };
        filterStores: { stores: IQueryFilterItem };
        hierarchyStore: string[];
        metrics: IReportMetrics[];
        sort: IReportSort[];
        timerange: string;
        timerangeComparisonMode: string;
    };
}

export interface ISchedulingAPI {
    jobs: {
        get: (jobId: string) => Promise<any>;
        list: () => Promise<any>;
        run: (report: any) => Promise<any>;
    };
    schedules: {
        create: (schedule: Schedule) => Promise<any>;
        update: (schedule: Schedule) => Promise<any>;
        run: (schedule: Schedule) => Promise<any>;
        delete: (schedule: Schedule | string) => Promise<any>;
        list: () => Promise<any>;
        get: () => Promise<any>;
    };
}

export interface ISchedulingAPIFactory {
    new (args: { organization: string }): Promise<ISchedulingAPI>;
}

module.factory('42.services.scheduling.api', [
    '$q',
    function SchedulingAPIFactory($q: angular.IQService) {
        return ({ organization: organizationId }: { organization: string }) => {
            return $q.when(SchedulingServiceAPI.get()).then(api => {
                return {
                    jobs: {
                        get: (jobId: string) => {
                            return $q.when(api.organizations.GetJob({ organizationId, jobId }));
                        },
                        list: () => {
                            return $q.when(api.organizations.ListJobs({ organizationId }));
                        },
                        run: (report: IReport) => {
                            report = cloneDeep(report);
                            report = deepStripAngularProperties(report);
                            const jobId = report.templateId;
                            return $q.when(api.organizations.RunJob({ organizationId, jobId, data: { report } }));
                        },
                    },
                    schedules: {
                        create: (schedule: Schedule) => {
                            schedule = deepStripAngularProperties(schedule);
                            return $q.when(api.organizations.CreateSchedule({ organizationId, schedule }));
                        },
                        update: (schedule: Schedule) => {
                            const data = { organizationId, scheduleId: schedule.id, schedule };
                            return $q.when(api.organizations.UpdateSchedule(data));
                        },
                        delete: (scheduleId: string | Schedule) => {
                            scheduleId = typeof scheduleId !== 'string' ? scheduleId.id : scheduleId;
                            return $q.when(api.organizations.DeleteSchedule({ organizationId, scheduleId }));
                        },
                        list: () => {
                            return $q.when(api.organizations.ListSchedules({ organizationId }));
                        },
                        get: () => {
                            return $q.when(api.organizations.GetSchedule({ organizationId }));
                        },
                        run: (schedule: Schedule) => {
                            schedule = cloneDeep(schedule);
                            schedule = deepStripAngularProperties(schedule);
                            const scheduleId = schedule.templateId;
                            return $q.when(
                                api.organizations.RunSchedule({ organizationId, scheduleId, data: schedule }),
                            );
                        },
                    },
                };
            });
        };
    },
]);
