Highcharts = require 'highcharts'
module = angular.module('42.modules.highcharts', [])


module.run (HIGHCHARTS_GLOBAL_OPTIONS) ->
    Highcharts.setOptions(HIGHCHARTS_GLOBAL_OPTIONS)


module.constant 'HIGHCHARTS_GLOBAL_OPTIONS',
    chart:
        backgroundColor: "white"
        borderColor: ""
        borderWidth: 0
        spacingLeft: 20
        spacingRight: 50
        spacingBottom: 20
        className: ""
        plotBackgroundColor: "white"
        plotBorderColor: ""
        plotBorderWidth: 0
    colors: [
        '#7b68ee',
        '#40e0d0',
        '#9FE18D',
        '#FFB665',
        '#ff6666',
        '#FB85D0',
        '#CBACF6',
        '#40e0d0',
        '#9FE18D',
        '#FFB665',
        '#ff6666',
    ]
    credits:
        enabled: false
    xAxis:
        gridLineColor: "#f5f5f5" #vertical lines
        gridLineWidth: 1
        labels:
            y: 20
            style:
                fontSize: "10px"
                fontWeight: "400"
                textTransform: "uppercase"
                color: "#a1aeb2"
        lineColor: "#ccc"
        tickColor: "#ddd"
        title:
            style:
                color: "rgb(161, 174, 178)"
                fontWeight: "bold"
                fontSize: "30px"
                fontFamily: "'Open Sans', Helvetica, sans-serif"
    yAxis:
        gridLineColor: "#ccc"
        gridLineWidth: 1
        gridLineDashStyle: 'shortdot'
        labels:
            y: 3
            # useHTML:true  # breaks the axis animations
            style:
                fontSize: "10px"
                fontWeight: "800"
                textTransform: "uppercase"
                color: "#a1aeb2"
                'min-width': '40px'
                'text-align':'right'
        lineColor: "#a1aeb2"
        minorTickInterval: null
        tickWidth: 0
        title:
            style:
                color: "rgb(161, 174, 178)"
                fontWeight: "bold"
                fontSize: "12px"
                fontFamily: "'Open Sans', Verdana, sans-serif"
    tooltip:
        backgroundColor: "rgba(0, 0, 0, 0.8)"
        borderWidth: 0
        shadow: false
        hideDelay: 10
        # crosshairs:
        #     width: 5
        #     color: "#299999"
        style:
            color: "white"
            padding: 10
    legend:
        itemHoverStyle:
            color: "#73838e"
        itemStyle:
            fontWeight: "400"
            color: "#9eabaf"
            fontSize: "12px"
            fontFamily: "'Open Sans', Verdana, sans-serif"
    # special colors for some of the
    legendBackgroundColor: "rgba(0, 0, 0, 0.5)"
    legendBackgroundColorSolid: "rgb(35, 35, 70)"
    dataLabelsColor: "#444"
    textColor: "#C0C0C0"
    maskColor: "rgba(255,255,255,0.3)"
