export class StorePopup {
    eGui!: HTMLDivElement;

    constructor(model: GeoJSON.Feature<GeoJSON.Geometry>) {
        if (!model.properties || !model.properties.address) {
            return;
        }

        const properties = model.properties;
        const address = JSON.parse(properties.address);

        this.eGui = document.createElement('div');
        this.eGui.classList.add('marker-popup');
        this.eGui.innerHTML = `
            <div class="title">${properties.name}</div>
            <div class="address">
                <span class="address-street">${address.street}</span>
                <span class="address-city">, ${address.city}</span>
                <span class="address-region">, ${address.region}</span>
                <span class="address-country">, ${address.country} </span>
                <span class="address-postal-code"> ${address.postalCode}</span>
            </div>
        `;
    }

    getGui() {
        return this.eGui;
    }
}
