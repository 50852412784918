import { CustomerStatsItem, CUSTOMER_STATS_CONFIG } from './customer-main/customer-stats.directive';
import { ICustomerStatsResponse } from './customer.types';

export const buildStatsViewModel = (
    data: ICustomerStatsResponse,
    $filter: angular.IFilterFunction,
): CustomerStatsItem[] => {
    return Object.keys(CUSTOMER_STATS_CONFIG).map(key => {
        const field = CUSTOMER_STATS_CONFIG[key];

        return Object.keys(field).reduce((acc, elemKey) => {
            acc[elemKey] = typeof field[elemKey] === 'string' ? field[elemKey] : field[elemKey](data, $filter);
            return acc;
        }, {} as CustomerStatsItem);
    });
};

export const compileSubfilters = (subfilters: Record<string, any>): Record<string, any> => {
    return Object.keys(subfilters).reduce((acc, subFilterKey) => {
        const sub = subfilters[subFilterKey] || {};
        Object.keys(sub).forEach(subKey => {
            acc[subKey] = sub[subKey];
        });

        return acc;
    }, {} as Record<string, any>);
};
