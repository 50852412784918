import type { IConfigObj, IConfigFlags } from './types';
import { ConfigAPI } from './config-api';

export const normalize = (
    orgConfig: { flags?: Partial<IConfigFlags> },
    userConfig?: { flags?: Partial<IConfigFlags> },
) => {
    const orgFlags = orgConfig?.flags ?? {};
    const userFlags = userConfig?.flags ?? {};
    return {
        map: orgFlags.showMap ?? false,
        metrics: orgFlags.showMetrics ?? true,
        customers: orgFlags.showCustomers ?? true,
        stores: orgFlags.showStores ?? true,
        transactions: orgFlags.showTransactions ?? false,
        storestatus: orgFlags.showStoreStatus ?? false,
        reporting: userFlags.showReporting ?? orgFlags.showReporting ?? false,
        kiosk: userFlags.showKiosk ?? orgFlags.showKiosk ?? false,
    };
};
export type ConfigPageFlags = ReturnType<typeof normalize>;

export const fetch = (orgConfig?: IConfigObj) => {
    return ConfigAPI.get().then(api => {
        return Promise.all([
            orgConfig ?? api.organization.get(),
            api.user.get(),
            //
        ]).then(([orgConfig, userConfig]) => {
            return normalize(orgConfig, userConfig);
        });
    });
};
