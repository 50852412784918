
Highcharts = require 'highcharts'
require('highcharts.exporting')(Highcharts)
require('highcharts.offline-exporting')(Highcharts)
require('highcharts.drilldown')(Highcharts)
require('highcharts.nodata')(Highcharts)
require('highcharts.data')(Highcharts)
require('highcharts.funnel')(Highcharts)
window.Highcharts = Highcharts

require('label_formatter.coffee')
require('tick_positioner.coffee')
require('highcharts.canvas')
require 'canvas-tools'
_ = require('../lib/lodash-helper.ts')


module = angular.module '42.controllers.store', []
module.constant 'hasCalendarTable', [true]
module.config ($routeProvider, ROUTES, CONFIG) ->
    routeId = 'stores'
    route = _.extend {}, ROUTES[routeId], _.pick(CONFIG.routes?[routeId], 'label', 'url')
    $routeProvider.when route.url, route


module.controller 'StoreController', ($rootScope, $scope, CONFIG) ->
    $scope.query = {}

module.directive 'storeActionsPanelOrder', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: """
        <article class="model-select model-select-small">
            <span class="label">Showing</span>
            <span class="selected">
                <span class="property">
                    {{ model.selected.sortOrder.label }}
                    <i class="icon-down-open-mini"></i>
                </span>
            </span>
            <select ng-options="order.label for order in model.available.sortOrder"
                    ng-model="model.selected.sortOrder"> </select>
        </article>
    """

module.filter 'maximum', -> (arr, max, enable) ->
    arr?.filter (x) -> enable or x <= max

module.directive 'storeActionsPanelLimit', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="model-select model-select-small">
        <span class="label">Limit</span>
        <span class="selected">
            <span class="property">
                {{ model.selected.limitBy }}
                <i class="icon-down-open-mini"></i>
            </span>
        </span>
        <select ng-options="count for count in model.available.limitBy | maximum:50:model.selected.timeGrouping.chartType == 'barchart'"
                ng-model="model.selected.limitBy"
                </select>
    </article>
    """

module.directive 'storeActionsPanelMetric', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: """
        <article class="model-select model-select-med">
            <span class="label" ng-if="model.selected.timeGrouping.chartType == 'barchart'">Sort By</span>
            <span class="label" ng-if="model.selected.timeGrouping.chartType == 'timeseries'">Metric</span>
            <span class="selected">
                <span class="property">
                    {{ model.selected.metric.label }}
                    <i class="icon-down-open-mini"></i>
                </span>
            </span>
            <select ng-options="property as (property.label) for property in model.available.metric"
                    ng-model="model.selected.metric"></select>
        </article>
        """

module.directive 'storeTimeGrouping', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class='model-select model-select-small'>
        <span class="label">Time Grouping</span>
        <span class="selected">
            <span class="property">
                {{ model.selected.timeGrouping.label }}
                <i class='icon-down-open-mini'></i>
            </span>
        </span>
        <select ng-options="timegroup.label for timegroup in model.available.timeGrouping"
                ng-model="model.selected.timeGrouping"
                ng-change="model.selected.limitBy = model.selected.limitBy > 10 ? 10 : model.selected.limitBy">
        </select>
    </article>
    """


module.directive 'storeFunnelState', ($rootScope, Utils) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="metrics-funnel-breadcrumb">
        <section class="funnel-state">
            <header>
                <h1>Selected Filters</h1>
                <button class="reset"
                    ng-if="model.funnel.nodes.length > 0"
                    ng-click="model.resetFilters()">reset
                </button>
            </header>
            <ul class="pellets">
                <li class="funnel-node filter-funnel-node">
                    <span class="pellet pellet-side-filter">
                        <span class="property">Selected Group</span>
                        <span class="value">{{ filterLabel }}</span>
                    </span>
                </li>
                <li class="funnel-node" ng-repeat="node in model.funnel.nodes"
                                        ng-click="model.applyFilters(node)"
                                        ng-class="{selected:model.funnel.selected == node}">
                    <span class="separator"><i class="icon-right-thin"></i></span>
                    <div class="pellet">
                        <span class="property">{{ node.label }}</span>
                        <span class="value">{{ node.choice }}</span>
                    </div>
                </li>
            </ul>
        </section>
    </article>
    """
    link: (scope) ->
        $rootScope.$watch 'smartGroupsService.selected.getModel().name', (name) ->
            scope.filterLabel = name or ""


module.directive 'storeFunnelProperties', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="metrics-funnel-breadcrumb">
        <section class="available-properties">
            <header>
                <h1>Group By</h1>
                <span class="hint">(click a pellet to view the values, and click on a chart column to drill down)</span>
            </header>
            <ul class="pellets">
                <li class="pellet available-property"
                    ng-repeat="property in model.available.grouping"
                    ng-click="model.selected.grouping = property"
                    ng-if="!model.nodeInFunnel(property)"
                    ng-class="{selected:model.selected.grouping.id == property.id}">
                    <span class="property-label">{{ property.label }}</span>
                </li>
            </ul>
        </section>
    </article>
    """


module.directive 'storeActionsPanel', (DataDescriptors) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: """
        <article class="store-actions-panel">
            <div class="actions-panel-row">
                <div class="selected-filters">
                    <store-funnel-state model="model"> </store-funnel-state>
                </div>
                <div class="dropdown-actions">
                    <store-actions-panel-limit model="model"> </store-actions-panel-limit>
                    <store-actions-panel-order model="model"> </store-actions-panel-order>
                    <store-actions-panel-metric model="model"> </store-actions-panel-metric>
                    <store-time-grouping model="model" ng-if="showTimeGrouping"></store-time-grouping>
                </div>
            </div>
            <div class="actions-panel-row">
                <store-funnel-properties model="model"> </store-funnel-properties>
            </div>
        </article>
        """
    link: (scope) ->
        DataDescriptors.fetch().then (descriptors) ->
            scope.showTimeGrouping = !!descriptors.calendar


module.directive 'storeTextRow', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: """
        <article class="store-text-row">
            <p class="natural-language">
                Showing the
                <span class="limit"> {{ model.selected.limitBy }}</span>
                <span class="grouping"> {{ model.selected.grouping.plural }}</span>
                with the
                <span class="order">{{ model.selected.sortOrder.label }}</span>
                <span class="metric"> {{ model.selected.metric.label }}</span>
            </p>

            <section class="actions">

                <button class="chart-export">
                    <i class="icon-export">export chart</i>
                </button>

                <button class="stacked-area" ng-class="{selected:model.selected.stacking}"
                    ng-click="model.selected.stacking = true"
                    ng-if="model.selected.timeGrouping.chartType == 'timeseries'">
                    <i class="icon-chart-area">Stacked Area</i>
                </button>

                <button class="line" ng-class="{selected:!model.selected.stacking}"
                    ng-click="model.selected.stacking = false"
                    ng-if="model.selected.timeGrouping.chartType == 'timeseries'">
                    <i class="icon-chart-line">Line</i>
                </button>
            </section>
        </article>
        """


module.directive "storeHighcharts", ($rootScope, $q, Hierarchy, QueryMetrics, HourProperty, HighchartsModel, HighchartsChartFactory) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="store-chart">
      <div class="loadable" promise-tracker="store-chart"></div>
      <main class="store-chart-container"></main>
    </article>
    """
    link: (scope, element) ->
        chartContainer = $(element).find('.store-chart-container')
        chart = new HighchartsChartFactory(chartContainer, "barchart")

        refreshHierarchy = ->
            $q.all([
                Hierarchy.fetch()
                QueryMetrics.fetch()
                HourProperty.fetch()
            ]).then ([hierarchy, metrics, hourProperty]) ->
                hierarchy.all.push(hourProperty) if hourProperty
                scope.model = new HighchartsModel(hierarchy.all, chart, scope.model, metrics)
                scope.$watch 'model.selected', scope.model.refreshQuery, true

        listeners = {}

        listeners.root = \
        $rootScope.$watch 'initialized', (initialized) ->
            return if not initialized
            refreshHierarchy()
            listeners.hierarchy = $rootScope.$watch 'hierarchyModel.selected.id', refreshHierarchy, true
            listeners.hierarchy = $rootScope.$watch 'currencyModel.selected.symbol', refreshHierarchy

            flags = {firstSelected:true, firstQueryChange:true}
            listeners.smartGroup = $rootScope.$watch 'smartGroupsService.selected', (selected, prev) ->
                listeners.query?()
                flags.firstQueryChange = not flags.firstSelected
                flags.firstSelected = false
                listeners.query = $rootScope.$on 'query.refresh', ->
                    refreshHierarchy() if flags.firstQueryChange
                    scope.model.refreshQuery() if not flags.firstQueryChange
                    flags.firstQueryChange = false

        scope.$on '$destroy', ->
            Object.keys(listeners).forEach (k) -> listeners[k]?()
            listeners = {}
            chart.cleanup()



module.service 'Stores', (QueryServiceAPI) ->
    fetch: (query) ->
        (new QueryServiceAPI).then (api) -> api.query.findStores(query)


module.factory 'HighchartsChartFactory', ($rootScope, Utils, HighchartsConfig) ->
    return (element, type) ->  # element is the chart container
        chart = null
        latestQueryHash = null
        refresh: (query, model) ->
            latestQueryHash = Utils.object.hash(query)
            HighchartsConfig.fetch(query, model).then (config) ->
                return if Utils.object.hash(query) isnt latestQueryHash
                config.chart.renderTo = $(element).get(0)
                chart = new Highcharts.Chart(config)
                $('.highcharts-button').remove()
                exportButton = $('.chart-export').off('click')
                exportButton.click -> chart.exportChartLocal(type:'image/png')
            .catch (error) ->
                console.error error
                chart?.destroy()
                chart = null
        cleanup: ->
            chart?.destroy()
            $('.chart-export').off('click')
            $(element).empty()


module.constant 'ChartsMetrics', [
    'net_sales'
    'gross_sales'
    'growth_net_sales_prev'
    'growth_net_sales'
    'gross_dollar_per_transaction'
    'gross_sales_units_per_transaction'
    'conversion'
    'growth_conversion_prev'
]

module.constant 'TimeGroupings', [
    label: 'None'
    property: []
    chartType: 'barchart'
    numSorters: 0
    tooltipFormatter: (point) ->
        "#{point.value0}"
,
    label: 'Day'
    property: ['calendar.year', 'calendar.day', 'calendar.timestamp', 'calendar.week', 'calendar.day_of_week']
    chartType: 'timeseries'
    numSorters: 2
    pointFormatter: (point) -> "#{point.calendar__year} W#{Number(point.calendar__week) + 1}|#{Number(point.calendar__day_of_week) + 1}"
    tooltipFormatter: (point) -> "#{point.calendar__timestamp}"
,
    label: 'Week'
    property: ['calendar.year', 'calendar.week']
    chartType: 'timeseries'
    numSorters: 2
    pointFormatter: (point) -> "#{point.calendar__year} W#{Number(point.calendar__week) + 1}"
    tooltipFormatter: (point) -> "week #{Number(point.calendar__week) + 1} of #{point.calendar__year}"   # would be nice if we showed "week of YYYY-MM-DD"
,
    label: 'Month'
    property: ['calendar.year', 'calendar.month', 'calendar.month_label']
    chartType: 'timeseries'
    numSorters: 2
    pointFormatter: (point) -> "#{point.calendar__year} #{_.capitalize(point.calendar__month_label)}"
    tooltipFormatter: (point) -> "#{_.capitalize(point.calendar__month_label)} #{point.calendar__year}"
,
    label: 'Quarter'
    property: ['calendar.year', 'calendar.quarter']
    chartType: 'timeseries'
    numSorters: 2
    pointFormatter: (point) -> "#{point.calendar__year} Q#{Number(point.calendar__quarter) + 1}"
    tooltipFormatter: (point) -> "#{point.calendar__year} Q#{Number(point.calendar__quarter) + 1}"
,
    label: 'Season'
    property: ['calendar.year', 'calendar.season']
    chartType: 'timeseries'
    numSorters: 2
    pointFormatter: (point) -> "#{point.calendar__year} S#{Number(point.calendar__season) + 1}"
    tooltipFormatter: (point) -> "#{point.calendar__year} Q#{Number(point.calendar__season) + 1}"
,
    label: 'Year'
    property: ['calendar.year']
    chartType: 'timeseries'
    numSorters: 1
    pointFormatter: (point) -> "#{point.calendar__year}"
    tooltipFormatter: (point) -> "#{point.calendar__year}"
]


module.factory 'HighchartsModel', ($rootScope, promiseTracker, Utils, ChartsMetrics, QueryMetrics, TimeGroupings, CONFIG) -> class HighchartsModel

    constructor: (all, chart, previousModel, availableMetrics) ->

        getAvailableMetrics = ->

            metrics = CONFIG.defaults?.stores?.kpis or CONFIG.views?.stores?.kpis
            metrics ?= do ->
                defaults = CONFIG.views?.sales?.kpis or []
                return defaults.flatMap (x) -> [
                    x,
                    "growth_#{x}_prev"
                ]
            metrics = _.uniq(metrics)

            return _.compact metrics.map (x) ->
                metric = _.find availableMetrics, {field: x}
                return null if not metric
                id:    metric.field
                type:  metric.cellFilter
                group: metric.headerGroup
                name:  metric.headerName
                label: do ->
                    return "#{metric.headerGroup} - #{metric.headerName}" if metric.headerGroup and metric.headerName
                    return metric.headerGroup or metric.headerName

        @available =
            grouping: Utils.copy(all)
            metric: getAvailableMetrics()
            limitBy: [5, 10, 20, 50, 100, 1000]
            sortOrder: [
                {id: -1, label: 'Highest'}
                {id:  1, label: 'Lowest'}
            ]
            timeGrouping: TimeGroupings
            stacking: [false, true]
            select: (key, defaultIndex = 0) ->
                defaultValue = do =>
                    return defaultIndex if not _.isNumber(defaultIndex)
                    return @[key][defaultIndex]
                return defaultValue if not previousModel
                prev = previousModel?.selected?[key]
                result = _.find @[key], (x) ->
                    return x is prev if _.isNumber(x)
                    return x.id is prev.id
                return result or defaultValue

        @selected =
            filters: {}
            grouping: @available.select 'grouping', do =>
                result = _.find @available.grouping, (x) -> x.id is 'stores.name'
                return result or @available.grouping[0]
            limitBy:      @available.select('limitBy', 2)
            metric:       @available.select('metric') # metric to sort by for bar charts, to display for timeseries
            sortOrder:    @available.select('sortOrder')
            timeGrouping: @available.select('timeGrouping')
            stacking:     @available.select('stacking', true)

        @funnel = {nodes: [], selected: {}}
        @tracker = promiseTracker('store-chart')
        @chart = chart
        @chart.labelFormatterOn = false
        @visible = @available.metric.map (x, index) -> index < 2
        Highcharts.wrap Highcharts.Series.prototype, 'setVisible', do =>
            model = this
            return (proceed) ->
                proceed.apply(@, Array.prototype.slice.call(arguments, 1))
                model.visible = @chart.series.map (x) -> x.visible

                if model.selected.timeGrouping.chartType is 'barchart' and not model.selected.stacking
                    # This updates the y-axis labels based on the metric selection
                    @chart.yAxis.forEach (axis) ->
                        series = axis.series.filter((x) -> x.visible)
                        return if series.length is 0
                        metrics = series.map (x) -> x.options.metric
                        metricGroups = _.groupBy metrics, (x) -> x.group
                        axis.update title: text: Object.keys(metricGroups).map((key) ->
                            "#{key}: " + metricGroups[key].map((x) -> x.name).join(' · ')
                        ).join(', ')


    findIndexById: (array, id) ->
        return _.findIndex(array, {'id': id})

    resetFilters: ->
        @selected.filters = {}
        @selected.grouping = _.find @available.grouping, ((x) -> x.id is 'stores.name') or @available.grouping[0]
        @funnel = {nodes: [], selected: {}}

    applyFilters: (selectedNode) ->
        # apply all filters only up to that node
        @selected.filters = {}
        for node in @funnel.nodes
            if node.id == selectedNode.id
                @funnel.selected = node
                @selected.grouping = selectedNode
                return
            @selected.filters[node.table] ?= {}
            @selected.filters[node.table][node.column] = node.choice

    nodeInFunnel: (node) ->
        activeFilters = @funnel.nodes
        activeFilters = @funnel.nodes[0 ... @findIndexById(@funnel.nodes, @funnel.selected.id)] if not _.isEmpty(@funnel.selected)
        return not _.isUndefined(_.find(activeFilters, {'id': node.id}))

    groupByNext: ->
        # change the groupby level to the next one not in the funnel
        groupIndex = initIndex = @findIndexById(@available.grouping, @selected.grouping.id)
        groupIndex = ++groupIndex % @available.grouping.length
        while @nodeInFunnel(@available.grouping[groupIndex]) and groupIndex != initIndex
            groupIndex = ++groupIndex % @available.grouping.length
        @selected.grouping = @available.grouping[groupIndex]

    refreshQuery: =>
        query = Utils.copy($rootScope.query)
        query.options = {metrics: {}, property: {}}
        query.options.metrics  = @available.metric.map (x) -> x.id
        query.options.property = [@selected.grouping.id].concat @selected.timeGrouping.property
        query.limit = @selected.limitBy

        selected = @selected.filters
        transactionsFilter = query.filters.transactions
        Object.keys(selected or {}).forEach (tableKey) ->
            Object.keys(selected[tableKey]).forEach (columnKey) ->
                return if tableKey is 'transactions' and columnKey is 'timestamp'
                query.filters[tableKey] ?= {$and:[]}
                columnIndex = Utils.indexOf (query.filters[tableKey]?.$and or []), (x) -> Object.keys(x)[0] is columnKey
                value = selected[tableKey][columnKey]
                if columnIndex is -1
                    column = {}
                    column[columnKey] = {$in:[value]}
                    query.filters[tableKey] ?= {}
                    query.filters[tableKey].$and ?= []
                    query.filters[tableKey].$and.push(column)
                else
                    column = query.filters[tableKey]?.$and[columnIndex]
                    column[columnKey] ?= {$in:[]}
                    column[columnKey].$in = _.union(column.$in, [value])
        query.filters.transactions = transactionsFilter

        query.sort =
            field: @selected.metric.id
            order: @selected.sortOrder.id

        @tracker.addPromise @chart.refresh(query, @)


# This is the new function to use once the db-growth query service branch is deployed
module.service 'HighchartsSeriesData', (QueryServiceAPI, Utils, $q) ->

    removeTotals = (rows) ->
        return rows if rows.length is 0
        properties = Object.keys(rows[0]).filter((x) -> x.indexOf('property') is 0)
        return rows.filter (row) ->
            for property in properties
                return false if row[property] is '$total'
            return true

    fetch: (model, rootQuery) ->
        {chartType} = model.selected.timeGrouping
        switch chartType
            when 'barchart' then return @fetchBarchart(model, rootQuery)
            when 'timeseries' then return @fetchTimeseries(model, rootQuery)
            else throw new Error("Unknown chart type `#{chartType}`.")

    fetchBarchart: (model, rootQuery) ->
        query = Utils.copy(rootQuery)
        query.options ?= {}
        query.options.includeTotals = false
        return (new QueryServiceAPI).then (api) -> api.query.storeBreakdown(query).then (series) ->
            series = removeTotals(series)
            return {series}

    fetchTimeseries: (model, rootQuery) ->
        query = Utils.copy(rootQuery)
        query.options ?= {}
        query.options.includeTotals = false
        return (new QueryServiceAPI).then (api) ->

            # first extract the top n series by total
            query.options.property = model.selected.grouping.id # get the `limitBy` top groupings
            api.query.storeBreakdown(query).then (totalSeries) ->

                # then get the time series data for each
                query = Utils.copy(rootQuery)
                query.filters ?= {}
                table = model.selected.grouping.table
                column = model.selected.grouping.column
                seriesNames = totalSeries.map((x) -> x.property0).filter (x) -> x != '$total'
                query.filters[table] ?= {}
                query.filters[table][column] = {$in:seriesNames}
                delete query.limit # the limit does not exist!

                # make a third query for totals
                totalQuery = Utils.copy(rootQuery)
                delete totalQuery.limit
                totalQuery.options.property[0] = 'stores.aggregate'

                $q.all([
                    api.query.storeBreakdown(query)
                ,   api.query.storeBreakdown(totalQuery)
                ]).then ([series, totalSeries]) ->
                    series = removeTotals(series)
                    totalSeries = removeTotals(totalSeries)
                    return {series, totalSeries}


module.service 'HighchartsConfig', ($q, $filter, Utils, HighchartsSeriesData) ->
    fetch: (query, model) ->
        HighchartsSeriesData.fetch(model, query).then ({series, totalSeries}) ->

            valueFormatter = (type, x) ->
                [filter, args...] = type.split(':')
                return $filter(filter)(x, args...)

            filterTotal = (d) ->
                dimensionValues = [d["value#{i}"] for i in [0 .. d.property_count]]
                return not ('$total' in dimensionValues)

            parseMetricValue = (x) ->
                x = parseFloat(x)
                return 0 if _.isNaN(x) or not _.isNumber(x)
                return parseFloat(x.toFixed(2))

            keyMatches = (key, arr) ->
                # e.g. {a: 1} matches [{a:1, b:4}, {c:2, d:3}]
                keys = _.pick(point, _.keys(key))
                return true if _.isEqual(keys, key) for point in arr
                return false

            isTimeseries = model.selected.timeGrouping.chartType is 'timeseries'

            # Create yAxes for each type of measurement
            types = do ->
                availableMetrics = do ->
                    return [model.selected.metric] if isTimeseries
                    return model.available.metric
                return _.groupBy availableMetrics, (x) -> x.type.split(':')[0]

            typesArray = _.compact _.map types, (metrics) ->
                metricType = metrics[0].type.split(':')[0]
                return [metricType, metrics[0].type, metrics]

            yAxes = _.compact _.map typesArray, ([type, filter, metrics], index) ->
                animation: false
                metricGroup: type
                title: text: do ->
                    return null
                    # return model.selected.metric.label if model.selected.timeGrouping.chartType is 'timeseries'
                    # metricGroups = _.groupBy metrics, (x) -> x.group
                    # return Object.keys(metricGroups).map((key) ->
                    #     "#{key}: " + metricGroups[key].map((x) -> x.name).join(' · ')
                    # ).join(', ')
                labels: formatter: ->
                    return valueFormatter(filter, @value)
                opposite: !!(index % 2)

            if model.selected.timeGrouping.chartType is 'barchart'
                combinedSeries = _.map model.available.metric, (metric, index) ->
                    name: metric.label
                    colorByPoint: false
                    metric: metric
                    type: 'column'
                    data: series.filter(filterTotal).map (point) ->
                        name:       point.value0
                        y:          parseMetricValue(point[metric.id])
                        drilldown:  true
                        fullInfo:   point
                    yAxis: _.findIndex typesArray, ([type]) ->
                        return type is metric.type.split(':')[0]
                    dataLabels:
                        formatter: -> valueFormatter(metric.type, @y)
                    visible: do ->
                        return model.visible[index] if model.visible
                        return metric.id in ['net_sales', 'growth_net_sales_prev']


            else if model.selected.timeGrouping.chartType is 'timeseries'
                # Grab all unique keys here, and ensure that all series have them. Doing them later messes up sorting

                series = series.filter(filterTotal)
                uniqueKeys = series.map (x) -> _.pick x, ("value#{i}" for i in [1..model.selected.timeGrouping.numSorters])
                uniqueKeys = _.uniqWith uniqueKeys, _.isEqual

                groupings = _.groupBy(series, 'value0')
                for ser of groupings
                    for key in uniqueKeys
                        if not keyMatches(key, groupings[ser])
                            template = _.find(totalSeries, key)
                            new_point = _.assign Utils.copy(template), key, {value0: groupings[ser][0].value0}
                            new_point[model.selected.metric.id] = 0
                            series.push new_point

                totalData = totalSeries.filter(filterTotal).map (x) ->
                    x.value0 = "Total"
                    return x

                series = totalData.concat(series)
                isTotal = (point) -> return point.value0 == "Total"

                groupedSeries = _.groupBy(series, 'value0')
                mappedSeries = _.mapValues groupedSeries, (grouping) ->                # create a data series for each grouping
                    mappedPoints =  _.map grouping, (point) ->     # convert value0 etc. to ints
                        _.mapValues point, (val, key) ->
                            return val if not key.startsWith('value') or isNaN(parseFloat val)
                            return parseFloat(val)
                    sortedMappedPoints = _.sortBy(mappedPoints, ("value#{i}" for i in [1 .. model.selected.timeGrouping.numSorters]))
                    data = _.map sortedMappedPoints, (point) -> # create data points
                        name:       model.selected.timeGrouping.pointFormatter(point)
                        y:          point[model.selected.metric.id]
                        drilldown:  not isTotal(point)
                        fullInfo:   point

                    return
                        name: grouping[0].value0
                        type: if model.selected.stacking and not isTotal(grouping[0]) then 'areaspline' else 'spline'
                        yAxis: 0
                        color: '#888' if isTotal(grouping[0])
                        dashStyle: 'longdash' if isTotal(grouping[0])
                        turboThreshold: 1000000
                        dataLabels:
                            formatter: -> valueFormatter(model.selected.metric.type, @y)
                        data: data

                combinedSeries = _.values(mappedSeries)

            chart:
                zoomType: 'x'
                events:
                    drilldown: (e) ->
                        chartType = model.selected.timeGrouping.chartType
                        if chartType == 'timeseries'
                            # workaround for bug in highcharts: http://stackoverflow.com/questions/38534164/highcharts-drilldown-on-area-chart
                            seriesIndex = _.findIndex @series, (series) -> $(e.originalEvent.path[1]).children().is(series.area?.element)

                            if @series[seriesIndex]
                                selectedDrilldown = @series[seriesIndex].name
                            else
                                return

                            selectedDrilldown = @series[seriesIndex].name
                        else if chartType == 'barchart'
                            selectedDrilldown = e.point.name

                        # apply the current selected level as a filter
                        {table, column} = model.selected.grouping
                        model.selected.filters[table] ?= {}
                        model.selected.filters[table][column] = selectedDrilldown

                        # remove funnel.selected and everything after it
                        if not _.isEmpty(model.funnel.selected)
                            model.funnel.nodes = model.funnel.nodes[0 ... model.findIndexById(model.funnel.nodes, model.funnel.selected.id)]
                            model.funnel.selected = {}

                        # add drilldown to the funnel
                        model.funnel.nodes.push Utils.copy model.selected.grouping
                        _.last(model.funnel.nodes).choice = selectedDrilldown

                        model.groupByNext()

                        # destroy the chart to avoid triggering drilldown twice
                        @destroy()
            exporting:
                fallbackToExportServer: false
                scale: 2
                sourceHeight: 600,
                sourceWidth: 1000

            title:
                text: null

            xAxis:
                type: 'category'
                reversed: false
                labels:
                    rotation: -45
                    align: 'right'

            yAxis: yAxes

            legend:
                enabled: true
                verticalAlign: "top"
                symbolRadius: "50px"
                squareSymbol: true
                symbolWidth:  10
                symbolHeight: 10
                itemDistance: 15
                padding: 0
                margin: 35
                marginBottom: 15
                y: 0
                itemHiddenStyle:
                    color: "#aaa"
                itemStyle:
                    fontSize: "11px"
            tooltip:
                do (model, query) ->
                    if model.selected.timeGrouping.chartType is 'timeseries'
                        shared: true
                        useHTML: true
                        formatter: ->
                            metricLabel = model.selected.metric.group + " for "
                            fullInfo = @points[0].point.fullInfo
                            headLabel = model.selected.timeGrouping.tooltipFormatter(fullInfo)
                            pointsHTML = @points.map((p) ->
                                return "" if not fullInfo
                                """
                                <tr>
                                    <td style='color:#{p.point.color}'>#{p.point.series.name}:&nbsp;</td>
                                    <td> #{valueFormatter(model.selected.metric.type, p.y)} </td>
                                </tr>
                                """
                            ).join('\n')
                            """
                            <span>#{metricLabel} #{headLabel} </span>
                            <br>
                            <table>
                            #{pointsHTML}
                            </table>
                            """
                    else if model.selected.timeGrouping.chartType is 'barchart'
                        headerFormat: "<span>#{model.selected.metric.group} for <em>{point.key}</em> </span> <br> <table>",
                        pointFormatter: (point) ->
                            metric = _.find model.available.metric, {label: @series.name}
                            name = @series.name.trim()
                            """
                            <tr>
                                <td style='color:#{@color}'>#{name}:&nbsp;</td>
                                <td>#{valueFormatter(metric.type, @y)}</td>
                            </tr>
                            """
                        footerFormat: "</table>"
                        shared: true
                        useHTML: true
            plotOptions:
                series:
                    cursor: 'pointer'
                    borderWidth: 0
                    marker:
                        radius: 2
                    lineWidth: 1
                areaspline:
                    trackByArea: true
                    stacking: if model.selected.stacking then "normal" else undefined

            series: combinedSeries
