_ = require('../../../lib/lodash-helper.ts')

module.exports = (Utils, Months, CONFIG) ->
    moment  = require 'moment'

    startMonth = CONFIG.datepicker?.startMonth or 'jan'

    startMonthIndex = 0
    for month in Months
        if month.shortName isnt startMonth
            startMonthIndex += 1
            continue
        break

    Function::getter = (prop, desc) ->
        Object.defineProperty @prototype, prop, desc

    class MonthMock
        constructor: (monthIndex) ->
            monthIndex = monthIndex % 12
            current = Utils.copy(Months[monthIndex])
            @shortName = current.shortName
            @fullName = current.fullName
            @offset = do ->
                diff = monthIndex - startMonthIndex
                return diff if diff >= 0
                return (diff + 12) % 12
            @_position = monthIndex
        @getter 'next',
            get: -> new MonthMock(@_position + 1)
        @getter 'prev',
            get: -> new MonthMock(@_position - 1)

    class YearMock
        constructor: (year) ->
            start = [year, startMonthIndex, 1]
            endIndex = (startMonthIndex + 11)  % 12
            days = moment.utc([year, endIndex, 1]).daysInMonth()
            end = [year, endIndex, days]
            @key = year
            @start = moment.utc(start)
            @end = moment.utc(end)
        @getter 'next',
            get: -> new YearMock(@key + 1)
        @getter 'prev',
            get: -> new YearMock(@key - 1)

    getYearByDate = (date) ->
        yearStart = new YearMock(date.year())
        return do ->
            dayDiff = date.diff(yearStart.start, 'days')
            return yearStart.key if dayDiff >= 0
            return yearStart.key - 1

    getDaysByDate = (date) ->
        yearStart = new YearMock(date.year())
        return 1 + do ->
            dayDiff = date.diff(yearStart.start, 'days')
            return dayDiff if dayDiff >= 0
            yearStart  = new YearMock(date.year() - 1)
            return date.diff(yearStart.start, 'days')

    class CalDate

        @CreateFromDate: (d) ->
            throw new Error("Cannot create Date from `undefined`.") if _.isUndefined(d)
            return d if d instanceof CalDate
            date = moment.utc do ->
                return d if not _.isString(d)
                [year, month, day] = d[0..9].split('-').map (x) -> parseInt(x)
                return [year, month-1, day]
            return new CalDate(date)

        @eq: (self, other) ->
            return self.date.isSame(other.date)
        @gt:  (self, other) ->
            return self.date.isAfter(other.date)
        @lt:  (self, other) ->
            return self.date.isBefore(other.date)
        @gte: (self, other) ->
            return CalDate.gt(self, other) or CalDate.eq(self, other)
        @lte: (self, other) ->
            return CalDate.lt(self, other) or CalDate.eq(self, other)

        @within: (a, self, b) ->
            return self.gte(a) and self.lte(b)

        @diff: (self, other, bucket) ->
            return self.date.diff(other.date, bucket)

        @clamp: (a, self, b) ->
            return a if self.lt(a) if a
            return b if self.gt(b) if b
            return self

        constructor: (date) ->
            @days = getDaysByDate(date)
            @date = moment.utc(date)
            @_dateStr = @date.format('YYYY-MM-DD')
            @month = new MonthMock(@date.month())
            @year = new YearMock(getYearByDate(@date))

            @weekOffset = do =>
                yearStart = moment.utc([@year.key, startMonthIndex, 1]).startOf('week')
                now = @date.clone().startOf('week')
                return moment.duration(now.diff(yearStart)).asWeeks()

            @weekOfMonth = do =>
                startOfMonth = @date.clone().startOf('month')
                week = @date.week()
                startOfMonthWeek = startOfMonth.week()
                if week < startOfMonthWeek
                    return (week + 52) - startOfMonthWeek
                return week - startOfMonthWeek

            @dayOfWeek = do =>
                return @date.day()

            normalized_month_offset = @month.offset
            @seasonOfYear = Math.floor normalized_month_offset / 6
            @quarterOffset = Math.floor normalized_month_offset / 3
            @quarterOfSeason = @quarterOffset % 2
            @monthOfQuarter = normalized_month_offset % 3


        add: (count, bucket) ->
            if bucket is 'season'
                count = 2
                bucket = 'quarter'
            return CalDate.CreateFromDate(@date.clone().add(count, bucket))

        subtract: (count, bucket) ->
            if bucket is 'season'
                count = 2
                bucket = 'quarter'
            return CalDate.CreateFromDate(@date.clone().subtract(count, bucket))

        startOf: (bucket) ->
            if bucket is 'season'
                return CalDate.CreateFromDate(moment.utc([@year.key, startMonthIndex, 1]).add(6 * @seasonOfYear, 'month'))
            if bucket is 'quarter'
                return CalDate.CreateFromDate(moment.utc([@year.key, startMonthIndex, 1]).add(@quarterOffset, 'quarter'))
            if bucket is 'year'
                return CalDate.CreateFromDate(moment.utc([@year.key, startMonthIndex, 1]))
            return CalDate.CreateFromDate(@date.clone().startOf(bucket))

        endOf: (bucket) ->
            bucket = @_normalizeBucket(bucket)
            return @clone() if bucket is 'day'
            return @add(1, bucket).startOf(bucket)

        lt:  (other) -> CalDate.lt(@, other)
        gt:  (other) -> CalDate.gt(@, other)
        gte: (other) -> CalDate.gte(@, other)
        lte: (other) -> CalDate.lte(@, other)
        eq:  (other) -> CalDate.eq(@, other)

        formatBucket: (bucket) ->
            weekOffset = Math.floor(@days / 7)
            switch @_normalizeBucket(bucket)
                when 'day'
                    dayOffset = @days - (weekOffset * 7)
                    return "W#{Utils.padLeft(weekOffset+1, 2)}/#{dayOffset+1}"
                when 'week'
                    return "W#{Utils.padLeft(weekOffset+1, 2)}"
                when 'month'
                    return "M#{Utils.padLeft(@month.offset, 2)}"
                else
                    throw new Error("Unsupported bucket `#{bucket}`.")

        within: (a, b) ->
            return CalDate.within(a, @, b)

        diff: (b, bucket) ->
            return CalDate.diff(@, b, bucket)

        clamp: (a, b) ->
            return CalDate.clamp(a, @, b)

        format: ->
            return @toDate().format(arguments...)

        clone: ->
            return CalDate.CreateFromDate(@date)

        toDate: ->
            return @date.clone()

        toKey: ->
            days = @days.toString()
            pad  = (_.range(3-days.length).map (x) -> "0").join('')
            return "#{@year.key}-#{pad}#{days}"

        prettyPrint: ->
            console.log "REALDATE:", @date.format('YYYY-MM-DD')
            console.log "FISCAL: Year #{@year.key}, Month #{@month.offset} - #{@month.shortName}"

        _normalizeBucket: (bucket) ->
            bucket = bucket.toLowerCase()
            return bucket[0..bucket.length - 2] if bucket[bucket.length - 1] is 's'
            return bucket
