{ cloneDeep } = require('../../../lib/lodash-helper.ts')

module.exports = ($timeout, NRFCalendarMonthSelectViewModel) ->
    restrict: 'E'
    scope:
        title:    '@'
        selected: '='
        bounds:   '='
        handlers: '='
        calendar: '='
    replace: true
    template: \
    """
    <article class="date-select date-select-nrf">
        <section class="date-pickers">
            <article class="date-picker">
                <h1>start date</h1>
                <nrf-date-input min="bounds.start" max="bounds.end" date="dateInput.start"></nrf-date-input>
                <nrf-calendar-month-select model="monthViews.start"></nrf-calendar-month-select>
                <nrf-datepicker-month class="datepicker-start"
                    month="monthViews.start.month"
                    on-select-week="onSelectWeek(week)"
                    on-select="handlers.start(nrfDate, true)"
                    start="selected.start"
                    end="selected.end"></nrf-datpicker-month>
            </article>
            <article class="date-picker">
                <h1>end date</h1>
                <nrf-date-input min="bounds.start" max="bounds.end" date="dateInput.end"></nrf-date-input>
                <nrf-calendar-month-select model="monthViews.end"></nrf-calendar-month-select>
                <nrf-datepicker-month class="datepicker-end"
                    month="monthViews.end.month"
                    on-select-week="onSelectWeek(week)"
                    on-select="handlers.end(nrfDate, true)"
                    start="selected.start"
                    end="selected.end"></nrf-datpicker-month>
            </article>
        </section>
    </article>
    """
    link: (scope, element) ->

        scope.monthViews =
            start: new NRFCalendarMonthSelectViewModel(scope.calendar)
            end: new NRFCalendarMonthSelectViewModel(scope.calendar)

        scope.dateInput =
            start: null
            end:   null

        scope.onSelectWeek = (week) ->
            scope.handlers.start(week[0], true) if week[0]
            scope.handlers.end(week[week.length-1], true) if week[week.length-1]

        scope.$watch 'calendar', ->
            scope.monthViews =
                start: new NRFCalendarMonthSelectViewModel(scope.calendar)
                end: new NRFCalendarMonthSelectViewModel(scope.calendar)

        scope.$watch 'selected.start', (date) ->
            return if not date
            scope.monthViews.start?.selectFromDate(date)
            scope.dateInput.start = date

        scope.$watch 'selected.end', (date) ->
            return if not date
            scope.monthViews.end?.selectFromDate(date)
            scope.dateInput.end = date

        scope.$watch 'dateInput.start', (date) ->
            if (!date)
                return

            if (date.gt(scope.dateInput.end))
                scope.dateInput.start = scope.selected.start
                return

            if (date.eq(scope.selected.start))
                return

            scope.handlers.start(date) if date

        scope.$watch 'dateInput.end', (date) ->
            if (!date)
                return

            if (date.lt(scope.dateInput.start))
                scope.dateInput.end = scope.selected.end
                return

            if (date.eq(scope.selected.end))
                return

            scope.handlers.end(date) if date
