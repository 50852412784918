import _ from 'lodash';
import { getUser, getOrganization } from './auth';
import { AppConfig, AppConfigService } from './config-app';
import { UserServiceAPI } from './api/api-user-service';
import { IConfigObj } from './types';
import { deepStripAngularProperties } from './utils-helper';

const ORGANIZATION_CONFIG_CACHE: Record<string, Promise<IConfigObj>> = {};

export const getOrganizationConfig = (api: UserServiceAPI, organizationId: string): Promise<IConfigObj> => {
    ORGANIZATION_CONFIG_CACHE[organizationId] ??= (() =>
        AppConfigService.get().then((appConfig: AppConfig) =>
            Promise.all([
                api.organizations.GetOrganizationInternalConfig({ organizationId }),
                api.organizations.GetOrganizationExternalConfig({ organizationId }),
            ]).then(([internal, external]) => {
                let config = { ...external, ...internal };
                config = deepStripAngularProperties(config);
                config.organization ??= {};
                config.organization.id = organizationId;
                config = normalizeOrgConfigServices(appConfig, config);
                config = normalizeOrgConfigHierarchies(config);
                return config;
            }),
        ))();
    return ORGANIZATION_CONFIG_CACHE[organizationId].then(x => _.cloneDeep(x));
};

export const getUserConfig = (api: UserServiceAPI, organizationId: string, userId: string) =>
    Promise.all([
        api.organizations.GetUserInternalConfig({ organizationId, userId }),
        api.organizations.GetUserExternalConfig({ organizationId, userId }).then(config => {
            // HACK: Access Control should always be specified as an internal config property.
            //       Because the internal config is merged with external, the accessControl
            //       gets saved in the external config. We delete it here.
            delete config.experiments;
            delete config.accessControl;
            delete config.flags;

            // Angular $$hashKey were persisted accidentally in the external config,
            // so we have to strip them out.
            config = deepStripAngularProperties(config);

            return config;
        }),
    ]).then(([internal, external]) => ({ ...external, ...internal }));

export const setUserConfig = (
    api: UserServiceAPI,
    organizationId: string,
    userId: string,
    data: Record<string, unknown>,
) => {
    // These are being added downstream as a side effect of merging the user config.
    // We remove these here as a safeguard.
    delete data.experiments;
    delete data.accessControl;
    delete data.flags;

    // Angular $$hashKey are added sometimes added to the config objects, so we strip them out.
    data = deepStripAngularProperties(data);

    return api.organizations.SetUserExternalConfig({ organizationId, userId }, { data });
};

export const ConfigAPI = (() => {
    // FIXME:
    // We cache these here because, for some reason, we sometimes see configs being
    // written to the wrong organization. This is likely due to multi-tabbing. The userPromise
    // can probably be removed, since there's a reproducible test case for the issue. The org
    // one has not been made reproducible yet.
    const orgPromise = getOrganization();
    const userPromise = getUser().then(user => user?.id);

    return {
        get() {
            return Promise.all([
                UserServiceAPI.get(),
                userPromise,
                orgPromise,
                //
            ]).then(([api, userId, organizationId]) => {
                if (!userId) throw new Error('[config-api] missing required: userId');
                if (!organizationId) throw new Error('[config-api] missing required: organizationId');
                const organization = {
                    get: () => getOrganizationConfig(api, organizationId),
                };
                const user = {
                    set: (data: Record<string, unknown>) => {
                        return setUserConfig(api, organizationId, userId, data);
                    },
                    get: () => {
                        return getUserConfig(api, organizationId, userId);
                    },
                };
                return { organization, user };
            });
        },
    };
})();

function normalizeOrgConfigServices<OrgConfig extends { services: unknown; organization: { id: string } }>(
    appConfig: AppConfig,
    orgConfig: OrgConfig,
): OrgConfig {
    appConfig = _.cloneDeep(appConfig);
    orgConfig = _.cloneDeep(orgConfig);

    const orgServices = isPlainObject(orgConfig.services) ? orgConfig.services : {};
    const serviceNames = new Set<string>([...Object.keys(appConfig.services), ...Object.keys(orgServices)]);

    // FIXME: unsafe any
    const services: any = {};
    serviceNames.forEach(serviceName => {
        const appService = appConfig.services[serviceName];
        const orgService = orgServices[serviceName];
        services[serviceName] = {
            organization: orgConfig.organization.id,
            ...(appService ?? {}),
            ...(isPlainObject(orgService) ? orgService : {}),
            ...(appService?.override ? appService : {}),
        };
    });

    return { ...orgConfig, services };
}

/**
 * Normalizes the org hierarchy configs.
 */
function normalizeOrgConfigHierarchies<
    T extends {
        organization: { id: string };
        items?: Record<string, unknown>;
        stores?: Record<string, unknown>;
    },
>(orgConfig: T) {
    // migrate hierarchy2 -> hierarchy
    const migrateHierarchy = (config: unknown) => {
        if (!isPlainObject(config)) return config;
        if (config.hierarchy2) {
            console.error('[config] organization has obsolete hierarchy2 config:', orgConfig.organization?.id);
        }
        return { ..._.omit(config, 'hierarchy2'), hierarchy: config.hierarchy2 || config.hierarchy };
    };
    orgConfig = {
        ...orgConfig,
        items: migrateHierarchy(orgConfig.items),
        stores: migrateHierarchy(orgConfig.stores),
    };
    return orgConfig;
}

function isPlainObject(obj: unknown): obj is Record<string, unknown> {
    return _.isPlainObject(obj);
}
