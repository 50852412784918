import { debounce } from 'lodash';
import { ICustomer } from '../customer.types';

export type ICustomerSearch = Record<string, string>;

export interface ICustomerListItem {
    customers: ICustomer[];
    actions: {
        onCustomerSearch: (customerSearch: ICustomerSearch) => void;
    };
}

export const CustomerListDirective = () => [
    function CustomerList() {
        return {
            restrict: 'E',
            replace: false,
            scope: {
                model: '=',
                sortable: '=',
            },
            template: `
                <article class="customer-list">
                    <article class="ui-table supertable customers-table" supertable sortable-container>
                        <main>
                            <div class="loadable" promise-tracker="customer-list"></div>
                            <table class="table-normal">
                                <tbody>
                                    <tr ng-repeat="d in model.customers">
                                        <td class="column-name" title="{{ d.name }}">
                                            <a ng-href="/#/reports/customers/{{ d.customer_id }}">{{ d.name }}</a>
                                        </td>
                                        <td class="column-transaction-count">{{ d.transaction_count | number }}</td>
                                        <td class="column-total-spent">{{ d.total_spent | money }}</td>
                                        <td class="column-average-spent">{{ d.avg_spent | money }}</td>
                                        <td class="column-latest-visit">{{ d.latest_visit | date:'MMM dd, yyyy' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </main>
                        <header>
                            <table>
                                <thead>
                                    <tr>
                                        <th class="column-name" title="Name" sortable-property="name">NAME</th>
                                        <th
                                            class="column-transaction-count"
                                            title="Number of Visits"
                                            sortable-property="transaction_count"
                                        >
                                            VISITS
                                        </th>
                                        <th class="column-total-spent" title="Total Spent" sortable-property="total_spent">
                                            TOTAL SPENT
                                        </th>
                                        <th class="column-average-spent" title="Average Spent" sortable-property="avg_spent">
                                            AVG. SPENT
                                        </th>
                                        <th class="column-latest-visit" title="Latest Visit" sortable-property="latest_visit">
                                            LATEST VISIT
                                        </th>
                                    </tr>
                                    <tr class="row-search">
                                        <th class="column-name" title="Name">
                                            <input type="search" ng-model="customerSearch.name" />
                                        </th>
                                        <th class="column-transaction-count" title="Number of Visits">
                                            <input type="search" ng-model="customerSearch.transaction_count" />
                                        </th>
                                        <th class="column-total-spent" title="Total Spent">
                                            <input type="search" ng-model="customerSearch.total_spent" />
                                        </th>
                                        <th class="column-average-spent" title="Average Spent">
                                            <input type="search" ng-model="customerSearch.avg_spent" />
                                        </th>
                                        <th class="column-latest-visit" title="Latest Visit"></th>
                                    </tr>
                                </thead>
                            </table>
                        </header>
                        <footer>
                            <table class="table-normal">
                                <tfoot>
                                    <tr>
                                        <td class="column-paginator" colspan="5">Showing <em>{{ model.customers.length }}</em> customers</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </footer>
                    </article>
                </article>
            `,
            link: ($scope: angular.IScope & any) => {
                const debouncedSearchUpdate = debounce((customerSearch: ICustomerSearch) => {
                    $scope.model.actions.onCustomerSearch(customerSearch);
                }, 400);

                $scope.$watch('customerSearch', debouncedSearchUpdate, true);
            },
        };
    },
];
