import { SmartGroupsFilterDirective } from './smart-group-filter.directive';
import {
    SmartGroupsItemDirective,
    SmartGroupsPanelDirective,
    SmartGroupEditDropdownDirective,
} from './smart-group-panel.directive';
import {
    SmartGroupsSimpleFilters,
    SmartGroupsFactory,
    SmartGroupsAPIFactory,
    SmartGroupsFilterService,
} from './smart-groups.service';

const module = angular.module('42.modules.smart-groups', []);

module.directive('buttonSmartGroupEditDropdown', SmartGroupEditDropdownDirective());
module.directive('smartGroupsPanelItem', SmartGroupsItemDirective());
module.directive('smartGroupsPanel', SmartGroupsPanelDirective());
module.directive('smartGroupsFilter', SmartGroupsFilterDirective());

module.service('SmartGroupsFilterService', SmartGroupsFilterService());
module.service('SmartGroupFilters', SmartGroupsSimpleFilters());
module.service('SmartGroupFilters', SmartGroupsSimpleFilters());
module.factory('SmartGroups', SmartGroupsFactory());
module.factory('SmartGroupsAPI', SmartGroupsAPIFactory());
