_ = require('../../../lib/lodash-helper.ts')

module.exports = (CONFIG) -> fetch: ->
    overrides = CONFIG.datepicker?.actions
    return null if not _.isArray(overrides)
    return _.compact overrides.map (override, index) ->
        return {id:override, hide:false} if _.isString(override)
        if override.id
            override = _.pick(override, ['id', 'label', 'section', 'hide', 'group'])
            override.hide = false if _.isUndefined(override.hide)
            return override
        console.warn "Invalid entry at index `#{index}` in datepicker actions override:", override
        return null
