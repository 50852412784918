import { IPromise } from 'angular';
import _ from 'lodash';
import { IConfigObj } from '../../lib/types';
import { IQueryServiceAPI } from '../services/query-service.types';
import { StorageAPI } from '../../lib/storage-user-config';

export interface ICurrency {
    id: string;
    symbol: string;
    label: string;
    shortLabel: string;
}

const CURRENCY_DATA: Record<string, ICurrency> = {
    usd: { id: 'usd', symbol: '$', shortLabel: 'USD $', label: '($ USD) US Dollar' },
    gbp: { id: 'gbp', symbol: '£', shortLabel: 'GBP £', label: '(£ GBP) British Pound' },
    cad: { id: 'cad', symbol: '$', shortLabel: 'CAD $', label: '($ CAD) Canadian Dollar' },
    aud: { id: 'aud', symbol: '$', shortLabel: 'AUD $', label: '($ AUD) Australian Dollar' },
    jpy: { id: 'jpy', symbol: '¥', shortLabel: 'YEN ¥', label: '(¥ YEN) Japanese Yen' },
    cny: { id: 'cny', symbol: '¥', shortLabel: 'CNY ¥', label: '(¥ CNY) Chinese Yuan' },
    krw: { id: 'krw', symbol: '₩', shortLabel: 'Won ₩', label: '(₩) South Korean Won' },
    eur: { id: 'eur', symbol: '€', shortLabel: 'EUR €', label: '(€ EUR) Euro' },
    trl: { id: 'trl', symbol: '₺', shortLabel: 'TRL ₺', label: '(₺ TRL) Turkish Lira' },
    btc: { id: 'btc', symbol: '₿', shortLabel: 'BTC ₿', label: '(₿ BTC) Bitcoin' },
    php: { id: 'php', symbol: '₱', shortLabel: 'PHP ₱', label: '(₱) Philippine Peso' },
    myr: { id: 'myr', symbol: 'RM', shortLabel: 'MYR RM', label: '(RM) Malaysian Ringgit' },
    sek: { id: 'sek', symbol: 'kr', shortLabel: 'SEK kr', label: '(kr) Swedish Krona' },
    brl: { id: 'brl', symbol: 'R$', shortLabel: 'BRL R$', label: '(R$) Brazilian Real' },
    twd: { id: 'twd', symbol: 'NT$', shortLabel: 'TWD NT$', label: '(NT$) New Taiwan Dollar' },
};

export class CurrencyModel {
    public available: ICurrency[] = [];
    public selected: ICurrency;

    constructor(private storageAPI: any, available: ICurrency[], selectedCurrency: string) {
        this.available = _.cloneDeep(available);
        this.selected = this.available.find(currency => currency.id === selectedCurrency) || this.available[0];

        if (this.selected) {
            this.save();
        }
    }

    save() {
        this.storageAPI.put(this.selected.id);
    }
}

export interface ICurrenciesService {
    fetch: () => IPromise<ICurrency[]>;
}

export const AvailableCurrenciesServiceInstance = () => [
    'QueryServiceAPI',
    function CurrenciesService(QueryServiceAPI: IQueryServiceAPI) {
        return {
            fetch: () => {
                const queryServiceAPIPromise = new QueryServiceAPI();

                return queryServiceAPIPromise
                    .then(api => api.query.findCurrencies())
                    .then(currencyIds => {
                        const currencies = _.flatMap(currencyIds, id => {
                            const value = CURRENCY_DATA[id];
                            if (!value) console.error(`Missing currency data for currency ${id}`);
                            return value || [];
                        });

                        return _.sortBy(currencies, currency => currency.id);
                    });
            },
        };
    },
];

const findCurrency = (available: ICurrency[], currencyId: string) => {
    if (!currencyId) return;

    const currency = currencyId.toLowerCase();
    return _.cloneDeep(available.find(currencyInfo => currencyInfo.id === currency));
};

const getSelectedCurrency = (available: ICurrency[], userCurrencyId: string, defaultCurrencyId: string): string => {
    let currency: string | undefined;
    currency = findCurrency(available, userCurrencyId)?.id;
    currency ??= findCurrency(available, defaultCurrencyId)?.id;
    currency ??= (available[0] ?? {}).id;
    return currency;
};

export const UserCurrencyModelInstance = () => [
    '$q',
    'CONFIG',
    'Currencies',
    function CurrenciesService($q: angular.IQService, CONFIG: IConfigObj, Currencies: ICurrenciesService) {
        const storagePromise = $q.when(StorageAPI('currency'));

        return {
            fetch: () => {
                return $q
                    .all([Currencies.fetch(), storagePromise.then(api => api.get()) as Promise<string>, storagePromise])
                    .then(([currencyIds, userCurrency, storageApi]) => {
                        const selectedCurrency = getSelectedCurrency(
                            currencyIds,
                            userCurrency,
                            CONFIG.defaults?.currency,
                        );

                        return new CurrencyModel(storageApi, currencyIds, selectedCurrency);
                    });
            },
        };
    },
];
