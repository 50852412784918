import angular from 'angular';
import { merge } from 'lodash';

const DEFAULT_MAP_CONFIG = {
    id: 'surjikal.map-jg2l7a27',
    token: 'pk.eyJ1IjoiNDItbWFwYm94IiwiYSI6ImNrbGNud3U1ZzEyNTMycW54dzVyemxmOGoifQ.AftwBqfbMr8Jf6qIDqWKRg',
    style: 'mapbox://styles/42-mapbox/cklqv1hch6o1k17mlno9j6cvj',
    view: {
        longitude: '39.35129035526705',
        latitude: '-98.0419921875',
        zoom: 4,
    },
};
export type IMapConfig = typeof DEFAULT_MAP_CONFIG;

export interface IMapStore {
    id: string;
    name: string;
    phone?: string;
    type?: string;
    address?: {
        street: string;
        city: string;
        region: string;
        country: string;
        postalCode: string;
    };
    location?: {
        latitude: number;
        longitude: number;
    };
}

export interface IMapCustomer {
    latitude: number;
    longitude: number;
}

interface IMapData {
    stores: IMapStore[];
    customers: IMapCustomer[];
}

export class Map {
    data: IMapData = undefined;
    config: IMapConfig;

    init(config: any) {
        config = merge(DEFAULT_MAP_CONFIG, config.map);
        return import(
            /* webpackChunkName: "map-data" */
            './data'
        ).then(({ MapData: data }) => {
            this.config = config;
            this.data = data;
        });
    }
}
