import customersPageHTML from './controllers/customer/customer-main/customers.html';
import customersDetailsPageHTML from './controllers/customer/customer-details/customers-details.html';
import salesPageHtml from './controllers/sales/sales.html';
import storePageHtml from './controllers/store.html';
import itemsPageHtml from './controllers/grid/items.html';
import metricsPageHtml from './controllers/metrics/metrics.html';

import transactionsPageHtml from './controllers/transactions/transactions.html';
import * as ConfigFlags from './lib/config-page-flags';

const initializeRoutes = () => {
    const routes: Record<string, any> = {
        sales: {
            label: 'Sales',
            icon: 'archive',
            url: '/reports/sales',
            template: salesPageHtml,
            controller: 'SalesController',
            group: 'dashboard',
        },
        metrics: {
            label: 'Metrics',
            icon: 'archive',
            url: '/reports/metrics',
            template: metricsPageHtml,
            controller: 'MetricsController',
        },
        customers: {
            label: 'Customers',
            icon: 'users',
            url: '/reports/customers',
            template: customersPageHTML,
            controller: 'CustomerController',
            group: 'dashboard',
        },
        customerDetails: {
            label: 'Customer Details',
            url: '/reports/customers/:customerId',
            template: customersDetailsPageHTML,
            controller: 'CustomerDetailsController',
            group: 'dashboard',
        },
        stores: {
            label: 'Stores',
            icon: 'house',
            url: '/reports/stores',
            template: storePageHtml,
            controller: 'StoreController',
            group: 'dashboard',
        },
        storestatus: {
            label: 'Status',
            icon: 'house',
            url: '/status/stores',
            template: '<view-store-status></view-store-status>',
            controller: 'StoreStatusController',
            group: 'dashboard',
        },
        items: {
            label: 'Items',
            icon: 'cart',
            url: '/reports/items',
            template: itemsPageHtml,
            controller: 'ItemsController',
            group: 'dashboard',
        },
        transactions: {
            label: 'Transactions',
            icon: 'archive',
            url: '/reports/transactions',
            template: transactionsPageHtml,
            controller: 'TransactionsController',
            group: 'dashboard',
        },
        map: {
            label: 'Map',
            icon: 'map',
            url: '/reports/map',
            template: '<map-view></map-view>',
            controller: 'MapController',
            group: 'dashboard',
            // resolve:
            //     mapData: (MapData) -> MapData.fetch()
        },
        reportingReports: {
            label: 'Reporting',
            url: '/reporting/reports',
            template:
                '<view-reporting-reports model="reportTemplatesModel" schedules="schedules"></view-reporting-reports>',
            controller: 'ReportingReportsController',
            group: 'reporting',
            resolve: {
                permissions: ($location: angular.ILocationService) => {
                    return ConfigFlags.fetch()
                        .then(flags => {
                            if (!flags.reporting) return $location.path('/');
                        })
                        .catch((err: Error) => console.error('Could not fetch Permissions!', err));
                },
            },
        },
        reportingSchedules: {
            label: 'Schedules',
            url: '/reporting/schedules/:id?',
            template: '<view-reporting-schedules model="schedules"></view-reporting-schedules>',
            controller: 'ReportingSchedulesController',
            group: 'reporting',
            resolve: {
                permissions: ($location: angular.ILocationService) => {
                    return ConfigFlags.fetch()
                        .then(flags => {
                            if (!flags.reporting) return $location.path('/');
                        })
                        .catch((err: Error) => console.error('Could not fetch Permissions!', err));
                },
            },
        },
        counter: {
            label: 'Counter',
            url: '/reports/counter',
            template: '<view-counter model="model"></view-counter>',
            controller: 'CounterController',
            group: 'counter',
        },
    };

    Object.keys(routes).forEach(key => {
        const route = routes[key];
        route.id = key;
        route.templates = [route.url];
    });

    return routes;
};

const module = angular.module('42.routes', ['ngRoute']);
module.constant('ROUTES', initializeRoutes());
