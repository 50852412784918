import { AuthServiceFactory } from '@42technologies/client-lib-auth';
import { AppConfigService } from './config-app';
import { UserOrganizationStorage } from './storage-user-organization';

const AuthService = new AuthServiceFactory({ window: window, config: AppConfigService.getAuth() });
export { AuthService };

export function getUser() {
    return AuthService.get().then(auth => auth.getUser() ?? auth.logout());
}

export function getOrganization(): Promise<string> {
    return getUser().then(user => {
        let organization = UserOrganizationStorage.get();

        if (organization && user.organizations.includes(organization)) {
            return organization;
        }

        if (!organization) {
            console.info('No saved organization found.');
        } else {
            console.info(`Stored organization '${organization}' is not part of user's available orgs.`);
            console.info('Available organizations:', user.organizations);
        }

        organization = user.organizations[0];
        UserOrganizationStorage.set(organization);
        return organization;
    });
}

export function setOrganization(organization: string): Promise<never> {
    if (!organization) return Promise.reject(new Error(`Missing required ${organization} argument.`));
    UserOrganizationStorage.set(organization);
    window.location.reload();
    return new Promise(() => void 0);
}

export function isAdmin(): Promise<boolean> {
    return getUser().then(user => user && (user.isOrgAdmin() || user.isRootAdmin()));
}

export function logout(): Promise<never> {
    return AuthService.get().then(auth => auth.logout());
}
