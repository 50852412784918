{ buildSupportLink } = require('./support-email.helper.ts')

module = angular.module('42.controllers.main')

module.directive 'mainNavbar', ($rootScope, OutsideElementClick, $window, CONFIG) ->
    restrict: 'E'
    scope: true
    replace: true
    template: \
        """
        <nav class="top-bar collapses" ng-class="{small: !wideWindow()}">
            <div class="collapse-header">
                <a class="header-logo" ng-href="{{ '/#' + routes.sales.url }}">
                  <div class="logo">
                  </div>
                </a>
                <i ng-click="toggleNav()" ng-hide="wideWindow()" ng-class="{active: navbarModel.expand}" class="icon-menu"></i>
            </div>
            <ul ng-if="initialized && !StatusMonitor.status.isLoading" ng-click="disableNav()" class="ng-cloak report-select">
                <div ng-if="$parent.activeRoute.group != 'reporting'" ng-class="{small: !wideWindow(), hide: !navbarModel.expand && !wideWindow()}" class="page-select collapses">
                    <route model="routes.sales"></route>
                    <route model="routes.metrics" ng-if="pages.metrics"></route>
                    <route model="routes.structure" ng-if="pages.structure"></route>
                    <route model="routes.customers" ng-if="pages.customers"></route>
                    <route model="routes.transactions" ng-if="pages.transactions"></route>
                    <route model="routes.items"></route>
                    <route model="routes.inventory" ng-if="pages.inventory"></route>
                    <route model="routes.map" ng-if="pages.map"></route>
                    <route model="routes.stores" ng-if="pages.stores"></route>
                    <route model="routes.reportingReports" ng-if="pages.reporting"></route>
                </div>
                <div ng-class="{small: !wideWindow(), hide: !navbarModel.expand && !wideWindow()}", ng-if="$parent.activeRoute.group == 'reporting'" class="page-select collapses">
                    <li><a ng-href="{{ '/#' + routes.sales.url }}" target="{{ routes.sales.href && '_blank'}}"><i class="icon-left"></i><span class="route-label">Dashboard</span></a></li>
                    <route model="routes.reportingReports"></route>
                    <route model="routes.reportingSchedules"></route>
                </div>
            </ul>
            <div ng-class="{small: !wideWindow(), hide: !navbarModel.expand && !wideWindow()}", ng-click="disableNav()" class="right collapses">
                <div ng-cloak="ng-cloak" class="support">
                    <a ng-if="handbookLink" ng-href="{{ handbookLink }}" target="_blank"><i class="icon-book"></i>Handbook</a>
                </div>
                <div ng-cloak="ng-cloak" class="support">
                    <a ng-if="!organization" ng-href="mailto:support@42technologies.com?subject=Support request"><i class="support-icon icon-paper-plane"></i>Support</a>
                    <a ng-if="organization" ng-href="{{ supportLink }}" target="_blank"><i class="support-icon icon-paper-plane"></i>Support</a>
                </div>
                <div class="status-button status-{{ statusMessageModel.state.type }}" ng-click="storeStatus()" ng-class="{'active':statusMessageModel.state.active}">
                    <a href="#">
                        <i class="{{ statusMessageModel.state.icon }}"></i><span class="label">Status</span>
                    </a>
                </div>
            </div>
        </nav>
        """
    link: (scope, element) ->
        scope.handbookLink = scope.organization.handbook
        scope.supportLink = buildSupportLink(CONFIG, scope.organization)

        OutsideElementClick scope, element, ->
            return if not scope.navbarModel.expand
            scope.navbarModel.expand = false

        scope.storeStatus = ->
            scope.statusMessageModel.toggle()
            return if not (CONFIG.flags?.showStoreStatus and $rootScope.flags?.showStoreStatus)
            window.location = '/#' + scope.routes.storestatus.url

        scope.toggleNav = ->
            scope.navbarModel.expand = not scope.navbarModel.expand

        scope.disableNav = ->
            scope.navbarModel.expand = false

        scope.wideWindow = ->
            $window.innerWidth > 768
