import { AvailableCurrenciesServiceInstance, UserCurrencyModelInstance } from './currency.service';

const QueryModifiersDirectiveInstance = () => [
    function QueryModifiersDirective() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <article class="query-modifiers hide-small">
                    <query-modifier-currency model="model"></query-modifier-currency>
                </article>
            `,
        };
    },
];

const QueryModifiersCurrencyDirectiveInstance = () => [
    function QueryModifiersCurrencyDirective() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
                small: '=',
            },
            replace: true,
            template: `
                <article class="query-modifier query-modifier-currency">
                    <article class="currency-switcher" ng-if="!small">
                        <label>
                            <span>
                                Display Currency
                            </span>
                            <i class="icon-down-open-mini" style="display: none"></i>
                            <select ng-model="model.selected"
                                ng-options="currency.label for currency in model.available | orderBy:'label'">
                            </select>
                        </label>
                    </article>
                    <article class="currency-switcher-select" ng-if="small">
                        <div class="select-title">
                            Display Currency
                        </div>
                        <div class="currency-switcher-dropdown">
                            <i class="icon-down-open-mini"></i>
                            <select ng-model="model.selected"
                                ng-options="currency.shortLabel for currency in model.available | orderBy:'label'">
                            </select>
                        </div>
                    </article>
                </article>
            `,
        };
    },
];

const module = angular.module('42.modules.currency', []);
module.service('Currencies', AvailableCurrenciesServiceInstance());
module.service('UserCurrencyModel', UserCurrencyModelInstance());
module.directive('queryModifiers', QueryModifiersDirectiveInstance());
module.directive('queryModifierCurrency', QueryModifiersCurrencyDirectiveInstance());
