import _ from 'lodash';
import { IQuery, IQueryFilters } from '../../lib/types';
import { IStorageAPI, IStorageInterfaceAPI } from '../storage-module';

interface ISmartGroupsSimpleFilter {
    id: string;
    tables: string[];
    label: string;
    selectedHeader: string;
    instructions: string;
}

interface IGroup {
    id?: string;
    name?: string;
    color?: string;
    query?: IQuery;
}

interface IHandlers {
    create?: (groupViewModel: SmartGroupViewModel) => void;
    delete?: (groupViewModel: SmartGroupViewModel) => void;
    reorder?: (oldIndex: number, newIndex: number) => void;
    update?: (groupViewModel: SmartGroupViewModel) => void;
}

export interface ISmartGroupsService {
    groups?: SmartGroupViewModel[];
    selected: SmartGroupViewModel;
    select: (group: SmartGroupViewModel) => void;
    create: () => SmartGroupViewModel;
    save: (group: SmartGroupViewModel) => void;
    reorder: (oldIndex: number, newIndex: number) => any;
    delete: (group: SmartGroupViewModel) => void;
    commit: () => void;
    resetSelection: () => void;
}

export const SmartGroupsSimpleFilters = () => [
    'CONFIG',
    (CONFIG: any): ISmartGroupsSimpleFilter[] => [
        /* {
                id: 'customers'
                label: "Customer Filter"
                selectedHeader: "Customers filtered by:"
                instructions: `
                    Use this filter to narrow down on the type of
                    customer you would like to report on.
                `
            }
        */
        {
            id: 'stores',
            tables: ['stores', 'acquisitions', 'employees', 'accounts', 'warehouses', 'customers'],
            label: CONFIG.stores?.label || 'Store Filter',
            selectedHeader: 'Selected filters:',
            instructions: 'This filter is used to find customers that shopped at one or more stores.',
        },
        {
            id: 'items',
            tables: ['items', 'transaction_items', 'item_timeseries', 'gift_cards', 'demand_items'],
            label: CONFIG.items?.label || 'Item Filter',
            selectedHeader: 'Selected filters:',
            instructions: `
                This filter allows you to find customers that have
                purchased a particular type of item, or an item of
                a certain category.
            `,
        },
        /*
            {
                id: 'location'
                label: "Location Filter"
                selectedHeader: "Customers who live:"
                instructions: `
                    Use this filter to find customers that are living
                    in a particular geographical area. Use Shift+Click
                    to create a selection.
                `
            }
        */
    ],
];

export class SmartGroupFilterViewModel {
    id!: string;
    tables!: string[];
    label!: string;
    selectedHeader!: string;
    instructions!: string;

    constructor(public group: SmartGroupViewModel, filter: ISmartGroupsSimpleFilter) {
        this.id = filter.id;
        this.tables = filter.tables;
        this.label = filter.label;
        this.selectedHeader = filter.selectedHeader;
        this.instructions = filter.instructions;
    }

    isSelected = () => {
        return this.group.filter && this.group.filter.id === this.id;
    };

    isActive = () => {
        const { query } = this.group.getModel();
        const filters: IQueryFilters = query?.filters || {};
        return this.tables.some(table => {
            const filter = filters[table] ?? {};
            return _.isPlainObject(filter) && !_.isEmpty(filter);
        });
    };

    select = () => {
        this.group.filter = this;
    };
}

export interface ISmartGroupViewModelEditMode {
    model: IGroup;
    enabled: boolean;
    enable: () => void;
    disable: () => void;
    commit: () => void;
    cancel: () => void;
}

export class SmartGroupViewModel {
    model: IGroup;
    markedForRemoval = false;
    isNew = false;
    filter?: SmartGroupFilterViewModel;
    filters?: SmartGroupFilterViewModel[] = [];

    constructor(private parent: any, group: IGroup, smartGroupFilters: ISmartGroupsSimpleFilter[]) {
        this.model = this.createModel(group);
        this.filters = _.cloneDeep(smartGroupFilters).map(filter => new SmartGroupFilterViewModel(this, filter));
    }

    private createModel = (group: IGroup) => {
        const model = _.cloneDeep(group || {});
        model.query = model.query || { filters: {} };
        model.query.filters = model.query.filters || {};
        return model;
    };

    isSelected = () => this.parent.selected === this;

    select = () => this.parent.select(this);

    save = () => this.parent.save(this);

    delete = () => this.parent.delete(this);

    getModel = () => this.model;

    getName = () => this.model.name;

    commit = (name: string) => {
        if (name && this.model.name && name !== this.model.name) {
            this.model.name = name;
            this.save();
        }
        this.isNew = false;
    };
}

export const SmartGroupsFactory = () => [
    '$rootScope',
    '$timeout',
    'Utils',
    'SmartGroupFilters',
    function SmartGroupsFactory(
        $rootScope: angular.IRootScopeService & any,
        $timeout: angular.ITimeoutService,
        Utils: any,
        smartGroupFilters: ISmartGroupsSimpleFilter[],
    ) {
        return (rawGroups: IGroup[], handlers: IHandlers, hierarchy: any) => {
            const remapGroup = (service: ISmartGroupsService) => (group: IGroup) =>
                new SmartGroupViewModel(service, group, smartGroupFilters);

            const createGroup = (service: ISmartGroupsService) =>
                remapGroup(service)({ id: Utils.uuid(), name: 'Untitled Group', query: {} });

            const service: ISmartGroupsService = {
                selected: {} as SmartGroupViewModel,
                select: group => {
                    if (service.selected && service.selected !== group) {
                        service.selected.filter = undefined;
                    }
                    service.selected = group;
                },
                resetSelection: () => {
                    service.selected = {} as SmartGroupViewModel;
                },
                create: () => {
                    const group = createGroup(service);
                    service.groups = service.groups || [];
                    service.groups.unshift(group);

                    // Add new Group and then select

                    group.isNew = true;
                    service.select(group);
                    if (handlers && handlers.create) handlers.create(group);
                    $timeout(() => (group.isNew = false), 0);

                    return group;
                },

                save: group => {
                    if (handlers && handlers.update) {
                        handlers.update(group);
                    }
                },

                reorder: (oldIndex: number, newIndex: number) => {
                    if (oldIndex === newIndex) {
                        return;
                    }

                    service.groups = Utils.move(service.groups, oldIndex, newIndex);

                    if (handlers && handlers.reorder) {
                        handlers.reorder(oldIndex, newIndex);
                    }
                },

                delete: group => {
                    if (!(group && group.getModel().id)) {
                        return;
                    }

                    service.groups = service.groups || [];
                    let removedAtIndex = Infinity;

                    const groups: SmartGroupViewModel[] = service.groups.reduce((result, groupElem, index) => {
                        if (groupElem === group) {
                            removedAtIndex = index;
                        } else {
                            result.push(groupElem);
                        }

                        return result;
                    }, [] as SmartGroupViewModel[]);

                    if (groups.length === 0) {
                        const newGroup = createGroup(service);
                        groups.push(newGroup);

                        if (handlers && handlers.create) {
                            handlers.create(newGroup);
                        }
                    }

                    removedAtIndex = Utils.clamp(0, removedAtIndex, groups.length - 1);
                    group.markedForRemoval = true;

                    if (handlers && handlers.delete) {
                        handlers.delete(group);
                    }

                    service.groups = groups;
                    service.select(groups[removedAtIndex]);
                },

                commit: () => {
                    if (handlers && handlers.update) {
                        handlers.update(service.selected);
                    }
                },
            };

            service.groups = rawGroups.map(rawGroup => remapGroup(service)(rawGroup));

            if (service.groups.length === 0) {
                service.create();
            }

            // TODO: It would be cool if this would only be called when the filters are changed.
            //       Right now, it gets called when the groups are changed also.

            $rootScope.$on('query.refresh', () => {
                if (!service.selected) {
                    return;
                }

                const { selected: selectedModel } = $rootScope.hierarchyModel || { selected: { id: null } };

                if (!hierarchy || (selectedModel && hierarchy === selectedModel.id)) {
                    service.selected.save();
                }
            });

            return service;
        };
    },
];

export type IGroupDic = Record<string, IGroup[]>;

function isGroupList(input: IGroup[] | IGroupDic): input is IGroup[] {
    return _.isArray(input);
}

export function moveFn<T>(list: T[], from: number, to: number): T[] {
    if (from === to) {
        return list;
    }

    [from, to] = [from, to].map(x => _.clamp(0, x, list.length - 1));

    const value = list[from];

    return list.reduce((result, item, index) => {
        if (index === to && from > to) {
            result.push(value);
        }

        if (index !== from) {
            result.push(item);
        }

        if (index === to && from < to) {
            result.push(value);
        }

        return result;
    }, [] as T[]);
}

const prepareGroups = (groups: IGroup[]) => {
    const filtersToClean = ['transactions', 'inventory'];
    const queriesToClean = ['currency', 'timezone', 'modifiers', 'comparison', 'options'];

    return _.cloneDeep(groups).map(group => {
        if (group.query && group.query.filters) {
            const filters = group.query.filters;
            filtersToClean.forEach(filter => {
                if (filters[filter]) {
                    delete filters[filter];
                }
            });
        }

        queriesToClean.forEach(query => {
            if (group.query) delete group.query[query];
        });

        return group;
    });
};

const findGroup = (id: string, hierarchy: string, state: IGroup[] | IGroupDic): IGroup | null => {
    console.info('SmartGroups: Looking for', id);

    const groups: IGroup[] = hierarchy ? (state as IGroupDic)[hierarchy] || [] : (state as IGroup[]);

    return groups.find(g => g.id === id) || null;
};

export class SmartGroupsAPI {
    constructor(public state: IGroupDic | IGroup[], private api: IStorageAPI<IGroup[] | IGroupDic>) {}

    private saveToStorage = _.debounce(() => {
        let query = _.cloneDeep(this.state);

        if (isGroupList(query)) {
            query = prepareGroups(query);
        } else {
            query = Object.keys(query).reduce((result, key) => {
                result[key] = prepareGroups((query as IGroupDic)[key]);
                return result;
            }, {} as IGroupDic);
        }

        return this.api.put(query).then(() => Promise.resolve(_.cloneDeep(this.state)));
    }, 200);

    get = (id: string, hierarchy: string) => {
        if (!id) {
            return Promise.resolve(_.cloneDeep(this.state));
        }

        const group = findGroup(id, hierarchy, this.state);

        return Promise.resolve(group ? _.cloneDeep(group) : null);
    };

    create = ({ id, name, color, query }: IGroup, hierarchy: string) => {
        let groups = this.state;

        if (hierarchy) {
            const state = this.state as IGroupDic;
            state[hierarchy] = state[hierarchy] || [];
            groups = state[hierarchy];
        }

        (groups as IGroup[]).unshift({ id, name, color, query });
        console.info(`SmartGroups: Created group w/ id ${id}:`, this.state);

        return this.saveToStorage();
    };

    update = ({ id, name, color, query }: IGroup, hierarchy: string) => {
        if (!id) {
            return;
        }

        const group = findGroup(id, hierarchy, this.state);

        if (!group) {
            throw new Error(`Smart group '${id}' does not exist.`);
        }

        group.name = name;
        group.query = _.cloneDeep(query);
        group.color = color;
        console.info(`SmartGroups: Updated group w/ id '${id}':`, this.state);

        return this.saveToStorage();
    };

    reorder = (oldIndex: number, newIndex: number, hierarchy: string) => {
        const reorderGroup = (groups: IGroup[]) => moveFn(groups, oldIndex, newIndex);

        if (hierarchy) {
            const state = this.state as IGroupDic;
            state[hierarchy] = reorderGroup(state[hierarchy]);
        } else {
            this.state = reorderGroup(this.state as IGroup[]);
        }

        console.info(`SmartGroups: Reordered group that was at index '${oldIndex}' to '${newIndex}':`, this.state);

        return this.saveToStorage();
    };

    delete = ({ id }: IGroup, hierarchy: string) => {
        const deleteGroup = (groups: IGroup[]) => groups.filter(group => group.id !== id);

        if (Array.isArray(this.state)) {
            this.state = deleteGroup(this.state);
        } else {
            const groups = this.state[hierarchy] ?? [];
            this.state[hierarchy] = deleteGroup(groups);
        }
        console.info(`SmartGroups: Deleted group w/ id ${id}:`, this.state);

        return this.saveToStorage();
    };
}

export const SmartGroupsAPIFactory = () => [
    'CONFIG',
    'StorageAPI',
    function SmartGroupsAPIFactory(CONFIG: any, StorageAPI: IStorageInterfaceAPI<IGroup[] | IGroupDic>) {
        return () => {
            const storageAPIInstance = new StorageAPI('groups');

            return storageAPIInstance.then(api => {
                const hierarchies = CONFIG.hierarchies;

                return api.get().then(initial => {
                    let state = _.cloneDeep(initial || []);

                    if (hierarchies && _.isArray(state)) {
                        console.warn('Converting single hierarchy groups to multiple hierarchy groups...');
                        const newState: IGroupDic = {};
                        newState[hierarchies[0].id] = state;
                        state = newState;
                    }
                    if (!hierarchies && !_.isArray(state) && _.isPlainObject(state)) {
                        console.warn('Converting multiple hierarchy groups to single hierarchy groups...');
                        const hierarchy = Object.keys(state)[0];
                        state = hierarchy ? state[hierarchy] || [] : [];
                    }

                    return new SmartGroupsAPI(state, api);
                });
            });
        };
    },
];

interface ISmartGroupFilterValue {
    count: number;
    id: string;
    label: string;
    selected: boolean;
}

export interface IProperty {
    column: string;
    group: string;
    id: string;
    label: string;
    plural: string;
    table: string;
    values: ISmartGroupFilterValue[];
}

export interface ISmartGroupFilter {
    label: string;
    model: {
        count: number;
        id: string;
        label: string;
        selected: boolean;
    };
    property: IProperty;
}

export interface ISmartGroupFilterService {
    selected: ISmartGroupFilter[];
    filter: IQueryFilters;
    callbacks: {
        [key: string]: () => void;
    };
    reset: () => void;
}

// We need to have a service in order to share state between the filters.
// Usually, we'd want to set up some directive scope variables, and pass the
// references through, but we can't because we're using `ng-switch` and it creates
// its own scope.
export const SmartGroupsFilterService = () => [
    function SmartGroupsFilterServiceInstance(): ISmartGroupFilterService {
        const serviceInstance = {
            selected: [],
            filter: {},
            callbacks: {},
            reset: () => {
                serviceInstance.selected = [];
                serviceInstance.callbacks = {};
            },
        };

        return serviceInstance;
    },
];
