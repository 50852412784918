module.exports = ->
    restrict: 'E'
    scope:
        month:        '='
        onSelect:     '&'
        onSelectWeek: '&'
        start:        '='
        end:          '='
    replace: true
    template: \
    """
    <div class="datepicker-month">
        <div class="datepicker-weeks">
            <div class="datepicker-week" ng-repeat="week in month.weeks track by $index">
                <span class="datepicker-week-number" ng-click="onSelectWeek({week:week})">W{{ week.offset + 1 }}</span>
                <div class="datepicker-day"
                    ng-repeat="nrfDate in week track by $index"
                    ng-class="{
                        'state-selected-start': start && nrfDate.eq(start)
                    ,   'state-selected-end':   end   && nrfDate.eq(end)
                    ,   'state-in-range':       start && end && nrfDate.within(start, end)
                    ,   'state-disabled':       nrfDate === null
                    }"
                    ng-click="onSelect({nrfDate:nrfDate})">
                    <span class="date-month">{{ nrfDate.toDate().format('MMM') }}</span>
                    <span class="date-day">{{ nrfDate.toDate().format('DD')}}</span>
                </div>
            </div>
            <div class="datepicker-week" ng-if="month.weeks.length === 4">
                <span class="datepicker-week-number"></span>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
                <div class="datepicker-day state-blank"></div>
            </div>
        </div>
    </div>
    """
