import { IConfigObj, IQuery, IQueryFilterItem } from '../../lib/types';
import { cloneDeep, compact, extend, get, keyBy, sortBy } from 'lodash';
import { IDataDescriptorsService } from '../../services/hierarchy.service';
import { ICustomersReportColumnDefs } from './customer.types';
import { ICustomer, IFileService, IQueryServiceAPI } from '../../modules/services/query-service.types';

export const CostumerStatsServiceInstance = () => [
    'QueryServiceAPI',
    function CustomerStatsService(QueryServiceAPI: IQueryServiceAPI) {
        return {
            fetch: (query: IQuery) => {
                query = cloneDeep(query);
                const queryServiceAPIInstance = new QueryServiceAPI();
                return queryServiceAPIInstance.then(api => {
                    return api.query.customerStats(query).then(data => data[0]);
                });
            },
        };
    },
];

export const CostumerCountServiceInstance = () => [
    'QueryServiceAPI',
    function CustomerCountService(QueryServiceAPI: IQueryServiceAPI) {
        return {
            fetch: (query: IQuery) => {
                query = cloneDeep(query);
                const queryServiceAPIInstance = new QueryServiceAPI();
                return queryServiceAPIInstance.then(api => {
                    return api.query.customerCount(query).then(data => data[0].count);
                });
            },
        };
    },
];

export const CustomersReportColumnDefsInstance = () => [
    '$q',
    '$filter',
    'DataDescriptors',
    'CONFIG',
    function CustomersReportColumnDefs(
        $q: angular.IQService,
        $filter: angular.IFilterFunction,
        DataDescriptors: IDataDescriptorsService,
        CONFIG: IConfigObj,
    ) {
        return {
            fetch: () => {
                let propertiesConfig = get(CONFIG, 'views.customers.properties') as string[];

                if (!Array.isArray(propertiesConfig) || propertiesConfig.length === 0) {
                    return $q.when(null);
                }

                propertiesConfig = propertiesConfig.map(c => c.replace('customers.', ''));

                return DataDescriptors.fetch()
                    .then(descriptors => {
                        if (!descriptors.customers) {
                            return $q.when(null);
                        }

                        const descriptorsDic = keyBy(descriptors.customers, c => c.name);
                        const compactDescriptors = compact(propertiesConfig.map(p => descriptorsDic[p]));

                        return compactDescriptors.map(d => {
                            const name = d.name;
                            return {
                                field: name,
                                headerName: $filter('inflector')(name),
                            };
                        });
                    })
                    .catch(err => {
                        console.error('Error while fetching customer report column defs:', err);

                        return $q.when(null);
                    });
            },
        };
    },
];

export const CustomersReportServiceInstance = () => [
    'QueryServiceAPI',
    'FileService',
    'CustomersReportColumnDefs',
    function CustomersReportService(
        QueryServiceAPI: IQueryServiceAPI,
        FileService: IFileService,
        CustomersReportColumnDefs: ICustomersReportColumnDefs,
    ) {
        const parseVisitFrequencies = (customersList: ICustomer[]) => {
            const maxTimestamp = customersList[0] && customersList[0].max_timestamp;

            if (!maxTimestamp) {
                return customersList;
            }

            const maxCounts: Record<string, number> = {};

            return customersList.map(customer => {
                customer.visit_frequencies = customer.visit_frequencies.map(v => {
                    const maxCount = maxCounts[v._key];
                    v.maxCount = maxCount;
                    v.percentage = !(maxCount && v.count) ? 0 : Math.max(0.05, v.count / maxCount);

                    return v;
                });

                return customer;
            });
        };

        const fetch = (query: IQuery) => {
            const queryServiceAPIInstance = new QueryServiceAPI();
            return queryServiceAPIInstance.then(api => {
                return api.query
                    .customersReport(query)
                    .then(data => parseVisitFrequencies(cloneDeep(data)))
                    .catch(err => {
                        console.error(err);
                        throw err;
                    });
            });
        };

        return {
            fetch,
            export: (query: IQuery) => {
                query = query ? cloneDeep(query) : {};
                return CustomersReportColumnDefs.fetch()
                    .then(columnDefs => {
                        delete query.limit;
                        delete query.offset;
                        query.sort = { total_spent: -1 };
                        query.type = 'xlsx';
                        query.export = columnDefs ? { columnDefs } : {};
                        return fetch(query);
                    })
                    .then((result: ICustomer[]) => FileService.send('42-export-customers.xlsx')(result));
            },
        };
    },
];

export const CustomerAPIFactory = () => [
    'QueryServiceAPI',
    function CustomerAPI(QueryServiceAPI: IQueryServiceAPI) {
        const queryServiceAPIInstance = new QueryServiceAPI();

        return () =>
            queryServiceAPIInstance.then(api => {
                const buildTransactions = (customer: ICustomer) => {
                    const result = sortBy(customer.transactions, x => x.timestamp).reverse();

                    return result
                        .map(transaction => {
                            if (!transaction.items[0] || !transaction.items[0].item) {
                                return transaction;
                            }

                            // normalizing the new hana result to work with old postgres result

                            transaction.items = transaction.items.map(transactionItem => {
                                transactionItem = extend(transactionItem, transactionItem.item);
                                delete transactionItem.item;
                                return transactionItem;
                            });

                            delete transaction.transactionItems;

                            return transaction;
                        })
                        .map((transaction, index) => {
                            transaction._index = index;
                            transaction.itemsPurchased = transaction.items.reduce(
                                (sum: number, x: { quantity: number }) => sum + x.quantity,
                                0,
                            );
                            transaction.items.forEach(item => (item.showQuantity = Math.abs(item.quantity) > 1));

                            return transaction;
                        });
                };

                const buildQuery = (customerId: string, modifiers: any): IQuery => {
                    return {
                        filters: {
                            customers: {
                                id: customerId,
                            } as IQueryFilterItem & { id: string },
                        },
                        modifiers,
                    };
                };

                return {
                    getDetails: (customerId: string, modifiers: any) => {
                        if (!customerId) {
                            throw new Error('`customerId` argument is required.');
                        }

                        return api.query.getCustomerDetails(buildQuery(customerId, modifiers)).then(result => {
                            const customer = result[0] || null;
                            if (!customer) {
                                return customer;
                            }

                            customer.transactions = buildTransactions(customer);

                            return customer;
                        });
                    },
                };
            });
    },
];
