import _ from 'lodash';
import { IOutsideElementClick, IQueryFilters } from '../../lib/types';
import { ISmartGroupFilter, ISmartGroupFilterService, SmartGroupFilterViewModel } from './smart-groups.service';

export const SmartGroupsFilterDirective = () => [
    '$timeout',
    '$q',
    'OutsideElementClick',
    'SmartGroupsFilterService',
    'Utils',
    function SmartGroupsFilterDirectiveInstance(
        $timeout: angular.ITimeoutService,
        $q: angular.IQService,
        OutsideElementClick: IOutsideElementClick,
        SmartGroupsFilterService: ISmartGroupFilterService,
        Utils: any,
    ) {
        return {
            restrict: 'E',
            scope: {
                descriptor: '=', // The object that describes the filter to be displayed.
                filters: '=', // The `filters` property of a `query` object.
            },
            replace: true,
            template: `
                <article class="modal smart-groups-filter-container animation-filter" ng-if="descriptor">
                    <div class="overlay"></div>
                    <article class="smart-groups-filter">
                        <header>
                            <h1>{{ descriptor.label }}</h1>
                            <div class="button-close" ng-click="actions.cancel()">
                                <svg class="icon topbar-sidebarToggleIcon" viewBox="0 0 32 32"><polygon points="28.374,5.747 26.253,3.626 16,13.879 5.747,3.626 3.626,5.747 13.879,16 3.626,26.253 5.747,28.374 16,18.121 26.253,28.374 28.374,26.253 18.121,16 "></polygon></svg>
                            </div>
                        </header>
                        <div class="sidepanel">
                            <main>
                                <section class="instructions">{{ descriptor.instructions }}</section>
                                <section class="selected">
                                <h1>{{ descriptor.selectedHeader }}</h1>
                                <ul>
                                    <li ng-repeat="value in selected">{{ value.label }}</li>
                                </ul>
                                </section>
                            </main>
                            <footer>
                                <div class="button-reset" ng-click="actions.reset()">reset</div>
                                <div class="button-save" ng-click="actions.save()" ng-class="{unsaved:isUnsaved()}">save</div>
                            </footer>
                        </div>
                        <main>
                            <div class="loadable" promise-tracker="smart-groups-filter"></div>
                            <article ng-if="descriptor.id == 'items'">
                                <smart-group-filter-items table="descriptor.id" filter="filters"></smart-group-filter-items>
                            </article>
                            <article ng-if="descriptor.id == 'stores'">
                                <smart-group-filter-items table="descriptor.id" filter="filters"></smart-group-filter-items>
                            </article>
                        </main>
                    </article>
                </article>
            `,
            link: function SmartGroupsFilterDirectiveLink(
                $scope: angular.IScope & { descriptor: SmartGroupFilterViewModel; filters: IQueryFilters } & any,
            ) {
                SmartGroupsFilterService.reset();

                $scope.SmartGroupsFilterService = SmartGroupsFilterService;

                let outsideElementClickCleanup: () => void;

                const reset = () => {
                    SmartGroupsFilterService.reset();
                    $scope.descriptor = null;
                };

                const initOutsideElementClick = () => {
                    outsideElementClickCleanup = new OutsideElementClick(
                        $scope,
                        $('.smart-groups-filter-container .smart-groups-filter'),
                        () => reset(),
                    );
                };

                $scope.$watch('descriptor.id', (descriptorId: string) => {
                    if (outsideElementClickCleanup) {
                        outsideElementClickCleanup();
                    }

                    if (!descriptorId) {
                        return;
                    }

                    $timeout(initOutsideElementClick);
                });

                $scope.$watchCollection(
                    'SmartGroupsFilterService.selected',
                    (selected: ISmartGroupFilter[], lastSelected: ISmartGroupFilter[]) => {
                        $scope.lastSelected = lastSelected;
                        $scope.selected = selected;
                    },
                );

                $scope.isUnsaved = () => {
                    if (!$scope.lastSelected || !SmartGroupsFilterService.filter || !$scope.filters) {
                        return false;
                    }

                    return Utils.object.hash(SmartGroupsFilterService.filter) !== Utils.object.hash($scope.filters);
                };

                $scope.actions = {
                    save: () => {
                        return $q.when(SmartGroupsFilterService.callbacks.save()).then(filter => {
                            $scope.filters = _.assign($scope.filters, Utils.copy(filter));
                            Object.keys($scope.filters).map(table => {
                                if (Utils.object.isEmpty($scope.filters[table])) {
                                    delete $scope.filters[table];
                                }
                            });
                            reset();
                        });
                    },
                    reset: () =>
                        $q
                            .when(SmartGroupsFilterService.callbacks.reset())
                            .then(() => (SmartGroupsFilterService.filter = _.cloneDeep($scope.filters))),
                    cancel: () => reset(),
                };
            },
        };
    },
];
