moment = require 'moment'
{ deepStripAngularProperties } = require '../../lib/utils-helper.ts'

module = angular.module('42.controllers.main')

module.directive 'statusMessageBar', ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <div class="status-message-bar" ng-if="model.state.active && !model.state.invalid">
        <div class="status status-{{ model.state.type }}" ng-click="model.hide()">
            <i class="icon-status {{ model.state.icon }}"></i>
            <span class="message">{{ model.state.message }}</span>
            <i class="icon-close">
                <svg viewBox="0 0 32 32"><polygon points="28.374,5.747 26.253,3.626 16,13.879 5.747,3.626 3.626,5.747 13.879,16 3.626,26.253 5.747,28.374 16,18.121 26.253,28.374 28.374,26.253 18.121,16 "></polygon></svg>
            </i>
        </div>
    </div>
    """

module.factory 'StatusMessageModel', ($rootScope) -> ->

    STATE_VERSION = 0
    STORAGE_KEY = '42.views.status-message-bar.state'

    MESSAGE_TYPES = {'error', 'warning', 'success', 'notification'}

    MESSAGE_TYPES_TO_ICON = do ->
        result = {}
        result[MESSAGE_TYPES.error]        = "icon-thumbs-down"
        result[MESSAGE_TYPES.warning]      = "icon-alert"
        result[MESSAGE_TYPES.notification] = "icon-thumbs-up"
        result[MESSAGE_TYPES.success]      = "icon-thumbs-up"
        return result

    loadState = ->
        try
            state = localStorage.getItem(STORAGE_KEY)
            state = try JSON.parse(state)
            return null if not (state and state.version is STATE_VERSION)
            state = deepStripAngularProperties(state)
            return state
        catch error
            console.error('failed to load status message state:', error)
            return null

    saveState = (state) ->
        try
            data = JSON.stringify(_.pick(state, 'id', 'active', 'version'))
            localStorage.setItem(STORAGE_KEY, data)
        catch error
            console.error('failed to save status message state:', error)
            return

    state:
        version: STATE_VERSION
        id:      null
        message: null
        type:    null
        timestamp: null
        icon:    null
        active:  false
        invalid: false

    show: ->
        @state.active = true

    toggle: ->
        @state.active = not @state.active
        saveState(@state)

    hide: ->
        @state.active = false

    save: ->
        saveState(@state)

    load: ->
        loadState()

    update: (status) ->

        loadedState = do ->
            state = loadState() ? {}
            state.id ?= @state?.id ? null
            state.active ?= @state?.active ? false
            return state

        @state = do ->
            isInvalid = !status or (!status.message and !status.latestTransactionTimestamp) or (!status.messageType)
            return {invalid:true} if isInvalid

            state =
                version: STATE_VERSION
                id: status.messageId
                timestamp: status.latestTransactionTimestamp
                type: status.messageType
                icon: MESSAGE_TYPES_TO_ICON[status.messageType]
                message: status.message or do ->
                    # This means we're doing realtime updates
                    timestamp = moment(status.load.end).format('MMM Do at h:mma')
                    return "Data updated successfully at #{timestamp}" if not status.latestTransactionTimestamp
                    timestamp = moment(status.latestTransactionTimestamp).format('MMM Do [at] h:mma')
                    return "The most recent transaction is from #{timestamp} BST"

            state.active = do ->
                isNewMessage = loadedState.id isnt state.id
                return loadedState.active if not isNewMessage
                return state.type isnt MESSAGE_TYPES.success

            return state

        saveState(@state)
        return
