_ = require('../../../lib/lodash-helper.ts')

module.exports = (NRFDateUtils) -> (start, end) ->

    result = []

    # startClamped = start.clampLeft('year')
    # endClamped   = end.clampRight('month').subtract(1, 'day')

    bounds =
        start: start.startOf('month')
        end:   end.endOf('month').subtract(1, 'day')

    current = null
    NRFDateUtils.range(bounds.start, bounds.end).forEach (date) ->

        month       = date.month
        year        = date.year
        weekOffset  = date.weekOffset
        weekOfMonth = date.weekOfMonth
        dayOfWeek   = date.dayOfWeek

        date = null if not date.within(start, end)

        if not current or (current.year.key isnt year.key)
            current = {}
            current.year = year
            current.views = {}
            current.views.segmented = []
            current.views.flattened = []
            current.views.monthly   = []
            result.push(current)

        current.views.flattened.push(date)

        current.views.monthly = do ->
            monthly = current.views.monthly
            monthly[month.offset] ?= do ->
                m = []
                m.month = month
                return m
            monthly[month.offset][weekOfMonth] ?= do ->
                week = (null for [1..7])
                week.offset = weekOffset
                return week
            monthly[month.offset][weekOfMonth][dayOfWeek] = date
            return monthly

    for year in result
        year.views.monthly = _.compact(year.views.monthly)

    return result
