import _, { isPlainObject } from 'lodash';
import { getOrganization } from '../../lib/auth';
import * as AgGrid from '@ag-grid-community/all-modules';
import { IQuery } from '../../lib/types';
import { IProperty } from '../smart-groups/smart-groups.service';
import { RowHeightParams } from 'ag-grid-community';
import { CellClassParams } from '@ag-grid-community/all-modules';
import { IPromise } from 'angular';

interface IMetricsKPIsService {
    fetch: (query: IQuery) => IPromise<IMetricData[]>;
}

export const MetricsFunnelNodeViewDirectiveInstance = () => [
    '$rootScope',
    '$q',
    'Utils',
    'MetricsFunnelNodeGridViewModel',
    'MetricsKPIsService',
    function MetricsFunnelNodeViewDirective(
        $rootScope: angular.IRootScopeService & IQuery,
        $q: angular.IQService,
        Utils: any,
        MetricsFunnelNodeGridViewModel: any,
        MetricsKPIsService: IMetricsKPIsService,
    ) {
        return {
            restrict: 'E',
            scope: {
                funnel: '=',
                selected: '=',
                exportSetter: '=',
                organization: '=',
            },
            replace: true,
            template: `
                <article class="metrics-funnel-node">
                    <div class="grid-container" ng-if="grid.options">
                        <div class="ag-42 grid grid-new ag-theme-alpine" ag-grid="grid.options"></div>
                    </div>
                </article>
            `,
            link: function MetricsFunnelNodeViewDirectiveLink($scope: angular.IScope & any) {
                $scope.grid = new MetricsFunnelNodeGridViewModel($scope);

                const isProperty = (field: string) => /^property\d+/.test(field);

                const isItemMetric = (field: string) => /^item_/.test(field);

                const getQuerySortFromGrid = () => {
                    const columnsState = $scope.grid.options?.columnApi.getColumnState();

                    const gridSortModel: { colId?: string; sort?: string | null | undefined } = {};

                    if (columnsState) {
                        const sortedColumn: AgGrid.ColumnState | undefined = columnsState.find(
                            (column: AgGrid.ColumnState) => column.sort,
                        );

                        if (sortedColumn) {
                            gridSortModel.colId = sortedColumn.colId;
                            gridSortModel.sort = sortedColumn.sort;
                        }
                    }

                    if (!gridSortModel.colId) {
                        return null;
                    }

                    return {
                        type: $scope.selected.node.property.type,
                        order: gridSortModel.sort === 'desc' ? -1 : 1,
                        limit: null,
                        field: !isProperty(gridSortModel.colId)
                            ? gridSortModel.colId
                            : $scope.selected.node.property.id,
                    };
                };

                const getRefreshId = () => {
                    if (!$scope.selected?.node?.property?.id) {
                        return;
                    }

                    const property = $scope.selected.node.property.id;
                    const queryHash = Utils.object.hash($rootScope.query || {});

                    return `${queryHash}:${property}`;
                };

                const refresh = () => {
                    if (!$scope.selected?.node) {
                        return;
                    }

                    $scope.grid.updateData($scope.selected.node, null, $scope.organization);
                    const query = Utils.copy($rootScope.query || {});
                    const refreshId = getRefreshId();

                    $scope.selected.node.fetch(query).then((data: IMetricsFunnelRowData) => {
                        if (refreshId !== getRefreshId()) {
                            return;
                        }

                        $scope.grid.updateData($scope.selected.node, data, $scope.organization);
                    });
                };

                $scope.select = (value: string) => {
                    $scope.funnel?.select($scope.selected.node, value);
                };

                $scope.export = () => {
                    if (!$scope.selected?.node) {
                        return;
                    }

                    const query = Utils.copy($rootScope.query || {});

                    return $q
                        .all([MetricsKPIsService.fetch(query), getOrganization()])
                        .then(([metrics, organizationId]) => {
                            const metricsByField = _.keyBy(metrics, x => x.field);

                            const queryProps = $scope.selected.node._getAssociatedProperties();
                            const metricProps = metrics
                                .filter(x => isItemMetric(x.field))
                                .map(x => x.field.replace(/^item_/, 'items.'));
                            query.options.associatedProperties = queryProps.concat(metricProps);

                            const columnDefs = _.cloneDeep(
                                $scope.grid.getColumnDefs($scope.selected.node, null, organizationId),
                            ) as IColumnDef[];

                            const buildQuery = () => {
                                const sort = getQuerySortFromGrid();

                                if (sort) {
                                    query.options ??= {};
                                    query.options.sort = [sort];
                                    delete query.sort;
                                }

                                const exportedColumnDefs = columnDefs.reduce((result, x) => {
                                    if (x.field) {
                                        if (isProperty(x.field) || x.field === 'item_image__left') {
                                            return result;
                                        }

                                        x.cellClass = metricsByField[x.field]?._cellClass;
                                        delete x._cellClass;
                                        delete x.cellRenderer;
                                        delete x.drilldown;
                                        delete x.filter;
                                        delete x.columnViewName;
                                        delete x.category;

                                        result.push(x);
                                    }

                                    return result;
                                }, [] as IColumnDef[]);

                                query.export = {
                                    properties: Utils.copy($scope.funnel.properties),
                                    columnStyle: 'tabular',
                                    columnDefs: exportedColumnDefs,
                                };

                                query.options.metrics = _.compact(exportedColumnDefs.map(columnDef => columnDef.field));
                                return query;
                            };

                            return $scope.selected.node.export(buildQuery());
                        });
                };

                $scope.$watch('exportSetter', () => $scope.exportSetter($scope.export));

                $scope.$watch(
                    'funnel.metrics.selected',
                    (selectedMetrics: IColumnDef[]) => {
                        if (!selectedMetrics) {
                            return;
                        }

                        const columnSorting = $scope.grid.columnSortOrder;

                        if (columnSorting && columnSorting.length === selectedMetrics.length) {
                            const hasSameOrder = selectedMetrics.every(
                                (metric, index) => columnSorting[index] === metric.field,
                            );

                            if (hasSameOrder) {
                                return;
                            }

                            const hasMoved = $scope.grid.moveColumns($scope.funnel.metrics?.selected);

                            if (hasMoved) {
                                return;
                            }
                        }

                        $scope.grid.resetColumnDefs();
                        $scope.grid.updateColumns(
                            $scope.selected.node,
                            _.cloneDeep($scope.funnel.metrics?.selected),
                            $scope.organization,
                        );
                    },
                    true,
                );

                $scope.$watch('grid.columnSortOrder', (columnSortOrder: string) => {
                    if ($scope.funnel?.metrics?.selected) {
                        $scope.funnel.metrics.selected = _.sortBy($scope.funnel?.metrics.selected, item =>
                            columnSortOrder.indexOf(item.field),
                        );
                    }
                });

                $rootScope.$watch('initialized', initialized => {
                    if (!initialized) {
                        return;
                    }

                    $scope.$watch(
                        'selected.property',
                        (property: IProperty) => {
                            if (
                                !property ||
                                !$scope.selected.node?.property ||
                                $scope.selected.node.property.id === property.id
                            ) {
                                return;
                            }

                            $scope.selected.node.property = property;
                            delete $scope.selected.node.value;
                            $scope.funnel.nodes = $scope.funnel.nodes.slice(0, $scope.selected.node.level + 1);
                            refresh();
                        },
                        true,
                    );

                    $scope.$watch('selected.node', (node: IMetricsFunnelNode) => {
                        if (!node) return;

                        $scope.selected.property = _.find(
                            $scope.selected.node.properties,
                            property => property.id === $scope.selected.node.property.id,
                        );

                        refresh();
                    });

                    $scope.$on(
                        '$destroy',
                        $rootScope.$on('query.refresh', () => refresh()),
                    );
                });
            },
        };
    },
];

export const MetricsFunnelNodeGridViewModelFactory = () => [
    '$filter',
    'MetricsGridCellRenderers',
    'MetricsGridDefaultCellRenderer',
    'MetricsFunnelNodeGridViewModelAssociatedColumns',
    'METRICS_TABLE_ROW_HEIGHT',
    function MetricsFunnelNodeGridViewModelInstance(
        $filter: angular.IFilterFunction,
        MetricsGridCellRenderers: any,
        MetricsGridDefaultCellRenderer: any,
        MetricsFunnelNodeGridViewModelAssociatedColumns: any,
        METRICS_TABLE_ROW_HEIGHT: number,
    ) {
        return ($scope: angular.IScope & any) => {
            let dataColumns: IColumnDef[] = [];

            const gridOptions: AgGrid.GridOptions = {
                angularCompileFilters: true,
                headerHeight: 35,
                suppressDragLeaveHidesColumns: true,
                sortingOrder: ['desc', 'asc', null],
                getRowHeight: (params: RowHeightParams) => (params.data?.property0 === 'total' ? 40 : 85),
                onDragStopped: () => updateColumnSortOrder(),
            };

            const updateColumnSortOrder = () => {
                const fields: string[] = [];

                gridOptions.api?.getColumnDefs().reduce((acc: string[], columnDef) => {
                    if ('children' in columnDef) {
                        columnDef.children.forEach((child: AgGrid.ColDef) => {
                            if (child.field && !child.pinned) {
                                acc.push(child.field);
                            }
                        });
                    }

                    return acc;
                }, fields);

                if (!_.isEqual(fields, modelFields.columnSortOrder)) {
                    modelFields.columnSortOrder = fields || [];
                }
            };

            const mergeColumnDefs = (
                node: IMetricsFunnelNode,
                columnsValues: IColumnDef[],
                organizationId: string,
            ): IColumnDef[] => {
                let valueColumns: AgGrid.ColDef[] = [];

                if (node.property.id.indexOf('items.') === 0 && node.property.id !== 'items.season') {
                    const imageColumn: AgGrid.ColDef = {
                        headerName: '',
                        field: 'item_image__left', // This is used as a column ID
                        width: METRICS_TABLE_ROW_HEIGHT,
                        cellClass: 'item-image-render',
                        lockPosition: true,
                        sortable: false,
                        pinned: 'left',
                        cellRenderer: ({ data }) =>
                            data.property0 === 'total' ? '' : MetricsGridCellRenderers.image(data),
                    };

                    valueColumns.push(imageColumn);
                }

                const valueColumn: AgGrid.ColDef = {
                    field: 'property0',
                    headerName: node.property.label,
                    filter: 'agTextColumnFilter',
                    sortable: true,
                };

                valueColumns.push(valueColumn);

                const [associatedColumns, width] = new MetricsFunnelNodeGridViewModelAssociatedColumns(
                    node,
                    organizationId,
                );

                if (width !== undefined) {
                    valueColumn.width = width;
                }

                valueColumns = valueColumns.concat(associatedColumns).map(column => {
                    if (column.pinned === undefined) {
                        column.pinned = true;
                    }

                    return column;
                });

                let columns = _.compact(valueColumns.concat(columnsValues));
                // shifting pinned columns to the left
                columns = columns.filter(x => x.pinned).concat(columns.filter(x => !x.pinned));

                return columns.map(column => {
                    if (isPlainObject(column)) {
                        column.cellClassRules = {
                            'cell-column-pinned-total-value': (params: CellClassParams) =>
                                column.pinned && params.data.property0 === 'total',
                            'cell-row-pinned-total-value': (params: CellClassParams) =>
                                !column.pinned && params.node.rowPinned,
                            'cell-column-pinned-value': (params: CellClassParams) =>
                                column.pinned && params.data.property0 !== 'total',
                            'cell-row-value': (params: CellClassParams) =>
                                !column.pinned && params.data.property0 !== 'total',
                        };
                    }

                    return column;
                });
            };

            const getColumnDefs = (
                node: IMetricsFunnelNode,
                columns: IColumnDef[] | null | undefined,
                organizationId: string,
            ): IColumnDef[] => {
                columns = columns || dataColumns;

                return mergeColumnDefs(node, columns, organizationId).map((columnDef: IColumnDef) => {
                    columnDef.headerName ??= $filter('inflector')(columnDef.field);
                    columnDef.cellRenderer ??= MetricsGridDefaultCellRenderer;
                    columnDef.columnGroupShow = 'open';

                    if (!columnDef.pinned) {
                        columnDef.sortable = true;
                    }

                    columnDef.resizable = true;
                    columnDef.filter = columnDef.filter || false;

                    if (columnDef.cellFilter) {
                        const cellFilterValue = columnDef.cellFilter.split(':')[0];
                        columnDef.filter =
                            ['number', 'percent', 'money'].indexOf(cellFilterValue) > -1
                                ? 'agNumberColumnFilter'
                                : false;
                    }

                    return columnDef;
                });
            };

            const updateColumns = (node: IMetricsFunnelNode, columns: IColumnDef[], organizationId: string) => {
                dataColumns = columns;

                const columnDefs = getColumnDefs(node, columns, organizationId);
                columnDefs.forEach((columnDef, index) => {
                    if (columnDef.pinned) {
                        columnDef.pinned = 'left';
                        columnDef.suppressMovable = true;
                    }

                    const nextHeaderGroup = columnDefs[index + 1]?.headerGroup;

                    if (columnDef.headerGroup !== nextHeaderGroup) {
                        if (columnDef.cellClass) {
                            columnDef.cellClass = 'cell-value-group-end';
                        }

                        if (_.isFunction(columnDef.cellClass)) {
                            const fn = columnDef.cellClass;

                            columnDef.cellClass = (...args) => {
                                let result = fn(...args);

                                if (!Array.isArray(result)) {
                                    result = _.compact([result]);
                                }

                                result.push('cell-value-group-end');
                                return _.uniq(result);
                            };
                        }

                        if (_.isString(columnDef.cellClass)) {
                            columnDef.cellClass = _.uniq(['cell-value-group-end', columnDef.cellClass]);
                        }

                        columnDef.headerClass = columnDef.cellClass;
                    }
                });

                const columnDefsResultDict: Record<string, AgGrid.ColGroupDef> = {};

                columnDefs.reduce((acc, column) => {
                    column.headerGroup = column.headerGroup || ' ';
                    const headerName = column.headerGroup;

                    const baseColumnDef = {
                        headerName: headerName,
                        children: [],
                        marryChildren: true,
                    };

                    acc[headerName] ??= baseColumnDef;
                    acc[headerName].children.push(_.omit(column, 'headerGroup'));

                    return acc;
                }, columnDefsResultDict);

                const columnDefsResult = Object.values(columnDefsResultDict);

                if (columnDefsResult[0] && columnDefsResult[0].children) {
                    columnDefsResult[0].children.forEach((childColumn: AgGrid.ColDef) => {
                        childColumn.onCellClicked = (cell: any) => {
                            if (cell.data.property0 === 'total') return;

                            $scope.select(cell.data.property0);
                        };
                    });
                }

                gridOptions.api?.setColumnDefs(_.cloneDeep(columnDefsResult));
                updateColumnSortOrder();
            };

            const updateData = (
                node: IMetricsFunnelNode,
                data: IMetricsFunnelRowData | null,
                organizationId: string,
            ) => {
                data ??= {};
                data.rows ??= null;
                data.total ??= null;

                let totalRow: IRowDataMetrics[] = data.total || [];

                if (!totalRow || totalRow.length === 0) {
                    totalRow = [{ property0: 'total' }];
                } else {
                    totalRow[0].property0 = 'total';
                }

                if (_.isNil(data.rows)) {
                    gridOptions.api?.setPinnedTopRowData([]);
                    gridOptions.api?.setRowData([]);
                    gridOptions.api?.showLoadingOverlay();
                } else {
                    gridOptions.api?.setPinnedTopRowData(totalRow);
                    gridOptions.api?.setRowData(data.rows);
                    updateColumns(node, dataColumns, organizationId);
                    gridOptions.api?.hideOverlay();
                }
            };

            const moveColumns = (data: IColumnDef[]): boolean => {
                const metricIndex = data.findIndex(
                    (metric, index) => metric.field !== modelFields.columnSortOrder[index],
                );

                const columns = gridOptions.columnApi?.getAllColumns();

                if (columns) {
                    const columnToSwapIndex = columns.findIndex(
                        column => data[metricIndex].field === column.getColId(),
                    );
                    const columnToSwap = columns[columnToSwapIndex];

                    const columnToSwapParent = columnToSwap?.getParent();
                    const columnDestinationParent = columns[metricIndex + 1].getParent();

                    if (
                        columnToSwapParent &&
                        columnDestinationParent &&
                        columnToSwapParent.getGroupId() === columnDestinationParent.getGroupId()
                    ) {
                        gridOptions.columnApi?.moveColumn(columnToSwap, metricIndex + 1);
                        modelFields.columnSortOrder = data.map(metric => metric.field || '');

                        return true;
                    }
                }

                return false;
            };

            const resetColumnDefs = () => gridOptions.api?.setColumnDefs([]);

            const modelFields: IMetricsFunnelNodeGridViewModel = {
                options: gridOptions,
                updateData,
                resetColumnDefs,
                getColumnDefs,
                updateColumns,
                moveColumns,
                columnSortOrder: [],
            };

            return modelFields;
        };
    },
];

export interface IColumnDef extends AgGrid.ColDef {
    headerGroup?: string;
    cellFilter?: string;
    _cellClass?: string;
    drilldown?: boolean;
    columnViewName?: string;
    category?: string;
}

export interface IMetricsFunnelNode {
    level: number;
    parent?: IMetricsFunnelNode;
    properties: IProperty[];
    property: IProperty;
    value: string | number | null | undefined;
}

export interface IMetricsFunnelNodeGridViewModel {
    options: AgGrid.GridOptions;
    updateData: (node: IMetricsFunnelNode, data: IMetricsFunnelRowData | null, organizationId: string) => void;
    resetColumnDefs: () => void;
    getColumnDefs: (node: IMetricsFunnelNode, columns: IColumnDef[], organizationId: string) => IColumnDef[];
    updateColumns: (node: IMetricsFunnelNode, columns: IColumnDef[], organizationId: string) => void;
    moveColumns: (data: IColumnDef[]) => boolean;
    columnSortOrder: string[];
}

export type IRowDataMetrics = Record<string, any>;
export interface IMetricsFunnelRowData {
    rows?: IRowDataMetrics[] | null | undefined;
    total?: IRowDataMetrics[] | null | undefined;
}

export interface IMetricData {
    category: string;
    cellFilter: string;
    field: string;
    headerGroup: string;
    headerName: string;
    queryName: string;
    cellClass: string;
}

angular
    .module('42.modules.views.metrics')
    .directive('metricsFunnelNodeView', MetricsFunnelNodeViewDirectiveInstance())
    .factory('MetricsFunnelNodeGridViewModel', MetricsFunnelNodeGridViewModelFactory());
