module.exports = -> class NRFCalendarViewModel

    constructor: (@calendar) ->
        @start = null
        @end   = null

    start: (start) ->
        return @start if not start
        @start = start

    end: (end) ->
        return @end if not end
        @end = end

    isSelected: (date) ->
        @start is date or @end is date

    isWithinRange: (date) ->
        @start?.days <= date.days <= @end?.days
