import * as Auth from '../../lib/auth';
import { IConfigObj } from '../../lib/types';
import { IQueryServiceAPI } from './query-service.types';
import { ISchedulingAPIFactory } from './scheduling.service';

require('./query-service-module.coffee');
require('./scheduling.service.ts');

interface IServicesQueryAPI {
    new (config: { host: string }): Promise<any>;
}

angular
    .module('42.modules.services', ['42.modules.services.query', '42.modules.services.scheduling'])

    .factory('QueryServiceAPI', [
        '42.services.query.api',
        'CONFIG',
        function QueryServiceAPIFactory(QueryServiceAPI: IServicesQueryAPI, CONFIG: IConfigObj) {
            const promise = new QueryServiceAPI(CONFIG.services.query);
            return () => promise;
        },
    ])

    .service('DataDescriptors', [
        'QueryServiceAPI',
        function DataDataDescriptorsService(QueryServiceAPI: IQueryServiceAPI) {
            const queryInstance = new QueryServiceAPI();
            const promise = queryInstance.then(api => api.getDescriptors());

            return {
                fetch: () => promise,
            };
        },
    ])

    .factory('SchedulingServiceAPI', [
        '$q',
        '42.services.scheduling.api',
        function SchedulingServiceAPIFactory(
            $q: angular.IQService,
            SchedulingServiceAPIFactory: ISchedulingAPIFactory,
        ) {
            const promise = $q
                .when(Auth.getOrganization())
                .then(organization => new SchedulingServiceAPIFactory({ organization }));

            return () => promise;
        },
    ]);
