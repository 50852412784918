_ = require('../../../lib/lodash-helper.ts')

module.exports = (Utils, CONFIG, NRFCounts, NRFYears, NRFMonths) ->
    moment = require 'moment'

    YearUtils =
        getYearByDate: (date) ->
            dateUnix = date.unix()
            for year in NRFYears
                return year if dateUnix < year.end.unix()
            return null
        getYearByKey: (key) ->
            key = parseInt(key)
            for year in NRFYears
                return year if key is year.key
            return null

    class NRFDate

        # Supports:
        # - A NRFDate, in which case this is a noop
        # - A string, in ISO format, or YYYY-MM-DD
        # - A unix timestamp
        @CreateFromDate: (d) ->
            throw new Error("Cannot create NRFDate from `undefined`.") if _.isUndefined(d)
            return d if d instanceof NRFDate
            date = moment.utc do ->
                return d if not _.isString(d)
                [year, month, day] = d[0..9].split('-').map (x) -> parseInt(x)
                return [year, month-1, day]
            throw new Error("Date `#{d}` is invalid.") if not date.isValid()
            year = YearUtils.getYearByDate(date)
            if year is null
                [start, end] = [NRFYears[0].start, NRFYears[NRFYears.length-1].end]
                [start, end, date] = [start, end, date].map (x) -> x.format('YYYY-MM-DD')
                throw new Error("Date `#{date}` is outside supported retail calendar time range (#{start} - #{end}).")
            days = date.diff(year.start, 'days')
            return new NRFDate(year, days)

        @eq:  (self, other) -> (other.year.key is self.year.key) and (other.days is self.days)
        @gt:  (self, other) -> (other.year.key < self.year.key) or ((other.year.key is self.year.key) and (other.days < self.days))
        @lt:  (self, other) -> (self.year.key < other.year.key) or ((self.year.key is other.year.key) and (self.days < other.days))
        @gte: (self, other) -> NRFDate.gt(self, other) or NRFDate.eq(self, other)
        @lte: (self, other) -> NRFDate.lt(self, other) or NRFDate.eq(self, other)

        @within: (a, self, b) ->
            return self.gte(a) and self.lte(b)

        @clamp: (a, self, b) ->
            return a if self.lt(a) if a
            return b if self.gt(b) if b
            return self

        @diff: (self, other, bucket) ->
            return self.toDate().diff(other.toDate(), bucket)

        constructor: (year, days) ->
            throw new Error("Missing required `year` argument") if _.isUndefined(year)
            throw new Error("Missing required `days` argument") if _.isUndefined(days)
            throw new Error("Argument `days` must be a number") if not _.isNumber(days)
            {@year, @month, @days} = @_normalizeConstructorArguments(year, days)
            @weekOffset = Math.floor(@days / NRFCounts.DAYS_PER_WEEK)
            @weekOfMonth = (@weekOffset - @month.weekOffset) % @month.weekCount
            @dayOfWeek = @days % NRFCounts.DAYS_PER_WEEK
            return @

        add: (count, bucket) ->
            throw new Error("Missing required `count` argument")  if _.isUndefined(count)
            throw new Error("Missing required `bucket` argument") if _.isUndefined(bucket)
            throw new Error("Argument `count` must be a number")  if not _.isNumber(count)
            switch @_normalizeBucket(bucket)
                when 'day'
                    return new NRFDate(@year, @days + count)
                when 'week'
                    return new NRFDate(@year, @days + count * NRFCounts.DAYS_PER_WEEK)
                when 'month'
                    weekOffset = 0
                    month = @month
                    while count > 0
                        weekOffset += month.weekCount
                        month = month.next or NRFMonths.get(@year)[0]
                        count -= 1
                    return new NRFDate(@year, @days + weekOffset * NRFCounts.DAYS_PER_WEEK)
                when 'quarter'
                    return @add(count * NRFCounts.MONTHS_PER_QUARTER, 'month')
                when 'season'
                    return @add(count * NRFCounts.QUARTERS_PER_SEASON, 'quarter')
                when 'year'
                    return @add(count * NRFCounts.SEASONS_PER_YEAR, 'season')
                else throw new Error("Unsupported bucket `#{bucket}`.")

        subtract: (count, bucket) ->
            throw new Error("Missing required `count` argument")  if _.isUndefined(count)
            throw new Error("Missing required `bucket` argument") if _.isUndefined(bucket)
            throw new Error("Argument `count` must be a number")  if not _.isNumber(count)
            switch @_normalizeBucket(bucket)
                when 'day'
                    return new NRFDate(@year, @days - count)
                when 'week'
                    return new NRFDate(@year, @days - count * NRFCounts.DAYS_PER_WEEK)
                when 'month'
                    weekOffset = 0
                    month = @month
                    while count > 0
                        prevYearMonths = NRFMonths.get(@year.prev)
                        month = month.prev or prevYearMonths[prevYearMonths.length-1]
                        weekOffset -= month.weekCount
                        count -= 1
                    return new NRFDate(@year, @days + weekOffset * NRFCounts.DAYS_PER_WEEK)
                when 'quarter'
                    return @subtract(count * NRFCounts.MONTHS_PER_QUARTER, 'month')
                when 'season'
                    return @subtract(count * NRFCounts.QUARTERS_PER_SEASON, 'quarter')
                when 'year'
                    return @subtract(count * NRFCounts.SEASONS_PER_YEAR, 'season')
                else throw new Error("Unsupported bucket `#{bucket}`.")

        startOf: (bucket) ->
            throw new Error("Missing required `bucket` argument") if _.isUndefined(bucket)
            switch @_normalizeBucket(bucket)
                when 'day'
                    return new NRFDate(@year, @days)
                when 'week'
                    offset = Math.floor(@days / NRFCounts.DAYS_PER_WEEK)
                    return new NRFDate(@year, offset * NRFCounts.DAYS_PER_WEEK)
                when 'month'
                    return new NRFDate(@year, @month.dayOffset)
                when 'quarter'
                    return new NRFDate(@year, @month.quarter.dayOffset)
                when 'season'
                    return new NRFDate(@year, @month.season.dayOffset)
                when 'year'
                    return new NRFDate(@year, 0)
                else
                    throw new Error("Unsupported bucket `#{bucket}`.")

        endOf: (bucket) ->
            throw new Error("Missing required `bucket` argument") if _.isUndefined(bucket)
            bucket = @_normalizeBucket(bucket)
            return @clone() if bucket is 'day'
            return @add(1, bucket).startOf(bucket)

        lt:  (other) -> NRFDate.lt(@, other)
        gt:  (other) -> NRFDate.gt(@, other)
        gte: (other) -> NRFDate.gte(@, other)
        lte: (other) -> NRFDate.lte(@, other)
        eq:  (other) -> NRFDate.eq(@, other)

        formatBucket: (bucket) ->
            weekOffset = Math.floor(@days / NRFCounts.DAYS_PER_WEEK)
            switch @_normalizeBucket(bucket)
                when 'day'
                    dayOffset = @days - (weekOffset * NRFCounts.DAYS_PER_WEEK)
                    return "W#{Utils.padLeft(weekOffset+1, 2)}/#{dayOffset+1}"
                when 'week'
                    return "W#{Utils.padLeft(weekOffset+1, 2)}"
                else
                    throw new Error("Unsupported bucket `#{bucket}`.")

        within: (a, b) ->
            return NRFDate.within(a, @, b)

        diff: (b, bucket) ->
            return NRFDate.diff(@, b, bucket)

        clamp: (a, b) ->
            return NRFDate.clamp(a, @, b)

        format: ->
            return @toDate().format(arguments...)

        clone: ->
            return new NRFDate(@year, @days)

        toDate: ->
            return @year.start.clone().add(@days, 'days')

        toKey: ->
            days = @days.toString()
            pad  = (_.range(3-days.length).map (x) -> "0").join('')
            return "#{@year.key}-#{pad}#{days}"

        _normalizeConstructorArguments: (year, days) ->
            year = YearUtils.getYearByKey(year) if not _.isObject(year)
            days = parseInt(days)

            while days < 0
                throw new Error("No year for date `year:#{year.key},days:#{days}`") if not year.prev
                year = year.prev
                days = days + NRFCounts.DAYS_PER_YEAR
                days = days + 7 if year.restate

            daysPerYear = do ->
                return NRFCounts.DAYS_PER_YEAR + 7 if year.restate
                return NRFCounts.DAYS_PER_YEAR

            while daysPerYear <= days
                throw new Error("No year for date `#{year.key}-#{days}`") if not year.next
                year = year.next
                days = days - daysPerYear

            month = NRFMonths.get(year)[0]
            previousOffset = 0
            while (month.weekCount * NRFCounts.DAYS_PER_WEEK) + previousOffset <= (days - month.dayOffset)
                month = month.next or do ->
                    previousOffset += month.dayOffset
                    return NRFMonths.get(year)[0]

            return {year, month, days}

        _normalizeBucket: (bucket) ->
            bucket = bucket.toLowerCase()
            return bucket[0..bucket.length-2] if bucket[bucket.length-1] is 's'
            return bucket
