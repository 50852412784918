{ createSortable } = require('../../lib/ui-sortable.ts')

module = angular.module '42.modules.libs.ui', [
    '42.modules.libs.utils'
    '42.libs.ui.dropdown'
    '42.libs.ui.input'
    '42.services.misc'
]

module.directive 'buttonExport', ($q, $timeout, Utils, promiseTracker, OutsideElementClick) ->
    restrict: 'E'
    scope:
        onClick: '&'
        text: '='
        promiseTrackerId: '@'
    replace: true
    template: \
    """
    <article ng-click="export()" class="button-export" promise-tracker="{{ trackerId }}">
        <i class="icon-export" ng-hide="hide"><span>{{ text || 'Export' }}</span></i>
    </article>
    """
    link: (scope, element, attributes) ->
        scope.trackerId = "export-#{attributes.promiseTrackerId}"
        tracker = promiseTracker(scope.trackerId)
        scope.export = ->
            scope.hide = true
            promise = do ->
                deferred = $q.defer()
                scope.onClick()
                .then(-> deferred.resolve())
                .catch(-> deferred.resolve())
                return deferred.promise
            tracker.addPromise(promise)
            promise.then (args) ->
                $timeout (-> scope.hide = false), 100
                return args


module.factory 'OutsideElementClick', ($document) ->
    $document = $($document)

    verifyElements = (elementsList, event) ->
        elementsList.toArray().find((element) -> event.target == element[0] or element.contains(event.target))

    return (scope, element, handler) ->
        $element = $(element)

        clickListeners =
            element: (event) ->
                scope.$apply()
            document: (event) ->
                return if element[0] and verifyElements(element, event)
                try handler(event) catch error then console.error(error)
                scope.$apply()

        $element.on 'click', clickListeners.element
        $document.on 'click', clickListeners.document

        cleanup = ->
            $element.off 'click', clickListeners.element
            $document.off 'click', clickListeners.document
            # Prevents implicit return
            return null

        scope.$on '$destroy', -> cleanup()

        return cleanup



module.directive 'promiseTracker', ($animate, $interval, promiseTracker) ->
    restrict: 'A'
    link: (scope, element, attributes) ->
        tracker = active: -> false

        attributes.$observe 'promiseTracker', (trackerName) ->
            return if not trackerName
            tracker = promiseTracker(trackerName)

        promise = $interval (do ->
            oldActive = null
            return ->
                active = tracker.active()
                if active isnt oldActive
                    method = if active then 'addClass' else 'removeClass'
                    $animate[method](element, 'loading')
                oldActive = active
            ), 100

        scope.$on '$destroy', -> $interval.cancel(promise)

        return


module.factory 'requestAnimationFrame', ($window, $rootScope) ->
    requestAnimationFrame = $window.requestAnimationFrame    or
                            $window.mozRequestAnimationFrame or
                            $window.msRequestAnimationFrame  or
                            $window.webkitRequestAnimationFrame
    return (fn) ->
        requestAnimationFrame ->
            fn()
            $rootScope.$apply()


module
.controller('TabsController', ($scope) ->
    @select = (value) ->
        $scope.selected = value
    @isSelected = (value) ->
        $scope.selected is value

    return

).directive('tabs', ->
    restrict: 'E'
    scope:
        selected: '='
        list: '='
    replace: false
    transclude: true
    controller: "TabsController"
    template: \
    """
    <article class="ui-tabs">
        <ul class="sortable-ul" ng-transclude dnd-list="list"></ul>
    </article>
    """
    link: (scope) ->
        scope.select = (value) -> scope.selected = value

).directive('tab', ->
    restrict: 'E'
    scope:
        value: '=tabValue'
    require:   '^tabs'
    replace: true
    transclude: true
    controller: "TabsController"
    template: \
    """
    <li class="ui-tab" dnd-draggable="value"
        ng-class="{'ui-tab-selected':isSelected()}"
        ng-click="select()">
        <div class="ui-tab-content" ng-transclude></div>
    </li>
    """
    link: (scope, element, attributes, controller) ->
        scope.select = ->
            controller.select(scope.value)
        scope.isSelected = ->
            controller.isSelected(scope.value)

        return
)
.directive('tabV2', ->
    restrict: 'E'
    scope:
        value: '=tabValue'
    require:   '^tabs'
    replace: true
    transclude: true
    controller: "TabsController"
    template: \
    """
    <li class="ui-tab"
        ng-class="{'ui-tab-selected':isSelected()}"
        ng-click="select()">
        <div class="ui-tab-content" ng-transclude></div>
    </li>
    """
    link: (scope, element, attributes, controller) ->
        scope.select = ->
            controller.select(scope.value)
        scope.isSelected = ->
            controller.isSelected(scope.value)

        return
)


angular
.module('42.libs.ui.dropdown', [])

.constant "dropdownConfig",
    openClass: "open"

.service "dropdownService", ($document) ->
    self = this
    openScope = null
    @open = (dropdownScope) ->
        unless openScope
            $document.bind "click", closeDropdown
            $document.bind "keydown", escapeKeyBind
        openScope.isOpen = false    if openScope and openScope isnt dropdownScope
        openScope = dropdownScope

    @close = (dropdownScope) ->
        if openScope is dropdownScope
            openScope = null
            $document.unbind "click", closeDropdown
            $document.unbind "keydown", escapeKeyBind

    closeDropdown = ->
        openScope.$apply ->
            openScope.isOpen = false

    escapeKeyBind = (evt) ->
        if evt.which is 27
            openScope.focusToggleElement()
            closeDropdown()

    return

.controller "DropdownController", ($scope, $attrs, $parse, dropdownConfig, dropdownService, $animate) ->
    self = this
    scope = $scope.$new()
    openClass = dropdownConfig.openClass
    getIsOpen = undefined
    setIsOpen = angular.noop
    toggleInvoker = (if $attrs.onToggle then $parse($attrs.onToggle) else angular.noop)
    @init = (element) ->
        self.$element = element
        if $attrs.isOpen
            getIsOpen = $parse($attrs.isOpen)
            setIsOpen = getIsOpen.assign
            $scope.$watch getIsOpen, (value) ->
                scope.isOpen = !!value

    @toggle = (open) ->
        scope.isOpen = (if arguments.length then !!open else not scope.isOpen)

    @isOpen = ->
        scope.isOpen

    scope.focusToggleElement = ->
        self.toggleElement[0].focus()    if self.toggleElement

    scope.$watch "isOpen", (isOpen) ->
        $animate[(if isOpen then "addClass" else "removeClass")] self.$element, openClass
        if isOpen
            scope.focusToggleElement()
            dropdownService.open scope
        else
            dropdownService.close scope
        setIsOpen $scope, isOpen
        toggleInvoker $scope,
            open: !!isOpen
    $scope.$on "$locationChangeSuccess", ->
        scope.isOpen = false
    $scope.$on "$destroy", ->
        scope.$destroy()

    return


.directive "dropdown", ->
    restrict: "CA"
    controller: "DropdownController",
    controllerAs: 'ctrl',
    link: (scope, element, attrs, dropdownCtrl) ->
        scope.ctrl.init element
        return

.directive "dropdownToggle", ->
    restrict: "CA"
    require: "?^dropdown"
    link: (scope, element, attrs, dropdownCtrl) ->
        return unless dropdownCtrl
        dropdownCtrl.toggleElement = element
        scope.toggleDropdown = toggleDropdown = (event) ->
            event.preventDefault()
            event.stopPropagation()
            if not element.hasClass("disabled") and not attrs.disabled
                scope.$apply -> dropdownCtrl.toggle()

        element.bind "click", toggleDropdown

        # WAI-ARIA
        element.attr
            "aria-haspopup": true
            "aria-expanded": false

        scope.$watch dropdownCtrl.isOpen, (isOpen) ->
            element.attr "aria-expanded", !!isOpen

        scope.$on "$destroy", ->
            element.unbind "click", toggleDropdown

        return


.directive "tabsWithMenu", (OutsideElementClick, Utils) ->
    restrict: "E"
    scope:
        tabs:       '='
        selected:   '='
        added:      '='
        removed:    '='
        dragged:    '='
        duplicated: '=?'
        maxlength:  '=?'
        # Expected Format for tabs is an array of
            # {
            #     id: "Some Unique Id",
            #     name: "Tab Name"
            # }
        #
    template: \
    """
    <article class="ui-tabs-with-menu">
        <button class="ui-tab-button" ng-click="added(); scrollToEnd();">
            <i class="column-view-label icon-plus"></i>
        </button>
        <tabs class="ui-view-tabs" selected="selected">
            <tab-v2 ng-repeat="model in tabs" tab-value="model">
            <div ng-double-click="setEditModeIndex($index)" ng-if="$index != editModeIndex" class="tab-inner-content">
            {{ model.name }}
            </div>
            <input class="tab-inner-content" ng-if="$index == editModeIndex" size="{{model.name.length > 0 ? model.name.length : 1}}" type="text" ng-enter="setEditModeIndex(-1);" ng-esc="setEditModeIndex(-1);" maxlength="{{maxLength}}" ng-model="model.name" focus-on-ng-if/>
            <i ng-click="toggleDropdown($index)" ng-class="{'icon-toggled': $index==dropDownIndex}" class="icon-down-open-mini tab-icon-content"></i>
            </tab-v2>
        </tabs>
        <button ng-show="shouldShowScroll()" class="ui-tab-button" ng-click="scrollLeft()"> <i class="column-view-label icon-left-open"></i> </button>
        <button ng-show="shouldShowScroll()" class="ui-tab-button" ng-click="scrollRight()"> <i class="column-view-label icon-right-open"></i> </button>
        <div class="dropdown" style="position:fixed; width:{{dropDownRect.width}}px; top:{{dropDownRect.height}}px; left:{{dropDownRect.left}}px" ng-class="{open:dropDownIndex!=-1, close:dropDownIndex==-1}">
            <ul class="dropdown-menu">
                <li ng-click="setEditModeIndex(dropDownIndex)"> Rename </li>
                <li ng-if="duplicated != undefined" ng-click="duplicated(selected.id)"> Duplicate </li>
                <li ng-if="tabs.length > 1" ng-click="removed(selected.id); toggleOffAllEditModes();"> Delete </li>
            </ul>
        </div>
    </article>
    """
    link: (scope, element) ->

        if scope.maxLength == undefined
            scope.maxLength = 60

        angular.element(element[0].querySelector('.ui-view-tabs')).bind('scroll', (() -> scope.dropDownIndex = -1).bind(scope))

        sortable = createSortable element.find('.sortable-ul')[0],
            ghostClass: 'ui-tabs-with-menu-placeholder'
            onEnd: (evt) ->
                scope.dragged(evt.oldIndex, evt.newIndex)
                scope.$apply()

        setDropDownRect = ->
            index = scope.dropDownIndex
            dropDownRect = element[0].getElementsByClassName('ui-tab')[index]?.getBoundingClientRect()
            return if not dropDownRect
            # 30 to account for the plus button
            if (dropDownRect.x < 30)
                element[0].getElementsByClassName('ui-view-tabs')[0].scrollLeft = index * dropDownRect.width
                dropDownRect.x = 0
            scope.dropDownRect = dropDownRect

        scope.dropDownIndex = -1
        scope.editModeIndex = -1

        scope.fillIfNeeded =  ->
            scope.selected.name = 'New View' if not scope.selected.name or scope.selected.name.trim().length == 0

        scope.$watch 'selected', ->
            scope.toggleOffAllEditModes(scope.selected)

        scope.$watch 'dropDownIndex', ->
            scope.editModeIndex = -1 if scope.dropDownIndex > -1

        OutsideElementClick scope, element.find('.ui-tabs, .dropdown-menu'), ->
            scope.toggleOffAllEditModes()

        scope.shouldShowScroll = _.throttle(->
            return false if not scope.tabs or scope.tabs.length == 0
            scrollableElement = element[0].getElementsByClassName('ui-view-tabs')[0]

            return (scrollableElement and (scrollableElement.scrollWidth > scrollableElement.clientWidth))
        , 100)

        scope.scrollLeft = ->
            currentScroll = element[0].getElementsByClassName('ui-view-tabs')[0].scrollLeft
            currentScroll -= 200
            $('.ui-view-tabs').animate({scrollLeft: "+#{currentScroll}"}, 100, null)
            return

        scope.scrollRight = ->
            currentScroll = element[0].getElementsByClassName('ui-view-tabs')[0].scrollLeft
            currentScroll += 200
            $('.ui-view-tabs').animate({scrollLeft: "+#{currentScroll}"}, 100, null)
            return

        scope.scrollToEnd = ->
            return if not scope.shouldShowScroll()
            scrollWidth = element[0].getElementsByClassName('ui-view-tabs')[0].scrollWidth
            $('.ui-view-tabs').animate({scrollLeft: "+#{scrollWidth}"}, 100, null)
            return

        scope.toggleOffAllEditModes =  (selected) ->
            # Don't toggle off the dropdown if it was selected
            if (selected)
                indexOfSelected = scope.tabs.indexOf(selected)
                return if (scope.dropDownIndex == indexOfSelected)
            scope.editModeIndex = -1
            scope.dropDownIndex = -1

        scope.setEditModeIndex = (index) ->
            scope.fillIfNeeded()
            scope.editModeIndex = index
            scope.dropDownIndex = -1

        scope.toggleDropdown = (index) ->
            if (index is scope.dropDownIndex)
                scope.dropDownIndex = -1
            else
                scope.dropDownIndex = index
                setDropDownRect()

        scope.$on 'destroy', ->
            sortable.destroy()

        return


inputModule = angular.module('42.libs.ui.input', [])

inputModule.directive 'ngEsc', ->
    (scope, element, attrs) ->
        element.bind 'keydown keypress', (event) ->
            isKey = event.key is 'Escape'
            isKey = isKey or (event.keyCode or event.which) is 27
            return if not isKey
            scope.$apply ->
                scope.$eval(attrs.ngEsc)
                return
            event.preventDefault()
            return
        return

inputModule.directive 'ngEnter', ->
    (scope, element, attrs) ->
        element.bind 'keydown keypress', (event) ->
            isKey = event.key is 'Enter'
            isKey = isKey or (event.keyCode or event.which) is 13
            return if not isKey
            scope.$apply ->
                scope.$eval(attrs.ngEnter)
                return
            event.preventDefault()
        return

inputModule.directive 'ngDoubleClick', ->
    (scope, element, attrs) ->
        element.bind 'dblclick', (event) ->
            scope.$apply ->
                scope.$eval(attrs.ngDoubleClick)
                return
            event.preventDefault()
            return
        return

inputModule.directive 'focusOnNgIf', ($timeout) ->
    restrict: 'A'
    link: ($scope, $element, $attr) ->
        return if not $attr.ngIf
        $scope.$watch $attr.ngIf, (newValue) ->
            return if not newValue
            $timeout (-> $element[0].focus()), 0
        return
