export const onSrcDirectiveInstance = () => [
    function onSrcDirective() {
        return {
            restrict: 'A',
            scope: {
                handleMethod: '&onErrorSrc',
            },
            link: (scope: angular.IScope & any, element: angular.IRootElementService) => {
                element.on('error', () => {
                    scope.handleMethod();
                });
            },
        };
    },
];
