module.exports = (DateWrapper) -> class DateSelectModel

    @ParseRange: (range) ->
        return null if not (range.start and range.end)
        result = {}
        result.start = DateWrapper.CreateFromDate(range.start) if range?.start
        result.end   = DateWrapper.CreateFromDate(range.end)   if range?.end
        return result

    @FilterToRange: (filter) ->
        range = DateSelectModel.ParseRange({start:filter.$gte, end:filter.$lt})
        range.end = range.end.subtract(1, 'day')
        return range

    @RangeToFilter: (range) ->
        {start, end} = range
        start = start?.format('YYYY-MM-DD')
        end   = end?.add(1, 'days').format('YYYY-MM-DD')
        return undefined if not (start and end)
        result = {}
        result.$gte = start if start
        result.$lt  = end   if end
        return result

    constructor: ({bounds, filter}) ->
        @selected = {}
        @bounds = DateSelectModel.ParseRange(bounds) if bounds
        throw new Error("Bounds not set.") if not @bounds
        @select do =>
            return DateSelectModel.FilterToRange(filter) if filter
            return @bounds

    select: (range) -> {@selected, @filter} = do =>
        return {selected:{}, filter:undefined} if not range
        range = do =>
            start: (range.start or @selected.start)?.clamp(@bounds.start, @bounds.end)
            end:   (range.end   or @selected.end)?.clamp(@bounds.start, @bounds.end)
        selected: range
        filter: DateSelectModel.RangeToFilter(range)
