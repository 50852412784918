require './libs/libs.module.coffee'
require './services/services.module.ts'
require './views/views.module.coffee'
require './natural-language-filter-display/natural-language-filter-display.module.ts'
require './datepicker/datepicker-module.coffee'
require './highcharts-module.coffee'
require './smart-groups/smart-groups.module.ts'
require './hierarchy/hierarchy.module.ts'
require './user-menu/user-menu.module.ts'
require './storage-module.ts'
require './currency/currency.module.ts'

angular.module '42.modules', [
    '42.modules.libs'
    '42.modules.services'
    '42.modules.views'
    '42.modules.datepicker.nrf'
    '42.modules.highcharts'
    '42.modules.storage'
    '42.modules.natural-language'
    '42.modules.hierarchy'
    '42.modules.smart-groups'
    '42.modules.currency'
]
