
moment = require 'moment'
_ = require('../lib/lodash-helper.ts')
module = angular.module '42.controllers.store-status', []


module.config ($routeProvider, ROUTES, CONFIG) ->
    routeId = 'storestatus'
    route = _.extend {}, ROUTES[routeId], _.pick(CONFIG.routes?[routeId], 'label', 'url')
    $routeProvider.when route.url, route


module.controller 'StoreStatusController', ($rootScope, $scope) ->
    $rootScope.statusMessageModel.show()


module.factory 'StoreStatusModel', ($rootScope, Utils, CONFIG, QueryServiceAPI) ->

    class StoreStatusModel

        constructor: (query) ->
            @update(query)

        update: (query) ->
            @fetch(query)
            .then((x) => @_format(x))
            .then((x) => @data = x)

        fetch: (query) -> (new QueryServiceAPI).then (api) =>
            config = @_getConfig()
            query = Utils.copy(query)
            query.options = {property:config.property}
            return api.query.getStoreUpdatedTimes(query)

        _format: (data) ->
            config = @_getConfig()
            return data.map (x) ->
                timestamp = try do ->
                    value = x.most_recent_transaction
                    return null if not value
                    value = moment(value)
                    return null if not value.isValid()
                    return value
                property: x.property
                relative_timestamp: try do ->
                    return "n/a" if not timestamp
                    return timestamp.fromNow()
                absolute_timestamp: try do ->
                    return "n/a" if not timestamp
                    return timestamp.format(config.format)

        _getConfig: ->
            property: CONFIG.views?.storeStatus?.property
            format:   CONFIG.views?.storeStatus?.format or 'YYYY-MM-DD HH:mm:ss'



module.directive 'viewStoreStatus', ($rootScope, StoreStatusModel) ->
    restrict: 'E'
    scope:true
    replace: true
    template:\
    """
    <article class="view view-store-status">
        <div class="loadable" ng-class="{loading:!model.data}"></div>
        <simple-table data="model.data"></simple-table>
    </article>
    """
    link: (scope) ->
        refresh  = ->
            return if not $rootScope.initialized
            return if not $rootScope.query
            scope.model = new StoreStatusModel($rootScope.query)
        scope.$on '$destroy', ($rootScope.$watch 'initialized', refresh)
        scope.$on '$destroy', ($rootScope.$on 'query.refresh', refresh)
        refresh()


module.directive 'simpleTable', ->
    restrict: 'E'
    scope:
        data: '='
    replace: true
    template: \
    """
    <div class="store-status-container card">
        <header>
            <table class="store-status-table">
                <thead>
                <tr>
                    <th>Store Name</th>
                    <th>Most Recent Transaction (Local Time)</th>
                    <!-- <th>Last Seen (Local Time)</th> -->
                </tr>
                </thead>
            </table>
        </header>
        <main>
        <table class="store-status-table">
            <tbody>
                <tr ng-repeat="row in data">
                    <td>{{ row.property }}</td>
                    <td>{{ row.absolute_timestamp }}</td>
                    <!-- <td>{{ row.relative_timestamp }}</td> -->
                </tr>
            </tbody>
        </table>
        </main>
    </div>
    """
