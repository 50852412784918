export interface ICustomerStatParseFunction {
    (stats: Record<string, string | number>, $filter: angular.IFilterFunction): string;
}

export const CUSTOMER_STATS_CONFIG: Record<string, Record<string, string | ICustomerStatParseFunction>> = {
    customer_count: {
        title: 'Customers',
        value: (stats, $filter) => `${$filter('number')(stats.customer_count)}`,
        avg: 'make them feel special!',
    },
    total_spent: {
        title: 'Total Spent',
        value: (stats, $filter) => `${$filter('money')(stats.total_spent)}`,
        avg: (stats, $filter) => `that's about <em>${$filter('money')(stats.avg_total_spent)}</em> per customer`,
    },
    transaction_count: {
        title: 'Transactions',
        value: (stats, $filter) => `${$filter('number')(stats.transaction_count, 0)}`,
        avg: (stats, $filter) =>
            `which is roughly <em>${$filter('number')(stats.avg_transaction_count, 1)}</em> per customer`,
    },
    item_count: {
        title: 'Items Bought',
        value: (stats, $filter) => `${$filter('number')(stats.item_count, 0)}`,
        avg: (stats, $filter) =>
            `which is about <em>${$filter('number')(stats.avg_item_count, 1)}</em> per transaction`,
    },
};

export interface CustomerStatsItem extends Record<string, any> {
    title: string;
    value: string;
    avg: string;
}

export const CustomerStatsDirective = () => [
    function CustomerState() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            template: `
                <ul class="customer-stats card stats" query="query">
                    <li class="customer-stat" ng-repeat="stat in model">
                        <div class="title" ng-bind-html="stat.title"></div>
                        <div class="value" ng-bind-html="stat.value"></div>
                        <div class="avg" ng-bind-html="stat.avg"></div>
                    </li>
                    <div class="loadable" promise-tracker="customer-stats"></div>
                </ul>
            `,
        };
    },
];
