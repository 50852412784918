import * as _ from '../../../lib/lodash-helper';

import { CustomerStatsDirective } from './customer-stats.directive';
import { CustomerListDirective } from './customer-list.directive';
import { CustomerControllerInstance } from './customer.controller';
import {
    CostumerStatsServiceInstance,
    CostumerCountServiceInstance,
    CustomersReportColumnDefsInstance,
    CustomersReportServiceInstance,
} from '../customer.service';

const module = angular.module('42.controllers.customer.main', []);
module.config(function CustomersMainConfig($routeProvider: angular.route.IRouteProvider, ROUTES: any, CONFIG: any) {
    const routeId = 'customers';
    const routes = CONFIG.routes ? CONFIG.routes[routeId] : {};
    const route = _.extend({}, ROUTES[routeId], _.pick(routes, 'label', 'url'));
    $routeProvider.when(route.url, route).otherwise({ redirectTo: '/' });
});

module.service('CustomerStats', CostumerStatsServiceInstance());
module.service('CustomerCount', CostumerCountServiceInstance());
module.service('CustomersReportColumnDefs', CustomersReportColumnDefsInstance());
module.service('CustomersReport', CustomersReportServiceInstance());

module.controller('CustomerController', CustomerControllerInstance());

module.directive('customerStats', CustomerStatsDirective());
module.directive('customerList', CustomerListDirective());
