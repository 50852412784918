import { ISmartGroupsService, SmartGroupViewModel } from './smart-groups.service';
import _ from 'lodash';
import { IOutsideElementClick } from '../../lib/types';
import { createSortable } from '../../lib/ui-sortable';

export interface IGroupFilterViewModel {
    label: string;
    selected: boolean;
    isActive: boolean;
    select: () => void;
}

export interface IGroupViewModel {
    name?: string;
    selected?: boolean;
    select?: () => void;
    save?: (name: string) => void;
    delete?: () => void;
    filters?: IGroupFilterViewModel[];
}

export const buildGroupsViewModel = (groups: SmartGroupViewModel[]): IGroupViewModel[] => {
    return groups.map(group => {
        const filters = (group.filters || []).map(filterObj => {
            return {
                label: filterObj.label,
                selected: filterObj.isSelected(),
                isActive: filterObj.isActive(),
                select: () => filterObj.select(),
            };
        }) as IGroupFilterViewModel[];

        return {
            name: group.getName(),
            selected: group.isSelected(),
            markedForRemoval: group.markedForRemoval,
            id: group.model.id,
            isNew: group.isNew,
            select: () => group.select(),
            delete: () => group.delete(),
            save: (name: string) => {
                group.commit(name);
            },
            filters,
        };
    });
};

export class SmartGroupsViewModel {
    groups: IGroupViewModel[] = [];

    constructor(private smartGroupsServiceInstance: ISmartGroupsService) {
        this.groups = buildGroupsViewModel(smartGroupsServiceInstance.groups || []);
    }

    create = () => {
        this.smartGroupsServiceInstance.create();
    };

    reorder = (oldIndex: number, newIndex: number) => {
        this.smartGroupsServiceInstance.reorder(oldIndex, newIndex);
        this.groups = buildGroupsViewModel(this.smartGroupsServiceInstance.groups || []);
    };
}

export const SmartGroupsPanelDirective = () => [
    (): angular.IDirective => {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                 <div class="smart-groups-panel">
                    <div class="smart-group-actions">
                        <div class="smart-group-actions-label" promise-tracker="smart-groups">Filter Groups</div>
                        <div class="new-filter-btn" ng-click="model.create()">
                            create<i class="icon-plus"></i>
                        </div>
                    </div>
                    <ul class="smart-groups sortable-ui">
                        <li class="smart-group-container" ng-repeat="group in groups track by group.id">
                            <smart-groups-panel-item model="group"></smart-groups-panel-item>
                        </li>
                    </ul>
                </div>
            `,
            link: ($scope: angular.IScope & any, element: angular.IRootElementService) => {
                $scope.groups = [];

                $scope.$watch('model.groups', (groups: IGroupViewModel[]) => {
                    $scope.groups = groups;
                });

                const sortableUiElements = element[0].querySelector('.sortable-ui') as HTMLElement;

                const sortable = createSortable(sortableUiElements, {
                    ghostClass: 'placeholder',
                    draggable: '.smart-group-container',
                    filter: '.edit',
                    preventOnFilter: false,
                    onEnd: evt => {
                        $scope.model.reorder(evt.oldIndex, evt.newIndex);
                        $scope.$apply();
                    },
                });

                $scope.$on('$destroy', () => sortable.destroy());
            },
        };
    },
];

export const SmartGroupsItemDirective = () => [
    '$timeout',
    'OutsideElementClick',
    ($timeout: angular.ITimeoutService, OutsideElementClick: IOutsideElementClick): angular.IDirective => {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <div
                    class="smart-group"
                    ng-click="selectModel()"
                    ng-class="{
                        selected: model.selected,
                        new: model.isNew,
                        edit: editMode,
                        remove: model.markedForRemoval
                    }"
                >
                    <div class="smart-group-edit-actions animation-slide-down" ng-if="editMode">
                        <button class="button-yellow smart-group-edit-save-button" ng-click="save()">rename</button>
                        <button class="smart-group-edit-cancel-button" ng-click="closeEditMode()">cancel</button>
                    </div>
                    <header class="smart-group-info">
                        <section class="smart-group-info-normal animation-fade" ng-if="!editMode">
                            <h1 class="smart-group-name" ng-dblclick="enableEditMode()">
                                <i class="icon-right-open-mini"></i>{{ model.name }}
                            </h1>
                            <button-smart-group-edit-dropdown
                                model="actionsViewModel"
                                ng-if="model.selected"
                            ></button-smart-group-edit-dropdown>
                        </section>
                        <section class="smart-group-info-edit animation-fade" ng-if="editMode">
                            <input
                                type="text"
                                ng-model="editData.modelName"
                                placeholder="{{ model.name }}"
                                focus="{{ model.selected }}"
                                ng-enter="save()"
                                ng-esc="closeEditMode()"
                            />
                        </section>
                    </header>
                    <ul class="smart-group-filters">
                        <li
                            class="smart-group-filter"
                            ng-repeat="filter in model.filters"
                            ng-click="filter.select()"
                            ng-class="{selected: filter.selected, active: filter.isActive}"
                        >
                            {{ filter.label }}
                        </li>
                    </ul>
                </div>
            `,
            link: (
                $scope: angular.IScope & { model: IGroupViewModel } & any,
                $element: angular.IRootElementService,
            ) => {
                let clickOutsideEditElement: null | (() => void);

                $scope.editMode = false;
                $scope.editData = {
                    modelName: '',
                };
                $scope.selectModel = () => {
                    if (!$scope.editMode) {
                        $scope.model.select();
                    }
                };

                $scope.enableEditMode = () => {
                    $scope.editMode = true;

                    $timeout(() => {
                        if (clickOutsideEditElement) {
                            clickOutsideEditElement();
                        }

                        clickOutsideEditElement = new OutsideElementClick(
                            $scope,
                            $element.find('.smart-group-info .smart-group-info-edit'),
                            () => {
                                $scope.closeEditMode();
                            },
                        );
                    }, 0);
                };

                $scope.save = () => {
                    if ($scope.editData.modelName && $scope.editData.modelName !== $scope.model.name) {
                        $scope.model.name = $scope.editData.modelName;
                        $scope.model.save($scope.editData.modelName);
                    }

                    $scope.closeEditMode();
                };

                $scope.closeEditMode = () => {
                    $scope.editData.modelName = '';
                    $scope.editMode = false;

                    if (clickOutsideEditElement) {
                        clickOutsideEditElement();
                        clickOutsideEditElement = null;
                    }
                };

                $scope.actionsViewModel = [
                    {
                        title: 'RENAME',
                        action: () => $scope.enableEditMode(),
                    },
                    {
                        title: 'DELETE',
                        action: () => {
                            $scope.model.markedForRemoval = true;
                            $timeout(() => $scope.model.delete(), 333);
                        },
                    },
                ];
            },
        };
    },
];

export const SmartGroupEditDropdownDirective = () => [
    'OutsideElementClick',
    (OutsideElementClick: IOutsideElementClick): angular.IDirective => {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <div class="button-smart-group-edit">
                    <div
                        class="smart-group-edit-button"
                        ng-class="{ opened: opened }"
                        ng-click="toggleDropdown()">
                        <i class="icon-cog"></i>
                    </div>
                    <div ng-if="opened" class="smart-group-edit-items-container">
                        <div class="smart-group-edit-item"
                            ng-repeat="item in model"
                            ng-click="actionClick($event, item)">{{ item.title }}</div>
                    </div>
                </div>
            `,
            link: ($scope: angular.IScope & any, $element: angular.IRootElementService) => {
                $scope.opened = false;

                $scope.actionClick = ($event: MouseEvent, item: any) => {
                    $event.preventDefault();

                    item.action();
                    $scope.opened = false;
                };

                $scope.toggleDropdown = () => ($scope.opened = !$scope.opened);

                new OutsideElementClick($scope, $element, () => {
                    $scope.opened = false;
                });
            },
        };
    },
];
