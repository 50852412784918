import _ from 'lodash';

export function omitDeep<T extends Record<string, any>>(input: T, excludes: string[]): T {
    if (!input) return {} as T;

    return Object.keys(input).reduce((nextInput, key) => {
        const value = input[key];
        const shouldExclude = excludes.includes(key);
        if (shouldExclude) return nextInput;

        if (Array.isArray(value)) {
            const arrValue = value;
            const nextValue = arrValue.map(arrItem => {
                if (typeof arrItem === 'object') {
                    return omitDeep(arrItem, excludes);
                }
                return arrItem;
            });
            nextInput[key] = nextValue;
            return nextInput;
        } else if (typeof value === 'object' && value !== null) {
            nextInput[key] = omitDeep(value as Record<string, unknown>, excludes);
            return nextInput;
        }

        nextInput[key] = value;

        return nextInput;
    }, {} as any);
}

const ANGULAR_PROPERTIES = ['$$hashKey'];
export function deepStripAngularProperties<T extends Record<string, any>>(obj: T): T {
    if (_.isPlainObject(obj)) obj = _.cloneDeep(obj);
    return omitDeep(obj, ANGULAR_PROPERTIES);
}
