_ = require('../../lib/lodash-helper.ts')
Auth = require('../../lib/auth')
{ QueryServiceAPI } = require('../../lib/api')

module = angular.module '42.modules.services.query', [
    '42.modules.libs.utils'
    '42.modules.datepicker.nrf'
]

module.factory 'QueryCacheMemory', ($q, Utils) -> return ->
    storage = {}

    get: (queryId, query) ->
        query ?= {}
        return undefined if (query.type isnt 'json')
        key = queryId + Utils.object.hash(query)
        return storage[key]

    set: (queryId, query, value) ->
        key = queryId + Utils.object.hash(query or {})
        return storage[key] = value

    del: (queryId, query) ->
        key = queryId + Utils.object.hash(query or {})
        delete storage[key]


module.factory 'QueryCache', (QueryCacheMemory) -> -> new QueryCacheMemory()


module.service 'UserRestrictions', (CONFIG, UserRestrictionsOld, UserRestrictionsNew) ->
    enabled = !!CONFIG.flags?.useQueryRestrictions
    return UserRestrictionsNew if enabled
    return UserRestrictionsOld


module.service 'UserRestrictionsOld', ($rootScope, CONFIG, Utils) ->
    restrictQuery: (query) ->
        query = Utils.copy(query)
        orgFilters = CONFIG.accessControl?.filters
        userFilters = $rootScope.accessControl?.filters
        restrictedFilters = Object.assign {}, userFilters, orgFilters
        return query if not restrictedFilters
        Object.keys(restrictedFilters).forEach (table) ->
            restrictedFilter = restrictedFilters[table]?.$and or []
            queryFilter = do ->
                filter = query.filters[table]?.$and or query.filters[table] or []
                return filter if not _.isPlainObject(filter)
                return Object.keys(filter).map (column) -> {"#{column}": $in:[filter[column]]}
            filters = restrictedFilter.concat(queryFilter)
            filters = filters.reduce ((result, value) ->
                Object.keys(value).forEach (column) -> result[column] = value[column]
                return result
            ), {}
            query.filters[table] = {$and:filters} if not _.isEmpty(filters)
        return query


module.service 'UserRestrictionsNew', ($rootScope, CONFIG, Utils) ->

    getOrganizationRestrictions = ->
        filters = CONFIG.accessControl?.filters or {}
        return null if _.isEmpty(filters)
        return Utils.copy(filters)

    getUserRestrictions = ->
        filters = $rootScope.accessControl?.filters or {}
        return null if _.isEmpty(filters)
        return Utils.copy(filters)

    getRestrictions = ->
        orgRestrictions = getOrganizationRestrictions()
        userRestrictions = getUserRestrictions()
        filters = _.assign {}, userRestrictions, orgRestrictions
        return null if not filters or _.isEmpty(filters)
        return Utils.copy(filters or {})

    restrictQuery: (query) ->
        query = Utils.copy(query or {})
        restrictions = getRestrictions()
        query.restrictions = restrictions if restrictions
        return query


# Most of the work here is to convert timerange ids (ex: std) into actual timeranges,
# based on the current calendar selection
module.service 'QueryMetricFilters', ($rootScope, DateSelectModel, DateSelectActions, Utils, CONFIG) ->

    # We re-create the datepicker actions to anchor them on the "end" of the current timerange selection
    getTimerangeActions = (datepicker) ->
        bounds = datepicker?.bounds
        models = datepicker?.models
        return null if not (bounds and models)
        bounds =
            start: bounds.start.clone(),
            end: models.selection.selected.end.clone()
        models =
            selection: new DateSelectModel({bounds, filter:models.selection.filter})
            comparison: new DateSelectModel({bounds, filter:models.comparison.filter}) if models.comparison
        return new DateSelectActions(models, bounds)

    # Apply the datepicker action for a given timerange id
    getActualTimerangeFromTimerangeId = (timeranges, id) ->
        return id if not _.isString(id) and id?.selection # already translated
        timerangeAction = _.find timeranges, (x) -> x.id is id
        if not timerangeAction
            console.warn('timerange action for', id, 'was not found')
            console.log(timeranges.map (x) -> x.id)
            return null
        {selection, comparison} = timerangeAction.getRange()
        selection: DateSelectModel.RangeToFilter(selection)
        comparison: DateSelectModel.RangeToFilter(comparison) if comparison

    translateMetricFilter = (timeranges, metricFilter) ->
        metricFilter = Utils.copy(metricFilter)
        timerange = getActualTimerangeFromTimerangeId(timeranges, metricFilter.timerange)
        return null if not timerange
        metricFilter.timerange = timerange
        return metricFilter

    translateMetricFilters = (timeranges, metricFilters) ->
        return Object.keys(metricFilters).reduce(((result, metricFilterId) ->
            metricFilter = metricFilters[metricFilterId]
            metricFilter = translateMetricFilter(timeranges, metricFilter)
            result[metricFilterId] = metricFilter if metricFilter
            return result
        ), {})

    get: ->
        metricFilters = CONFIG.kpis?.filters
        return null if not metricFilters
        actions = getTimerangeActions($rootScope.datepicker)
        return null if not actions
        return translateMetricFilters(actions, metricFilters)


module.factory '42.services.query.api', ['$rootScope', '$q', 'Utils', 'QueryCache', 'CONFIG', 'UserRestrictions', 'QueryMetricFilters', ($rootScope, $q, Utils, QueryCache, CONFIG, UserRestrictions, QueryMetricFilters) ->
    return ({host, organization}) -> $q.when(QueryServiceAPI.get({host})).then (api) ->
        cache = new QueryCache()

        # Add custom composite metric definitions from org config
        addMetricDefinitions = (query) ->
            definitions = CONFIG.kpis?.definitions
            query.definitions = definitions if definitions
            return query

        # Add custom metric definitions from org config
        addCustomMetrics = (query) ->
            foundations = CONFIG.kpis?.foundations
            query.foundations = foundations if foundations
            return query

        addMetricFilters = (query) ->
            metricFilters = QueryMetricFilters.get()
            query.metricFilters = metricFilters if metricFilters
            return query

        addModifiers = (query) ->
            modifiers = CONFIG.modifiers ? {}
            modifiers.enableFoundationPruningFromFilters = CONFIG.flags?.enableFoundationPruningFromFilters
            modifiers.unifiedSalesDemandOptIn = CONFIG.flags?.unifiedSalesDemandOptIn
            modifiers.bypassLegacyDemandMetrics = CONFIG.flags?.bypassLegacyDemandMetrics
            modifiers.calendar = CONFIG.datepicker?.calendar

            query.modifiers = { ...modifiers, ...query.modifiers }
            query.modifiers.timezone = (new Date).getTimezoneOffset()

            return query

        # special case for frye wholesale to remove all material level data for certain retailers
        checkForMaterialLevel = (query) ->

            # case for the items filter
            materialLevelInItemsFilter = do ->
                return 'material' in _.keys(query.filters.items?.$and) or \
                    'items.material' is query.options?.groupBy or \
                    'items.material' is query.options?.itemsGroupBy

            if materialLevelInItemsFilter and 'material_level_data' not in (query.filters.stores?.$and or [])
                query.filters.stores ?= {}
                query.filters.stores.$and ?= []
                query.filters.stores.$and.push({material_level_data: 'available'})

            # case for the metrics page
            hasMaterialLevel = do ->
                return query.filters?.items?.material or 'items.material' is query.options?.property

            if hasMaterialLevel and not query.filters?.stores?.material_level_data
                query.filters.stores ?= {}
                query.filters.stores.material_level_data = 'available'
            return query

        # FIXME: Remove this / generalize it... both here and at query service level...
        addAllsaintsRealtimeMaxTimestampFilterHack = (query, user) ->
            if query.filters?.transactions?.timestamp?.$lt and query.comparison?.timestamp
                status = $rootScope.StatusMonitor.getStatus()
                maxTimestamp = status?.latestTransactionTimestamp
                if maxTimestamp
                    query.modifiers.maxTimestamp = maxTimestamp
                else
                    console.error("Could not get 'latestTransactionTimestamp' from database status. Can't set max timestamp.")
            if user.name is 'digital dashboard'
                query.comparison = {}
            return query

        wrapper =
            doQuery: (queryId) -> (query, canceler) ->
                $q.all([
                    Auth.getOrganization()
                    Auth.getUser()
                ]).then ([organizationId, user]) ->
                    query = Utils.copy(query or {})
                    query.filters ?= {}
                    query.type = do ->
                        return 'json' if _.isUndefined(query.type)
                        return query.type.toLowerCase()

                    # FIXME: organization specific modifications
                    allsaintsOrganizations = ['allsaints', 'allsaints-new', 'allsaints_dev', 'allsaints_wholesale', 'allsaints_dtc']

                    query = checkForMaterialLevel(query) if organizationId is 'frye_wholesale'
                    query = addAllsaintsRealtimeMaxTimestampFilterHack(query, user) if organizationId in allsaintsOrganizations
                    query = UserRestrictions.restrictQuery(query)
                    query = addModifiers(query)
                    query = addMetricDefinitions(query)
                    query = addCustomMetrics(query)
                    query = addMetricFilters(query)

                    doQuery = -> $q.when do ->
                        httpOptions = {}
                        httpOptions.timeout = canceler
                        api.organizations
                        .doQuery({organization, queryId, query}, httpOptions)
                        .then (data) ->
                            return data if query.type is 'json' # json response
                            return {host, data} # export response

                    (cache.get(queryId, query) or cache.set(queryId, query, doQuery()))
                    .then (data) ->
                        return Utils.copy(data) if _.isObject(data)
                        return data
                    .catch (error) ->
                        cache.del(queryId, query)
                        throw error

            getQueryIds: -> $q.when do ->
                api.organizations.getQueryIds({organization})
            getMetrics: -> $q.when do ->
                api.organizations.getMetrics({organization})
            getDescriptors: -> $q.when do ->
                api.organizations.getDescriptors({organization})
            getStatus: -> $q.when do ->
                api.organizations.getStatus({organization})

        # announce = (queries) ->
        #     label = 'Available QueryServiceAPI queries:'
        #     console.group label
        #     queries.sort().forEach (q) -> console.log "- #{q}"
        #     console.groupEnd(label)

        buildAPIFromAvailableQueries = (queries = []) ->
            # announce(queries)
            return queries.reduce ((result, queryId) ->
                result[queryId] = wrapper.doQuery(queryId)
                return result
            ), {}

        return wrapper.getQueryIds().then (queries) ->
            result = {}
            result.query = buildAPIFromAvailableQueries(queries)
            result.getMetrics = -> wrapper.getMetrics()
            result.getDescriptors = -> wrapper.getDescriptors()
            result.getStatus = -> wrapper.getStatus()
            return result
]


module.service 'FileService', ($document) ->

    # `host` the query service host
    # `data` is whatever the server returned.
    send: (filename) -> ({host, data}) ->
        url = "#{host}/exports/#{data.type}/#{filename}?id=#{data.id}"
        link = $document[0].createElement('a')
        link.href = url
        link.dispatchEvent new MouseEvent "click",
            view: window
            bubbles: true
            cancelable: false
        return
