module.exports = -> class DateSelectAction

    constructor: (@action, @models, @bounds) ->
        @id       = @action.id
        @label    = @action.label
        @active   = @isActive()
        @disabled = @isDisabled()
        @section  = @action.section
        @hide     = @action.hide
        return

    onClick: -> @action.onClick?() or do =>
        range = @action.getRange()
        return if @isDisabled(range)
        {selection, comparison} = range
        @models.selection.select(selection)
        @models.comparison.select(comparison)

    getRange: ->
        return @action.getRange()

    isActive: (range) -> @action.isActive?() or do =>
        return false if not @models.selection
        {start, end} = (range or @action.getRange()).selection
        (@models.selection.selected.start or start).eq(start) and \
        (@models.selection.selected.end or end).eq(end)

    isDisabled: (range) -> @action.isDisabled?() or do =>
        return null if not @models.selection or not @bounds
        range  = (range or @action.getRange()).selection
        result = false
        result or= range.start.lt(@bounds.start) if @bounds?.start
        result or= range.end.gt(@bounds.end)     if @bounds?.end
        return result
