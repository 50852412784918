import _ from 'lodash';
import * as echarts from 'echarts';
import {
    shouldGroupSeries,
    SLICE_VALUE_THRESHOLD,
    SLICE_NUMBER_THRESHOLD,
    hasGroupSeries,
    validateThresholds,
} from './chart-echarts.helper';

const module = angular.module('42.directives.charts.echartswrapper', []);
export interface ISeriesMetricData {
    category: string;
    cellFilter: string;
    field: string;
    headerGroup: string;
    headerName: string;
    queryName: string;
}

export interface ISeriesData extends echarts.EChartOption.SeriesLine.DataObject {
    metric: ISeriesMetricData;
    percent?: number;
    name: string;
    value: number;
    isNegative?: boolean;
    groupedSeries?: ISeriesData[];
}

export interface EChartsCustomOptions {
    showPercentage: boolean;
    multiline: boolean;
    maxNumberOfSlices: number;
    groupSlicesThresholdValue: number;
}

module.directive('echartsCustom', [
    '$window',
    function EChartsCustom($window) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                options: '=',
                resizer: '=',
            },
            template: `
                <div class="echarts-container">
                    <div class="echarts-main"></div>
                </div>
            `,
            link: function EchartsCustomLink(scope: angular.IScope & any, element) {
                const chartElement = element.find('.echarts-main');
                const eChartsInstance = echarts.init(chartElement[0] as HTMLDivElement) as echarts.ECharts & {
                    id: string;
                };

                const viewEl = document.querySelector('#view') as Element;

                const onResize = () => eChartsInstance.resize();

                const onViewTransition = (e: Event) => {
                    if (e.target !== viewEl) return;
                    eChartsInstance.resize();
                };

                viewEl.addEventListener('transitionend', onViewTransition);
                $window.addEventListener('resize', onResize);

                scope.$on('$destroy', () => {
                    $window.removeEventListener('resize', onResize);
                    viewEl.removeEventListener('transitionend', onViewTransition);
                    eChartsInstance.dispose();
                    scope.resizer(null, eChartsInstance.id);
                });

                const shouldGroupSlices = (
                    options: any,
                    series: echarts.EChartOption.Series[],
                    sliceNumberThreshold: number,
                    sliceValueThreshold: number,
                ) =>
                    !_.isEmpty(options) &&
                    series.length === 1 &&
                    (series[0].type === 'pie' || series[0].type === 'bar') &&
                    !hasGroupSeries(series[0]) &&
                    shouldGroupSeries(series[0], sliceNumberThreshold, sliceValueThreshold);

                scope.$watch(
                    'options',
                    () => {
                        const options = _.cloneDeep(scope.options);

                        if (options) {
                            const series = options.series as echarts.EChartOption.Series[];
                            const customOptions: EChartsCustomOptions = scope.options.customOptions || {};

                            const maxNumberOfSlicesThreshold = validateThresholds(
                                customOptions.maxNumberOfSlices,
                                SLICE_NUMBER_THRESHOLD,
                            );
                            const sliceValueThreshold = validateThresholds(
                                customOptions.groupSlicesThresholdValue,
                                SLICE_VALUE_THRESHOLD,
                            );

                            if (shouldGroupSlices(options, series, maxNumberOfSlicesThreshold, sliceValueThreshold)) {
                                const data = series[0].data as ISeriesData[];

                                const totalValue = data.reduce((acc, item) => acc + item.value, 0);
                                const slicesAboveThreshold: ISeriesData[] = [];
                                const slicesBelowThreshold: ISeriesData[] = [];
                                let slicesBelowThresholdTotalSum = 0;

                                data.forEach((item, index) => {
                                    const isBelowValueThreshold = item.value / totalValue < sliceValueThreshold;
                                    const isBelowSliceNumberThreshold =
                                        maxNumberOfSlicesThreshold !== 0 && index >= maxNumberOfSlicesThreshold;

                                    if (isBelowSliceNumberThreshold && isBelowValueThreshold) {
                                        const percentValue = Math.abs(item.value) / totalValue;

                                        slicesBelowThreshold.push({
                                            ...item,
                                            percent: percentValue,
                                        });

                                        slicesBelowThresholdTotalSum += Math.abs(item.value);

                                        return;
                                    }

                                    slicesAboveThreshold.push(item);
                                });

                                if (slicesBelowThreshold.length) {
                                    const firstNegativeIndex = slicesAboveThreshold.findIndex(item => item.isNegative);
                                    const groupedItem = {
                                        metric: data[0].metric,
                                        value: slicesBelowThresholdTotalSum,
                                        name: 'Other',
                                        groupedSeries: slicesBelowThreshold,
                                        itemStyle: {
                                            color: '#BABCC8',
                                        },
                                    };

                                    if (firstNegativeIndex < 0) {
                                        slicesAboveThreshold.push(groupedItem);
                                    } else {
                                        slicesAboveThreshold.splice(firstNegativeIndex, 0, groupedItem);
                                    }
                                }

                                options.legend.data = slicesAboveThreshold.map(s => s.name);
                                options.series[0].data = slicesAboveThreshold;

                                if (series[0].type === 'bar') {
                                    setBarChartGroupedOptions(options, slicesAboveThreshold);
                                }
                            }

                            eChartsInstance.clear();
                            eChartsInstance.setOption(options, true);
                        }
                    },
                    true,
                );

                const setBarChartGroupedOptions = (
                    options: echarts.EChartOption,
                    slicesAboveThreshold: ISeriesData[],
                ) => {
                    if (options && options.xAxis && !Array.isArray(options.xAxis)) {
                        options.xAxis.data = slicesAboveThreshold.map(s => s.name);

                        if (options.xAxis.axisLabel) options.xAxis.axisLabel.fontSize = 6;
                    }
                };

                scope.$watch('resizer', () => {
                    if (scope.resizer) {
                        scope.resizer(onResize, eChartsInstance.id);
                    }
                });
            },
        };
    },
]);
