_ = require('../lib/lodash-helper.ts')

module = angular.module '42.directives.smart-groups', []



module.service 'TableProperties', (Hierarchy) ->

    fetch: (table) ->
        Hierarchy.fetch().then (hierarchies) ->
            hierarchy = hierarchies[table]
            hierarchy = _.filter hierarchy, (x) ->
                return false if ["transactions"].includes(x.table)
                return false if ["stores.company", "stores.aggregate"].includes(x.id)
                # TODO: check if we can put that in the x.table includes list
                return false if x.id?.startsWith("calendar_periods")
                return true
            return hierarchy


module.directive 'smartGroupFilterItems', (TableProperties, $q, Utils, promiseTracker, ObjectWatcher, TablePropertyValues, SmartGroupsFilterService, QueryServiceAPI) ->
    restrict: 'E'
    scope:
        table:  '='
        filter: '='
    replace: true
    template: \
    """
    <article class="smart-group-filter smart-group-filter-items">
        <item-filter model="model" properties="properties" selected="selected" />
    </article>
    """
    link: (scope) ->

        tracker = promiseTracker('smart-groups-filter')

        scope.$watchCollection 'selected', (selected) ->
            SmartGroupsFilterService.selected = (selected or [])
            SmartGroupsFilterService.filter = compileFilters(scope.model?.state?.filters or [])

        # Converts these filters to an object that can be used by the
        # query service.
        compileFilters = (filters) ->
            compiled = filters.reduce compileFilter, {}

        compileFilter = (result, filter) ->
            property = filter.property
            table = property.table
            id = property.column
            selected = _.filter filter.values, (value) -> value.selected
            selected = selected.map (value) -> value.id
            if selected.length isnt 0
                result[table] = {$and:[]}
                where = {}
                where[id] = $in:selected
                result[table].$and.push where
            return result


        getValues = (property, filters) ->
            filters ?= compileFilters(scope.model.state.filters)
            table = property.table

            processResult = (results) ->
                results = results.map (result) ->
                    id: result.value
                    # We need to replace ' because otherwise we get some kind
                    # of escaping error, because we use `ng-bind-html`.
                    label: result.value?.replace(/'/g, "’")
                    count: result.count
                return _.filter results, (result) -> result.id

            promise = TablePropertyValues.fetch(table, property.column, filters[table]).then processResult

            tracker.addPromise(promise)
            return promise


        updateSelectedFromQuery = (query) ->
            return [] if not query?.filters?[scope.table]?.$and?
            query.filters[scope.table].$and.map (propertyDescriptor) ->
                id = Object.keys(propertyDescriptor)[0]
                values = (propertyDescriptor[id].$in or []).map (id) ->
                    id:       id
                    label:    id
                    selected: true
                return {id, label:id, values}


        updateModel = (properties, filter) ->
            allProperties = properties

            decompileQuery = do ->

                decompile = (filter) ->
                    propertiesDict = _.keyBy scope.properties, 'id'
                    scope.tables.map (table) ->
                        (filter[table]?.$and or []).map (propertyDescriptor) ->
                            column = Object.keys(propertyDescriptor)[0]
                            id = "#{table}.#{column}"
                            property = propertiesDict[id]
                            return if not property
                            values = propertyDescriptor[column].$in or []
                            property.values = values.map (idx) ->
                                id:       idx
                                label:    idx
                                selected: true
                            return property

                syncSelectedProperties = (filter, selectedProperties) ->
                    $q.all selectedProperties.map (property) ->
                        indexed = _.keyBy property.values, 'id'
                        getValues(property, filter)
                        .then (values) ->
                            property.values = values.map (value) ->
                                value.selected = !!indexed[value.id]
                                return value
                            return property

                return (filter) -> $q.when do ->
                    selectedProperties = _.compact _.flatten decompile(filter)

                    model = {state:{}}
                    model.tables = scope.tables
                    model.selected = _.flatten selectedProperties.map (property) -> property.values
                    model.state.filters = []
                    model.state.properties =
                        all:      allProperties
                        selected: selectedProperties

                    return model if not selectedProperties

                    syncSelectedProperties(filter, selectedProperties)
                    .then (selectedProperties) ->
                        model.state.properties.selected = selectedProperties
                        model.state.filters = selectedProperties.map (property) ->
                            property: property
                            values:   property.values
                        return model

            decompileQuery(filter).then (result) ->
                scope.model = result

        tracker.addPromise \
        TableProperties.fetch(scope.table).then (properties) ->
            scope.properties = properties
            scope.tables = _.uniq properties.map (property) -> property.table
            unsub = ObjectWatcher(scope) 'filter', (filter) ->
                filter = Utils.copy(filter)
                filterMissing = scope.tables.every (table) ->
                    return not filter or not filter[table] or filter[table].$and.length is 0
                return if filterMissing
                console.log "[filter] input filter changed:", JSON.stringify(filter)
                updateModel(properties, filter)


module.service 'TablePropertyValues', (EmployeePropertyValues, CustomersPropertyValues, ItemPropertyValues, TransactionItemPropertyValues,
    TransactionPropertyValues, StorePropertyValues, AcquisitionPropertyValues, GiftCardsPropertyValues, ItemTimeseriesPropertyValues,
    AccountPropertyValues, WarehousePropertyValues, DemandItemPropertyValues) ->
    queries =
        "employees":         EmployeePropertyValues
        "customers":         CustomersPropertyValues
        "accounts":          AccountPropertyValues
        "warehouses":        WarehousePropertyValues
        "items":             ItemPropertyValues
        "transaction_items": TransactionItemPropertyValues
        "transactions":      TransactionPropertyValues
        "demand_items":      DemandItemPropertyValues
        "stores":            StorePropertyValues
        "acquisitions":      AcquisitionPropertyValues
        "gift_cards":        GiftCardsPropertyValues
        "item_timeseries":   ItemTimeseriesPropertyValues

    fetch: (table, property, filter) ->
        query = queries[table]
        query.fetch(property, filter)


module.service 'ItemPropertyValues', (QueryServiceAPI) ->
    fetch: (property, filter) ->
        sort = do ->
            return value: -1 if property is 'season'
            return count: -1
        query =
            options:{property}
            filters:{items:filter}
            sort: sort
        itemFilters = filter?.$and
        if itemFilters
            query.filters.items.$and = _.filter itemFilters, (filter) ->
                return Object.keys(filter)[0] isnt property
        (new QueryServiceAPI).then (api) -> api.query.filterItems(query)


module.service 'TransactionItemPropertyValues', (QueryServiceAPI) ->
    fetch: (property, filter) ->
        query =
            options:{property}
            filters:{transaction_items:filter}
            sort: count: -1
        (new QueryServiceAPI).then (api) -> api.query.filterTransactionItems(query)


module.service 'TransactionPropertyValues', (QueryServiceAPI) ->
    fetch: (property, filter) ->
        query =
            options:{property}
            filters:{transactions:filter}
            sort: count: -1
        (new QueryServiceAPI).then (api) -> api.query.filterTransactions(query)


module.service 'DemandItemPropertyValues', (QueryServiceAPI) ->
    fetch: (property, filter) ->
        query =
            options:{property}
            filters:{demand_items:filter}
            sort: count: -1
        (new QueryServiceAPI).then (api) -> api.query.filterDemandItems(query)


module.service 'AcquisitionPropertyValues', (QueryServiceAPI) ->
    fetch: (property, filter) ->
        query =
            options:{property}
            filters:{acquisitions:filter}
            sort: count: -1
        return (new QueryServiceAPI).then (api) -> api.query.filterAcquisitions(query)


module.service 'AccountPropertyValues', (QueryServiceAPI) ->
    fetch: (property, filter) ->
        query =
            options:{property}
            filters:{accounts:filter}
            sort: value: 1
        accountFilters = filter?.$and
        if accountFilters
            query.filters.accounts.$and = _.filter accountFilters, (filter) ->
                return Object.keys(filter)[0] isnt property
        (new QueryServiceAPI).then (api) -> api.query.filterAccounts(query)


module.service 'WarehousePropertyValues', (QueryServiceAPI) ->
    fetch: (property, filter) ->
        query =
            options:{property}
            filters:{warehouses:filter}
            sort: value: 1
        warehouseFilters = filter?.$and
        if warehouseFilters
            query.filters.warehouses.$and = _.filter warehouseFilters, (filter) ->
                return Object.keys(filter)[0] isnt property
        (new QueryServiceAPI).then (api) -> api.query.filterWarehouses(query)


module.service 'ItemTimeseriesPropertyValues', (QueryServiceAPI) ->
    fetch: (property, filter) ->
        query =
            options:{property}
            filters:{item_timeseries:filter}
            sort: value: 1
        (new QueryServiceAPI).then (api) -> api.query.filterItemTimeseries(query)


module.service 'EmployeePropertyValues', (QueryServiceAPI) ->
    fetch: (property, filter) ->
        query =
            options:{property}
            filters:{employees:filter}
            sort: count: -1
        (new QueryServiceAPI).then (api) -> api.query.filterEmployees(query)


module.service 'CustomersPropertyValues', (QueryServiceAPI) ->
    fetch: (property, filter) ->
        query =
            options:{property}
            filters:{customers:filter}
            sort: count: -1
        customerFilters = filter?.$and
        if customerFilters
            query.filters.customers.$and = _.filter customerFilters, (filter) ->
                return Object.keys(filter)[0] isnt property
        (new QueryServiceAPI).then (api) -> api.query.filterCustomers(query)


module.service 'GiftCardsPropertyValues', (QueryServiceAPI) ->
    fetch: (property, filter) ->
        query =
            options:{property}
            filters:{gift_cards:filter}
            sort: value: 1
        (new QueryServiceAPI).then (api) -> api.query.filterGiftCards(query)


module.service 'StorePropertyValues', ($rootScope, QueryServiceAPI, Utils) ->
    fetch: (property, filter) ->
        query = Utils.copy $rootScope.query
        query.options = {property}
        query.filters.stores = filter
        query.sort = count:-1
        storeFilters = filter?.$and
        if storeFilters
            query.filters.stores.$and = _.filter storeFilters, (filter) ->
                return Object.keys(filter)[0] isnt property
        (new QueryServiceAPI).then (api) -> api.query.filterStores(query)


module.directive "itemFilter", ($q, $timeout, promiseTracker, Utils, QueryServiceAPI, TablePropertyValues, SmartGroupsFilterService) ->
    restrict: 'E'
    replace: true
    scope:
        properties: '='
        model:      '='
        selected:   '='
    template: \
    """
    <article class="property-filters">
        <ul class="item-properties">
            <li ng-repeat="property in model.state.properties.available">
                <button ng-click="selectProperty($event, property)" class="card">{{ property.label }}</button>
            </li>
        </ul>
        <section class="item-property-filters" ng-class="{loading:isLoading}">
            <item-property-filter
                ng-repeat="filter in model.state.filters"
                model="filter"
                on-search="onFilterSearchChange(filter)"
                on-change="onFilterChange(filter)"
                on-remove="removeSelectedFilter(filter)" />
        </section>
    </article>
    """
    link: (scope, element) ->
        # scope.model = {}
        # scope.model.state = {}

        # Converts these filters to an object that can be used by the
        # query service.
        compileFilters = (filters) ->
            filters.reduce compileFilter, {}


        compileFilter = (result, filter) ->
            property = filter.property
            table = property.table
            id = property.column
            selected = _.filter filter.values, (value) -> value.selected
            selected = selected.map (value) -> value.id
            if selected.length isnt 0
                result[table] ?= {$and:[]}
                where = {}
                where[id] = $in:selected
                result[table].$and.push where
            return result


        getValues = (property, filters) ->
            table = property.table

            processResult = (results) ->
                results = results.map (result) ->
                    id: result.value
                    # We need to replace ' because otherwise we get some kind
                    # of escaping error, because we use `ng-bind-html`.
                    label: result.value?.replace(/'/g, "’")
                    count: result.count
                return _.filter results, (result) -> result.id

            promise = TablePropertyValues.fetch(table, property.column, filters[table]).then processResult

            propertyTracker = promiseTracker("property-filter-#{ property.id }")
            propertyTracker.addPromise(promise)
            return promise


        propertyDiff = (existing, removing) ->
            existing ?= []
            removing ?= []
            removing = _.keyBy removing, 'id'
            return _.filter existing, (value) -> not removing[value.id]


        uniqueProperties = (properties) ->
            seen = {}
            properties.reduce ((result, property) ->
                return result if seen[property.id]
                return result.concat (seen[property.id] = property)
            ), []


        createFilter = (property) ->
            filter = {}
            filter.property = property
            filter.values = []
            getValues(property, compileFilters(scope.model.state.filters)).then (values) ->
                filter.values = values
            return filter


        refreshFilter = (filter) ->
            console.log "Refreshing filter for property:", filter.property
            oldIndexedValues = _.keyBy filter.values, 'id'
            filters = compileFilters(scope.model.state.filters)
            getValues(filter.property, filters)
            .then (values) ->
                filter.values = values.map (value) ->
                    value.selected = !!(oldIndexedValues[value.id]?.selected and value.count > 0)
                    return value
                return filter

        # Put the selected values at the top, while still preserving
        # the general sort order of the values.
        sortFilterValues = (filterModel) ->
            values = filterModel.values
            [selected, deselected] = filterModel.values.reduce (result, value) ->
                index = if value.selected then 0 else 1
                result[index].push(value)
                return result
            , [[],[]]
            filterModel.values = selected.concat(deselected)
            return filterModel

        sortAllFilterValues = (activeFilter) ->
            filters = do ->
                return scope.model.state.filters if not activeFilter
                return _.filter scope.model.state.filters, (filter) ->
                    return activeFilter.property.id isnt filter.property.id
            filters.forEach (filter) -> sortFilterValues(filter)

        refreshFilters = (activeFilter) ->
            filters = scope.model.state.filters
            filters = _.filter filters, (filter) -> activeFilter.property.id isnt filter.property.id
            console.log "Refreshing filters...", activeFilter.property.id
            $q.all(filters.map refreshFilter)


        updateSelected = ->
            return if not scope.model?.state?.filters?
            filters = scope.model.state.filters
            indexedProperties = _.keyBy scope.properties, 'id'
            scope.selected = _.flatten filters.map (filter) ->
                selectedValues = _.filter filter.values, (value) -> value.selected
                selectedValues.map (value) ->
                    property: filter.property
                    model: value
                    label: value.id

        scope.onFilterSearchChange = (filter) ->
            sortFilterValues(filter) if filter

        scope.onFilterChange = (filter) ->
            updateSelected()
            refreshFilters(filter).then ->
                filters = scope.model.state.filters
                # scope.filter = compileFilters(filters)
                sortAllFilterValues(filter)

        syncFilters = (available) ->
            # Here, we sync the filters with the selected properties.
            properties = scope.model.state.properties.selected or []
            filters    = scope.model.state.filters or []
            indexed =
                properties: _.keyBy properties, 'id'
                filters:    _.keyBy filters, (filter) -> filter.property.id
            union = _.union indexed.properties, indexed.filters
            scope.model.state.filters = \
            Object.keys(indexed.properties).map (id) ->
                # If a filter exists and is selected, we reuse it.
                indexed.filters[id] or do ->
                    # If a filter doesn't exist, but is selected, we create one.
                    property = indexed.properties[id]
                    return createFilter(property)


        resetModel = (properties) ->
            console.log "Resetting model state."
            scope.model ?= {}
            scope.model.state =
                filters: []
                properties:
                    all: properties
                    available: []
                    selected:  []


        SmartGroupsFilterService.callbacks.reset = ->
            resetModel(scope.properties)
            updateSelected()


        SmartGroupsFilterService.callbacks.save = ->
            defaultFilters = {}
            scope.model.tables?.forEach (table) -> defaultFilters[table] = {}
            return _.assign defaultFilters, compileFilters(scope.model.state.filters)


        scope.selectProperty = (event, property) ->
            event.stopImmediatePropagation()
            console.log "Selecting property:", property
            selected = scope.model.state.properties.selected
            scope.model.state.properties.selected = uniqueProperties(selected.concat(property))


        scope.removeSelectedFilter = (filter) ->
            console.log "Removing selected property:", filter.property
            filter.values.forEach (value) -> value.selected = false
            selected = scope.model.state.properties.selected
            scope.model.state.properties.selected = _.filter selected, ({id}) -> id isnt filter.property.id
            updateSelected()


        scope.$watch 'properties', (properties) ->
            return if not properties
            return resetModel(properties) if not scope.model?.state?.properties?
            ids =
                added:    _.pick properties,  'id'
                existing: _.pick scope.model, 'id'
            diff = _.difference ids.added, ids.existing
            return resetModel(properties) if diff.length isnt 0


        scope.$watch 'model.state.properties.all', (properties) ->
            return if not scope.model
            return if not properties
            console.log "All properties changed:", properties
            scope.model.state.properties.available = properties


        scope.$watch 'model.state.properties.selected', (selected) ->
            return if not scope.model
            return if not selected
            console.log "Selected properties changed:", selected
            existing = scope.model.state.properties.all
            scope.model.state.properties.available = propertyDiff(existing, selected)


        scope.$watch 'model.state.properties.available', (available, lastAvailable) ->
            return if not scope.model
            return if not available
            console.log "Available properties changed:", available
            syncFilters()
            updateSelected()
            sortAllFilterValues()



module.directive "itemPropertyFilter", ($timeout) ->
    restrict: 'E'
    replace: true
    scope:
        model:    '='
        onSearch: '&'
        onChange: '&'
        onRemove: '&'
    template: \
    """
    <article class="item-property-filter">
        <header>
            <h1>{{ model.property.label }}</h1>
            <div class="remove-item" ng-click="remove($event)">×</div>
            <input type="text" class="icon-search" placeholder="search" ng-model="model.search.id">
            <p class="icon-search"></p>
        </header>
        <main>
            <div class="loadable" promise-tracker="property-filter-{{ model.property.id }}"></div>
            <ul class="item-property-values" infinite-scroll="load()">
                <li ng-repeat="value in model.values | filter:model.search | limitTo:limit"
                    ng-class="{unavailable:(value.count == 0), selected:value.selected}"
                    class="item-property-value"
                    ng-click="toggle(value, $event)"
                    >
                    <label>
                        <input type="checkbox" ng-model="value.selected" ng-disabled="value.count == 0">
                        <span class="item-property-value-id" ng-bind-html=" '{{ value.label }}' | highlight:highlight"></span>
                        <span class="item-property-value-count">{{ value.count }}</span>
                    </label>
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope, element) ->
        $main = $(element).find('main')

        resetScrollLimit = ->
            scope.limit = 50

        scope.load = ->
            scope.limit += 20

        onChange = ->
            scope.onChange(scope.model)

        scope.toggle = (value, $event) ->
            $event.preventDefault()
            $event.stopPropagation
            return false if value.count is 0
            value.selected = !value.selected
            $timeout -> onChange()
            return false

        scope.remove = (event) ->
            event.stopImmediatePropagation()
            scope.onRemove(scope.model)
            scope.onChange(scope.model)

        scope.$watch 'model.values', (values) ->
            $main.scrollTop(0)
            resetScrollLimit()

        scope.$watch 'model.search.id', (id) ->
            scope.highlight = _.escapeRegExp id?.replace(/'/g, "’")
            scope.onSearch?(scope.model, id)

        resetScrollLimit()
