_ = require('../../lib/lodash-helper.ts')
module = angular.module '42.controllers.transactions', []
module.config ($routeProvider, ROUTES, CONFIG) ->
    routeId = 'transactions'
    route = _.extend {}, ROUTES[routeId], _.pick(CONFIG.routes?[routeId], 'label', 'url')
    $routeProvider.when route.url, route


module.service 'Transactions', (QueryServiceAPI) ->
    fetch: (query) -> (new QueryServiceAPI).then (api) ->
        api.query.transactionsReport(query)


module.directive 'transactionsTable', ($rootScope, promiseTracker, Utils, Transactions) ->
    restrict: 'E'
    replace: false
    template: \
    """
    <article class="ui-table supertable transactions-table" supertable sortable-container>
        <main>
            <div class="loadable" promise-tracker="transactions-table"></div>
            <table class="table-normal">
                <tbody infinite-scroll="loadMore()">
                    <tr ng-repeat="d in model.data">
                        <td class="column-timestamp">{{ d.timestamp | date:'medium' }}</td>
                        <td class="column-customer">{{ d.customer_name }}</td>
                        <td class="column-total">{{ d.total | money }}</td>
                        <td class="column-item-count">{{ d.item_count | number }}</td>
                    </tr>
                </tbody>
            </table>
        </main>
        <header>
            <table class="table-normal">
                <thead>
                    <tr class="table-titles">
                        <th class="column-timestamp" sortable-property="timestamp">TIMESTAMP</th>
                        <th class="column-customer" sortable-property="customer_name">CUSTOMER</th>
                        <th class="column-total" sortable-property="total">TOTAL</th>
                        <th class="column-item-count" sortable-property="item_count">ITEM COUNT</th>
                    </tr>
                </thead>
            </table>
        </header>
        <footer>
            <table class="table-normal">
                <tfoot>
                    <tr>
                        <td class="column-paginator" colspan="4">Showing <em>{{ model.data.length }}</em> transactions</td>
                    </tr>
                </tfoot>
            </table>
        </footer>
    </article>
    """
    link: (scope) ->
        tracker = promiseTracker('transactions-table')

        scope.model = {}
        scope.model.data = []

        refresh = ->
            scope.model.data = []
            query = Utils.copy ($rootScope.query or {})
            query.limit = 250
            query.sort  = scope.sortable?.model or {timestamp:-1}
            promise = Transactions.fetch(query).then (result) ->
                scope.model.data = result
            tracker.addPromise(promise)
            return promise

        scope.$watch 'sortable.model', refresh, true

        scope.$on '$destroy', \
        $rootScope.$on 'query.refresh', refresh



module.controller 'TransactionsController', ($scope) ->
    $scope.sortable = {}
    $scope.sortable.model = {timestamp:-1}
