import _ from 'lodash';
import { IMapSeries } from './chart-echarts-map-model';
import { ISeriesData } from './chart-echarts-wrapper.directive';

export const SLICE_VALUE_THRESHOLD = 0.02;
export const SLICE_NUMBER_THRESHOLD = 10;

// TODO - Study best slice strategy.
// At the moment, `maxNumberOfSlices` and `groupSlicesThresholdValue` are "OR" conditions
// When both options enabled, `maxNumberOfSlices` prevails

export interface IGroupSeriesOptions {
    maxNumberOfSlices?: number;
    groupSlicesThresholdValue?: number;
}

export const validateThresholds = (value: number | undefined, defaultValue: number) =>
    _.isNumber(value) && value > 0 ? value : defaultValue;

function getSlicesTotalValue(slices: ISeriesData[]): number {
    return slices.reduce((acc, item) => acc + Math.abs(item.value || 0), 0);
}

export const shouldGroupSeries = (
    serie: echarts.EChartOption.Series,
    sliceNumberThreshold: number,
    sliceValueThreshold: number,
) => {
    if (!serie) return false;

    const slices = (serie.data || []) as ISeriesData[];

    if (sliceNumberThreshold > 0) {
        return slices.length > sliceNumberThreshold;
    }

    const totalValue = getSlicesTotalValue(slices);

    return !slices.every(item => Math.abs(item.value / totalValue) >= sliceValueThreshold);
};

export const hasGroupSeries = (serie: echarts.EChartOption.Series): boolean => {
    if (!serie) return false;
    const data = (serie.data || []) as ISeriesData[];

    return Boolean(data.length === 0 ? (data[0] || {}).groupedSeries : data[data.length - 1].groupedSeries);
};

export function getSeriesMaxMinValue(serie: IMapSeries): { max: number; min: number } {
    const { value: max } = _.maxBy(serie.data, item => item.value ?? 0) || { value: 0 };
    const { value: min } = _.minBy(serie.data, item => item.value ?? 0) || { value: 0 };

    return { max, min };
}

export const STATES: { [key: string]: string } = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY',
    'American Samoa': 'AS',
    'District of Columbia': 'DC',
    'Guam': 'GU',
    'Northern Mariana Islands': 'MP',
    'Puerto Rico': 'PR',
    'Virgin Islands': 'VI',
};

export const getStateFullName = (stateShortVersion: string) => {
    const stateName = STATES[stateShortVersion];
    if (stateName) return stateShortVersion;
    return _.findKey(STATES, stateValue => stateValue === stateShortVersion);
};
