import angular from 'angular';

export const UserMenuDirective = () => [
    function UserMenu() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <article class="user-menu dropdown">

                    <!-- this is the section that's visible in the top bar -->
                    <article class="user-menu-face dropdown-toggle">
                        <img
                            class="organization-logo"
                            ng-src="{{ model.organization.logo }}"
                            on-error-src="onLogoError()"
                            ng-if="!logoError && model.organization.logo"
                        >
                        <section class="labels" ng-class="{ 'no-logo': logoError || !model.organization.logo }">
                            <span class="organization-label">{{ model.organization.label }}</span>
                            <span class="user-label">{{ model.user.name || model.user.email }}</span>
                        </section>
                        <i class="icon-menu hide-small"></i>
                    </article>

                    <!-- enabled if user has multiple organizations -->
                    <ul class="user-menu-dropdown dropdown-menu" ng-if="model.organizations.length > 1">
                        <li class="submenu">
                            <h1>Available Dashboards</h1>
                            <user-menu-organization-list model="model"></user-menu-organization-list>
                        </li>
                        <li class="submenu">
                            <h1>Actions</h1>
                            <user-menu-actions model="model"></user-menu-actions>
                        </li>
                    </ul>

                    <!-- enabled if user has only one org -->
                    <ul class="user-menu-dropdown dropdown-menu" ng-if="model.organizations.length == 1">
                        <li class="submenu">
                            <user-menu-actions model="model"></user-menu-actions>
                        </li>
                    </ul>
                </article>
            `,
            link: function UserMenuLink($scope: angular.IScope & any) {
                $scope.logoError = false;

                $scope.onLogoError = () => ($scope.logoError = true);
            },
        };
    },
];

export const UserMenuOrganizationListDirective = () => [
    function UserMenuOrganizationList() {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <ul class="organizations">
                    <li class="dropdown-menu-item"
                        ng-click="model.setOrganization(organization.id)"
                        ng-repeat="organization in model.organizations"
                        ng-class="{active:model.organization.id == organization.id}">
                        {{ organization.label || organization.id || organization }}
                    </li>
                </ul>
            `,
        };
    },
];

export const UserMenuActionsDirective = () => [
    'ROUTES',
    function UserMenuActions(ROUTES: any) {
        return {
            restrict: 'E',
            scope: {
                model: '=',
            },
            replace: true,
            template: `
                <ul class="user-menu-actions">
                    <li class="reporting" ng-click="goToCounterPage()" ng-if="model.flags.kiosk">
                        <i class="icon-picture">Kiosk Mode</i>
                    </li>
                    <li class="reporting" ng-click="goToReportingPage()" ng-if="model.flags.reporting">
                        <i class="icon-docs">Reporting</i>
                    </li>
                    <li class="admin" ng-click="goToUserAdminPage()" ng-if="model.user.isAdmin() && model.adminUrl">
                        <i class="icon-vcard">User Admin</i>
                    </li>
                    <li class="logout" ng-click="model.logout()">
                        <i class="icon-logout">Logout</i>
                    </li>
                </ul>
            `,
            link: (scope: angular.IScope & any) => {
                scope.goToUserAdminPage = () => {
                    window.open(`${scope.model.adminUrl}/organizations/${scope.model.organization.id}/users`);
                };
                scope.goToReportingPage = () => {
                    window.location.hash = ROUTES.reportingReports.url;
                };
                scope.goToCounterPage = () => {
                    window.location.hash = ROUTES.counter.url;
                };
            },
        };
    },
];
