module.exports = ($rootScope) ->
    restrict: 'E'
    replace: true
    template: \
    """
    <button class="toggle-sidebar" ng-click="onClick($event)" ng-class="{'showing-sidebar': !hideSidebar}">
        <i class="icon-right-open" ng-hide="hideSidebar" ></i>
        <i class="icon-left-open" ng-hide="!hideSidebar" ></i>
    </button>
    """
    link: (scope) ->
        scope.onClick = (value, $event) ->
            $rootScope.hideSidebar = !$rootScope.hideSidebar
            $rootScope.sidebarAnimating = true
            document.querySelector('#view').addEventListener 'transitionend', ->
                $rootScope.sidebarAnimating = false