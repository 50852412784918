import { ITransaction } from '../customer.types';
export const ScrollToSelectedTransactionDirective = () => [
    function ScrollToSelectedTransaction() {
        return {
            restrict: 'A',
            link: ($scope: angular.IScope, element: angular.IRootElementService) => {
                const $element = $(element);
                const scrollEvents = 'scroll mousedown DOMMouseScroll mousewheel keyup';

                $scope.$watch('transaction', (transaction: ITransaction) => {
                    if (!transaction) {
                        return;
                    }

                    const $transaction = $element.find(`#transaction-${transaction.id}`);

                    if ($transaction.length === 0) {
                        return;
                    }

                    const offset = $transaction.height() / 2 - $element.height() / 2 + 1;
                    const scrollTop = $element.scrollTop() + $transaction.position().top + offset;
                    $element.stop().unbind(scrollEvents);
                    $element.animate({ scrollTop }, 500);
                    $element.bind(scrollEvents, (e: any) => {
                        if (e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel') {
                            $element.stop().unbind(scrollEvents);
                        }
                    });
                });
            },
        };
    },
];
