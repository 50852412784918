import { StorageAPI } from '../lib/storage-user-config';

export interface IStorageInterfaceAPI<T> {
    new (key: string): Promise<IStorageAPI<T>>;
}
export interface IStorageAPI<T> {
    get: () => Promise<T>;
    put: (data: any) => Promise<void>;
}

const StorageApiInstance = () => [
    '$q',
    ($q: angular.IQService) => (key: string) =>
        $q.when(StorageAPI(key)).then(api => ({
            get() {
                return $q.when(api.get());
            },
            put(data: any) {
                return $q.when(api.put(data));
            },
        })),
];

const module = angular.module('42.modules.storage', []);
module.factory('StorageAPI', StorageApiInstance());
