import * as echarts from 'echarts';
import { MAPS } from './maps/usa-map';

export type geoJson = Record<string, any>;
export type IEChartMaps = Record<string, geoJson>;

const SPECIAL_AREAS_DICT: Record<string, any> = {
    USA: {
        'Alaska': {
            left: -131,
            top: 25,
            width: 15,
        },
        'Hawaii': {
            left: -110,
            top: 28,
            width: 5,
        },
        'Puerto Rico': {
            left: -76,
            top: 26,
            width: 2,
        },
    },
};

const getMaps = (): Promise<IEChartMaps> => {
    return Promise.resolve(MAPS);
};

export const init = async () => {
    const maps = await getMaps();

    Object.keys(maps).forEach(mapName => {
        echarts.registerMap(mapName, maps[mapName], SPECIAL_AREAS_DICT[mapName] || {});
    });
};
