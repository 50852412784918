module.exports = (DateWrapper) ->
    restrict: 'E'
    replace: true
    scope:
        min:  '='
        max:  '='
        date: '='
    template: \
    """
    <article class="date-input"><input ng-model="view"></article>
    """
    link: (scope, element) ->
        $inputEl = $($(element).children())

        scope.$watch 'date', (date) ->
            return if not date
            scope.view = date.format('YYYY-MM-DD')

        scope.$watch 'view', (view) ->
            return if not view
            return if not /\d\d\d\d-\d\d-\d\d/.test(view)
            date = try DateWrapper.CreateFromDate(view)
            if not date
                scope.view = scope.date?.format('YYYY-MM-DD')
                return
            scope.date = date

        $inputEl.focusout ->
            scope.view = scope.date.format('YYYY-MM-DD')
            scope.$apply()
