import _ from 'lodash';
import { ConfigAPI } from './config-api';

export interface UserExternalConfig {
    'currency'?: string;
    'groups'?: unknown;
    'metrics.views.v1'?: unknown;
    'items.action-model-state-v2'?: unknown;
    'items.action-model-state-v3'?: unknown;
    'reports'?: {
        'metrics-breakdown'?: unknown;
        [x: string]: unknown;
    };
    [x: string]: unknown;
}

export class StorageAPISlice<K extends keyof UserExternalConfig> {
    constructor(readonly key: K, private storage: StorageBackend<UserExternalConfig>) {}
    async get(): Promise<UserExternalConfig[typeof this.key]> {
        return this.storage.get(this.key);
    }
    async put(data: UserExternalConfig[typeof this.key]): Promise<void> {
        return this.storage.put(this.key, data);
    }
}

interface StorageBackend<T extends Record<string, unknown>> {
    get<K extends keyof T>(key: K): Promise<T[K]>;
    put<K extends keyof T>(key: K, data: T[K]): Promise<void>;
}

class UserExternalConfigStorageBackend implements StorageBackend<UserExternalConfig> {
    static Create() {
        return ConfigAPI.get()
            .then(api => api.user.get())
            .then(state => new UserExternalConfigStorageBackend(state ?? {}));
    }

    constructor(private state: UserExternalConfig) {}

    async get(key: keyof UserExternalConfig) {
        const value = key in this.state && this.state[key] !== undefined ? _.cloneDeep(this.state[key]) : undefined;
        return Promise.resolve(value);
    }

    async put<K extends keyof UserExternalConfig>(key: K, data: UserExternalConfig[K]): Promise<void> {
        if (_.isPlainObject(data)) data = _.cloneDeep(data);
        this.state[key] = data;
        return ConfigAPI.get().then(api => api.user.set(this.state));
    }
}

export const StorageAPI = (() => {
    const promise = UserExternalConfigStorageBackend.Create();
    return (key: string) => promise.then(storage => new StorageAPISlice(key, storage));
})();
