_ = require('../../../lib/lodash-helper.ts')

ComparisonModes = (CONFIG) ->

    config =
        sameWeekdayLY: CONFIG.datepicker?.sameWeekdayLY or false
        restated:      CONFIG.datepicker?.restated or false

    yearComparisonMode = (yearCount) -> (selection, bounds) ->
        {start, end} = selection

        calculateDayOffset = (ty, ly) ->
            return 0 if not config.sameWeekdayLY
            return 2 if ly.date.isLeapYear() and ly.date.month()  < 2
            return 2 if ty.date.isLeapYear() and ty.date.month() >= 2
            return 1

        start = do -> try
            start = start.add(1, 'week') if start.year.restate && start.month.offset > start.year.restateMonth
            start.subtract(yearCount, 'year')

        if not start or start?.lt(bounds.start)
            return null

        end = do -> try
            end = end.add(1, 'week') if end.year.restate && end.month.offset > end.year.restateMonth
            end.subtract(yearCount, 'year')

        if not end or end.gt(bounds.end)
            return null

        startDayOffset = calculateDayOffset selection.start, start
        start = start.add(startDayOffset, 'day')

        endDayOffset = calculateDayOffset(selection.end, end)
        end = end.add(endDayOffset, 'day')

        if config.restated
            end = end.startOf('week').subtract(1, 'day') if selection.end.year.restate and selection.end.weekOffset is 52
            start = start.subtract(1, 'week') if start.year.restate
            end = end.subtract(1, 'week') if end.year.restate

        return {start, end}


    period: (selection, bounds) ->
        return null if not (selection.start and selection.end)
        comparison =
            start: selection.start.subtract(selection.end.diff(selection.start, 'days')+1, 'days')
            end:   selection.start.subtract(1, 'day')
        return null if comparison.start.lt(bounds.start)
        return comparison

    'year':   yearComparisonMode(1)
    'year-2': yearComparisonMode(2)
    'year-3': yearComparisonMode(3)



module.exports = (CONFIG) ->

    return class ComparisonModeSelectModel

        constructor: (mode) ->
            mode ?= 'year'
            @modes = [
                {id:'year',   label:'Last Year'},
                {id:'year-2', label:'2 Years Ago'},
                {id:'year-3', label:'3 Years Ago'}
                {id:'period', label:'Last Period'}
            ]
            @mode = _.find(@modes, (x) -> x.id is mode)?.id
            @mode ?= do =>
                console.warn "Comparison mode '#{mode}' was not found. Using default."
                return @modes[0].id

        getComparisonTimerange: (selection, bounds) ->
            getComparisonTimerangeFn = ComparisonModes(CONFIG)[@mode]
            throw new Error("Unsupported mode '#{@mode}'.") if not getComparisonTimerangeFn
            return getComparisonTimerangeFn(selection, bounds)
