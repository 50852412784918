_ = require('../lib/lodash-helper.ts')

module = angular.module '42.directives.ui', []
module.constant 'KEYS',
    LEFT:  37
    UP:    38
    RIGHT: 39
    DOWN:  40


module.directive 'dropdownSelect', ->
    restrict: 'E'
    scope:
        selected: '='
        options:  '='
    replace: true
    template: \
    """
    <article class="ui-dropdown-select dropdown">
        <div class="ui-dropdown-select-value">{{ selected.label }}</div>
        <div class="ui-dropdown-select-toggle dropdown-toggle"></div>
        <ul class="ui-dropdown-select-options dropdown-menu">
            <li class="ui-dropdown-select-option dropdown-menu-item"
                ng-repeat="option in options"
                ng-class="{selected:option.id == selected.id}"
                ng-click="select($event, option)">{{ option.label }}</li>
        </ul>
    </article>
    """
    link: (scope, element, attributes) ->
        scope.selected = null
        scope.select = (event, option) ->
            event.preventDefault()
            event.stopPropagation()
            scope.selected = option
            _.defer -> scope.toggleDropdown(event)
            return false

module.directive 'sortableContainer', ->
    restrict: 'A'
    controller: ($scope) ->

        ORDERS = ['descending', 'ascending']
        sortables = {}
        sortOrder = 0
        sortProperty = null

        unregister = (property) ->
            # console.log "unregistering sortable:", property
            element = sortables[property]
            delete sortables[property]
            $(element).off 'click'

        resetAllElements = (property) ->
            if sortProperty isnt property
                sortOrder = 0
                sortProperty = property
            Object.keys(sortables).forEach (key) ->
                element = sortables[key]
                element.attr 'sort-order', null
                if sortOrder in [1, 0]
                    delete $scope.sortable.model
                    ($scope.sortable.model = {})[property] = do ->
                        return  1 if sortOrder is 1
                        return -1 if sortOrder is 0
                else
                    delete $scope.sortable.model

        attachClickHandler = (property, element) ->
            $(element).on 'click', (event) ->
                resetAllElements(property)
                $(this).attr 'sort-order', ORDERS[sortOrder]
                sortOrder = (sortOrder + 1) % 2

        $scope.sortable = {}

        $scope.sortable.register = (property, element) ->
            # console.log 'registering sortable:', property
            $element = $(element)
            sortables[property] = $element
            attachClickHandler(property, $element)

        $scope.$on '$destroy', ->
            Object.keys(sortables).forEach unregister
            sortables = null

        return


module.directive 'sortableProperty', ->
    restrict: 'A'
    require: '^sortableContainer'
    link: (scope, element, attributes) ->
        $(element).addClass 'sortable'

        attributes.$observe 'sortableProperty', (property) ->
            return if not property
            # console.log "found sortable:", property
            return scope.sortable.register(property, element) if scope.sortable?.register
            return scope.$parent.sortable.register(property, element) if scope.$parent.sortable.register
            throw new Error("Can't register sortable!")
