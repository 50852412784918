_                                = require '../../lib/lodash-helper.ts'
moment                           = require 'moment'
hexToRgba                        = require('hex-to-rgba')
{ EchartsMapChartModelInstance } = require('./chart-echarts-map-model.ts')

ECHARTS_THEME =
    fontFamily:     "Open Sans"
    axisLineColor:  "rgba(161,174,178,0.7)"
    axisLabelColor: "#888"
    colors: [
        '#7b68ee',
        '#40e0d0',
        '#9FE18D',
        '#FFB665',
        '#ff6666',
        '#FB85D0',
        '#CBACF6',
        '#40e0d0',
        '#9FE18D',
        '#FFB665',
        '#ff6666',
        '#FB85D0',
        '#CBACF6',
    ]


ECHARTS_THEME_LEGEND =
    inactiveColor: ECHARTS_THEME.axisLineColor
    pageIcons:
        horizontal: [
            # http://www.entypo.com/images/chevron-small-left.svg
            "M12.141,13.418c0.268,0.271,0.268,0.709,0,0.978c-0.268,0.27-0.701,0.272-0.969,0l-3.83-3.908  c-0.268-0.27-0.268-0.707,0-0.979l3.83-3.908c0.267-0.27,0.701-0.27,0.969,0c0.268,0.271,0.268,0.709,0,0.978L9,10L12.141,13.418z"
            # http://www.entypo.com/images/chevron-small-right.svg
            "M11,10L7.859,6.58c-0.268-0.27-0.268-0.707,0-0.978c0.268-0.27,0.701-0.27,0.969,0l3.83,3.908  c0.268,0.271,0.268,0.709,0,0.979l-3.83,3.908c-0.267,0.272-0.701,0.27-0.969,0c-0.268-0.269-0.268-0.707,0-0.978L11,10z"
        ]
    pageIconSize: 11
    pageIconInactiveColor: ECHARTS_THEME.axisLineColor
    pageIconColor: '#444'
    pageTextStyle:
        color: ECHARTS_THEME.axisLabelColor
        fontSize: 12
    textStyle:
        color: ECHARTS_THEME.axisLabelColor
        fontSize: 11


module = angular.module '42.directives.charts.echartsquery', []
module.constant('ECHARTS_THEME', ECHARTS_THEME)
module.factory('EchartsMapChartModel', EchartsMapChartModelInstance())

module.factory 'EchartsPieChartModel', ($filter, Utils, ECHARTS_THEME) ->
    ECHARTS_DEFAULT_PIE_OPTIONS =
        color: ECHARTS_THEME.colors,
        grid:
            containLabel: true
            top: 0
            bottom: 0
            left: '0%'
            right: '0%'
        textStyle:
            fontFamily: ECHARTS_THEME.fontFamily
        tooltip:
            contain: true
            trigger: 'item',
            backgroundColor: "rgba(0, 0, 0, 0.8)"
            textStyle:
                fontFamily: ECHARTS_THEME.fontFamily
        legend: {
            type: 'scroll'
            orient: 'horizontal'
            data:[]
            bottom: 0
            left: 0
            right: 0
            ...Utils.copy(ECHARTS_THEME_LEGEND)
        }
        series: [
            {
                name: ''
                type: 'pie'
                radius: ['20%', '40%']
                data: []
                cursor: 'default'
                selectedOffset: 5
                labelLine:
                    smooth: false
                itemStyle:
                    emphasis:
                        shadowBlur: 1
                        shadowOffsetY: 3
                        shadowColor: 'rgba(0, 0, 0, 0.15)'
                        borderWidth: 0
                    normal:
                        borderWidth: 1
                        borderColor: 'white'
            }
        ]

    class EchartsPieChartModel
        constructor: (metrics, data, options = {}) ->
            options = do ->
                showPercentage: Utils.normalizeBoolean(options.showPercentage, true)
                multiline:      Utils.normalizeBoolean(options.multiline,      true)
                maxNumberOfSlices:         options.maxNumberOfSlices,
                groupSlicesThresholdValue: options.groupSlicesThresholdValue,

            chartOptions = Utils.copy(ECHARTS_DEFAULT_PIE_OPTIONS)
            chartOptions.customOptions = options

            buildTooltipListItem = (color, name, percent, value, isNegative) ->
                valueLabel = do ->
                    return '' if not value
                    return "<span class='tooltip-series-value negative'>(#{value})</span>" if isNegative
                    return "<span class='tooltip-series-value'>(#{value})</span>"
                return \
                """
                <h1 class='tooltip-header' style='color:#{color}'>#{name}</h1>
                <div class='tooltip-series'>
                    <span class='tooltip-series-label'>#{percent}</span>
                    #{valueLabel}
                </div>
                """

            buildNegativeDataObject = (dataObj, index)->
                color = ECHARTS_THEME.colors[index % ECHARTS_THEME.colors.length]

                dataObj.label ?= {}
                dataObj.label.color = ECHARTS_THEME.colors[index]
                dataObj.labelLine ?= {}
                dataObj.labelLine.lineStyle ?= {}
                dataObj.labelLine.lineStyle.color = ECHARTS_THEME.colors[index]
                dataObj.value = dataObj.value * -1
                dataObj.isNegative = true
                dataObj.itemStyle =
                    color: hexToRgba(color, 0.1)
                    borderWidth: 1
                    borderColor: ECHARTS_THEME.colors[index]
                    borderType: 'dashed'
                dataObj.emphasis =
                    shadowBlur: 0
                    shadowOffsetX: 0
                    shadowOffsetY: 0
                    itemStyle:
                        borderWidth: 1
                        borderColor: ECHARTS_THEME.colors[index]
                        borderType: 'dashed'
                return dataObj

            chartOptions.tooltip.formatter = (params) ->
                groupedSeries = params.data.groupedSeries

                isNegative = params.data.isNegative or false

                if groupedSeries
                    tooltipStr = groupedSeries.reduce (acc, group) ->
                        percent = $filter('percent')(group.percent)
                        value = $filter('metric')(group.value, params.data.metric)

                        if group.isNegative
                            value = "- #{value}"

                        return acc + buildTooltipListItem(params.color, group.name, percent, value, group.isNegative)
                    , ''
                else
                    percent = $filter('percent')(params.percent / 100)
                    value = $filter('metric')(params.value, params.data.metric)
                    color = params.color

                    if isNegative
                        value = "- #{value}"
                        color = params.borderColor

                    tooltipStr = buildTooltipListItem(color, params.name, percent, value, isNegative)

                """
                <div class='tooltip'>
                #{tooltipStr}
                </div>
                """

            chartOptions.series[0].data = data.reduce (acc, x, index) ->
                if (_.isNumber(x[metrics[0].field]) and x[metrics[0].field] != 0)
                    baseObj =
                        metric: metrics[0]
                        name:  x['property0']
                        value: x[metrics[0].field]

                    if baseObj.value < 0
                        baseObj = buildNegativeDataObject(baseObj, index)

                    acc.push(baseObj)

                return acc
            , []

            chartOptions.series[0].data.sort((a,b) ->
                valueA = if a.isNegative then a.value * -1 else a.value
                valueB = if b.isNegative then b.value * -1 else b.value

                return valueB - valueA
            )

            chartOptions.label ?= {}
            chartOptions.label.formatter = do ->
                return if not options.showPercentage
                return (params) ->
                    result = params.name
                    percent = $filter('percent')(params.percent / 100)
                    return "#{result} (#{percent})" if not options.multiline
                    return "#{result}\n#{percent}\n"

            chartOptions.legend.data = chartOptions.series[0].data.map (x) -> x.name

            @chartOptions = chartOptions



module.factory 'EchartsBarChartModel', ($filter, $sanitize, Utils, ECHARTS_THEME) ->
    ECHARTS_DEFAULT_BAR_OPTIONS =
        color: ECHARTS_THEME.colors,
        grid:
            containLabel: true
            top: 40
            bottom: 10
            left: '1%'
            right: '3%'
        textStyle:
            fontFamily: ECHARTS_THEME.fontFamily
        xAxis:
            type: 'category'
            data: []
            animation: false
            splitLine:
                lineStyle:
                    opacity: 1
                    width: 1
                    type: 'dotted'
                    color: [ECHARTS_THEME.axisLineColor]
            axisLine:
                lineStyle:
                    width: 1
                    type: 'solid'
                    color: ECHARTS_THEME.axisLineColor
            axisLabel:
                color: [ECHARTS_THEME.axisLabelColor]
                rotate: 45
        legend: Utils.copy(ECHARTS_THEME_LEGEND)
        yAxis:
            type: 'value'
            boundaryGap: true
            animation: false
            axisPointer:
                show: false
            splitLine:
                lineStyle:
                    opacity: 1
                    width: 1
                    type: 'dotted'
                    color: [ECHARTS_THEME.axisLineColor]
            axisLine:
                lineStyle:
                    width: 1
                    type: 'solid'
                    color: [ECHARTS_THEME.axisLineColor]
            axisLabel:
                color: [ECHARTS_THEME.axisLabelColor]
        series: [{
            data: []
            type: 'bar'
            roundCap: true
        }]
        tooltip:
            trigger: 'axis'
            contain: false
            backgroundColor: "rgba(0, 0, 0, 0.9)"
            transitionDuration: 0
            textStyle:
                fontFamily: ECHARTS_THEME.fontFamily
            axisPointer:
                show: true
                z: 1000
                type: 'shadow'
                shadowStyle:
                    color: "rgba(0,0,0,0.05)"
                # lineStyle:
                #     type: 'solid'
                #     color: ECHARTS_THEME.axisLineColor


    class EchartsBarChartModel

        constructor: (metrics, data, options = {}) ->
            chartOptions = Utils.copy(ECHARTS_DEFAULT_BAR_OPTIONS)
            options = do ->
                maxNumberOfSlices:         options.maxNumberOfSlices,
                groupSlicesThresholdValue: options.groupSlicesThresholdValue,

            chartOptions.customOptions = options

            buildTooltipListItem = (color, name, value, isNegative) ->
                valueLabel = do ->
                    return '' if not value
                    return "<span class='tooltip-series-value negative'>#{value}</span>" if isNegative
                    return "<span class='tooltip-series-value'>#{value}</span>"
                return \
                """
                <h1 class='tooltip-header' style='color:#{color}'>#{name}</h1>
                <div class='tooltip-series'>
                    #{valueLabel}
                </div>
                """

            chartOptions.tooltip.formatter = (params) ->
                params = params[0] if _.isArray(params)
                groupedSeries = params.data.groupedSeries

                isNegative = params.data.isNegative or false

                if groupedSeries
                    tooltipStr = groupedSeries.reduce (acc, group) ->
                        value = $filter('metric')(group.value, params.data.metric)

                        if group.isNegative
                            value = "- #{value}"

                        return acc + buildTooltipListItem(params.color, group.name, value, group.isNegative)
                    , ''
                else
                    value = $filter('metric')(params.value, params.data.metric)
                    color = params.color

                    if isNegative
                        value = "- #{value}"
                        color = params.borderColor

                    tooltipStr = buildTooltipListItem(color, params.name, value, isNegative)

                """
                <div class='tooltip'>
                #{tooltipStr}
                </div>
                """

            chartOptions.yAxis.axisPointer ?= {}
            chartOptions.yAxis.axisPointer.label ?= {}
            chartOptions.yAxis.axisLabel ?= {}
            chartOptions.yAxis.axisLabel.formatter = (value) ->

            chartOptions.yAxis.axisPointer.label.formatter = chartOptions.tooltip.formatter

            buildNegativeDataObject = (dataObj) ->
                dataObj.isNegative = true
                dataObj.value = dataObj.value * -1
                dataObj.itemStyle =
                    borderWidth: 1
                    borderType: 'dashed'

                return dataObj

            chartOptions.series[0].data = data.reduce (acc, dataItem, idx) ->
                if _.isNumber(dataItem[metrics[0].field])
                    baseObj =
                        metric: metrics[0]
                        value: dataItem[metrics[0].field]
                        name: dataItem['property0']

                    if baseObj.value < 0
                        baseObj = buildNegativeDataObject(baseObj, idx)

                    acc.push(baseObj)

                return acc
            , []

            chartOptions.series[0].data.sort((a,b) ->
                valueA = if a.isNegative then a.value * -1 else a.value
                valueB = if b.isNegative then b.value * -1 else b.value

                return valueB - valueA
            )

            chartOptions.series[0].data.forEach (dataItem, idx) ->
                if _.isNumber(dataItem.value)
                    color = ECHARTS_THEME.colors[idx % ECHARTS_THEME.colors.length]

                    if dataItem.value >= 0
                        dataItem.itemStyle =
                            color: color

                    if dataItem.value < 0
                        dataItem.itemStyle.color = hexToRgba(color, 0.1)

            chartOptions.xAxis.data = chartOptions.series[0].data.map (x) -> x.name

            chartOptions.yAxis.axisLabel.formatter = (value, index) ->
                return $filter('metric')(value, metrics[0])
            @chartOptions = chartOptions



module.factory 'EchartsLineChartModel',  ($filter, Utils, DateWrapper, ECHARTS_THEME) ->
    ECHARTS_DEFAULT_LINE_OPTIONS =
        color: [
            '#00FF00', '#5DA5DA', '#40e0d0', '#ff6666', '#ffa500', '#40e0d0', '#ff6666', '#ffa500', '#40e0d0'
        ]
        grid:
            containLabel: true
            top: 10
            bottom: 10
            left: '1%'
            right: '3%'
        textStyle:
            fontFamily: ECHARTS_THEME.fontFamily
        xAxis:
            type: 'category'
            snap: false
            data: []
            animation: false
            nameLocation: 'center'
            boundaryGap: true
            splitLine:
                show: true
                # interval: 0
                lineStyle:
                    opacity: 0.3
                    width: 1
                    type: 'dashed'
                    color: ECHARTS_THEME.axisLineColor
            axisTick:
                show: true
                lineStyle:
                    width: 1
                    color: ECHARTS_THEME.axisLineColor
            axisLine:
                show: true
                lineStyle:
                    width: 1
                    color: ECHARTS_THEME.axisLineColor
            axisLabel:
                # interval: 0
                color: ECHARTS_THEME.axisLabelColor
        yAxis:
            type: 'value'
            nameLocation: 'center'
            boundaryGap: true
            animation: false
            axisPointer:
                z: 1000
                lineStyle:
                    color: ECHARTS_THEME.axisLineColor
            axisLine:
                lineStyle:
                    width: 1
                    color: ECHARTS_THEME.axisLineColor
            splitLine:
                lineStyle:
                    opacity: 0.5
                    width: 1
                    type: 'dotted'
                    color: ECHARTS_THEME.axisLineColor
            axisLabel:
                color: ECHARTS_THEME.axisLabelColor
        series: []
        legend:
            show: false
            height: 0
            inactiveColor: ECHARTS_THEME.axisLineColor
            textStyle:
                color: ECHARTS_THEME.axisLabelColor
                fontSize: 10
        tooltip:
            trigger: 'axis'
            snap: true
            transitionDuration: 0
            backgroundColor: "rgba(0, 0, 0, 0.7)"
            axisPointer:
                type: 'shadow'
                shadowStyle:
                    color: "rgba(0,0,0,0.03)"
            textStyle:
                fontFamily: ECHARTS_THEME.fontFamily
                fontSize: 12

    class EchartsLineChartModel

        constructor: (metrics, properties, data, overrides) ->
            chartOptions = Utils.copy(ECHARTS_DEFAULT_LINE_OPTIONS)
            chartOptions.tooltip ?= {}
            chartOptions.tooltip.formatter = (paramsBySeries) ->
                formatTimestamp = (x) ->
                    timestamp = moment.utc(x)
                    return null if not timestamp.isValid()
                    return timestamp.format('ddd, MMM DD')
                formatTimerange = (calendar) ->
                    return "" if _.isEmpty(calendar)
                    year = calendar.year
                    month = $filter('inflector')(calendar.month_label)
                    week = parseInt(calendar.week) + 1
                    dayLabel = $filter('inflector')(calendar.day_of_week_label)[..2]
                    timerange = do ->
                        isSame = calendar.timerange_start is calendar.timerange_end
                        return formatTimestamp(calendar.timerange_start) if isSame
                        return _.compact(['start', 'end'].map((x) ->
                            formatTimestamp(calendar["timerange_#{x}"])
                        )).join(' - ')
                    return "#{year} - #{month} W#{week}" if not timerange
                    return "#{year} - #{month} W#{week} (#{timerange})"
                renderHeader = (title) ->
                    return "<h1 class='tooltip-header'>#{title}</h1>"
                renderSeries = (params) ->
                    value = $filter('metric')(params.value, params.metric)
                    label = if params.title then params.title else formatTimerange(params.calendar)
                    # label = "#{formatTimerange(params.calendar)}#{labelTitle}"

                    """
                    <div class='tooltip-series'>
                        <span class='tooltip-series-label' style='color:#{params.color}'>#{label}</span>
                        <span class='tooltip-series-value'>#{value}</span>
                    </div>
                    """

                buildSeriesPayloadToRender = (series) ->
                    series.map((serie) ->
                        baseObj =
                            value: serie.value
                            metric: serie.data.metric
                            calendar: serie.data.calendar
                            color: serie.color

                        if overrides and overrides.displayTitle
                            baseObj.title = serie.name

                        return baseObj
                    )

                buildHeaderToRender = (series) ->
                    data = series[0]?.data
                    if overrides and overrides.timeRangeAsHeader and data
                        return formatTimerange(data.calendar)

                    return data.metric.headerGroup

                rows = _.flatten([renderHeader(buildHeaderToRender(paramsBySeries)), buildSeriesPayloadToRender(paramsBySeries).map(renderSeries)])
                return "<div class='tooltip'>#{rows.join('')}</div>"

            # Hacky metric specific coloring
            metrics.forEach (metric, idx) ->
                chartOptions.color[idx] = do ->
                    if overrides and overrides.colors and overrides.colors[idx]
                        return overrides.colors[idx]

                    return '#A5A3FF' if metric.field.indexOf('budget') > -1 or metric.field.indexOf('latest_estimate') > -1
                    return '#F17CB0' if metric.field.indexOf('growth_') is 0
                    return '#5DA5DA'

            data = data.filter((x) -> x["calendar__timestamp"])

            allSeries = Object.values(data.reduce ((series, row, rowIdx) ->
                # Create a series for each metric
                metrics.forEach (m, idx) ->
                    series[m.field] ?=
                        type: 'line'
                        animation: false
                        animationDuration: (-> return 0)
                        smooth: true
                        showAllSymbol: data.length < 200
                        smoothMonotone: 'x'
                        hoverAnimation: true
                        symbol: 'emptyCircle'
                        symbolSize: 5
                        cursor: 'default'
                        lineStyle: {width: 0.5}
                        areaStyle: {opacity: 2/(idx+3)}
                        data: []
                        name: "#{m.headerName} #{m.headerGroup}"
                    cell = do ->
                        calendarFieldPrefix = do ->
                            return "comparison__calendar__" if m.headerName is 'LY'
                            return "calendar__"
                        value: row[m.field] or 0
                        metric: m
                        name: series[m.field].name
                        timerange: do ->
                            start: row["#{calendarFieldPrefix}timerange_start"]
                            end:   row["#{calendarFieldPrefix}timerange_end"]
                        calendar: Object.keys(row).reduce ((result, field) ->
                            return result if field.indexOf(calendarFieldPrefix) isnt 0
                            return result if row[field] is null
                            result[field.replace(calendarFieldPrefix, "")] = row[field]
                            return result
                        ), {}
                    series[m.field].data.push(cell) if cell.timerange.start
                return series
            ), {})


            # Edge case:
            # Display bar charts if all series have 1 data point
            shouldDisplayBarcharts = _.every do ->
                allSeries.filter((s) -> s.data.length > 0).map((s) -> s.data.length is 1)

            if shouldDisplayBarcharts
                allSeries.forEach (s) ->
                    s.type = "bar"
                    s.tooltip ?= {}
                    s.tooltip.axisPointer = false

            chartOptions.series = allSeries

            chartOptions.yAxis ?= {}
            chartOptions.yAxis.axisLabel ?= {}
            chartOptions.yAxis.axisLabel.formatter = (value, index) ->
                return $filter('metric')(value, metrics[0])

            chartOptions.xAxis ?= {}

            if data.length > 30
                delete chartOptions.xAxis.splitLine.interval
                delete chartOptions.xAxis.axisTick.interval

            prevWeek = null
            chartOptions.xAxis.data = data.map (row, index) ->
                [start, end] = [row['calendar__timerange_start'], row['calendar__timerange_end']].map (x) -> moment.utc(x)
                isDaily = (start.format('YYYYMMDD') is end.format('YYYYMMDD'))

                formatWeeklyTimerange = (row) ->
                    week = parseInt(row['calendar__week']) + 1
                    return "" if week is prevWeek
                    prevWeek = week
                    return "W#{week}"

                formatDailyTimerange = (row) ->
                    week = formatWeeklyTimerange(row)
                    day = $filter('inflector')(row['calendar__day_of_week_label'])[..2]
                    return "#{day}\n#{week}".trim()

                return formatDailyTimerange(row) if isDaily and data.length < 30
                return formatWeeklyTimerange(row)

            @chartOptions = chartOptions



module.factory 'EchartsWithQueryViewModel', ($q, $rootScope, $filter, CONFIG, Utils, QueryServiceAPI, QueryMetrics, Hierarchy, CalendarProperties, EchartsBarChartModel, EchartsPieChartModel, EchartsLineChartModel, EchartsMapChartModel) ->

    class EchartsWithQueryViewModel

        constructor: (model, rootQuery) ->
            {properties, metrics, type, options, overrides} = Utils.copy(model or {})
            @displayOptions = (options or {})
            @overrides = overrides or {}
            @isLoading = true
            @type = type

            return if not _.every([properties, metrics, type])
            @init(properties, metrics, type, @displayOptions, rootQuery)
            .then ({@properties, @metrics, @title, @options, @data}) =>
                @isLoading = false
                @isBlank = @isBlankModel(@data, @metrics, @options)
                @options = {} if @isBlank
            .catch (error) =>
                console.error error
                @isLoading = false

        init: (properties, metrics, type, displayOptions, rootQuery) ->
            $q.all([
                @fetchProperties(properties)
            ,   @fetchMetrics(metrics)
            ,   @fetchData(type, properties, metrics, rootQuery)
            ]).then ([properties, metrics, data]) =>
                title = @getTitle(properties, metrics)
                metrics = metrics.filter (m) ->
                    dataByMetric = data.map((x) -> x[m.field])
                    return not _.every(dataByMetric, _.isUndefined)
                options = @getChartOptions(metrics, properties, type, displayOptions, data) if not (metrics.length is 0)
                return {properties, metrics, options, title, data}

        isBlankModel: (data, metrics, options) ->
            return true if data.length is 0 or metrics.length is 0

            if options and Array.isArray(options.series)
                series = options.series
                return series.every (serie) -> !serie.data or serie.data.length == 0

            return false

        getTitle: (properties, metrics) ->
            return "" if not properties or not metrics
            metrics = _.uniq(metrics.map((x) -> x.headerGroup))
            props = properties.map((x) -> (x.label or x).trim().split('.').join(' ').replace('calendar', '').trim())
            return "#{metrics.join(', ')} by #{props.join(', ')}".trim()

        getChartOptions: (metrics, properties, type, displayOptions, data) ->
            switch type
                when "pie"  then return new EchartsPieChartModel(metrics, data, displayOptions).chartOptions
                when "line" then return new EchartsLineChartModel(metrics, properties, data, @overrides['lineChart']).chartOptions
                when "bar"  then return new EchartsBarChartModel(metrics, data, displayOptions).chartOptions
                when "map" then return new EchartsMapChartModel(metrics, data).chartOptions
                else console.warning("Unknown chart type: #{type}")

        fetchMetrics: (metrics) ->
            QueryMetrics.fetch().then (available) ->
                available = _.keyBy available, (x) -> x.field
                return _.compact metrics.map (x) -> available[x]

        fetchProperties: (properties) ->
            $q.all([
                CalendarProperties.fetch()
                Hierarchy.fetch()
            ]).then ([calendar, hierarchy]) ->
                all = hierarchy.all.concat(calendar or [])
                available = _.keyBy all, (x) -> x.id
                result = _.compact properties.map (x) -> available[x]
                return properties if (not result or result.length == 0)
                return result

        getBaseQueryOptions = (rootQuery) ->
            query = Utils.copy(rootQuery)
            query.options.includeItemInformation = false
            return query

        getGenericQueryOptions = (properties, metrics, baseQuery) ->
            query = Utils.copy(baseQuery)
            query.options.includeTotals = false
            query.options.metrics = metrics
            query.options.properties = properties
            query.options.sort = properties.map((prop) -> {property:prop, field:metrics[0], order:-1, limit:null})
            return query

        getLineQueryOptions = (properties, metrics, baseQuery) ->
            query = Utils.copy(baseQuery)
            query.options.includeTotals = false
            query.options.fillCalendarGaps = true
            query.options.metrics = metrics
            query.options.properties = properties
            query.options.sort = properties.map((prop) -> {property:prop, field:prop, order:1, limit:null})
            return query

        fetchData: (type, properties, metrics, rootQuery) ->
            QueryMetrics.fetch().then (metricsData) ->
                metricsList = metricsData.map (metric) -> metric.field
                baseQuery = getBaseQueryOptions(rootQuery)
                query = do -> switch type
                    when "pie"  then return getGenericQueryOptions(properties, metricsList, baseQuery)
                    when "bar"  then return getGenericQueryOptions(properties, metricsList, baseQuery)
                    when "map"  then return getGenericQueryOptions(properties, metricsList, baseQuery)
                    when "line" then return getLineQueryOptions(properties, metricsList, baseQuery)
                    else throw new Error("Unknown chart type `#{type}`.")
                return (new QueryServiceAPI).then (api) -> api.query.metricsFunnel(query)

        setType: (type) ->
            @type = type
            @options = @getChartOptions(@metrics, @properties, type, @displayOptions, @data)
            @isBlank = @isBlankModel(@data, @metrics, @options)

        getType: -> @type


module.directive 'chartPicker', ->
    restrict: 'E'
    scope:
        selected: '='
        enabled: '='
    replace: true
    template: \
    """
    <article class="chart-picker">
        <ul>
            <li ng-repeat="type in chartTypes"
                ng-click="select(type)"
                ng-class="{active:isSelected(type)}"
            > <i class='icon-chart-{{type}}'></i> </li>
        </ul>
    </article>
    """
    link: (scope, element) ->

        scope.chartTypes = ['bar', 'pie']

        scope.isSelected = (type) ->
            return false if not scope.enabled
            scope.selected is type

        scope.select = (type) ->
            scope.selected = type



module.directive "echartsWithQuery", ($rootScope, $filter, EchartsWithQueryViewModel) ->
    restrict: "E"
    replace: false
    scope:
        chart: '='
        resizer: '='
    template: """
    <div class="card" ng-class="{loading:model.isLoading}">
        <header>
            <h1 class="echarts-title">{{ model.title }}</h1>
            <chart-picker ng-if="!isMap" enabled="!model.isBlank" selected="chart.type"></chart-picker>
        </header>
        <div class="blank" ng-if="model.isBlank">No Data</div>
        <echarts-custom options="model.options" resizer="resizer"></echart-custom>
    </div>
    """
    link: (scope, element)  ->
        scope.isMap = false

        updateModel = _.debounce (->
            scope.model = new EchartsWithQueryViewModel(_.cloneDeep(scope.chart), $rootScope.query)
            scope.isMap = scope.model.getType() is 'map'
        ), 30

        updateType = (type) ->
            return updateModel() if not scope.model
            scope.model.setType(type)

        $rootScope.$watch 'initialized', (initialized) ->
            return if not initialized
            scope.$on '$destroy', $rootScope.$on('query.refresh', updateModel)
            scope.$watch 'chart.properties', updateModel
            scope.$watch 'chart.metrics', updateModel
            scope.$watch 'chart.options', updateModel
            scope.$watch 'chart.type', updateType



module.directive "echartsWithQueryGrid", ($window, $rootScope, Utils) ->
    restrict: "E"
    replace: true
    scope:
        charts: '='
        selectedChart: '='
    template: """
        <div class="echarts-grid">
            <echarts-with-query
                ng-repeat="chart in charts track by $index"
                resizer="addResize"
                class="echarts-item"
                chart="chart">
            </echarts-with-query>
        </div>
    """
    link: (scope, element)  ->
        scope.resizeFuncs = {}

        updateChart = ->
            return if not scope.charts or not scope.selectedChart
            scope.charts.forEach((c) -> c.metrics = [scope.selectedChart.property])

        scope.$watch 'selectedChart', ->
            updateChart()

        scope.$watch 'charts', ->
            updateChart()

        scope.addResize = (resizeFn, id) ->
            if not resizeFn
                delete scope.resizeFuncs[id]
            else
                scope.resizeFuncs[id] = resizeFn
            resizeAll()

        resizeAll = _.debounce((->
            Object.values(scope.resizeFuncs).forEach((x) -> x())) if scope.resizeFuncs
        , 30)


# The sales chart
module.directive "echartsWithSelectedQuery", ($rootScope, $filter, EchartsWithQueryViewModel) ->
    restrict: "E"
    replace: true
    scope:
        chart: '='
    template: """
    <div class="card echarts-item-single" ng-class="{loading:model.isLoading}">
        <header>
            <h1 class="echarts-title">{{ model.title }}</h1>
            <bucket-picker selected="bucket"></bucket-picker>
        </header>
        <main>
            <div class="blank" ng-if="model.isBlank">No Data</div>
            <echarts-custom ng-if="!model.isBlank" options="model.options" resizer="setResizer"></echart-custom>
        </main>
    </div>
    """
    link: (scope, element)  ->
        scope.chart = null

        scope.setResizer = (resizeFunc) ->
            scope.resizer = resizeFunc

        createModel = ->
            # FIXME: [DEV-1811] Hardcoded metrics
            isBudgetMetric = (scope.chart.property in ["budget", "store_budget", "plan", "latest_estimate", "gross_sales_rof", "gross_sales_plan"])
            properties = ["calendar.#{scope.bucket.id}"]
            properties.push("calendar.year") if scope.bucket.id is 'week'

            baseModel =
                type: 'line'
                properties: properties
                metrics: do ->
                    ty = scope.chart.property
                    ly = do ->
                        return "profit" if ty is "budget_profit"
                        return "gross_sales" if isBudgetMetric and _.includes(scope.chart.property, 'gross_sales')
                        return "net_sales" if isBudgetMetric
                        return "growth_#{ty}_prev"
                    return [ty, ly]

            if isBudgetMetric
                baseModel.overrides =
                    lineChart:
                        timeRangeAsHeader: true
                        displayTitle: true
                        colors: [
                            '#A5A3FF'
                            '#5DA5DA'
                        ]

            return baseModel

        updateModel = _.debounce (->
            return if not scope.chart or not scope.bucket

            query = _.cloneDeep($rootScope.query)
            scope.model = new EchartsWithQueryViewModel(createModel(), query)
            scope.resizer?()
        ), 100

        $rootScope.$watch 'initialized', (initialized) ->
            return if not initialized
            scope.$on '$destroy', $rootScope.$on('query.refresh', updateModel)
            scope.$watch 'chart', updateModel
            scope.$watch 'bucket', updateModel
