import _ from 'lodash';
import $ from 'jquery';
import { IQuery } from '../../lib/types';
import { IHierarchy, IHierarchyDict } from '../../services/hierarchy.service';
import { IPromise } from 'angular';
import {
    buildRenderedQuery,
    mapQueryByTables,
    IQueryTables,
    IRendererFilter,
    buildTimestampModel,
    hasEnabledTables,
} from './natural-language-filter.helper';
import { ResizeObserver } from '@juggle/resize-observer';
import { SmartGroupViewModel } from '../smart-groups/smart-groups.service';

export interface IHierarchyService {
    fetch: () => IPromise<IHierarchy>;
}

const module = angular.module('42.modules.natural-language', []);

interface INaturalLanguageFilterDisplayModelOptions {
    query: IQuery;
    showTimestampOnly: boolean;
}

const NaturalLanguageFilterDisplayModelFactory = (Hierarchy: IHierarchyService) => {
    class NaturalLanguageFilterDisplayModel {
        public data: IQueryTables | null = null;
        public propertiesAdjustement: Record<string, IRendererFilter> = {};
        public propertyFiltersToDisplay: any = [];
        public noFiltersApplied = false;
        public timestamp: { start: string; end: string } | null = null;

        constructor(private options: INaturalLanguageFilterDisplayModelOptions) {
            this.init();
        }

        init() {
            if (this.options.showTimestampOnly) {
                this.data = mapQueryByTables(this.options.query, {});

                this.setTimestamp(this.data);
                return;
            }

            Hierarchy.fetch().then(hierarchies => {
                const hierarchy = _.keyBy(hierarchies.all, 'id') as IHierarchyDict;
                this.data = mapQueryByTables(this.options.query, hierarchy);

                this.noFiltersApplied = !hasEnabledTables(this.data);
                this.setTimestamp(this.data);
                this.updateFilters(null);
            });
        }

        resetPropertiesAdjustement() {
            this.propertiesAdjustement = {};
        }

        setTimestamp(tablesProperties: IQueryTables) {
            this.timestamp = buildTimestampModel(tablesProperties);
        }

        updateFilters(shouldDecreaseSize: boolean | null): boolean {
            const updatedRenderedQuery = buildRenderedQuery(
                this.data || {},
                this.propertiesAdjustement,
                shouldDecreaseSize,
            );

            if (!updatedRenderedQuery) {
                return false;
            }

            this.propertiesAdjustement = updatedRenderedQuery?.propertiesAdjustement || {};
            this.propertyFiltersToDisplay = updatedRenderedQuery?.propertiesFiltersItems;

            return true;
        }
    }

    return NaturalLanguageFilterDisplayModel;
};

module.directive('naturalLanguage', [
    '$rootScope',
    'Hierarchy',
    function NaturalLanguage($rootScope: angular.IRootScopeService & any, Hierarchy: IHierarchyService) {
        return {
            restrict: 'E',
            scope: {
                smartGroups: '=',
            },
            replace: true,
            transclude: true,
            template: `
                <div class="natural-language-filter-display">
                    <div class="display-bar-content" ng-if="model && model.data">
                        <div ng-if="model.noFiltersApplied" class="display-bar-message" ng-class="{ 'drawing': drawing }">
                            Viewing data
                            <div class="display-bar-single-title">without any filters</div>
                        </div>
                        <div ng-if="model.options.showTimestampOnly && model.timestamp" class="display-bar-message">
                            Viewing data between
                            <div class="display-bar-single-title">{{model.timestamp.start}}</div>
                            and
                            <div class="display-bar-single-title">{{model.timestamp.end}}</div>
                        </div>
                        <div ng-if="!model.options.showTimestampOnly && !model.noFiltersApplied" class="display-bar-message" ng-class="{ 'drawing': drawing }">
                            Viewing data for
                            <div ng-repeat="propertyFilter in model.propertyFiltersToDisplay" class="display-bar-property-filter">
                                <div
                                    class="display-bar-property-filter-items"
                                    ng-class="{'excluded': !propertyFilter.included}"
                                    ng-click="openFiltersPopup(propertyFilter.propertyKey, propertyFilter.filterType)">
                                    <div class="property">{{propertyFilter.propertyName}}</div>
                                    <div ng-if="!propertyFilter.onlyPropertyName" class="property-separator">|</div>
                                    <div class="value" ng-if="!propertyFilter.onlyPropertyName">
                                        <div ng-if="propertyFilter.isCollapsed" class="property-value-item-collapsed">
                                            {{propertyFilter.filters.length}} filters
                                        </div>
                                        <div class="property-value-item"
                                            ng-if="!propertyFilter.isCollapsed"
                                            ng-repeat="filterItem in propertyFilter.filters"
                                            ng-click="openFiltersPopup(filterItem, propertyFilter.propertyKey, propertyFilter.filterType)">
                                            {{ filterItem }}
                                            <div ng-if="!$last" class="value-separator">,</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            `,
            link: ($scope: angular.IScope & any, $element: angular.IRootElementService) => {
                const NaturalLanguageFilterDisplayModel = NaturalLanguageFilterDisplayModelFactory(Hierarchy);

                const DECREASE_SIZE = true;
                const INCREASE_SIZE = false;
                let lastAdjustment: boolean | undefined = undefined;

                $scope.drawing = false;
                $scope.htmlToBind = '';
                $scope.model = undefined;

                $scope.openFiltersPopup = (property: string, table: string) => {
                    const selectedGroup = $scope.smartGroups.selected as SmartGroupViewModel;
                    const selectedGroupsFilters = selectedGroup && selectedGroup.filters ? selectedGroup.filters : [];
                    const selectedFilter = selectedGroupsFilters.find(
                        filterItem => filterItem.tables.indexOf(table) > -1,
                    );

                    if (selectedFilter) {
                        selectedFilter.select();
                    }
                };

                const hasSelectedGroup = () => !!$scope.smartGroups?.selected;

                const adjustWidth = () => {
                    if (!$scope.model) {
                        return;
                    }

                    setTimeout(() => {
                        const naturalLanguageElementHeight = $element.width() as number;
                        const renderedQueryElementHeight = $($element).find('.display-bar-message').width() as number;

                        const shouldDecrease = renderedQueryElementHeight > naturalLanguageElementHeight;

                        if (lastAdjustment === undefined) {
                            lastAdjustment = shouldDecrease;
                        }

                        if (shouldDecrease === DECREASE_SIZE && lastAdjustment === DECREASE_SIZE) {
                            $scope.model.updateFilters(DECREASE_SIZE);
                            return;
                        }

                        if (shouldDecrease === INCREASE_SIZE && lastAdjustment === INCREASE_SIZE) {
                            $scope.model.updateFilters(INCREASE_SIZE);
                            return;
                        }

                        if (shouldDecrease === INCREASE_SIZE && lastAdjustment === DECREASE_SIZE) {
                            lastAdjustment = undefined;
                            $scope.drawing = false;
                            return;
                        }

                        if (shouldDecrease === DECREASE_SIZE && lastAdjustment === INCREASE_SIZE) {
                            lastAdjustment = DECREASE_SIZE;
                            $scope.model.updateFilters(DECREASE_SIZE);
                            return;
                        }
                    }, 100);
                };

                const refresh = () => {
                    if (!hasSelectedGroup()) {
                        return;
                    }

                    const isMetricsPage = $rootScope.activeRoute?.id === 'metrics';
                    const isSameQuery = _.isEqual($rootScope.query, $scope.model?.options.query);
                    const isLastPageMetricsPage = $scope.model?.options.showTimestampOnly;

                    if (!isMetricsPage && isSameQuery && !isLastPageMetricsPage) {
                        return;
                    }

                    lastAdjustment = undefined;

                    $scope.model = new NaturalLanguageFilterDisplayModel({
                        query: _.cloneDeep($rootScope.query || {}) as IQuery,
                        showTimestampOnly: isMetricsPage,
                    });
                };

                $scope.$watch('model.propertyFiltersToDisplay', () => adjustWidth());

                const debouncedRefresh = _.debounce(() => refresh(), 100);

                const init = () => {
                    const resizeObserver = new ResizeObserver(
                        _.debounce(() => {
                            if ($scope.model) {
                                adjustWidth();
                            }
                        }, 200),
                    );

                    const unSubRefreshListener = $rootScope.$on('query.refresh', () => debouncedRefresh());

                    $rootScope.$watch('activeRoute.id', (activeRouteId: string) => {
                        if (activeRouteId) {
                            debouncedRefresh();
                        }
                    });

                    $scope.$on('$destroy', () => {
                        unSubRefreshListener();
                        resizeObserver.disconnect();
                    });

                    debouncedRefresh();
                    resizeObserver.observe($element[0]);
                };

                init();
            },
        };
    },
]);
