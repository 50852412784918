import { IObjectWatcher, IPromiseTracker, IQuery } from '../../../lib/types';
import { cloneDeep, debounce, extend, isEmpty } from 'lodash';
import { buildStatsViewModel, compileSubfilters } from '../customer.helper';
import { ICustomerListItem, ICustomerSearch } from './customer-list.directive';
import { ICustomer, ICustomerCountService, ICustomerReportService, ICustomerStatsService } from '../customer.types';

const NATURAL_LANGUAGE_QUERY_CONFIG = {
    name: 'string',
    email: 'string',
    total_spent: 'number',
    transaction_count: 'number',
    avg_spent: 'number',
    average_visit_distance: 'number',
};

export const CustomerControllerInstance = () => [
    '$q',
    '$scope',
    '$filter',
    '$rootScope',
    'CustomersReport',
    'ObjectWatcher',
    'SimplePaginator',
    'NaturalLanguageQueryFilterBuilder',
    'promiseTracker',
    'CustomerCount',
    'CustomerStats',
    function CustomerController(
        $q: angular.IQService,
        $scope: angular.IScope & any,
        $filter: angular.IFilterFilter,
        $rootScope: angular.IRootScopeService & any,
        CustomersReport: ICustomerReportService,
        ObjectWatcher: IObjectWatcher,
        SimplePaginator: any,
        NaturalLanguageQueryFilterBuilder: any,
        promiseTracker: IPromiseTracker,
        CustomerCount: ICustomerCountService,
        CustomerStats: ICustomerStatsService,
    ) {
        const trackers = {
            list: promiseTracker('customer-list'),
            stats: promiseTracker('customer-stats'),
            customerStatsTracker: promiseTracker('customer-stats'),
            customerExport: promiseTracker('customer-export'),
        };

        const builder = new NaturalLanguageQueryFilterBuilder(NATURAL_LANGUAGE_QUERY_CONFIG);

        $scope.paginator = new SimplePaginator(50);
        $scope.query = null;

        $scope.sortable = {};

        $scope.subfilters = {
            list: {},
            rank: {},
        };

        const listActions = {
            onCustomerSearch: (customerSearch: ICustomerSearch) => {
                if (!customerSearch) {
                    return;
                }

                $scope.subfilters.list = builder.parse(customerSearch);
                $scope.paginator.setPage(1);
            },
        };

        const buildCustomersListViewModel = (customers: ICustomer[]): ICustomerListItem => {
            return {
                customers,
                actions: listActions,
            };
        };

        $scope.listViewModel = buildCustomersListViewModel([]);

        const compileListQuery = (query: IQuery) => {
            $scope.sortable.model = $scope.sortable.model || { rank_total_spent: 1 };
            const sort = $scope.sortable.model;
            const paginatorQuery = $scope.paginator.toQuery() || {};

            return extend(query, paginatorQuery, { sort });
        };

        const compileStatsQuery = (query: IQuery): IQuery => {
            query.where = compileSubfilters($scope.subfilters);

            if (isEmpty(query.where)) {
                delete query.where;
            }

            return query;
        };

        const updateState = (returnToFirstPage?: boolean) => {
            const newQuery = ($rootScope.query ? cloneDeep($rootScope.query) : {}) as IQuery;
            $scope.query = compileStatsQuery(newQuery);
            $scope.listQuery = compileListQuery(cloneDeep($scope.query));

            const promise = $q
                .all([
                    CustomerStats.fetch($scope.query),
                    CustomerCount.fetch($scope.listQuery),
                    CustomersReport.fetch($scope.listQuery),
                ])
                .then(([stats, count, customers]) => {
                    $scope.statsViewModel = buildStatsViewModel(stats, $filter);
                    $scope.listViewModel = buildCustomersListViewModel(customers);
                    $scope.paginator.setItemCount(count);

                    if (returnToFirstPage) {
                        $scope.paginator.setPage(1);
                    }
                });

            trackers.list.addPromise(promise);
            trackers.stats.addPromise(promise);
            trackers.customerStatsTracker.addPromise(promise);
        };

        const refresh = debounce((returnToFirstPage?: boolean) => updateState(returnToFirstPage), 30);

        $scope.export = () => {
            const promise = CustomersReport.export(compileStatsQuery($scope.query));
            trackers.customerExport.addPromise(promise);
        };

        $scope.goToNextPage = () => {
            $scope.paginator.next();
            if ($scope.query) {
                refresh();
            }
        };

        $scope.goToPreviousPage = () => {
            $scope.paginator.prev();
            if ($scope.query) {
                refresh();
            }
        };

        $scope.$watch(
            'sortable.model',
            () => {
                if ($scope.query) {
                    refresh();
                }
            },
            true,
        );

        $rootScope.$watch('initialized', (initialized: boolean) => {
            if (!initialized) {
                return;
            }

            ObjectWatcher($scope)('subfilters', () => refresh(true));

            const unWatch = $rootScope.$on('query.refresh', () => refresh(true));

            $scope.$on('$destroy', () => unWatch());
        });
    },
];
