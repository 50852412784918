_ = require('../../../lib/lodash-helper.ts')

module.exports = (Utils, CONFIG, Months, NRFYears, NRFCounts) ->
    {startMonth, pattern} = Utils.copy(CONFIG.datepicker or {})
    startMonth ?= 'feb'
    pattern = (pattern or '454')?.toString()
    throw new Error("Invalid NRF pattern `#{pattern}`") if not (pattern in ['445', '454', '544'])
    fiveWeekMonthIndex = pattern.indexOf('5')

    # shift months according to start month
    months = do ->
        sourceIndex = Months.map((x)->x.shortName).indexOf(startMonth)
        throw new Error("Start month `#{startMonth}` was not found") if sourceIndex is -1
        months = Utils.copy(Months)
        return months if sourceIndex is 0
        return months[sourceIndex..].concat months[0..sourceIndex-1]

    cache = {}

    get: (year) ->
        return cache[year.key] if cache[year.key]
        months = _.cloneDeep(months).map (month, index, result) ->
            previousMonth = result[index-1]
            month.offset = index

            month.weekCount = do ->
                return 6 if (index % 3) is fiveWeekMonthIndex and (year.restate and (year.restateMonth is index))
                return 5 if (index % 3) is fiveWeekMonthIndex or (year.restate and (year.restateMonth is index))
                return 4

            month.weekOffset = do ->
                return 0 if not previousMonth
                return previousMonth.weekOffset + (previousMonth.weekCount)

            month.dayOffset = do ->
                return 0 if not previousMonth
                return previousMonth.dayOffset + (previousMonth.weekCount * NRFCounts.DAYS_PER_WEEK)

            month.quarter =
                offset: Math.floor(month.offset / NRFCounts.MONTHS_PER_QUARTER)

            month.season =
                offset: Math.floor(month.quarter.offset / NRFCounts.QUARTERS_PER_SEASON)

            return month
        months = months.map (month) ->
            monthsInQuarter = months.filter (x) -> x.quarter.offset is month.quarter.offset
            month.quarter.weekCount  = _.sum(monthsInQuarter.map (x) -> x.weekCount * NRFCounts.DAYS_PER_WEEK)
            month.quarter.dayOffset  = monthsInQuarter[0].dayOffset
            month.quarter.weekOffset = monthsInQuarter[0].weekOffset
            quartersInSeason = months.filter (x) -> x.season.offset is month.season.offset
            month.season.weekCount  = _.sum(quartersInSeason.map (x) -> x.weekCount * NRFCounts.DAYS_PER_WEEK)
            month.season.dayOffset  = quartersInSeason[0].dayOffset
            month.season.weekOffset = quartersInSeason[0].weekOffset
            return month
        months.forEach (month, index) ->
            month.prev = months[index-1]
            month.next = months[index+1]
        cache[year.key] = months
        return months
