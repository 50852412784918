_ = require('../../lib/lodash-helper.ts')
moment = require 'moment'
module = angular.module '42.modules.libs.utils', []

module.service 'FnUtils', ($timeout, $rootScope) ->

    debounce: (time, fn) ->
        timerId = null
        return resetTimer = (args...) ->
            _this = this
            call = ->
                $rootScope.$apply -> fn.apply(_this, args)
            clearTimeout(timerId)
            timerId = setTimeout call, time
            return resetTimer



module.factory 'ObjectWatcher', ($timeout, Utils) -> ($scope) ->
    $scope._objectWatchers ?= {}

    return (id, callback) ->
        throw new Error("`callback` argument is required.") if not callback

        $scope._objectWatchers[id] = ->
            value = Utils.object.query($scope, id)
            return null if not value
            return Utils.object.hash(value)

        $scope.$watch "_objectWatchers['#{id}']()", do ->
            previous = undefined
            return (hash) ->
                current = do ->
                    return null if not hash
                    return Utils.object.query($scope, id)
                $timeout -> callback(current, previous)
                previous = current

module.service 'ObjectUtils', ->

    isObject = (x) ->
        Boolean(x) and (x.constructor is Object)

    isArray = (x) ->
        _.isArray(x)

    flattenObject = (object, {separator, maxDepth} = {}, result = {}, prefix = '', depth = 0) ->
        maxDepth   = Number.POSITIVE_INFINITY if not (typeof maxDepth is 'number')
        separator ?= ''

        return object if object is null

        Object.keys(object).forEach (key) ->
            invalid = key.indexOf(separator) isnt -1
            throw new Error("Key `#{key}` contains separator `#{separator}`") if invalid

        for key, value of object
            if isObject(value) and depth < maxDepth
                flattenObject(value, {separator, maxDepth}, result, "#{prefix}#{key}#{separator}", depth+1)
            else
                result["#{prefix}#{key}"] = value

        return result

    objectMap = (result, object, fn) ->
        object = fn(object)
        for key, value of object
            result[key] = objectMap({}, value, fn) if isObject(value)
        return result


    flatten: flattenObject

    isEmpty: (object) ->
        Object.keys(object or {}).length is 0

    query: (object, key, separator) ->
        separator ?= '.'
        tokens = key.split(separator)
        current = undefined
        for token in tokens
            current = (current or object)[token]
            return undefined if current is undefined
        return current

    objectMap: (object, fn) -> objectMap({}, object, fn)

    hash: do ->
        defaultHashFn = (x) -> return x
        (object, hashFn = defaultHashFn) ->
            throw new Error("`object` argument is required.")            if not object
            # throw new Error("`object` argument must be of type Object.") if not isObject(object) or not isArray(object)
            object = flattenObject(object)
            object = Object.keys(object).sort().reduce ((result, key) ->
                result.concat [[key, object[key]]]
            ), []
            return hashFn(JSON.stringify(object))




module.service 'Utils', (FnUtils, ObjectUtils, $timeout) ->

    sum = (list, fn) ->
        fn = ((x) -> x) if not fn
        _.reduce list, ((result, x) -> result + fn(x)), 0


    average = (list, fn) ->
        return 0 if list.length is 0
        (sum list, fn) / list.length


    mapRange = (times, fn) ->
        _.map (_.range times), fn


    clamp = (min, x, max) ->
        Math.min (Math.max x, min), max


    copy = (object) ->
        _.cloneDeep object
        # JSON.parse JSON.stringify object


    chunk = (array, size) ->
        result = []
        while array.length
            result.push array.splice 0, size
        return result

    rejectDuplicateModels = (collection) ->
        seen = {}
        collection.filter (model) ->
            (not seen[model.id]) and (seen[model.id] = true)

    rotate = (sourceIndex, targetIndex, array) ->
        targetIndex = array.length-1 if _.isUndefined(targetIndex) and _.isArray(array)
        [targetIndex, array] = [targetIndex.length-1, targetIndex] if _.isArray(targetIndex)
        beforeSource = array[0..sourceIndex]
        afterSource  = array[sourceIndex+1..-1]
        return afterSource.concat beforeSource

    timeIt = (label, fn) ->
        console.time label
        result = fn()
        console.timeEnd label
        return result

    promiseTimer = (label) -> (promise) ->
        label = "[timer] #{label}"
        console.time(label)
        promise.then (result) ->
            console.timeEnd(label)
            return result

    dateRange = (min, max, bucket) ->
        result = []
        moment().range(min, max).by bucket, (date) ->
            result.push date
        result.push max
        return result[0..-2]

    uuid = do ->
        s4 = -> Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
        return -> s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()

    padLeft = (x, minCharacters = 1, padCharacter = '0') ->
        x = x.toString()
        return x if x.length >= minCharacters
        return _.range(minCharacters-1).map((x) -> padCharacter).concat([x]).join('')

    indexOf = (array, callback) ->
        curr = 0
        for item in array
            return curr if callback(item)
            curr++
        return -1

    move = (array, from, to) ->
        return array if from is to
        [from, to] = [from, to].map (x) -> clamp(0, x, array.length-1)
        value = array[from]
        result = []
        array.forEach (item, index) ->
            result.push(value) if index is to and from > to
            result.push(item)  if index isnt from
            result.push(value) if index is to and from < to
        return result

    insertAt = (array, index, item) ->
        result = array.slice(0)
        result.splice(index, 0, item)
        return result

    removeAt = (array, index) ->
        result = array.slice(0)
        result.splice(index, 1)
        return result

    remove = (array, callback) ->
        index = indexOf array, callback
        return array if index is -1
        return removeAt array, index

    return {
        sum
        rotate
        copy
        chunk
        indexOf
        insertAt
        removeAt
        remove
        move
        mapRange
        rejectDuplicateModels
        uniqueModels: rejectDuplicateModels
        timeIt
        average
        clamp
        promiseTimer
        dateRange
        padLeft
        object: ObjectUtils
        function: FnUtils
        uuid: uuid
        normalizeBoolean: (bool, defaultValue = false) ->
            return defaultValue if _.isUndefined(bool)
            return bool
    }


