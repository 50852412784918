const initBrowserState = () => {
    const state: { isVisible: boolean | undefined } = { isVisible: undefined };

    const updateVisibility = () => {
        const isVisible =
            state.isVisible === undefined || document.visibilityState === undefined
                ? true
                : document.visibilityState !== 'hidden';

        state.isVisible = isVisible;
    };

    const userAgent = window.navigator.userAgent;
    const unsupportedBrowser = userAgent.indexOf('Trident/') >= 0 || userAgent.indexOf('Edge/') >= 0;

    updateVisibility();

    document.addEventListener('visibilitychange', updateVisibility, false);

    return {
        Visibility: state,
        isLocalhost: () => document.location.hostname === 'localhost',
        isNotSupported: () => unsupportedBrowser,
    };
};

const Browser = initBrowserState();

export { Browser };
