import mapboxgl, { GeoJSONSource, GeoJSONSourceRaw } from 'mapbox-gl';
import { StorePopup } from './mapbox-store-popup';

export interface IClusterLayerConfig {
    source: string;
    layers: {
        cluster: string;
        point: string;
        count: string;
    };
    popup?: typeof StorePopup;
    clusterPaint?: Partial<mapboxgl.CirclePaint>;
    pointPaint?: Partial<mapboxgl.CirclePaint>;
}

export class Mapbox {
    map!: mapboxgl.Map;

    constructor(token: string) {
        mapboxgl.accessToken = token;
    }

    initMap(config: mapboxgl.MapboxOptions): Promise<void> {
        this.map = new mapboxgl.Map(config);
        return new Promise(resolve => this.map.on('load', () => resolve()));
    }

    addClusterLayer(config: IClusterLayerConfig, features: GeoJSON.Feature<GeoJSON.Geometry>[]) {
        const { layers, source: sourceData } = config;

        this.map.addSource(sourceData, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features,
            },
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50,
        } as GeoJSONSourceRaw);

        this.map.addLayer({
            id: layers.cluster,
            type: 'circle',
            source: sourceData,
            filter: ['has', 'point_count'],
            paint: {
                'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
                ...config.clusterPaint,
            },
        });

        this.map.addLayer({
            id: layers.count,
            type: 'symbol',
            source: sourceData,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-size': 12,
            },
        });

        this.map.addLayer({
            id: layers.point,
            type: 'circle',
            source: sourceData,
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-radius': 4,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#fff',
                ...config.pointPaint,
            },
        });

        this.map.on('click', layers.cluster, e => {
            if (!(e.features && e.features[0])) {
                return;
            }

            const feature = e.features[0];
            const [lng, lat] = (e.features[0].geometry as GeoJSON.Point).coordinates;
            const clusterId = feature.properties?.cluster_id ?? null;

            if (clusterId === null) {
                return;
            }

            const source = this.map.getSource(sourceData) as GeoJSONSource;
            source.getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (!err) {
                    this.map.easeTo({
                        center: [lng, lat],
                        zoom,
                    });
                }
            });
        });

        this.map.on('mouseenter', layers.cluster, () => (this.map.getCanvas().style.cursor = 'pointer'));
        this.map.on('mouseleave', layers.cluster, () => (this.map.getCanvas().style.cursor = ''));

        if (config.popup) {
            const Popup = config.popup;

            this.map.on('click', layers.point, e => {
                if (!(e.features && e.features[0])) {
                    return;
                }

                const feature = e.features[0];
                const geometry = e.features[0].geometry as GeoJSON.Point;
                const coordinates = geometry.coordinates.slice();
                const { lng, lat } = e.lngLat;
                // Ensure that if the map is zoomed out such that
                // multiple copies of the feature are visible, the
                // popup appears over the copy being pointed to.
                while (Math.abs(lng - coordinates[0]) > 180) {
                    coordinates[0] += lng > coordinates[0] ? 360 : -360;
                }

                const storePopup = new Popup(feature);
                new mapboxgl.Popup().setLngLat([lng, lat]).setDOMContent(storePopup.getGui()).addTo(this.map);
            });

            this.map.on('mouseenter', layers.point, () => (this.map.getCanvas().style.cursor = 'pointer'));
            this.map.on('mouseleave', layers.point, () => (this.map.getCanvas().style.cursor = ''));
        }
    }

    setLayerVisibility(layerId: string, visible: boolean) {
        this.map.setLayoutProperty(layerId, 'visibility', visible ? 'visible' : 'none');
    }

    resize() {
        this.map.resize();
    }
}
