module.exports = [
    {shortName:'jan', fullName:'january'}
    {shortName:'feb', fullName:'february'}
    {shortName:'mar', fullName:'march'}
    {shortName:'apr', fullName:'april'}
    {shortName:'may', fullName:'may'}
    {shortName:'jun', fullName:'june'}
    {shortName:'jul', fullName:'july'}
    {shortName:'aug', fullName:'august'}
    {shortName:'sep', fullName:'september'}
    {shortName:'oct', fullName:'october'}
    {shortName:'nov', fullName:'november'}
    {shortName:'dec', fullName:'december'}
]
