_ = require('../lib/lodash-helper.ts')

module = angular.module '42.directives.widgets', [
    '42.services.misc'
]

module.factory 'SimplePaginator', -> (pageSize) ->
    pageSize = parseInt(pageSize)
    throw new Error("`pageSize` argument must be of type `Number`.") if _.isNaN(pageSize)
    throw new Error("`pageSize` argument must be greater than 0.")   if pageSize < 1
    page: 1
    size: pageSize
    prev: ->
        @page = Math.max(1, @page - 1)
    next: ->
        return @page += 1 if _.isUndefined(@pages)
        @page = Math.min(@pages, @page+1)
    setPage: (page) ->
        @page = 1
    setItemCount: (count) ->
        @total = if _.isNull(count) then undefined else parseInt(count)
        @pages = if _.isNull(count) then undefined else Math.ceil(count / @size)
    toQuery: ->
        offset: Math.max(0, (@page-1)) * @size
        limit:  @size


module.service 'FilterExecutors', ->

    OperatorHandlers =
        "==": (x, getter = _.noop) -> (d) -> getter(d) is x
        ">":  (x, getter = _.noop) -> (d) -> getter(d) > x
        "<":  (x, getter = _.noop) -> (d) -> getter(d) < x
        ">=": (x, getter = _.noop) -> (d) -> getter(d) >= x
        "<=": (x, getter = _.noop) -> (d) -> getter(d) <= x

    number: (filter, getter) ->
        {operator, value} = filter
        handler = OperatorHandlers[filter.operator]
        throw new Error("Unsupported operator `#{operator}`.") if not handler
        return handler(value, getter)

    percent: -> @number(arguments...)
    money:   -> @number(arguments...)


module.service 'FilterCompiler', ($log, FilterValueParser, FilterExecutors) ->

    parseType = (x) ->
        result = x.match(/([^:]+)/)?[1..]
        throw new Error("Cannot parse type `#{x}`.") if not result
        return result

    NOOP_FILTER_FUNCTION     = (x) -> (d) -> true
    EQUALITY_FILTER_EXECUTOR = (x) -> (d) -> x is d

    compile: (type, filterValue, getter) ->
        type = parseType(type)
        filterParser = FilterValueParser[type]
        $log.warn "No filter parser found for type `#{type}`." if not filterParser
        filter = (filterParser or _.noop).call(FilterValueParser, filterValue)
        if _.isUndefined(filter)
            $log.warn "Filter parse result is undefined."
            return NOOP_FILTER_FUNCTION
        filterExecutor = FilterExecutors[type]
        $log.warn "No filter executor found for type `#{type}`." if not filterExecutor
        return (filterExecutor or EQUALITY_FILTER_EXECUTOR).call(FilterExecutors, filter, getter)


module.service 'FilterValueParser', ->

    TOKENS =
        operator: '<|>|<=|>=|==?|!='
        number:   '\\d+|\\d+\\.\\d+'
        unit:     'k|m|b'

    NUMBER_REGEX = new RegExp("^(#{TOKENS.operator})?(#{TOKENS.number})(#{TOKENS.unit})?$")

    parseNumeric = (value) ->
        value = value?.trim().toLowerCase().replace(/,/g, '').replace(/\s/g, '')
        return if not value
        tokens = value.match(NUMBER_REGEX)?[1..]
        return if not tokens
        [operator, value, unit] = tokens
        operator = do ->
            return "==" if not operator or operator is "="
            return operator
        value = parseFloat(value)
        return {operator, value, unit}

    number: (value) ->
        tokens = parseNumeric(value)
        return if not tokens
        {operator, value, unit} = tokens
        value = value * switch unit
            when 'k' then 1000
            when 'm' then 1000000
            when 'b' then 1000000000
            else 1
        return {operator, value}

    money: (value) ->
        value = value?.replace('$', '')
        return @number(value)

    percent: (value) ->
        value = value?.replace('%', '')
        result = parseNumeric(value)
        return if not result
        result.value = result.value / 100
        return result


module.factory 'NaturalLanguageQueryFilterBuilder', (FilterValueParser) ->

    OPERATOR_MAP =
        "<":  "$lt"
        ">":  "$gt"
        "<=": "$lte"
        "=<": "$lte"
        "=>": "$gte"
        ">=": "$gte"
        "==": "$equals"
        "!=": "$ne"

    parsers =

        number: (value) ->
            tokens = FilterValueParser.money(value)
            return {} if not tokens
            operator = OPERATOR_MAP[tokens.operator]
            result = {}
            result[operator] = tokens.value
            return result

        string: (value) ->
            return undefined if not value
            return $fuzzy:value

    return (descriptors) ->
        unsupportedTypes = do ->
            parserTypes     = Object.keys(parsers)
            descriptorTypes = _.uniq(_.values(descriptors))
            return _.difference(descriptorTypes, parserTypes)
        throw new Error("Parsers types `#{unsupportedTypes}` are not supported.") if unsupportedTypes.length isnt 0
        parse: (properties) ->
            Object.keys(properties).reduce ((result, property) ->
                value  = properties[property]
                parser = parsers[descriptors[property]]
                result[property] = parser(value) if not _.isUndefined(value)
                return result
            ), {}


module.directive 'compactGroupSelect', ($window) ->
    restrict: 'E'
    scope:
        smartGroups: '='
    replace: true
    template: \
    """
    <article class="compact-group-select">
        <div class="select-title">Filter Group</div>
        <div class="compact-group-select-dropdown">
            <i class="icon-down-open-mini"></i>
            <select ng-options="group.getName() for group in smartGroups.groups"
                ng-model="smartGroups.selected">
            </select>
        </div>
    </article>
    """

module.directive 'supertable', (Scrollbar) ->
    restrict: 'A'
    link: (scope, element) ->
        $element = $(element)
        $main    = $element.find('main')
        $header  = $element.find('header')
        $footer  = $element.find('footer')
        $header.css right:"#{Scrollbar.getScrollbarWidth()}px"
        $footer.css right:"#{Scrollbar.getScrollbarWidth()}px"

        rows = $header.find('tr').length
        headerHeight = rows * $header.height() + 2
        $header.height(headerHeight)
        $main.css top:"#{headerHeight}px"

        $headerCells = $header.find('.row-search th').has('input[type=search]')
        $headerCells.append('<i class="icon-search"></i>')
        $headerCells.wrapInner('<div class="search-container"></div>')
