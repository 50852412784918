require 'angular.ui'
require 'angular.route'
require 'angular.sanitize'
require 'angular.infinitescroll.coffee'
require 'angular.bindonce'
require 'angular.promisetracker'
require 'angular.highcharts'
require 'angular.ui.bootstrap'
require 'angular.sortable' #dndLists

require 'jstree'
require 'jquery.fittext'

require './services'
require './filters/misc-filter.coffee'
require './modules/modules.coffee'
require './directives/directives.coffee'
require './controllers/controllers.coffee'
require './router.ts'

_ = require('./lib/lodash-helper.ts')


window.app = app = angular.module '42.dashboard', [
    'ui'
    'ngSanitize'
    'infinite-scroll'
    '42.routes'
    '42.config'
    '42.modules'
    '42.controllers'
    '42.directives'
    'ajoslin.promise-tracker'
    'highcharts-ng'
    'ui.bootstrap'
    'dndLists'
    'pasvaz.bindonce'
]

# Angular UI configuration
app.value 'ui.config', {}

# FIXME: Temporary until angular ui uses $sce for `highlight` filter.
app.config ($sceProvider) ->
    $sceProvider.enabled false

app.config ($httpProvider) ->
    # This is to prevent angular from removing keys prefixed with `$`,
    # as those are needed for the query api.
    $httpProvider.defaults.transformRequest = [
        (data) -> JSON.stringify(data)
    ]

    # Allows CORS
    $httpProvider.defaults.useXDomain = true
    delete $httpProvider.defaults.headers.common['X-Requested-With']


app.run ($rootScope) -> $rootScope.initialized = false

app.run (['$route', '$rootScope', '$location', ($route, $rootScope, $location) ->
    original = $location.path
    $location.path = (path, reload) ->
        if reload == false
            lastRoute = $route.current
            un = $rootScope.$on('$locationChangeSuccess', ->
                $route.current = lastRoute
                un()
            )

        return original.apply($location, [path])
])

app.config ($provide, $routeProvider, $locationProvider, ROUTES, CONFIG) ->
    $locationProvider.hashPrefix('')
    defaultUrl = CONFIG.routes?.sales?.url or ROUTES.sales.url
    $routeProvider.otherwise({redirectTo: defaultUrl})

    # Workaround for showing source maps on unhandled exceptions that happen in angular
    # https://github.com/angular/angular.js/issues/5217#issuecomment-50993513
    $provide.decorator '$exceptionHandler', ($delegate) -> (exception, cause) ->
        # $delegate(exception, cause)
        setTimeout -> throw exception


app.directive 'route', ->
    restrict: 'E'
    scope:
        route: '=model'
        showIcon: '@'
    replace: true
    template: \
    """
    <li ng-class="{active:route.active}">
        <a ng-href="{{ link }}" ng-class="{active:route.active}" target="{{ route.href && '_blank' }}">
            <i ng-if="showIcon" class="route-icon icon-{{ route.icon }} icon-route-{{ route.id }}"></i>
            <span class="route-label">{{ route.label }}</span>
        </a>
    </li>
    """
    link: (scope) ->
        # Remove last path param
        scope.link = (scope.route.href or ('/#' + scope.route.url)).split('/:')[0]

app.run ($rootScope, $location, $route, Utils, ROUTES, CONFIG) ->
    ROUTES = do ->
        routes = Utils.copy(ROUTES)
        return routes if not CONFIG.routes
        Object.keys(routes).forEach (routeId) ->
            routeOverrides = CONFIG.routes?[routeId]
            return if not routeOverrides
            routeOverrides = _.pick(routeOverrides, 'label', 'url', 'icon')
            routes[routeId] = _.extend routes[routeId], routeOverrides
            routes[routeId].templates = [routes[routeId].url]
        return routes

    $rootScope.routes = ROUTES

    $rootScope.$on '$routeChangeSuccess', ->
        $rootScope.activeRoute = null
        for route in _.values($rootScope.routes)
            route.active = $route.current.originalPath is route.url
            $rootScope.activeRoute = route if route.active

    previousPagePath = null
    $rootScope.$on '$viewContentLoaded', ->
        pagePath = $location.path()
        pageName = $rootScope.activeRoute?.label or pagePath
        return if previousPagePath is pagePath
        previousPagePath = pagePath
        analytics.page pageName,
            title: pageName
            path:  pagePath
