import $ from 'jquery';
const SUPPORT_EMAIL_LABEL = 'support@42technologies.com';

const ORGANIZATIONS_SUPPORT_EMAILS = {
    sportsdirect: 'daniel.drew@sportsdirect.com',
};

const getOrganizationSupportEmail = (organization: string) => {
    const orgs = Object.keys(ORGANIZATIONS_SUPPORT_EMAILS) as (keyof typeof ORGANIZATIONS_SUPPORT_EMAILS)[];
    const prefix = orgs.find(org => organization.indexOf(org) === 0);
    if (!prefix) return;
    return ORGANIZATIONS_SUPPORT_EMAILS[prefix];
};

const getErrorMessage = (supportEmail: string) => `
    <h1>Whoops, something went wrong while loading your dashboard :(</h1>
    <h2>Try to <a id="#refresh" href="javascript:location.reload()">refresh the dashboard</a> in a minute or two.</h2>
    <p class="contact-support">If the problem persists, please contact
    <a href="${supportEmail}">${SUPPORT_EMAIL_LABEL}</a></p>
`;

const getBrowserCompatibilityMessage = (supportEmail: string) => `
    <h1>Sorry, your browser is not supported :(</h1>
    <h2>Please switch to <a href="https://www.google.com/chrome/">Google Chrome</a> or <a href="https://www.mozilla.org/firefox/">Firefox</a></h2>
    <p class="contact-support">If you have any concerns about browser support, please contact
    <a href="${supportEmail}">${SUPPORT_EMAIL_LABEL}</a></p>
`;

export const LoadingStatusMessages = {
    DashboardSetupMessage: (organization: string, supportEmail: string) => {
        supportEmail = getOrganizationSupportEmail(organization) || supportEmail;
        return `
            <h1>Our team is setting up your dashboard!</h1>
            <h2>This shouldn't take more than a day or two.</h2>
            <h2>If you have questions or need help, please contact<br /><br /><a href="mailto:${supportEmail}">${supportEmail}</a><br /><br /></h2>
            <a href="${supportEmail}">${SUPPORT_EMAIL_LABEL}</a></p>
        `;
    },

    SuspendedMessage: (organization: string, supportEmail: string) => {
        supportEmail = getOrganizationSupportEmail(organization) || supportEmail;

        return `
            <h1>Your account has been temporarily disabled. Don't panic!</h1>
            <h2>If you need to access the dashboard, please contact<br /><br /><a href="mailto:${supportEmail}">${supportEmail}</a><br /><br />or your internal IT / Support team.</h2>
            <p class="contact-support">Your scheduled reports remain active.<br />Your filters, views and reports are safe.<br /><br /><br />If you need help with anything else, please contact
            <a href="${supportEmail}">${SUPPORT_EMAIL_LABEL}</a></p>
        `;
    },

    ConfigurationError: (organization: string, type: string) => {
        organization ??= '42';
        const address = `support+${organization}+bootstrap+${type}@42technologies.com`;
        const subject = `[${organization}] dashboard configuration error`;
        return getErrorMessage(`mailto:${address}?subject=${subject}`);
    },

    InitializationError: (organization: string) => {
        organization ??= '42';
        const address = `support+${organization}+app+init@42technologies.com`;
        const subject = `[${organization}] dashboard initialization error`;
        return getErrorMessage(`mailto:${address}?subject=${subject}`);
    },

    BrowserCompatibilityError: (organization: string) => {
        organization ??= '42';
        const address = `support+${organization}+browser@42technologies.com`;
        const subject = `[${organization}] my browser is not supported`;
        return getBrowserCompatibilityMessage(`mailto:${address}?subject=${subject}`);
    },

    LoadingDataFromAppInit: () => `
        <h1>The dashboard is currently loading up some new data.</h1>
        <h2>We should be back online soon!</h2>
        <p>The page will be refreshed once the loading is done,<br/>but you can also
        <a id="refresh" href="javascript:location.reload()">click here</a>
        to refresh manually.</p>
    `,

    LoadingDataFromAppStarted: () => `
        <h1>The dashboard has started loading up some new data.</h1>
        <h2>Sorry about the interruption!</h2>
        <p>The page will be refreshed once the loading is done,<br/>but you can also
        <a id="refresh" href="javascript:location.reload()">click here</a>
        to refresh manually.</p>
    `,
};

export class LoadingStatusView {
    $body: JQLite;
    $status: JQLite;
    $message: JQLite;
    oldStatus: string | null = null;

    constructor() {
        this.$body = $('body');
        this.$status = $('.loading-status');
        this.$message = this.$status.find('.message');
    }

    clear = (model: LoadingStatusService) => {
        model.STATUSES.map(status => this.$message.removeClass(`message-${status}`));
        model.STATUSES.map(status => this.$body.removeClass(`status-${status}`));
    };

    private updateModel = (model: LoadingStatusService) => {
        this.clear(model);
        this.$body.addClass(`status-${model.status}`);
        this.$status.css({ display: 'block' });
        this.$message.addClass(`message-${model.status}`);
        this.$message.html(model.message ?? '');
        this.$status.find('.spinner').css({
            display: model.isError() ? 'none' : 'block',
        });
    };

    update = (model: LoadingStatusService) => {
        if (model.isDone()) {
            this.$status.css({ opacity: '1' });
            this.$status.animate({ opacity: '0' }, 300, () => {
                this.updateModel(model);
                this.$status.css({ display: 'none' });
            });
        } else if (this.oldStatus && this.oldStatus === 'done' && !model.isDone()) {
            this.$status.css({ opacity: '0' });
            this.updateModel(model);
            this.$status.animate({ opacity: '1' }, 300);
        } else {
            this.updateModel(model);
        }

        this.oldStatus = model.status;
    };
}

export class LoadingStatusService {
    loadingStatusView: LoadingStatusView;

    constructor() {
        this.loadingStatusView = new LoadingStatusView();
    }

    status = 'loading';
    Messages = LoadingStatusMessages;
    STATUSES = ['loading', 'error', 'done'];
    message?: string;

    isLoading = () => this.status === 'loading';
    isError = () => this.status === 'error';
    isDone = () => this.status === 'done';

    private update = (message = '') => {
        this.message = message;
        this.loadingStatusView.update(this);
    };

    loading = (message: string) => {
        this.status = 'loading';
        this.update(message);
    };

    error = (message: string) => {
        this.status = 'error';
        this.update(message);
    };

    done = () => {
        this.status = 'done';
        this.update();
    };
}
