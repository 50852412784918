_ = require('../../lib/lodash-helper.ts')
AuthServiceAPI =  require('../../lib/auth')

module = angular.module '42.controllers.metrics', []
module.config ($routeProvider, ROUTES, CONFIG) ->
    routeId = 'metrics'
    route = _.extend {}, ROUTES[routeId], _.pick(CONFIG.routes?[routeId], 'label', 'url')
    $routeProvider.when route.url, route


module.service 'MetricsHierarchy', ($q, CONFIG, Hierarchy, HourProperty) ->
    fetch: -> $q.all([
            Hierarchy.fetch().then(({all}) -> all)
            HourProperty.fetch()
        ]).then ([hierarchy, hourProperty]) ->
            hierarchy.push(hourProperty) if hourProperty
            hierarchy.forEach (x) -> x.sort ?= do ->
                field = CONFIG.defaults?.metrics?.sortBy or 'net_sales'
                return {field, order:-1}
            return hierarchy

module.controller 'MetricsController', ($rootScope, $scope, CONFIG, Utils, MetricsHierarchy, MetricsKPIsService) ->
    refresh = -> Promise.all([MetricsHierarchy.fetch(), MetricsKPIsService.fetch($rootScope.query), AuthServiceAPI.getOrganization()]).then (result) ->
        [properties, metrics, organization] = result
        $scope.model = {properties, metrics, organization}
    $rootScope.$on '$destroy', \
    $rootScope.$watch 'initialized', (initialized) ->
        return if not initialized
        return refresh() if not $rootScope.hierarchyModel
        $rootScope.$on '$destroy', \
        $rootScope.$watch 'hierarchyModel.selected', (selected) ->
            return if not selected
            refresh()
