require 'trix'
require 'angular.trix'
require 'angular.elastic'
moment = require 'moment'
require 'moment-timezone'
_ = require('../../lib/lodash-helper.ts')

{ createSortable } = require('../../lib/ui-sortable.ts')
cronstrue = require('cronstrue')
AuthServiceAPI = require('../../lib/auth.ts')

module = angular.module('42.controllers.scheduling', [
    '42.modules.services'
    '42.modules.storage'
    '42.services.misc'
    'angularTrix'
    'monospaced.elastic'
]).config ($routeProvider, ROUTES, CONFIG) ->
    routes =
        reports:   _.extend {}, ROUTES.reportingReports,   _.pick(CONFIG.routes?.reportingReports,   'label', 'url')
        schedules: _.extend {}, ROUTES.reportingSchedules, _.pick(CONFIG.routes?.reportingSchedules, 'label', 'url')
    Object.keys(routes).forEach (k) -> $routeProvider.when(routes[k].url, routes[k])


module.factory 'ReportingModels', (ReportTemplatesModel, SchedulesModel) ->
    class ReportingModel
        @Create: ->
            ReportTemplatesModel.Create().then (templates) ->
                SchedulesModel.Create(templates).then (schedules) ->
                    return new ReportingModel({templates, schedules})
        constructor: ({@templates, @schedules}) ->

module.service 'ReportingState', ($q, ReportingModels) ->
    fetch: -> ReportingModels.Create().then (x) =>
        @models = x
        return x
    # This is used to optimize checks for `isInvalid` and `hasUnsavedChanges` across directives.
    schedule: {flags:{}}
    report:
        flags:{}
        invalidFields:{}
        updateInvalidFields: (invalidFields) ->
            @invalidFields = _.extend {}, @invalidFields, invalidFields
            @flags.isInvalid = Object.keys(@invalidFields).filter((k) => @invalidFields[k]).length > 0


module.controller 'ReportingReportsController', ($scope, ReportingState, promiseTracker) ->
    tracker = promiseTracker('reports-loading')
    loadingPromise = ReportingState.fetch()
    tracker.addPromise(loadingPromise)
    loadingPromise.then (models) ->
        $scope.reportTemplatesModel = models.templates
        $scope.schedules            = models.schedules

module.controller 'ReportingSchedulesController', ($routeParams, $location, $scope, ReportingModels, promiseTracker) ->
    $scope.schedules = null

    tracker = promiseTracker('schedules-loading')
    loadingPromise = ReportingModels.Create()
    tracker.addPromise(loadingPromise)

    loadingPromise.then ({schedules}) ->
        id = $routeParams.id

        selectedSchedule = do ->
            return if schedules.available.length is 0
            return schedules.viewState.active if not id
            selected = schedules.available.find((schedule) -> schedule.id is id)
            return selected if selected
            return schedules.viewState.active

        if selectedSchedule
            if not id or id isnt selectedSchedule.id
                $location.path(schedules.getScheduleLink(selectedSchedule), false)
            if selectedSchedule?.id isnt schedules.viewState.active
                schedules.select(selectedSchedule)

        $scope.schedules = schedules

module.directive 'reportTemplateList', ->
    restrict: "E"
    scope:
        reportTemplates: "=model"
    replace: true
    template: \
    """
    <article class="list-container report-templates list-container-report-templates" ng-if="!reportTemplates.selected">
        <ul>
            <li ng-repeat="template in reportTemplates.available" ng-click="reportTemplates.select(template)">
                <h1 class="title">{{ template.label }}</h1>
                <p class="description">{{ template.description }}</p>
            </li>
        </ul>
    </article>
    """

module.directive 'reportSelectList', ->
    restrict: "E"
    scope:
        model: "="
        schedules: "="
    replace: true
    template: \
    """
    <article class="report-select">
        <report-template-list model="model"></report-template-list>
        <report-list model="model" schedules="schedules" ng-if="model.selected"></report-list>
    </article>
    """

module.service 'ReportSmartGroups', ->
    model: null

module.directive 'viewReportingReports', ($q, $rootScope, $timeout, promiseTracker, ReportingState, ReportSmartGroups) ->
    restrict: 'E'
    scope:
        model: '='
        schedules: '='
    replace: true
    template: \
    """
    <article
        class="view view-reporting view-reporting-list view-reporting-reports"
        ng-class="{invalid: ReportingState.report.flags.isInvalid, unsaved: ReportingState.report.flags.hasUnsavedChanges}">
            <div class="loadable" promise-tracker="reports-loading"></div>
            <aside ng-class={'has-selected':model.selected}>
                <report-select-list model="model" schedules="schedules" ></report-select-list>
            </aside>
            <main ng-show="model.selected.reports.viewState.active">
                <view-reporting-reports-header model="model"></view-reporting-reports-header>
                <main>
                    <report-editor model="model"></report-editor>
                </main>
            </main>
            <article class="smart-groups-filter-container">
                <smart-groups-filter descriptor="ReportSmartGroups.model.descriptor" filters="ReportSmartGroups.model.filters"></smart-groups-filter>
            </article>
    </article>
    """
    link: (scope) ->
        scope.ReportSmartGroups = ReportSmartGroups
        scope.ReportingState = ReportingState

        scope.hierarchyModel = $rootScope.hierarchyModel
        scope.$watch 'hierarchyModel.selected', (selected) ->
            return if not selected
            scope.model?.selected.reports.viewState.active.data.hierarchy = $rootScope.hierarchyModel.getSelectedId()

        scope.$watch 'model.selected.reports.viewState.active.data.hierarchy', (hierarchyId) ->
            return if not scope.hierarchyModel
            if hierarchyId
                $rootScope.hierarchyModel.setSelectedHierarchy(hierarchyId)
                scope.hierarchyModel = $rootScope.hierarchyModel
            scope.model?.selected?.reports.viewState.active.data.hierarchy = scope.hierarchyModel.selected?.id

        scope.$watch 'model.selected.reports.hasUnsavedChanges()', (unsavedChanges) ->
            ReportingState.report.flags.hasUnsavedChanges = unsavedChanges

        scope.$watch 'model.selected.reports.viewState.selected', (result) ->
            ReportingState.report.flags.isInvalid = false
            ReportingState.report.invalidFields = {}

module.directive 'reportList', ($timeout, $sanitize, Utils) ->
    restrict: "E"
    scope:
        reportTemplates: "=model"
        schedules: "="
    replace: true
    template: \
    """
    <article class="list-container reports list-container-reports" ng-class="{'has-templates':reportTemplates.available.length > 1}">

        <article class="list-action list-action-back report-template" ng-if="reportTemplates.available.length > 1" ng-click="reportTemplates.select(null)">
            <i class="icon-left-open-big"></i>
            <h1>{{ reportTemplates.selected.label }}</h1>
        </article>

        <article class="list-action list-action-create report-create"
            ng-if="!reportTemplates.selected.reports.isInCreateMode()"
            ng-click="reportTemplates.selected.reports.create()">
            <i class="icon-plus-circled"></i>
            <h1>New Report</h1>
        </article>

        <article class="list-action list-action-create report-create"
            ng-if="reportTemplates.selected.reports.isInCreateMode()"
            ng-click="reportTemplates.selected.reports.cancel()">
            <i class="icon-cancel-circled"></i>
            <h1>Cancel</h1>
        </article>

        <article class="list-action list-action-search"
            ng-if="reportTemplates.selected.reports.viewState.available.length > 1 && !reportTemplates.selected.reports.isInCreateMode()"
            ng-class="{filtered:reportFilter.value}">
            <i class="icon-cancel-circled" ng-click="reportFilter.value = ''" ng-show="reportFilter.value"></i>
            <input ng-model="reportFilter.value" type="text" placeholder="Filter..." />
            <i class="icon-search"></i>
        </article>

        <ul class="models sortable-ui">

            <li class="item item-new report report-new selected" ng-if="reportTemplates.selected.reports.isInCreateMode()">
                <h1>{{ reportTemplates.selected.reports.viewState.active.label }}</h1>
                <p class="description">{{ reportTemplates.selected.reports.viewState.active.description }}</p>
            </li>

            <li ng-repeat="report in reportTemplates.selected.reports.viewState.available | filter:reportFilter.value"
                class="item"
                ng-click="reportTemplates.selected.reports.select(report)"
                ng-class="{selected:report === reportTemplates.selected.reports.viewState.active}">
                    <h1>{{ report.label }}</h1>
                    <report-schedule-info-tooltip
                        ng-if="reportSchedules && reportSchedules[report.id]"
                        schedules="reportSchedules[report.id]">
                    </report-schedule-info-tooltip>
                    <h2 class="updated-at">
                        <span class="date">{{ report.updatedAt | date:'MMM dd' }}</span>
                        <span class="separator">at</span>
                        <span class="time">{{ report.updatedAt | date:'HH:mm' }}</span>
                        <i class="icon-clock"></i>
                    </h2>
                    <p class="description">{{ report.description }}</p>
            </li>
        </ul>
    </article>
    """
    link: (scope, element) ->
        reportSchedules = {}

        if scope.schedules.available.length > 0
            scope.schedules.available.forEach (schedule) ->
                schedule.data.reportId.forEach (reportId) ->
                    reportSchedules[reportId] = reportSchedules[reportId] || []
                    reportSchedules[reportId].push({ name: schedule.label, id: schedule.id })

        scope.reportSchedules = reportSchedules
        scope.reportFilter = {value:''}

        sortable = createSortable element[0].querySelector('.sortable-ui'),
            ghostClass: 'placeholder'
            filter: '.item-new'
            draggable: '.item'
            onEnd: (evt) ->
                scope.reportTemplates.selected.reports.reorder(evt.oldIndex, evt.newIndex)
                scope.$apply()

        scope.$on 'destroy', ->
            sortable.destroy()

module.directive 'reportScheduleInfoTooltip', ($location) ->
    restrict: 'E'
    scope:
        schedules: "="
    replace: true
    template: \
    """
    <div class="report-schedule">
        <div
            ng-mouseover="showTooltip()"
            ng-mouseleave="hideTooltip()"
            class="schedule-tooltip-wrapper">
            <div class="schedule-label">Scheduled</div>
            <div ng-if="enableTooltip" class="schedule-tooltip">
                <div class="schedule-tooltip-header">Used in Schedules:</div>
                <div ng-repeat="schedule in schedules" class="schedule-tooltip-series">
                    <span class="schedule-tooltip-series-label" ng-click="navigateToSchedule(schedule)">- {{ schedule.name }}</span>
                </div>
            </div>
        </div>
    </div>
    """
    link: (scope) ->
        scope.enableTooltip = false
        scope.showTooltip = -> scope.enableTooltip = true
        scope.hideTooltip = -> scope.enableTooltip = false

        scope.navigateToSchedule = (schedule) ->
            $location.path('/reporting/schedules/' + schedule.id)

module.directive 'scheduleTargetEditor', (RegularExpressions) ->
    restrict: 'E'
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="schedule-target-editor" ng-if="model">
        <main>
            <label class="target-recipients" ng-class="{error:emailModels.recipients.invalid}">
                <span>Recipients</span>
                <span class="hint">(required, enter a newline or comma for multiple addresses)</span>
                <span class="error-message" ng-if="emailModels.recipients.value.length > 0">Invalid email address(es)</span>
                <span class="error-message" ng-if="!emailModels.recipients.value.length">(required)</span>
                <multiline-email-editor model="emailModels.recipients"></multiline-email-editor>
            </label>
            <label class="target-bcc" ng-class="{error:emailModels.bcc.invalid && emailModels.bcc.value.length > 0}">
                <span>BCC</span>
                <span class="error-message">Invalid email address(es)</span>
                <multiline-email-editor model="emailModels.bcc"></multiline-email-editor>
            </label>
            <!--
            <label class="target-subject" ng-class="{error:invalid.subject}">
                <span>Subject</span>
                <span class="hint">(required)</span>
                <span class="error-message">(required)</span>
                <input type="text" ng-model="model.subject"></input>
            </label>
            -->
            <section class="target-body" ng-class="{error:invalid.body}">
                <h1>Note</h1>
                <trix-editor angular-trix trix-initialize="initialized(e, editor)" trix-change="bodyChanged(e, editor)" ng-model="model.body" class="trix-content"></trix-editor>
            </section>
        </main>
    </article>
    """
    link: (scope) ->
        editor = null

        scope.invalid = {}

        changed = {subject:false, body:false}

        scope.$watch 'model', (model) ->
            changed = {subject:false, body:false}
            scope.invalid = {body:false, subject:false}
            scope.emailModels =
                recipients: {invalid:false, changed:false, value:model?.recipients or ''}
                bcc:        {invalid:false, changed:false, value:model?.bcc or ''}

        scope.$watch 'emailModels.recipients.value', (value) ->
            scope.model?.recipients = value
        scope.$watch 'model.recipients', (value) ->
            return if not scope.emailModels
            scope.emailModels.recipients.value = value
        scope.$watch 'emailModels.bcc.value', (value) ->
            scope.model?.bcc = value
        scope.$watch 'model.bcc', (value) ->
            return if not scope.emailModels
            scope.emailModels.bcc.value = value

        scope.invalid = {recipients:false, body:false}
        scope.initialized = (event, editor) ->
            editor = editor.loadHTML(scope.model.body)
        scope.$watch 'model', (model) ->
            editor?.loadHTML(scope.model.body)
        scope.bodyChanged = (event, editor) ->
            changed.body = true
            scope.model.body = $(editor.element).html()

module.directive 'multilineEmailEditor', (EmailAddressMultiFieldParser, RegularExpressions) ->
    restrict: 'E'
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="multiline-email-editor">
        <textarea rows="1" msd-elastic ng-model="view"></textarea>
    </article>
    """
    link: (scope, element) ->
        scope.view = ""
        scope.$watch 'model.value', (emails) ->
            scope.view = do ->
                return null if not emails
                return emails if _.isString(emails)
                return emails?.join('\n') or null
        scope.$watch 'view', (view) ->
            scope.model.changed = scope.model.changed or (view and view.length > 0)
            scope.model.value = view
            scope.model.invalid = do ->
                return true if scope.model.changed and not view
                return _.some(EmailAddressMultiFieldParser.parse(view) or [], (x) -> not x.isValid) or false


module.directive 'viewReportingReportsHeader', ($rootScope, promiseTracker, CONFIG, FileService, ReportingState) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <header class="view-reporting-list-header view-reporting-reports-header" ng-if="model">
        <section class="actions">
            <button class="button-run" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" promise-tracker="run-report" ng-click="actions.run()">Run</button>
            <button class="button-save" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" ng-click="actions.save()">Save</button>
            <button class="button-cancel button-bare" ng-click="actions.cancel()" ng-if="reports.isInCreateMode()">Cancel</button>
            <button class="button-copy" ng-click="actions.copy()" ng-if="!reports.isInCreateMode()">Save As...</button>
            <button class="button-reset button-bare" ng-click="actions.reset()" ng-if="!reports.isInCreateMode()">Reset</button>
            <button class="button-delete button-bare" ng-click="actions.delete()" ng-if="!reports.isInCreateMode()">Delete</button>
        </section>
        <hierarchy-select ng-if="hierarchyModel" model="hierarchyModel"></hierarchy-select>
    </header>
    """
    link: (scope) ->

        scope.hierarchyModel = $rootScope.hierarchyModel
        tracker = promiseTracker('run-report')

        scope.$watch 'model.selected.reports', (reports) ->
            scope.reports = reports

        scope.errorMessage = ""
        scope.onSaveOrRunHover = ->
            scope.errorMessage = do ->
                return "" if not ReportingState.report.flags.isInvalid
                invalidFields = Object.keys(ReportingState.report.invalidFields).filter (key) -> ReportingState.report.invalidFields[key]
                return "Invalid or missing field(s): #{invalidFields.join(', ')}."

        getReportFilename = (response) ->
            DEFAULT_LABEL = "Report Export"
            timestamp = moment().format('YYYY-MM-DD HHmmss')
            label = scope.reports.viewState?.active?.label or DEFAULT_LABEL
            label = label.replace(/&/g, 'and')
            label = label.replace(/[^A-Z0-9\-_\ ]/gi, '')
            label = label.replace(/\ +/g, ' ')
            label = label.trim()
            label = DEFAULT_LABEL if label.length is 0
            label = label.slice(0, 200)
            return "42 - #{label} - #{timestamp}.#{response.type}"

        scope.actions =
            save: ->
                return if not ReportingState.report.flags.hasUnsavedChanges
                return if ReportingState.report.flags.isInvalid
                return scope.reports.save()
            reset: ->
                return if not ReportingState.report.flags.hasUnsavedChanges
                return scope.reports.reset()
            cancel: ->
                return scope.reports.cancel()
            copy: ->
                return scope.reports.copy()
            delete: ->
                return if not window.confirm("""
                Are you sure you want to delete this report?
                This cannot be un-done.
                """)
                return scope.reports.delete()
            run: ->
                return if ReportingState.report.flags.isInvalid
                tracker.addPromise do ->
                    scope.reports.run()
                    .then (response) ->
                        data = {host:CONFIG.services.query.host, data:response}
                        filename = getReportFilename(response)
                        FileService.send(filename)(data)
                    .catch (error) ->
                        alert "Could not run report due to error... sorry!"

module.directive 'reportInfoEditor', (ReportsModel) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="report-info report-info-editor">
        <main>
            <label class="report-label">
                <span>Report Name</span>
                <input type="text" ng-model="reports.viewState.active.label"></input>
            </label>
            <label class="report-description">
                <span>Description</span>
                <textarea ng-model="reports.viewState.active.description"></textarea>
            </label>
        </main>
    </article>
    """
    link: (scope) ->
        scope.$watch 'model.selected.reports', (reports) ->
            scope.reports = reports

module.directive 'reportEditor', (ReportsModel) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="report-editor" ng-if="model.selected.reports.viewState.active">
        <report-info-editor model="model"></report-info-editor>
        <report-params-editor model="model"></report-params-editor>
    </article>
    """

module.directive 'scheduleReportEditor', (ReportsModel) ->
    restrict: "E"
    scope:
        model:    "="
        schedule: "="
    replace: true
    template: \
    """
    <article class="report-viewer">
        <schedule-report-template-editor model="model" schedule="schedule"></schedule-report-template-editor>
        <report-params-viewer ng-if="model.hasReportId(model.selected.reports.viewState.active.id)" params="model.selected.reports.getActiveParams()"></report-params-viewer>
    </article>
    """

module.directive 'scheduleReportTemplateEditor', ($location, $timeout, ReportingState) ->
    restrict: "E"
    scope:
        model:    "="
        schedule: "="
    replace: true
    template: \
    """
    <article class="report-info report-info-viewer">
        <main ng-if="model.hasReports()">
            <label class="report-template" ng-class="{error:model.selectedReportIsInvalid()}">
                <span>What is the report to be scheduled?</span>
                <p class="error-message">
                The report that was previously selected was deleted. Please select a new report.
                </p>
                <schedule-report-template-select model="model"></report-template-editor>
            </label>
            <label class="report-description" ng-if="model.selected.reports.viewState.active.description">
                <span>Description</span>
                <p>{{ reports.viewState.active.description }}</p>
            </label>
        </main>
        <main ng-if="!model.hasReports()">
            <label class="report-description error">
                <span>No reports available!</span>
                <p>You must create a report before you can save this schedule.</p>
            </label>
        </main>
        <aside>
            <button class="button-delete" ng-if="showDeleteButton()" ng-click="delete()">
                <span>Remove</span>
            </button>
            <!--
            <aside ng-if="model.selected.reports.viewState.active">
            <button class="button-edit" ng-click="edit()">Edit Report</button>
            <button class="button-copy" ng-click="copy()">Copy Report</button>
            <button class="button-create" ng-click="create()">New Report</button>
            -->
        </aside>
    </article>
    """
    link: (scope) ->
        postEdit = (report) ->
            ReportingState.models.schedules.viewState.selected.data.reportId = report.id
            $location.path('/reporting/schedules')
        scope.$watch 'model.selected.reports', (reports) ->
            scope.reports = reports
        scope.create = ->
            scope.reports.create({postEdit})
            $location.path('/reporting/reports')
        scope.edit = ->
            scope.reports.edit({postEdit})
            $location.path('/reporting/reports')
        scope.copy = ->
            scope.reports.copy({postEdit})
            $location.path('/reporting/reports')
        scope.delete = ->
            scope.schedule.reportTemplatesList.remove(scope.model)
            scope.schedule.reportTemplatesList.add() if scope.schedule.reportTemplatesList.available.length is 0
        scope.showDeleteButton = ->
            scope.schedule.reportTemplatesList.available.length > 1

module.directive 'scheduleReportTemplateSelect', (Utils) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="schedule-report-template-select">
        <select ng-options="report as (report.label) group by (report.template.label) for report in view.available" ng-model="view.selected">
            <option value="" ng-if="!view.selected">Select a report...&nbsp;</option>
        </select>
    </article>
    """
    link: (scope) ->
        scope.view = {modelHash:null}

        modelChanged = ->
            current = do ->
                return null if not scope.model
                return Utils.object.hash getAvailable(scope.model)
            previous = scope.view.modelHash
            scope.view.modelHash = current
            return current is previous

        getAvailable = (model) ->
            _.flatten model.available.map (template) ->
                reports = template.reports.viewState.available or []
                reports.map (report) -> {id:report.id, label:report.label, template:{id:template.id, label:template.label}}

        scope.$watch 'view.selected', (selected) ->
            return if not selected
            scope.model.selectByReportId(selected.id)

        scope.$watch 'model.selected.reports.viewState.active.id', (reportId, prev) ->
            return if not scope.model?.selected
            return if reportId is prev
            report = _.find scope.view.available, (x) -> x.id is reportId
            report = report or scope.view.available?[0]
            scope.view.selected = report if report

        scope.$watch modelChanged, ->
            scope.view.available = do ->
                return [] if not scope.model
                return getAvailable(scope.model)
            scope.view.selected = do ->
                return null if not scope.model?.selected
                reportId = scope.model.selected.reports.viewState.active.id
                report = _.find scope.view.available, (x) -> x.id is reportId
                return report or scope.view.available[0]


module.directive 'viewReportingSchedules', ($q, $timeout, promiseTracker, ReportingState) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="view view-reporting view-reporting-list view-reporting-schedules" ng-class="{invalid:ReportingState.schedule.flags.isInvalid, unsaved:ReportingState.schedule.flags.hasUnsavedChanges}">
        <div class="loadable" promise-tracker="schedules-loading"></div>
        <aside><schedules-list model="model"></schedules-list></aside>
        <main ng-show="model.viewState.active">
            <view-reporting-schedules-header model="model"></view-reporting-schedules-header>
            <main>
                <schedule-editor model="model"></schedule-editor>
                <section class="report-templates">
                    <section class="report-template" ng-repeat="reportTemplates in model.reportTemplatesList.available track by reportTemplates.id">
                        <schedule-report-editor model="reportTemplates" schedule="model"></schedule-report-editor>
                    </section>
                    <section class="report-templates-actions">
                        <button class="button-add" ng-if="showReportAddButton()" ng-click="model.reportTemplatesList.add()">
                            <i class="icon-plus-circled"></i>
                            <span>Add Report</span>
                        </button>
                    </section>
                </section>
                <schedule-target-editor model="model.viewState.active.data.target"></schedule-target-editor>
            </main>
        </main>
    </article>
    """
    link: (scope) ->
        scope.ReportingState = ReportingState

        scope.$watch 'model.hasUnsavedChanges()', (unsavedChanges) ->
            ReportingState.schedule.flags.hasUnsavedChanges = unsavedChanges

        scope.$watch 'model.isInvalid()', (invalid) ->
            ReportingState.schedule.flags.isInvalid = invalid

        unWatchViewState = scope.$watch 'model.viewState.active', (active) ->
            if active
                unWatchViewState()

        hashReportTemplatesList = ->
            return undefined if not scope.model
            result = scope.model.reportTemplatesList.available.map (x) -> x.selected?.reports?.viewState?.selected?.id
            return result.join('')

        hashSelectedReports = ->
            reportIds = scope.model?.viewState.active?.data.reportId or []
            reportIds = [reportIds] if _.isString(reportIds)
            return reportIds.join('')

        scope.$watch hashSelectedReports, (hash) ->
            scope.model?.updateReportTemplatesFromModel()

        scope.$watch hashReportTemplatesList, (hash) ->
            return if not hash
            scope.model?.updateModelFromReportTemplates()

        scope.showReportAddButton = ->
            available = scope.model?.reportTemplatesList?.available
            return false if not available or available.length is 0
            last = available[available.length-1]
            return last?.selected?.reports?.viewState.selected

module.directive 'scheduleEditor', ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="schedule-editor">
        <schedule-info-editor model="model"></schedule-info-editor>
    </article>
    """

module.directive 'scheduleListItem', (CronExpressionUtils) ->
    restrict: "E"
    scope:
        schedule: "="
        view: "="
        selected: "="
    replace: true
    template: \
    """
    <li class="schedule"
        ng-class="{selected: selected}">
        <h1 class="schedule-label">{{ schedule.label }}</h1>
        <section class="schedule-report-labels" ng-class="{error:getView(schedule).reportLabels.length == 0}">
            <h2 class="schedule-report-error">No reports assigned!</h2>
            <h2 ng-if="getView(schedule).reportLabelsHeader">{{ getView(schedule).reportLabelsHeader }}</h2>
            <span class="schedule-report-label" ng-repeat="label in getView(schedule).reportLabels track by $index">
                {{ label }}
            </span>
        </section>
        <span class="schedule-expression-label">{{ getScheduleExpressionLabel(schedule) }}</span>
    </li>
    """
    link: (scope, element) ->

        scope.getView = (schedule) ->
            scope.view[schedule.id]

        scope.getScheduleExpressionLabel = (schedule) ->
            CronExpressionUtils.expressionToNaturalLanguage(schedule.expression)

        unWatch = scope.$watch 'selected', ->
            if scope.selected
                listContainerBottomHeigth = element.parent()[0].getBoundingClientRect().bottom
                elementBottomHeight = element[0].getBoundingClientRect().bottom

                if elementBottomHeight > listContainerBottomHeigth
                    element[0].scrollIntoView()

                unWatch()



module.directive 'schedulesList', ($location, CronExpressionUtils) ->
    restrict: "E"
    scope:
        schedules: "=model"
    replace: true
    template: \
    """
    <article class="list-container schedules list-container-schedules" ng-if="schedules">
        <article class="list-action list-action-create schedule schedule-create" ng-if="!schedules.isInCreateMode()" ng-click="schedules.create()">
            <i class="icon-plus-circled"></i>
            <span>New Schedule</span>
        </article>

        <article class="list-action list-action-create schedule schedule-create"
            ng-if="schedules.isInCreateMode()"
            ng-click="schedules.cancel()">
            <i class="icon-cancel-circled"></i>
            <h1>Cancel</h1>
        </article>

        <ul class="schedules-list">
            <li class="schedule schedule-new selected" ng-if="schedules.isInCreateMode()">
                <h1 class="schedule-label">{{ schedules.viewState.active.label }}</h1>
                <span class="schedule-report-label" ng-repeat="label in getReportLabels(schedules)">{{ label }}</span>
                <span class="schedule-expression-label">{{ getScheduleExpressionLabel(schedules.viewState.active) }}</span>
            </li>
            <schedule-list-item
                ng-repeat="schedule in schedules.available | orderBy:'createdAt':-1"
                ng-click="selectSchedule(schedule)"
                schedule="schedule"
                view="view.schedules"
                selected="schedule.id === schedules.viewState.active.id">
            </schedule-list-item>
        </ul>
    </article>
    """
    link: (scope, element) ->
        scope.view = {schedules:{}}

        scope.selectSchedule = (schedule) ->
            $location.path(scope.schedules.getScheduleLink(schedule), false)
            scope.schedules.select(schedule)

        scope.getScheduleExpressionLabel = (schedule) ->
            CronExpressionUtils.expressionToNaturalLanguage(schedule.expression)

        getReportLabels = (schedule, schedules) ->
            reportIds = schedule.data.reportId
            reports = schedules.reportTemplatesList.getReportDataById(reportIds)
            return reports.map (x) -> x.label

        scope.getView = (schedule) ->
            scope.view.schedules[schedule.id]

        watchReportLabels = ->
            result = _.flatten (scope.schedules?.available or []).map (schedule) ->
                labels = getReportLabels(schedule, scope.schedules)
                return [schedule.id].concat(labels)
            return result.join('')

        scope.$watch watchReportLabels, ->
            scope.view.schedules = (scope.schedules?.available or []).reduce ((result, schedule) ->
                labels = getReportLabels(schedule, scope.schedules)
                result[schedule.id] =
                    reportLabels: labels
                    reportLabelsHeader: do ->
                        return 'Reports' if labels.length > 1
                        return 'Report'  if labels.length is 1
                        return null
                return result
            ), {}

module.directive 'viewReportingSchedulesHeader', ($window, $timeout, promiseTracker, CONFIG, ReportingState) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <header class="view-reporting-list-header view-reporting-list-schedules-header">
        <section class="actions">
            <button class="button-run" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" promise-tracker="run-schedule" ng-click="actions.run()">Run</button>
            <button class="button-save" hover-msg="{{ errorMessage }}" ng-mouseover="onSaveOrRunHover()" ng-click="actions.save()" promise-tracker="update-schedule">Save</button>
            <button class="button-cancel button-bare" ng-click="actions.cancel()" ng-if="model.isInCreateMode()">Cancel</button>
            <button class="button-copy" ng-click="actions.copy()" ng-if="!model.isInCreateMode()">Save As...</button>
            <button class="button-reset button-bare"  ng-click="actions.reset()" ng-if="!model.isInCreateMode()">Reset</button>
            <button class="button-delete button-bare" ng-click="actions.delete()" ng-if="!model.isInCreateMode()">Delete</button>
            <button class="button-bare" ng-click="actions.toggleActive()" ng-if="!model.isInCreateMode()" promise-tracker="update-schedule">{{activeButtonLabel}}</button>
        </section>
    </header>
    """
    link: (scope) ->
        tracker = promiseTracker('run-schedule')
        updateTracker = promiseTracker('update-schedule')
        updateTracker.setActivationDelay(null)
        scope.errorMessage = ""
        scope.onSaveOrRunHover = ->
            scope.errorMessage = do ->
                return "" if not ReportingState.schedule.flags.isInvalid
                invalidFields = scope.model.getInvalidFields()
                return "Invalid or missing field(s): #{Object.keys(invalidFields).join(', ')}."
        scope.$watch 'model.viewState.active.active', (isActive) ->
            $timeout (->
                scope.activeButtonLabel = do ->
                    return 'Activate' if not isActive
                    return 'Deactivate'
            ), 50
            scope.actions =
                save: ->
                    return if not ReportingState.schedule.flags.hasUnsavedChanges
                    return if ReportingState.schedule.flags.isInvalid
                    updateTracker.addPromise do ->
                        scope.model.save().catch (error) ->
                            console.error error
                reset: ->
                    return if not ReportingState.schedule.flags.hasUnsavedChanges
                    return scope.model.reset()
                cancel: ->
                    return scope.model.cancel()
                copy: ->
                    return scope.model.copy()
                delete: ->
                    return if not window.confirm("""
                    Are you sure you want to delete this schedule?
                    This cannot be un-done.
                    """)
                    return scope.model.delete()
                toggleActive: ->
                    updateTracker.addPromise do ->
                        scope.model.toggleActiveState()
                run: ->
                    return if ReportingState.schedule.flags.isInvalid
                    tracker.addPromise do ->
                        scope.model.run()
                        .then(-> alert("The schedule was run successfully!"))
                        .catch (error) ->
                            console.error('Error occurred after running schedule:', error)
                            alert "Could not run schedule due to error... sorry!"


module.directive 'scheduleInfoEditor', ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="schedule-info schedule-info-editor">
        <main>
            <label class="schedule-name">
                <span>Label</span>
                <input type="text" ng-model="model.viewState.active.label"></input>
            </label>
            <div class="row-schedule-expression" ng-class="{error:!model.viewState.active.expression}">
                <label>
                    <span>Expression</span>
                    <span ng-if="translatedExpression" class="hint">({{ translatedExpression }})</span>
                </label>
                <span class="hint error-message" ng-if="errorMessage">{{ errorMessage }}</span>
                <!-- <span class="hint">({{ model.viewState.active.expression }})</span> -->
                <schedule-expression-editor model="model.viewState.active" error-message="errorMessage"></schedule-expression-editor>
            </div>
            <label class="schedule-timezone">
                <span>Timezone</span>
                <span class="hint">(current time: {{ currentTime }})</span>
                <select ng-options="x.id as (x.label) group by (x.group) for x in model.TIMEZONES" ng-model="model.viewState.active.timezone"></select>
            </label>
        </main>
    </article>
    """
    link: (scope) ->
        moment = require 'moment'
        require 'moment-timezone'
        scope.translatedExpression = null

        scope.$watch('model.viewState', ->
            timezone = scope.model?.viewState?.active?.timezone
            scope.currentTime = do ->
                return null if not timezone
                return moment.tz(timezone).format('MMMM DD, h:mma Z')

            expression = scope.model?.viewState?.active?.expression
            scope.translatedExpression = (try cronstrue.toString(expression, { verbose: true })) or null
        , true)


module.service 'CronExpressionUtils', (CONFIG, Utils, RegularExpressions) ->

    EVERY_DAY: ['Every Day']
    EVERY_FIRST_DAY: ['Every First Day of Month']
    CRON: ['Cron']

    DAYS: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    normalizeCron: (cron) ->
        cron = Utils.copy(cron or {})

        cron.minute = 0 if not cron.minute or cron.minute is '*'
        cron.hour       ?= '*'
        cron.dayOfMonth ?= '*'
        cron.month      ?= '*'
        cron.dayOfWeek  ?= '*'
        return cron

    timeToCron: (time) ->
        return null if not time
        matches = time.match(RegularExpressions.TIME)?[1..]
        return null if not matches
        tokens = matches.map (x) -> (x?.replace?(/\s/g, '')) or x
        [hour, minute, amPm] = [parseInt(tokens[2]), tokens[3]?.replace(':', ''), tokens[4].toLowerCase()]
        hour = do ->
            return 0         if hour is 12 and amPm is 'am'
            return hour + 12 if hour isnt 12 and amPm is 'pm'
            return hour
        minute = if minute then parseInt(minute) else 0
        return {minute, hour, dayOfMonth:'*', month:'*', dayOfWeek:'*'}

    cronToTime: (cron) ->
        return null if not cron
        return null if cron.hour is '*'
        {minute, hour} = cron
        [m, h, amPm] = do ->
            [m, h] = [minute, hour].map (x) -> Math.max(0, parseInt(x))
            [m, h] = [Math.min(59, m), Math.min(23, h)]
            m = do ->
                return "" if m is 0
                return ":0#{m}" if m <= 9
                return ":#{m}"
            return [m, h-12, 'pm'] if h > 12
            return [m, h, 'pm']    if h is 12
            return [m, 12, 'am']   if h is 0
            return [m, h, 'am']
        return "#{h}#{m}#{amPm}"

    cronToExpression: (cron) ->
        {minute, hour, dayOfMonth, month, dayOfWeek} = cron
        return "#{minute} #{hour} #{dayOfMonth} #{month} #{dayOfWeek}"

    expressionToCron: (expression) ->
        return null if not expression
        expression = expression.replace(/s+/g, ' ').trim()
        matches = expression.match(RegularExpressions.CRON)?[1..]
        return null if not matches
        [minute, hour, dayOfMonth, month, dayOfWeek] = matches.map (x) ->
            return x if x is '*'
            return parseInt(x)
        return {minute, hour, dayOfMonth, month, dayOfWeek}

    cronToLevels: (cron) ->
        return ['Cron'] if not cron

        calendarType = CONFIG.datepicker?.type or 'nrf'
        {minute, hour, dayOfMonth, month, dayOfWeek} = cron
        return ['Every First Day of Month', 'At Time'] if dayOfMonth is 1 and month is '*' and calendarType is 'gregorian'
        return ['Cron']                    if dayOfMonth isnt '*' or month isnt '*'
        return ['Every Day', 'At Time']    if minute isnt '*' and hour isnt '*' and dayOfWeek is '*'
        return ['Every Day', 'Every Hour'] if minute isnt '*' and hour is '*' and dayOfWeek is '*'
        dayLabel = @DAYS[parseInt(dayOfWeek)]
        return ['Cron'] if not dayLabel
        return ["Every #{dayLabel}", 'At Time']    if minute isnt '*' and hour isnt '*' and dayOfWeek isnt '*'
        return ["Every #{dayLabel}", 'Every Hour'] if minute isnt '*' and hour is '*' and dayOfWeek isnt '*'
        return ['Cron']

    expressionToNaturalLanguage: (expression) ->
        return @cronToNaturalLanguage(@expressionToCron expression)

    cronToNaturalLanguage: (cron) ->
        return null if not cron
        levels = @cronToLevels(cron)
        return "Cron: #{@cronToExpression(cron)}" if levels[0] is 'Cron'

        if (levels[0] != 'Every First Day of Month')
            levels[0] = "E#{levels[0].toLowerCase()[1..]}"

        if levels[1] is 'Every Hour'
            return "#{levels[0]} on every hour at minute #{cron.minute}"

        if levels[1] is 'At Time'
            return "#{levels[0]} at #{@cronToTime(cron)}"

        return null


module.directive 'scheduleExpressionEditor', (CONFIG, Utils, CronExpressionUtils, RegularExpressions) ->
    restrict: "E"
    scope:
        model: "="
        errorMessage: "="
    replace: true
    template: \
    """
    <article class="schedule-expression-editor" ng-class="{invalid:isInvalid}" ng-if="model">

        <select ng-options="x for x in options" ng-model="levels[0]"></select>

        <div class="level-every-day" ng-if="levels[0] == 'Every Day'">
            <select ng-options="x for x in ['At Time', 'Every Hour']" ng-model="levels[1]"></select>

            <!-- <minute> <hour> * * * -->
            <input class="input-time" placeholder="ex: 8:05am" type="text" ng-if="levels[1] == 'At Time'" ng-model="view.time" ng-pattern="regex.TIME"></input>

            <!-- <minute> * * * * -->
            <div ng-if="levels[1] == 'Every Hour'">
                <span>at minute</span>
                <input class="input-minute" data-tooltip="{{ errorMessage }}" type="number" ng-model="view.minute" min="0" max="59" integer></input>
            </div>
        </div>

        <div class="level-every-day" ng-if="levels[0] == 'Every First Day of Month'">
            <select ng-options="x for x in ['At Time']" ng-model="levels[1]"></select>

            <!-- <minute> <hour> * * * -->
            <input class="input-time" placeholder="ex: 8:05am" type="text" ng-model="view.time" ng-pattern="regex.TIME"></input>
        </div>

        <!-- <minute> <hour> <day> * * -->
        <div class="level-day" ng-if="dayOfWeekSelected()">

            <select ng-options="x for x in ['At Time', 'Every Hour']" ng-model="levels[1]"></select>

            <!-- <minute> <hour> * * * -->
            <input class="input-time" type="text" ng-if="levels[1] == 'At Time'" ng-model="view.time" ng-pattern="regex.TIME"></input>

            <!-- <minute> * * * * -->
            <div ng-if="levels[1] == 'Every Hour'">
                <span>at minute</span>
                <input class="input-minute" type="number" ng-model="view.minute" min="0" max="59" placeholder="0" integer></input>
            </div>
        </div>

        <!-- catchall -->
        <div class="level-cron" ng-if="levels[0] == 'Cron'">
            <input class="input-cron" type="text" ng-model="model.expression"></input>
            <div ng-if="translatedExpression" class="translated-expression">{{ translatedExpression }}</div>
        </div>
    </article>
    """
    link: (scope, element) ->
        scope.regex = RegularExpressions
        scope.options = CronExpressionUtils.EVERY_DAY
            .concat(CronExpressionUtils.DAYS.map((day) -> "Every #{day}"))

        calendarType = CONFIG.datepicker?.type or 'nrf'

        if calendarType is 'gregorian'
            scope.options = scope.options.concat(CronExpressionUtils.EVERY_FIRST_DAY)

        scope.options = scope.options.concat(CronExpressionUtils.CRON)

        getCronSelectedDayIndex = ->
            return "*" if not scope.dayOfWeekSelected()
            return CronExpressionUtils.DAYS.indexOf(scope.levels[0].replace('Every ', ''))

        getCronSelectedDayOfMonth = (cron) ->
            if scope.levels and scope.levels[0] and scope.levels[0] is 'Every First Day of Month'
                return '1'

            return '*' if !cron or cron.dayOfMonth is null or cron.dayOfMonth is undefined
            return cron.dayOfMonth

        scope.$watch 'model', (model) ->
            return if not model
            scope.view ?= {}
            scope.view.cron = CronExpressionUtils.expressionToCron(model.expression)
            scope.levels = CronExpressionUtils.cronToLevels(scope.view.cron)

        scope.dayOfWeekSelected = ->
            return scope.levels?[0]?.replace('Every ', '') in CronExpressionUtils.DAYS

        scope.$watch 'levels[0]', (level0) ->
            return if not level0
            if level0 is 'Cron'
                delete scope.levels[1]
                return

            scope.view.cron ?= {}
            scope.view.cron.month = '*'

            if level0 is 'Every First Day of Month'
                scope.view.cron.dayOfMonth = 1
            else
                scope.view.cron.dayOfMonth = '*'

            scope.view.cron = CronExpressionUtils.normalizeCron(scope.view.cron or {})
            scope.view.cron.dayOfWeek = getCronSelectedDayIndex()
            scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
            scope.levels = CronExpressionUtils.cronToLevels(scope.view.cron)
            scope.view.minute = scope.view.cron?.minute

        scope.$watch 'levels[1]', (level1) ->
            return if not level1
            if level1 is 'At Time'
                scope.view.cron ?= {}
                scope.view.cron = CronExpressionUtils.normalizeCron(scope.view.cron)
                scope.view.cron.hour = 8 if scope.view.cron.hour is '*'
                scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
            if level1 is 'Every Hour'
                minute = scope.view.cron?.minute
                dayOfWeek = getCronSelectedDayIndex()
                scope.view.cron = CronExpressionUtils.normalizeCron({minute, dayOfWeek})
                scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
                scope.view.minute = 0

        scope.$watch 'view.cron', ((cron) ->
            return if not scope.model
            isInvalid = not cron or _.some _.values(cron), (x) -> _.isNull(x) or _.isUndefined(x)
            scope.view.cron = CronExpressionUtils.normalizeCron(cron) if not isInvalid
            scope.model.expression = do ->
                return scope.model.expression if scope.levels?[0] is 'Cron'
                return null if isInvalid
                return CronExpressionUtils.cronToExpression(cron)
            return if isInvalid or scope.levels[0] is 'Cron'
            scope.view.cron.dayOfWeek = getCronSelectedDayIndex()
            scope.view.cron.dayOfMonth = getCronSelectedDayOfMonth(scope.view.cron)

            scope.view.time = do ->
                return null if scope.levels[1] isnt 'At Time'
                return CronExpressionUtils.cronToTime(cron)
            scope.view.minute = scope.view.cron?.minute
            return if scope.levels[1] is 'At Time'
            scope.levels = CronExpressionUtils.cronToLevels(cron)
        ), true

        scope.$watch 'model.expression', (expression) ->
            scope.isInvalid = not expression
            return if not scope.model
            scope.view.cron = (try CronExpressionUtils.expressionToCron(expression)) or null
            if scope.view.cron
                scope.view.time = CronExpressionUtils.cronToTime(scope.view.cron)
                scope.view.minute = scope.view.cron.minute

        scope.$watch 'view.minute', (minute, prevMinute) ->
            return if not scope.model
            return if scope.levels[1] isnt 'Every Hour'
            before = minute
            minute = do ->
                return null if _.isUndefined(minute) or _.isNull(minute)
                result = parseInt(minute)
                return null if _.isNaN(result)
                return result
            scope.view.cron = CronExpressionUtils.normalizeCron({minute}) if not scope.view.cron
            scope.view.cron.dayOfWeek = getCronSelectedDayIndex()
            scope.view.cron.minute = minute

        scope.$watch 'view.time', (time) ->
            return if not scope.model
            return if scope.levels[1] isnt 'At Time'
            cronTime = CronExpressionUtils.timeToCron(time)
            return scope.view.cron = null if not cronTime
            {minute, hour} = cronTime
            dayOfWeek = getCronSelectedDayIndex()

            cronToNormalize = {hour, minute, dayOfWeek}
            cronToNormalize.dayOfMonth = getCronSelectedDayOfMonth(scope.view.cron)

            scope.view.cron = CronExpressionUtils.normalizeCron(cronToNormalize)

        scope.$watch 'isInvalid', (isInvalid) ->
            scope.errorMessage = do ->
                return "" if not isInvalid or not scope.levels
                return "Invalid minute, must be a number between 0 and 59" if scope.levels[1] is 'Every Hour'
                return "Invalid time, must be like '9am' or '6:45pm'" if scope.levels[1] is 'At Time'


module.constant 'RegularExpressions',
    EMAIL: new RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i)
    TIME:  new RegExp(/^\s*(((0?[1-9]|1[0-2])(:[0-5][0-9])?\s*(am|pm)))\s*$/i)
    CRON:  new RegExp(/^(0?[0-9]|[1-5][0-9]|\*) ([0-1]?[0-9]|2[0-3]|\*) ([0-1]?[0-9]|2[0-9]|3[0-1]|\*) (0?[0-9]|1[0-2]|\*) (0?[0-6]|\*)$/)


module.factory 'ListModel', ($q, Utils) ->
    class ListModel

        @MODES: {'SELECT', 'EDIT', 'CREATE'}

        constructor: (@tracker) ->
            throw new Error("Missing required `@_fetch` method in subclass.") if not @_fetch
            @viewState = {}

        init: ->
            @_fetch().then (x) => @setAvailable(x)

        refresh: ->
            @init()

        setAvailable: (available) ->
            @available = Utils.copy(available or [])

            if not @viewState or not @viewState.active
                selected = @available[0]?.id
                @selectById(selected)
                return

            @viewState.available = Utils.copy(@available)

            savedInAvailableIndex = do =>
                return -1 if not @viewState.saved
                return Utils.indexOf @viewState.available, (x) => x.id is @viewState.saved.id

            savedInAvailable = @viewState.available[savedInAvailableIndex]

            savedChanged = do =>
                # If we don't have the saved item...
                not savedInAvailable or \
                    # If we have the selected item but it has changed...
                    Utils.object.hash(@viewState.saved) is Utils.object.hash(savedInAvailable)

            if savedChanged
                @viewState.selected = savedInAvailable
                @viewState.saved = Utils.copy(@viewState.selected)
            else
                @viewState.saved = Utils.copy(savedInAvailable)
                @viewState.available[savedInAvailableIndex] = @viewState.selected

            if @viewState.mode isnt ListModel.MODES.CREATE
                @viewState.active = @viewState.selected

            delete @viewState.saved?.$$hashKey

        getActiveParams: ->
            return @viewState.active?.data

        reorder: (oldIndex, newIndex) ->
            return if oldIndex is newIndex

            @available = Utils.move(@available, oldIndex, newIndex)
            @_saveOrder @available

        select: (item) ->
            return @selectById(item.id) if item isnt null
            @viewState ?= {}
            @viewState.mode = ListModel.MODES.SELECT
            @viewState.selected = null
            @viewState.active = null
            @viewState.saved = null
            return null

        selectById: (id) ->
            available = Utils.copy(@available)
            selected = _.find available, (x) -> x.id is id
            return null if not selected
            @viewState ?= {}
            @viewState.mode = ListModel.MODES.SELECT
            @viewState.available = available
            @viewState.selected = selected
            @viewState.active = @viewState.selected
            @viewState.saved = Utils.copy(@viewState.selected)
            delete @viewState.saved?.$$hashKey
            delete @viewState.postEdit
            return Utils.copy(@viewState.selected)

        hasUnsavedChanges: ->
            return true if @viewState.mode is ListModel.MODES.CREATE
            return false if (@viewState.available or []).length is 0

            [selected, saved] = [@viewState.selected, @viewState.saved]

            return true if _.some [selected, saved], (x) -> not x

            [selected, saved] = [selected, saved].map (x) ->
                result = Utils.copy(x)
                delete result.$$hashKey
                delete result.updatedAt
                return result
            return Utils.object.hash(selected) isnt Utils.object.hash(saved)

        # Reset the selected item to the saved value
        reset: ->
            @select(@viewState.saved)

        edit: ({postEdit} = {}) ->
            @select(@viewState.active)
            @viewState.postEdit = postEdit
            return @viewState.active

        copy: ({postEdit} = {}) ->
            return if not @_copy
            @viewState.mode = ListModel.MODES.CREATE
            @viewState.active = @_copy(Utils.copy @viewState.active)
            @viewState.available = Utils.copy(@available)
            @viewState.selected = _.find @viewState.available, (x) => x.id is @viewState.selected.id
            @viewState.saved = Utils.copy(@viewState.selected)
            delete @viewState.saved?.$$hashKey
            @viewState.postEdit = postEdit
            return @viewState.active

        delete: (item) ->
            item ?= @viewState.active
            index = Utils.indexOf @viewState.available, (x) -> x.id is item.id
            return if index is -1
            @_delete(item).then =>
                @viewState.available = Utils.removeAt @viewState.available, index
                @available = Utils.copy(@viewState.available)
                @viewState.mode = ListModel.MODES.SELECT
                @viewState.selected = @viewState.available[0]
                @viewState.saved = Utils.copy(@viewState.selected)
                @viewState.active = @viewState.selected

        # Create a new item
        create: ({postEdit} = {}) ->
            return if not @_create
            @viewState.mode = ListModel.MODES.CREATE
            @viewState.postEdit = postEdit
            @viewState.active = @_create()
            @viewState.available = Utils.copy(@available)
            @viewState.selected = _.find(@viewState.available, (x) => x.id is @viewState.selected.id) or null
            @viewState.saved = (try Utils.copy(@viewState.selected)) or null
            delete @viewState.saved?.$$hashKey
            return @viewState.active

        # Cancel create
        cancel: ->
            @_cancel?()
            @select(@viewState.selected)

        _savePreprocess: ->
            return if not @hasUnsavedChanges()
            @viewState.mode = ListModel.MODES.SELECT
            item = @viewState.active
            delete item.$$hashKey
            activeIndex = Utils.indexOf @available, (x) -> x.id is item.id
            return [activeIndex, item]

        # Save changes to the selected, edited or created set
        save: ->
            [activeIndex, item] = @_savePreprocess()
            savePromise = do =>
                return @_save(item) if activeIndex is -1
                return @_update(item)
            savePromise
            .catch (error) ->
                console.error "Could not save item:", item
                throw error
            .then ([available, item]) =>
                @viewState.postEdit?(item)
                @setAvailable(available)
                @selectById(item.id)

        isInCreateMode: ->
            @viewState.mode is ListModel.MODES.CREATE


module.factory 'ReportTemplatesModel', ($q, Utils, PromiseGuard, SchedulingServiceAPI, ReportsModel, Currencies) ->

    class ReportTemplatesModel

        @Create: ->
            model = new ReportTemplatesModel()
            model.init().then -> return model

        @Fetch: do ->
            guard = new PromiseGuard()
            fetchJobs = ->
                (new SchedulingServiceAPI).then (api) -> api.jobs.list()
            return (reportsModelData) -> guard do ->
                $q.all([
                    fetchJobs()
                    Currencies.fetch()
                ]).then ([jobs, currencies]) ->
                    result = []
                    jobs = jobs.map (job) ->
                        id:          job.id
                        label:       job.label or 'Untitled'
                        description: job.description or ""
                        params: (job.data?.params or []).filter (x) ->
                            return true if x isnt 'currency'
                            return true if currencies.length > 1
                            return true if currencies?[0].id isnt 'usd'
                            return false
                        reports: new ReportsModel(job.id)
                    $q.all((jobs.map (x) -> x.reports.init())).then -> return jobs

        constructor: ->
            @id = Utils.uuid()

        init: -> ReportTemplatesModel.Fetch().then (available) =>
            @available = available or []
            @selected  = if @available.length is 1 then @available[0] else null

        refresh: -> ReportTemplatesModel.Fetch().then (available) =>
            @available = available
            @selected  = _.find(available, (x) -> x.id is @selected.id) or null
            @selected  = available[0] if @available.length is 1 and not @selected

        hasReports: ->
            for template in @available
                return true if template.reports?.viewState?.available?.length > 0
            return false

        selectedReportIsInvalid: ->
            not @selected?.reports.viewState.active and @selected?.reports.viewState.saved

        hasReportId: (reportId) ->
            report = @findReportById(reportId)
            return !!report

        findReportById: (reportId) ->
            for template in @available
                for report in template.reports.available
                    return report if report?.id is reportId
            return null

        select: (reportTemplate) ->
            @selected = reportTemplate

        selectByReportId: (reportId) ->
            if not reportId
                @selected = null
                return null
            for template in @available
                report = template.reports.selectById(reportId)
                continue if not report
                return @select(template)
            @selected = null
            return null


module.factory 'ReportsModel', ($q, Utils, StorageAPI, SchedulingServiceAPI, ListModel) ->
    class ReportsModel extends ListModel
        @STORAGE_KEY: 'reports'

        @Create: ->
            model = new ReportsModel(arguments...)
            model.init().then -> model

        @Fetch: (templateId) ->
            (new StorageAPI ReportsModel.STORAGE_KEY)
            .then((api) -> api.get())
            .then (templates) ->
                templates = null if _.isArray(templates)
                templates ?= {}
                reports = templates[templateId] or []
                return reports

        constructor: (templateId) ->
            super()
            throw new Error("Missing required `templateId` argument.") if not templateId
            @templateId = templateId

        _buildReportPayload: (report) ->
            unwantedFields = ['$$hashKey']

            return Object.keys(report).reduce((acc, key) ->
                if unwantedFields.indexOf(key) is -1
                    acc[key] = _.cloneDeep(report[key])

                return acc
            {})

        run: ->
            report = @_buildReportPayload(@viewState.active)
            (new SchedulingServiceAPI).then (api) -> api.jobs.run(report)

        _fetch: ->
            ReportsModel.Fetch(@templateId)

        _update: (report) ->
            throw new Error("Missing required `report` argument.") if not report
            report = Utils.copy(report)
            @__updateStorage((reports) ->
                index = Utils.indexOf reports, (x) -> x.id is report.id
                report.updatedAt = Date.now()
                reports[index] = report
                return reports
            ).then (reports) -> return [reports, Utils.copy(report)]

        _delete: (report) ->
            @__updateStorage((reports) ->
                index = Utils.indexOf reports, (x) -> x.id is report.id
                reports = Utils.removeAt reports, index
                return reports
            ).then (reports) -> [reports, true]

        _save: (report) ->
            @__updateStorage((reports) ->
                report = Utils.copy(report)
                report.createdAt = Date.now()
                report.updatedAt = Date.now()
                reports.unshift(report)
                return reports
            ).then (reports) -> return [reports, Utils.copy(report)]

        _copy: (report) ->
            id:          Utils.uuid()
            createdAt:   null
            updatedAt:   null
            templateId:  report.templateId
            description: report.description
            label:       "#{report.label} (COPY)"
            data:        report.data

        _create: ->
            id:          Utils.uuid()
            createdAt:   null
            updatedAt:   null
            templateId:  @templateId
            description: ''
            label:       'Untitled'
            data:        {}

        _saveOrder: (reports) ->
            @__updateStorage -> return reports

        __updateStorage: (cb) ->
            (new StorageAPI ReportsModel.STORAGE_KEY).then (api) =>
                api.get().then (templates) =>
                    templates = null if _.isArray(templates)
                    templates ?= {}
                    reports = templates[@templateId] or []
                    $q.when(cb reports).then (reports = []) =>
                        templates[@templateId] = reports
                        api.put(templates).then -> Utils.copy(reports)


module.service 'TIMEZONES', ($filter) ->
    WHITELIST = ['etc','america','europe']
    seen = {}
    result = []
    for timezone in moment.tz.names()
        [group, label] = timezone.split('/')
        label = group if not label
        skipFormatting = (label.toUpperCase() is label)
        if not seen[group+label]
            result.push({group, label:timezone, id:timezone})
            seen[group+label] = true
    result = result.filter (x) -> x.group.toLowerCase() in WHITELIST
    result = _.sortBy result, (x) -> x.label
    return result


module.service 'EmailAddressMultiFieldParser', (RegularExpressions) ->
    parse: (addresses) ->
        return null if not addresses
        tokens = do ->
            return addresses if _.isArray(addresses)
            return _.flatten (addresses or "").split('\n').map (x) -> x.split(',')
        return tokens.map((x) -> x.trim()).filter((x)-> x).map (x) ->
            value: x
            isValid: RegularExpressions.EMAIL.test(x)


module.factory 'SchedulesReportListModel', (Utils, ReportsModel, ReportTemplatesModel) ->

    class SchedulesReportListModel

        constructor: (reportTemplateData, reportsData) ->
            @reportTemplateData = reportTemplateData
            @reportsData = reportsData
            @available = []

        add: (index) ->
            model = new ReportTemplatesModel()
            model.available = Utils.copy(@reportTemplateData)
            model.available.forEach (job) =>
                job.reports = new ReportsModel(job.id)
                job.reports.setAvailable(Utils.copy @reportsData[job.id])
            index = @available.length if not _.isNumber(index)
            @available = Utils.insertAt(@available, index, model)
            return model

        push: ->
            @add()

        pop: ->
            @removeAt(@available.length-1)

        getReportDataById: (reportId) ->
            reports = _.flatten Object.keys(@reportsData).map (x) => @reportsData[x]
            reportId = [reportId] if not _.isArray(reportId)
            return _.compact reportId.map (id) -> _.find reports, (report) -> report.id is id

        remove: (reportTemplates) ->
            index = Utils.indexOf @available, (x) -> x.id is reportTemplates.id
            console.log "Removing `#{reportTemplates.id}` at index `#{index}`."
            if index is -1
                console.warn "Could not find report template `#{reportTemplates.id}`"
                return @
            return @removeAt(index)

        removeAt: (index) ->
            @available = Utils.removeAt(@available, Math.max(0, index))
            return @

        reset: ->
            @available = []
            return @


module.factory 'SchedulesModel', ($q, $timeout, Utils, TIMEZONES, SchedulingServiceAPI, EmailAddressMultiFieldParser, ListModel, SchedulesReportListModel, ReportsModel, ReportTemplatesModel) ->
    moment = require 'moment'

    serializeEmailAddresses = (addresses) ->
        EmailAddressMultiFieldParser.parse(addresses)?.filter((x) -> x.isValid).map((x) -> x.value)

    class SchedulesModel extends ListModel

        @Create: (reportTemplates) ->
            SchedulesModel.CreateReportTemplatesListModel(reportTemplates)
            .then (reportTemplatesList) ->
                model = new SchedulesModel(reportTemplatesList)
                model.init().then -> model

        @CreateReportTemplatesListModel: (reportTemplates) ->
            reportTemplatesDataPromise = do ->
                return $q.when(reportTemplates.available) if reportTemplates
                return ReportTemplatesModel.Fetch()
            reportTemplatesDataPromise
            .then (reportTemplateData) ->
                result = {}
                $q.all(reportTemplateData.map (template) ->
                    ReportsModel.Fetch(template.id).then (x) -> result[template.id] = x
                ).then -> [reportTemplateData, result]
            .then ([reportTemplateData, reportsData]) ->
                reportTemplatesList = new SchedulesReportListModel(reportTemplateData, reportsData)
                reportTemplatesList.add()
                return reportTemplatesList

        TIMEZONES: Utils.copy(TIMEZONES)

        constructor: (reportTemplatesList) ->
            super()
            @reportTemplatesList = reportTemplatesList
            @reportTemplates = @reportTemplatesList.available[0]

        getScheduleLink: (schedule) ->
            return '/reporting/schedules/' + schedule.id

        getInvalidFields: ->
            return [] if not @viewState.active
            result =
                'Timezone':         @viewState.active.timezone
                'Expression':       @viewState.active.expression
                'Report':           @viewState.active.data.reportId
                'Email Recipients': @viewState.active.data.target.recipients
                'Email BCC':        @viewState.active.data.target.bcc
            # 'Email Subject':    @viewState.active.data.target.subject
            # 'Email Body':       @viewState.active.data.target.body
            validKeys = Object.keys(result).filter (key) ->
                value = result[key]
                if key is 'Email Recipients'
                    value = serializeEmailAddresses(value)
                if key is 'Email BCC'
                    return _.every EmailAddressMultiFieldParser.parse(value), (x) -> x.isValid
                return value.length > 0 if _.isArray(value)
                return !!value if not _.isString(value)
                value = value.trim()
                return value.length > 0
            validKeys.forEach (key) -> delete result[key]
            return result

        isInvalid: ->
            Object.keys(@getInvalidFields()).length > 0

        hasUnsavedChanges: ->
            return true if @viewState.mode is ListModel.MODES.CREATE
            return false if (@viewState.available or []).length is 0
            [selected, saved] = [@viewState.selected, @viewState.saved].map (x) ->
                result = Utils.copy(x)
                ['recipients', 'cc', 'bcc'].forEach (key) ->
                    try
                        result.data.target[key] ?= ''
                        result.data.target[key] = result.data.target[key].join('\n') if _.isArray(result.data.target[key])
                        result.data.target[key] = result.data.target[key].trim()
                delete result.$$hashKey
                delete result.updatedAt
                delete result.active
                return result
            return Utils.object.hash(selected) isnt Utils.object.hash(saved)

        updateModelFromReportTemplates: ->
            @viewState.active.jobId = "metrics-breakdown"
            @viewState.active.data.reportId = @reportTemplatesList.available.map((template) ->
                return template.selected?.reports.viewState.selected.id
            ).filter((x) -> x)
            return

        updateReportTemplatesFromModel: (reportIds) ->
            reportIds = reportIds or @viewState.active?.data?.reportId
            reportIds = [reportIds] if not _.isArray(reportIds)
            @reportTemplatesList.reset()
            reportIds.forEach (reportId, index) =>
                @reportTemplatesList.add()
                reportTemplates = @reportTemplatesList.available[index]
                reportTemplates.selectByReportId(reportId)
            @reportTemplatesList.add() if reportIds.length is 0
            return

        run: ->
            $q.all([
                (new SchedulingServiceAPI),
                AuthServiceAPI.getUser(),
                AuthServiceAPI.getOrganization()
            ]).then ([api, user, organizationId]) =>
                schedule = @_serialize(@viewState.active, user.id, organizationId)
                api.schedules.run(schedule)

        _fetch: ->
            (new SchedulingServiceAPI)
            .then (api) ->
                $q.all([
                    api.schedules.list()
                    SchedulesModel.CreateReportTemplatesListModel()
                    AuthServiceAPI.getUser()
                ])
            .then ([schedules, templatesList, user]) =>
                schedules = schedules
                    .filter((x) -> x.data?.userId == user.id)
                    .map (x) =>
                        reportIds = x.data.reportId
                        reportIds = [reportIds] if not _.isArray(reportIds)
                        @reportTemplatesList = templatesList
                        @reportTemplatesList.reset()
                        x.data.reportId = reportIds.filter (reportId) =>
                            reportTemplates = @reportTemplatesList.push()
                            if reportTemplates.hasReportId(reportId)
                                reportTemplates.selectByReportId(reportId)
                                return true
                            # @reportTemplatesList.pop()
                            return false
                        @reportTemplatesList.push() if x.data.reportId.length is 0
                        return x
                return _.sortBy schedules, (x) -> -1 * moment.utc(x.createdAt).unix()

        _delete: (schedule) ->
            id = schedule.id
            (new SchedulingServiceAPI).then (api) -> api.schedules.delete(id)

        _serialize: (schedule, userId, organizationId) ->
            schedule = Utils.copy(schedule)
            schedule.jobId = "metrics-breakdown"
            schedule.data ?= {}
            schedule.data.target = try @_serializeTarget(schedule.data.target)
            schedule.data.userId = userId
            schedule.data.organizationId = organizationId
            schedule.data.reportId = _.compact @reportTemplatesList.available.map (x) ->
                x.selected?.reports?.viewState.selected.id
            return schedule

        _serializeTarget: (target) ->
            recipients: serializeEmailAddresses(target.recipients)
            bcc:        serializeEmailAddresses(target.bcc)
            body:       target.body?.trim() or ""
            # subject:    target.subject?.replace(/\n/g, ' ').trim()
            filename:   target.filename?.replace(/\n/g, ' ').trim()

        _save: (schedule) ->
            @_api schedule, 'create'

        _update: (schedule) ->
            @_api(schedule, 'update')

        _api: (schedule, method) ->
            error = null

            return $q.all([
                AuthServiceAPI.getUser(),
                AuthServiceAPI.getOrganization()
            ]).then ([user, organizationId]) =>
                try
                    schedule = @_serialize(schedule, user.id, organizationId)
                catch err
                    console.error "Could not serialize schedule for saving:", err
                    error = new Error "Could not serialize schedule for saving."
                error = new Error("Cannot save schedule: Missing `schedule.data.reportId` value.") if not schedule.data.reportId
                error = new Error("Cannot save schedule: Missing `schedule.jobId` value.")         if not schedule.jobId
                if error
                    deferred = $q.defer()
                    $timeout -> deferred.reject(error)
                    return deferred.promise
                (new SchedulingServiceAPI).then (api) ->
                    api.schedules[method](schedule)
                .then (schedule) =>
                    @_fetch().then (schedules) =>
                        @updateReportTemplatesFromModel()
                        return [schedules, schedule]

        _copy: (schedule) ->
            label:       schedule.label
            expression:  schedule.expression
            timezone:    schedule.timezone
            jobId:       schedule.jobId
            data:        Utils.copy(schedule.data)

        _create: ->
            @reportTemplatesList.reset()
            @reportTemplatesList.add()
            label:      "Untitled Schedule"
            expression: "30 8 * * *"
            timezone:   moment.tz.guess()
            jobId:      null
            active: 1
            data:
                userId: null
                reportId: []
                target: {}

        toggleActiveState: ->
            @viewState.active.active = do (schedule = @viewState.active) ->
                return 1 if schedule.active is 0
                return 0
            {active, saved} = @viewState
            delete active.$$hashKey
            saved = Utils.copy(saved)
            saved.active = active.active
            @_update(saved).then ([available, updated]) =>
                active.active = updated.active
                active.objectVersion = updated.objectVersion
                @viewState.postEdit?(active)
                @setAvailable(available)

        save: ->
            [activeIndex, item] = @_savePreprocess()
            active = null
            savePromise = do =>
                return @_save(item) if activeIndex is -1
                savedViewState = Utils.copy(@viewState.saved)
                active = @viewState.active
                @viewState.saved = Utils.copy(@viewState.active)
                return @_update(item).catch (error) =>
                    @viewState.saved = savedViewState
                    throw error
            savePromise
                .catch (error) ->
                    console.error "Could not save item:", item
                    throw error
                .then ([available, item]) =>
                    @viewState.postEdit?(item)
                    @setAvailable(available)
                    if active and active.id is item.id
                        active.objectVersion = item.objectVersion
                    else
                        @selectById(item.id)


module.directive 'reportParamsViewer', (ReportParamsFilterItemsModel, ReportParamsFilterStoresModel) ->
    restrict: "E"
    scope:
        params: "="
    replace: true
    template: \
    """
    <article class="report-params-container report-params-viewers">
        <report-params-viewer-filter ng-repeat="model in models.filters" model="model"></report-params-viewer-filter>
        <report-params-viewer-timerange params="params"></report-params-viewer-timerange>
        <report-params-viewer-hierarchy-store params="params"></report-params-viewer-hierarchy-store>
        <report-params-viewer-metric-select params="params"></report-params-viewer-metric-select>
    </article>
    """
    link: (scope) ->
        scope.models = {}
        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            scope.models =
                filters: [
                    new ReportParamsFilterItemsModel(params)
                    new ReportParamsFilterStoresModel(params)
                ]

module.directive 'reportParamsEditor', ($rootScope, ReportParamsFilterItemsModel, ReportParamsFilterStoresModel) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="report-params-editors" ng-if="model.selected.params.length > 0">
        <section class="report-params-editor-group" ng-repeat="availableParams in editorGroups">
            <div ng-repeat="paramType in availableParams" class="report-params">
                <ng-switch on="paramType">
                    <report-params-metric-select     params="params" ng-switch-when="metrics"></report-params-metric-select>
                    <report-params-column-properties params="params" ng-switch-when="columns"></report-params-column-properties>
                    <report-params-column-style      params="params" ng-switch-when="columnStyle"></report-params-column-style>
                    <report-params-timerange         params="params" datepicker-data="datepickerData" ng-switch-when="timerange"></report-params-timerange>
                    <report-params-hierarchy-store   params="params" ng-switch-when="hierarchyStore"></report-params-hierarchy-store>
                    <report-params-sort              params="params" ng-switch-when="sort"></report-params-sort>
                    <report-params-filter-stores     params="params" ng-switch-when="filterStores"></report-params-filter-stores>
                    <report-params-filter-items      params="params" ng-switch-when="filterItems"></report-params-filter-items>
                    <report-params-currency          params="params" ng-switch-when="currency"></report-params-currency>
                </ng-switch>
            </div>
        </section>
    </article>
    """
    link: (scope) ->
        scope.datepickerData = $rootScope.datepicker
        scope.$watch 'model.selected', (selected) ->
            available = selected?.params or []
            scope.editorGroups = [
                _.intersection(available, ['filterStores', 'filterItems'])
                _.difference(available, ['filterStores', 'filterItems'])
            ].filter (x) -> x.length > 0
        scope.$watch 'model.selected.reports.getActiveParams()', (params) ->
            scope.params = params

module.factory 'ReportParamsFilterModel', ($q, ReportSmartGroups) ->
    class ReportParamsFilterModel
        constructor: (@title, @descriptorRef, @filters) ->
            @descriptor = null
        init: ->
            @refresh()
        refresh: ->
            $q.when()
        reset: ->
            Object.keys(@filters).forEach (key) => delete @filters[key]
        editFilter: ->
            @descriptor = _.cloneDeep(@descriptorRef)
            ReportSmartGroups.model = @

module.factory 'ReportParamsFilterItemsModel', (SmartGroupFilters, ReportParamsFilterModel, CONFIG) ->
    return (params) ->
        throw new Error("Missing required `params` argument.") if not params
        descriptor = _.cloneDeep _.find SmartGroupFilters, (x) -> x.id is 'items'
        params.filterItems ?= {}
        label = CONFIG.items?.label or "Item Filter"
        return new ReportParamsFilterModel(label, descriptor, params.filterItems)

module.factory 'ReportParamsFilterStoresModel', (SmartGroupFilters, ReportParamsFilterModel, CONFIG) ->
    return (params) ->
        throw new Error("Missing required `params` argument.") if not params
        descriptor = _.cloneDeep _.find SmartGroupFilters, (x) -> x.id is 'stores'
        params.filterStores ?= {}
        label = CONFIG.stores?.label or "Store Filter"
        return new ReportParamsFilterModel(label, descriptor, params.filterStores)

module.service 'QueryObjectFilterUtils', (Utils) ->
    deleteValue: (filters, x) ->
        throw new Error("Missing required `value.collection` property.") if not x.collection
        throw new Error("Missing required `value.value` property.")      if not x.value
        collection = filters[x.collection]?.$and
        return if not collection
        propertyIndex = Utils.indexOf collection, (group) -> Object.keys(group)?[0] is x.property
        return if propertyIndex is -1
        property = do ->
            key = Object.keys(collection[propertyIndex])
            return collection[propertyIndex]?[key]?.$in
        return if not property
        valueIndex = Utils.indexOf property, (value) -> value is x.value
        return if valueIndex is -1
        property.splice(valueIndex, 1)
        collection.splice(propertyIndex, 1) if property.length is 0
        delete filters[x.collection]  if collection.length is 0
        return filters
    getValues: (filters) ->
        return [] if _.isUndefined(filters)
        return Object.keys(filters).reduce ((result, collection) ->
            (filters[collection]?.$and or []).forEach (group) ->
                property = Object.keys(group)?[0]
                values = (group[property]?.$in or [])
                values.forEach (value) -> result.push({collection, property, value})
            return result
        ), []

module.directive 'reportParamsFilterSelected', (Utils, Hierarchy, QueryObjectFilterUtils) ->
    restrict: 'E'
    scope:
        filters: '='
    replace: true
    template: \
    """
    <article class="report-params-filter-selected">
        <ul class="pellets">
            <li class="pellet" ng-repeat="x in view.selected" ng-click="delete(x)">
                <span class="property">{{ x.label }}</span> |
                <span class="value">{{ x.value }}</span>
                <i class="close icon-cancel-circled"></i>
            </li>
        </ul>
    </article>
    """
    link: (scope) ->
        filtersHaveChanged = do ->
            prevFilters = null
            return ->
                currentFilters = scope.filters
                result = (not prevFilters) or (Utils.object.hash(currentFilters) is Utils.object.hash(prevFilters))
                prevFilters = Utils.copy(currentFilters)
                return result

        # This mega function deletes an entry from a query object filter
        scope.delete = (x) ->
            QueryObjectFilterUtils.deleteValue(scope.filters, x)

        getHierarchyById = do ->
            cache = null
            return ->
                cache ?= Hierarchy.fetch().then ({all}) -> _.keyBy(all, (x) -> x.id)
                return cache.then (x) -> Utils.copy(x)

        getSelected = ->
            selected = QueryObjectFilterUtils.getValues(scope.filters)
            selected.forEach (x) ->
                x.id = "#{x.collection}.#{x.property}"
                x.label = x.collection
            getHierarchyById().then (hierarchy) ->
                selected.forEach (x) -> x.label = hierarchy[x.id]?.label or x.label
                return selected
            .catch (error) ->
                console.error "Could not get hierarchy to update filter display:"
                console.error error
                return selected

        scope.view = {}
        scope.$watch filtersHaveChanged, ->
            getSelected().then (x) -> scope.view.selected = x

module.constant 'PreventScrollEventBubblingEventHandler', (e) ->
    # Usage:
    # $(element).on 'DOMMouseScroll mousewheel', PreventScrollEventBubblingEventHandler
    up = false
    up = e.originalEvent.wheelDelta / -1 < 0 if e.originalEvent?.wheelDelta
    up = e.originalEvent.deltaY < 0          if e.originalEvent?.deltaY
    up = e.originalEvent.detail < 0          if e.originalEvent?.detail

    prevent = ->
        e.stopPropagation()
        e.preventDefault()
        e.returnValue = false
        return false

    if not up and @scrollHeight <= $(this).innerHeight() + @scrollTop + 1
        prevent()
    else if up and 0 >= @scrollTop - 1
        prevent()

    return

module.filter 'reportParamsMetricSelectAvailableFilter', -> (metricGroups, filter) ->
    filter = filter.toLowerCase().trim()
    return metricGroups if not filter
    return metricGroups.filter (metricGroup) ->
        value = metricGroup.label.toLowerCase().trim()
        filterIndex = 0
        for c in value
            filterIndex++ if c is filter[filterIndex]
            return true if filterIndex is filter.length
        return false

module.directive 'reportParamsMetricSelect', ($q, $filter, Utils, promiseTracker, ReportingState, ReportParamsMetricSelectModel, PreventScrollEventBubblingEventHandler) ->
    moved = false

    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-metric-select" style="position:relative" promise-tracker="reportParamsMetricSelect">
        <header>
            <h1>What metrics should be included?</h1>
        </header>
        <main>
            <section class="available">
                <header>
                    <div class="row row-title">
                        <div class="search-container" ng-class="{filtered:view.filter.length > 0}">
                            <i class="icon-clear-filter icon-cancel-circled" ng-click="view.filter = ''"></i>
                            <input type="text" placeholder="Filter..." ng-model="view.filter"></input>
                            <i class="icon-search"></i>
                        </div>
                    </div>
                    <div class="row row-info">
                        <span class="info available-info">{{ getAvailableInfo() }}</span>
                        <button class="button-bare" ng-click="actions.selectAll()" ng-if="view.available.length > 0">select all</button>
                    </div>
                </header>
                <main>
                    <ul class="metric-groups">
                        <li class="metric-group"
                        ng-repeat="metricGroup in view.available track by metricGroup.id"
                        ng-click="actions.select(metricGroup)">
                            <div class="metrics-container">
                                <span class="label">{{ metricGroup.label }}</span>
                                <span class="metrics">
                                    <span class="metric" ng-repeat="metric in metricGroup.metrics track by metric.field">{{ metric.headerName }}</span>
                                </span>
                                <i class="move-icon move-icon-right icon-right-open-mini"></i>
                            </div>
                        </li>
                    </ul>
                </main>
            </section>
            <section class="selected">
                <header>
                    <div class="row row-title">
                        <h1>Selected</h1>
                    </div>
                    <div class="row row-info">
                        <span class="info selected-info" ng-if="model.selected.length > 1">{{ model.selected.length }} metrics selected</span>
                        <span class="info selected-info" ng-if="model.selected.length == 1">{{ model.selected.length }} metric selected</span>
                        <span class="info selected-info" ng-if="model.selected.length == 0">Click a metric on the left to select it</span>
                        <button class="button-bare" ng-click="actions.reset()" ng-if="model.selected.length > 0">reset</button>
                    </div>
                </header>
                <main>
                    <ul class="metric-groups"
                        dnd-list="model.selected"
                        dnd-drop="drop(event, index, item)"
                        dnd-horizontal-list="false">
                        <li class="metric-group"
                            dnd-draggable="metricGroup"
                            dnd-effect-allowed="move"
                            dnd-moved="moved($index, event)"
                            ng-repeat="metricGroup in model.selected track by metricGroup.id">
                            <div dnd-nodrag>
                                <div class="metrics-container" ng-click="actions.remove(metricGroup)">
                                    <i class="move-icon move-icon-left icon-left-open-mini"></i>
                                    <span class="label">{{ metricGroup.label }}</span>
                                    <span class="metrics">
                                        <span class="metric" ng-repeat="metric in metricGroup.metrics track by metric.field">{{ metric.headerName }}</span>
                                    </span>
                                </div>
                                <i dnd-handle class="drag-icon icon-menu"></i>
                            </div>
                        </li>
                    </ul>
                </main>
            </section>
        </main>
    </article>
    """
    link: (scope, element) ->

        scope.drop = (event, nextIndex, item) ->
            currentIndex = Utils.indexOf scope.model.selected, (x) -> x.id is item.id
            # if the property is in the list already, then we move it
            if currentIndex >= 0
                moved = true
                # The `nextIndex` that's returned by the framework will be wrong if the item is moved
                # after it's current location because it is counting the index of the placeholder element.
                nextIndex = nextIndex - 1 if nextIndex > currentIndex
                scope.model.selected = Utils.move(scope.model.selected, currentIndex, nextIndex)
            # Otherwise we just insert the element.
            else
                moved = false
                scope.model.selected = Utils.insertAt(scope.model.selected, nextIndex, item)
            return true

        scope.moved = (index) ->
            scope.model.selected = Utils.removeAt(scope.model.selected, index) if not moved
            moved = false
            return true

        watchers = []
        tracker = promiseTracker('reportParamsMetricSelect')

        scope.view = {filter:"", available:[]}

        listElements =
            available: $($(element).find('.available main'))
            selected:  $($(element).find('.selected main'))

        _.values(listElements).forEach (x) ->
            x.on('DOMMouseScroll mousewheel', PreventScrollEventBubblingEventHandler)

        scope.$on '$destroy', ->
            _.values(listElements).forEach (x) -> x.off('DOMMouseScroll mousewheel')

        updateAvailableView = ->
            scope.view.available = do ->
                return [] if _.isUndefined(scope.model?.available)
                available = scope.model.available.filter (x) -> not scope.model.isSelected(x)
                return $filter('reportParamsMetricSelectAvailableFilter')(available, scope.view.filter)

        scope.getAvailableInfo = ->
            {view, model} = scope
            metrics = if view.available.length is 1 then "metric" else "metrics"
            filtered = if view.filter then "found (#{ model.available.length - model.selected.length - view.available.length } filtered)" else "available"
            count = if view.available.length is 0 then "No" else view.available.length
            return "#{count} #{metrics} #{filtered}"

        scope.actions =
            reset: ->
                scope.model.reset()
                updateAvailableView()
            remove: (item) ->
                scope.model.remove(item)
                updateAvailableView()
            select: (item) ->
                scope.model.select(item)
                updateAvailableView()
                setTimeout (-> listElements.selected.scrollTop(listElements.selected[0].scrollHeight)), 0
            selectAll: ->
                scope.view.available.forEach (x) -> scope.model.select(x)
                updateAvailableView()

        scope.$watch 'view.filter', updateAvailableView

        scope.$watch 'model.selected.length', ->
            return if not scope.model
            ReportingState.report.updateInvalidFields(scope.model.getInvalidFields())

        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            watchers.forEach (x) -> x() # unregisters the existing watchers
            watchers = []

            modelPromise = do ->
                if not scope.model or scope.model.initialized == false
                    scope.model = new ReportParamsMetricSelectModel(params)
                    scope.model.init()
                else
                    scope.model.reset()
                    scope.model.params = params
                    scope.model.updateModelFromParams()
                    return $q.when()

            tracker.addPromise(modelPromise)

            modelPromise.then ->
                updateAvailableView()
                watchers.push \
                scope.$watch 'model.selected', ((properties) ->
                    return if _.isUndefined(properties)
                    scope.model.updateParamsFromModel()
                ), true
                watchers.push \
                scope.$watch 'params.metrics', (->
                    scope.model.updateModelFromParams()
                ), true
                watchers.push \
                scope.$watch 'params.currency', ((currency) ->
                    return if not currency
                    scope.model.refresh().then(updateAvailableView)
                ), true

module.directive 'reportParamsFilterStores', ($rootScope, ReportParamsFilterStoresModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params-filter-stores">
        <report-params-filter model="model"></report-params-filter>
    </article>
    """
    link: (scope) ->
        scope.model = null
        scope.hierarchyModel = $rootScope.hierarchyModel
        scope.$watch 'hierarchyModel.selected', (selected) ->
            return if not selected
            scope.model?.reset()
        scope.$watch 'params', (params) ->
            scope.model = do ->
                return null if not params
                return new ReportParamsFilterStoresModel(params)

module.directive 'reportParamsFilterItems', ($rootScope, ReportParamsFilterItemsModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params-filter-items">
        <report-params-filter model="model"></report-params-filter>
    </article>
    """
    link: (scope) ->
        scope.model = null
        scope.hierarchyModel = $rootScope.hierarchyModel
        scope.$watch 'hierarchyModel.selected', (selected) ->
            return if not selected
            scope.model?.reset()
        scope.$watch 'params', (params) ->
            scope.model = do ->
                return null if not params
                return new ReportParamsFilterItemsModel(params)

module.directive 'reportParamsFilter', (Utils) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-filter">
        <header>
            <h1>{{ model.title }}</h1>
            <section class="hint-right">
                <button class="button-edit-filters" ng-click="model.editFilter()">
                    <i class="icon-pencil"></i>
                    Edit Filter
                </button>
                <button class="button-bare action" ng-click="model.reset()">reset</button>
            </section>
        </header>
        <main>
            <report-params-filter-selected filters="model.filters"></report-params-filter-selected>
        </main>
    </article>
    """

module.factory 'UISelectDirective', ($window, OutsideElementClick, PreventScrollEventBubblingEventHandler) -> (templateFn) ->
    restrict: "E"
    scope:
        available: "="
        selected:  "="
    replace: true
    template: \
    """
    <article class="ui-select2" ng-class="{active:view.active}">
        <section class="faceplate" ng-click="view.toggle()">
            <div class="selected">#{templateFn('selected')}</div>
            <i class="icon-down-open-mini"></i>
        </section>
        <section class="popup">
            <ul><li bindonce="item" ng-repeat="item in available track by item.id" ng-click="view.select(item)">
            #{templateFn('item')}
            </li>
            </ul>
        </section>
    </article>
    """
    link: (scope, element) ->
        $faceplate = $(element).find('.faceplate')
        $popup     = $(element).find('.popup')

        $popup.on 'DOMMouseScroll mousewheel', PreventScrollEventBubblingEventHandler
        scope.$on '$destroy', -> $popup.off('DOMMouseScroll mousewheel')

        OutsideElementClick scope, element, ->
            return if not scope.view.active
            scope.view.active = false

        updateWidth = ->
            $faceplate.width do ->
                return 'auto' if not scope.view.active
                return $faceplate.width() if not $window.getComputedStyle
                style = $window.getComputedStyle($faceplate[0])
                styles = ['padding-left', 'padding-right', 'border-left-width', 'border-right-width']
                width = parseFloat(style.width.replace('px', ''))
                return styles.reduce ((sum, key) ->
                    value = style[key]
                    return sum if not value
                    value = parseFloat value.replace('px', '')
                    value = 0 if _.isNaN(value)
                    return sum - value
                ), width
            return

        scope.view =
            active: false
            toggle: ->
                scope.view.active = !scope.view.active
                updateWidth()
            select: (item) ->
                scope.selected = item
                scope.view.active = false
            isSelected: (item) ->
                scope.selected is item

module.directive 'reportParamsColumnStyle', ($q, ReportParamsColumnStyleModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-column-style">
        <header>
            <h1>What's the report format?</h1>
        </header>
        <main>
            <section class="layouts">
                <ul>
                    <li class="layout layout-{{ layout.id }}" ng-repeat="layout in model.available"
                        ng-click="view.select(layout)"
                        ng-class="{selected:view.isSelected(layout)}">
                        <span class="label">{{ layout.label }}</span>
                        <section class="example">
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                            <div class="row"><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div></div>
                        </section>
                        <span class="description">{{ layout.description }}</span>
                    </li>
                </ul>
            </section>
        </main>
    </article>
    """
    link: (scope) ->
        scope.view =
            select: (item) ->
                scope.model.selected = item
            isSelected: (item) ->
                scope.model.selected is item

        watchers = []
        scope.$watch 'params', (params) ->
            return if not params
            scope.model = new ReportParamsColumnStyleModel(params)
            scope.model.updateModelFromParams()
            watchers.forEach (x) -> x()
            watchers.push scope.$watch 'model.selected', ->
                scope.model.updateParamsFromModel()

module.factory 'ReportParamsColumnStyleModel', ->

    class ReportParamsColumnStyleModel

        constructor: (@params) ->
            @selected = null
            @available = [
                {
                    id:          "compact"
                    label:       "Compact"
                    description: "In one column"
                }
                {
                    id:          "tabular"
                    label:       "Tabular"
                    description: "In separate columns"
                }
                {
                    id:          "pivot"
                    label:       "Pivot"
                    description: "Best for pivot table"
                }
                {
                    id:          "csv"
                    label:       "CSV"
                    description: "Best for lots of rows"
                }
            ]

        updateModelFromParams: ->
            id = @params.columnStyle
            @selected = _.find(@available, (x) -> x.id is id) or @available[0]

        updateParamsFromModel: ->
            @params.columnStyle = @selected.id

module.directive 'reportParamsColumnProperties', (ReportParamsColumnPropertiesModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-column-properties">
        <header>
            <h1>How should the report's columns be broken down?</h1>
        </header>
        <main>
            <ul class="pellets">
                <li class="pellet null" ng-click="select()" ng-class="{selected:!model.selected}">None</li>
                <li class="pellet" ng-click="select(item)" ng-class="{selected:item === model.selected}" ng-repeat="item in model.available">
                    {{ item.label }}
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope) ->
        scope.select = (item) ->
            scope.model.selected = item or null
            scope.model.updateParamsFromModel()

        scope.$watch 'params', (params) ->
            if not scope.model
                scope.model = new ReportParamsColumnPropertiesModel(params)
                scope.model.init()
            else
                scope.model.params = params
                scope.model.updateModelFromParams()

module.directive 'reportParamsSortFieldSelect', (UISelectDirective) ->  UISelectDirective (x) ->
    if x is 'selected'
        """
        <article class="field" ng-class="{first:$index && #{x}.group != available[$index-1].group}">
            <span class="group">{{ #{x}.group }}</span>
            <span class="label">{{ #{x}.label }}</span>
        </article>
        """
    else
        """
        <article class="field" bindonce="#{x}" bo-class="{first:$index && #{x}.group != available[$index-1].group}">
            <span class="group" bo-text="#{x}.group"></span>
            <span class="label" bo-text="#{x}.label"></span>
        </article>
        """

module.directive 'reportParamsSortOrderSelect', (UISelectDirective) ->  UISelectDirective (x) ->
    """
    <article class="order">
        <span class="label">{{ #{x}.label }}</span>
    </article>
    """

module.directive 'reportParamsSort', ($q, Utils, ReportParamsSortModel) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-sort">
        <header>
            <h1>How should the report be sorted and limited?</h1>
        </header>
        <main ng-if="!model.selected">
            <p>{{ view.errorMessage }}</p>
        </main>
        <main ng-if="model.selected && view.mode == MODES.linked">
            <article class="sort-select">
                <div class="selectors">
                    <div class="selector field-select">
                        <report-params-sort-field-select available="model.selected[0].field.available" selected="model.selected[0].field.selected"></report-params-sort-field-select>
                    </div>
                    <div class="selector order-select">
                        <report-params-sort-order-select available="model.selected[0].order.available" selected="model.selected[0].order.selected"></report-params-sort-field-select>
                    </div>
                    <div class="selector limit">
                        <label>
                            <span>limit:</span>
                            <input type="number" step="1" min="0" ng-model="model.selected[0].limit" placeholder="n/a"></input>
                        </label>
                    </div>
                </div>
            </article>
            <button class="button-bare" ng-if="model.selected.length > 1" ng-click="setMode(MODES.separate)">
                Click here to set sort order and limit for each property separately...
            </button>
        </main>
        <main ng-if="model.selected && view.mode == MODES.separate">
            <article class="sort-select" ng-repeat="item in model.selected track by item.property.id">
                <div class="property">
                    <span class="index">{{ $index + 1 }}.</span>
                    <span class="label">{{ item.property.label }}</span>
                </div>
                <div class="selectors">
                    <div class="selector field-select">
                        <report-params-sort-field-select available="item.field.available" selected="item.field.selected"></report-params-sort-field-select>
                    </div>
                    <div class="selector order-select">
                        <report-params-sort-order-select available="item.order.available" selected="item.order.selected"></report-params-sort-field-select>
                    </div>
                    <div class="selector limit">
                        <label>
                            <span>limit:</span>
                            <input type="number" step="1" min="0" ng-model="item.limit" placeholder="n/a"></input>
                        </label>
                    </div>
                </div>
            </article>
            <button class="button-bare" ng-if="model.selected.length > 1" ng-click="setMode(MODES.linked)">
                Click here to set sort order for all properties at the same time...
            </button>
        </main>
    </article>
    """
    link: (scope) ->
        scope.MODES = {'linked', 'separate'}
        scope.view  = {mode:scope.MODES.linked}

        scope.$watch 'model.missing', ((missing)-> scope.view.errorMessage = do ->
            return "" if not missing
            items = []
            items.push('how the report should be broken down') if missing.properties
            items.push('which metrics should be included') if missing.metrics
            return "You must first specify #{items.join(' and ')}."
        ), true

        updateModelFromParams = ->
            if not scope.model
                scope.model = new ReportParamsSortModel(scope.params)
                scope.model.init().then -> scope.model.updateModelFromParams()
            else
                scope.model.params = scope.params
                scope.model.updateModelFromParams()
                return $q.when()

        updateModeFromSelected = ->
            field = _.union scope.model.selected.map (x) -> x.field.selected.id
            order = _.union scope.model.selected.map (x) -> x.order.selected.id
            limit = _.union scope.model.selected.map (x) ->
                return "null" if _.isNull(x.limit) or _.isUndefined(x.limit)
                return x.limit.toString()
            scope.view.mode = do ->
                return scope.MODES.linked if field.length is 1 and order.length is 1 and limit.length is 1
                return scope.MODES.separate

        updateSelectedFromMode = ->
            if scope.view.mode is scope.MODES.linked
                selected = scope.model.selected[0]
                return if not selected
                scope.model.selected.forEach (item) ->
                    item.limit = selected?.limit
                    if item.property.id.indexOf('calendar.') is 0
                        item.field.selected = _.find item.field.available, (x) -> x.id is 'property'
                        item.order.selected = _.find item.order.available, (x) -> x.id is 1
                    else
                        item.field.selected = _.find item.field.available, (x) -> x.id is selected.field.selected.id
                        item.order.selected = _.find item.order.available, (x) -> x.id is selected.order.selected.id
                updateModeFromSelected()
            return if not scope.model
            scope.model.updateParamsFromModel()

        scope.setMode = (mode) ->
            scope.view.mode = mode
            updateSelectedFromMode()

        scope.modelHasChanged = ->
            return null if not (scope.model and scope.model.selected)
            return Utils.object.hash(scope.model.selected)

        scope.$watch 'modelHasChanged()', ->
            if not (scope.model and scope.model.selected)
                scope.view.mode = null
                return
            updateModeFromSelected() if not scope.view.mode
            updateSelectedFromMode()

        scope.$watch 'params.hierarchyStore', (properties) ->
            return if _.isUndefined(scope.params) or _.isUndefined(properties)
            updateModelFromParams()

        scope.$watch 'params.metrics', (metrics) ->
            return if _.isUndefined(scope.params) or _.isUndefined(metrics)
            updateModelFromParams()

module.factory 'ReportParamsSortModel', (Utils, SchedulingHierarchy) ->

    class ReportParamsSortModel

        constructor: (@params) ->
            @selected = null
            @missing = null

        init: ->
            @refresh()

        refresh: ->
            SchedulingHierarchy.fetch().then (x) => @properties = x

        updateModelFromParams: ->

            selected =
                metrics: (@params.metrics or []).map (x) ->
                    return {id:x.field, group:x.headerGroup, label:x.headerName or "TY", field:x.field}
                properties: do =>
                    propertiesById = _.keyBy @properties, (x) -> x.id
                    propertyIds = @params.hierarchyStore or []
                    return Utils.copy propertyIds.map((id) -> propertiesById[id]).filter((x)->x)

            @missing =
                properties: selected.properties.length is 0
                metrics: selected.metrics.length is 0

            if @missing.metrics or @missing.properties
                @selected = null
                return

            @selected = selected.properties.map (property) =>
                existing = _.find @params.sort, (x) -> x.property is property.id

                isTimeProperty = property.id.indexOf('calendar.') is 0 \
                    or property.id.indexOf('calendar_periods.') is 0 \
                    or property.id.indexOf('transactions.timestamp__hour') is 0

                available = do ->
                    label = if isTimeProperty then "Chronological" else "Alphanumeric"
                    fields: [{id:"property", group:"Property", label, field:property.id}].concat(Utils.copy selected.metrics)
                    orders: [{id:1, label:"Ascending", order:1}, {id:-1, label:"Descending", order:-1}]

                property: property

                field:
                    available: available.fields
                    selected: do ->
                        defaultValue = do ->
                            return available.fields[0] if isTimeProperty
                            return available.fields[1]
                        return defaultValue if not existing
                        return _.find(available.fields, (x) -> x.field is existing.field) or defaultValue

                order:
                    available: available.orders
                    selected: do ->
                        defaultValue = do ->
                            return available.orders[0] if isTimeProperty
                            return available.orders[1]
                        return defaultValue if not existing
                        return _.find(available.orders, (x) -> x.order is existing.order) or defaultValue

                limit: existing?.limit or null

        updateParamsFromModel: ->
            @params.sort = do =>
                return null if not @selected
                return @selected.map ({property, field, order, limit}) ->
                    property: property.id
                    field:    field.selected.field
                    order:    order.selected.order
                    limit:    limit

module.directive 'reportParamsViewerMetricSelect', (ReportParamsMetricSelectModel, $q) ->
    restrict: 'E'
    scope:
        params: '='
    replace: true
    template: \
    """
    <article class="report-params-viewer report-params-metric-select-viewer" ng-if="view.metrics && view.metrics.length > 0">
        <article class="report-params">
            <header>
                <h1>Selected Metrics</h1>
            </header>
            <main>
                <ul class="pellets">
                    <li class="pellet" ng-repeat="x in view.metrics">
                        <span class="label">{{ x.label }}</span> |
                        <span class="metrics">
                            <span class="metric" ng-repeat="metric in x.metrics">{{ metric.headerName }}</span>
                        </span>
                    </li>
                </ul>
            </main>
        </article>
    </article>
    """
    link: (scope) ->
        scope.view = {metrics:[]}
        scope.$watch 'params', (params) ->
            if _.isUndefined(params)
                scope.view.metrics = []
                return
            modelPromise = do ->
                if not scope.model
                    scope.model = new ReportParamsMetricSelectModel(params)
                    scope.model.init()
                else
                    scope.model.reset()
                    scope.model.params = params
                    scope.model.updateModelFromParams()
                    return $q.when()
            modelPromise.then ->
                scope.view.metrics = scope.model.selected

module.directive 'reportParamsViewerFilter', (Utils, QueryObjectFilterUtils, Hierarchy) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="report-params-filter report-params-viewer-filter" ng-if="view.selected && view.selected.length > 0">
        <article class="report-params">
            <header>
                <h1>{{ model.title }}</h1>
            </header>
            <main>
                <article class="report-params-filter-selected">
                    <ul class="pellets">
                        <li class="pellet" ng-repeat="x in view.selected">
                            <span class="property">{{ x.label }}</span> |
                            <span class="value">{{ x.value }}</span>
                        </li>
                    </ul>
                </article>
            </main>
        </article>
    </article>
    """
    link: (scope, $q) ->

        filtersHaveChanged = do ->
            prevFilters = null
            return ->
                currentFilters = scope.model.filters
                result = (not prevFilters) or (Utils.object.hash(currentFilters) is Utils.object.hash(prevFilters))
                prevFilters = Utils.copy(currentFilters)
                return result

        getHierarchyById = do ->
            cache = null
            return ->
                cache ?= Hierarchy.fetch().then ({all}) -> _.keyBy(all, (x) -> x.id)
                return cache.then (x) -> Utils.copy(x)

        getSelected = ->
            selected = QueryObjectFilterUtils.getValues(scope.model.filters)
            selected.forEach (x) ->
                x.id = "#{x.collection}.#{x.property}"
                x.label = x.collection
            getHierarchyById().then (hierarchy) ->
                selected.forEach (x) -> x.label = hierarchy[x.id]?.label or x.label
                return selected
            .catch (error) ->
                console.error "Could not get hierarchy to update filter display:"
                console.error error
                return selected

        scope.view = {}
        scope.$watch filtersHaveChanged, ->
            getSelected().then (x) -> scope.view.selected = x

module.directive 'reportParamsViewerTimerange', ($rootScope, ReportParamsTimerangeModel) ->
    restrict: "E"
    scope: params: "="
    replace: true
    template: \
    """
    <article class="report-params report-params-timerange">
        <header>
            <h1>What's the time range?</h1>
        </header>
        <span class="pellet">
            {{ view.timerange }}
            <span ng-if="model.mode && model.mode.id != 'year'">| {{ model.mode.label }} Comparison</span>
        </span>
    </article>
    """
    link: (scope) ->
        moment = require 'moment'
        watchers = []
        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            datePickerData = _.cloneDeep $rootScope.datepicker
            scope.model = new ReportParamsTimerangeModel(params, datePickerData)
            watchers.forEach (x) -> x()
            watchers = []
            watchers.push scope.$watch 'params.timerange', ->
                scope.model.updateModelFromParams()
            watchers.push scope.$watch 'model.timerange', ->
                scope.model.updateParamsFromModel()
                scope.view = {}
                scope.view.timerange = do ->
                    return scope.model.timerange.label if scope.model.timerange.id isnt 'custom'
                    [start, end] = [scope.model.datepicker.selection.$gte, scope.model.datepicker.selection.$lt].map (x) -> moment.utc(x)
                    end.subtract(1, 'day')
                    [start, end] = [start, end].map (x) -> x.format('MMM DD, YYYY')
                    return "Custom Timerange | #{start} to #{end}"

module.directive 'reportParamsTimerangeSelectXFromNow', (ReportingState, ReportParamsTimerangeXFromNowDisplay) ->
    restrict: "E"
    scope:
        model: "="
    replace: true
    template: \
    """
    <article class="report-params-timerange-select-x-from-now">
        <header>
            <article class="start-date">
                How many
                <select ng-options="x.label for x in model.start.offset.unit.available" ng-model="model.start.offset.unit.selected"></select>
                from the last complete day, <em>{{ display.now }}</em>?
                <article class="input-offset-count-container">
                    <input class="input-offset-count" type="number" ng-model="model.start.offset.count"></input>
                    <span>{{ model.start.offset.unit.selected.label.toLowerCase() }}</span>
                </article>
            </article>
        </header>
        <article class="display" ng-if="display.isValid()">
            <h1>
                Time Range Preview
                <span class="hint">(when used in a schedule, this time range will update automatically)</span>
            </h1>
            <article ng-if="display.isValid()" class="display-content display-timerange">
                <span class="start">{{ display.start }}</span>
                to
                <span class="end">{{ display.end }}</span>
            </article>
            <article ng-if="!display.isValid()" class="display-content display-error">
                Enter the number of {{ model.start.offset.unit.selected.label.toLowerCase() }} to get a preview
            </article>
        </article>
    </article>
    """
    link: (scope) ->
        scope.display = null

        updateDisplay = _.throttle (->
            scope.display = new ReportParamsTimerangeXFromNowDisplay(scope.model)
        ), 50

        updateModel = ->
            scope.model.updateSelectorModels()
            ReportingState.report.updateInvalidFields(scope.model.getInvalidFields())

        # We debounce the params update otherwise we get lag when using arrow keys to change unit input
        updateParamsFromModel = _.debounce (->
            scope.model.updateParamsFromModel()
        ), 50

        update = ->
            return if not scope.model
            updateModel()
            updateParamsFromModel()
            updateDisplay()

        scope.$watch 'model.start.offset', update, true
        scope.$on '$destroy', ->
            fields = scope.model.getInvalidFields()
            Object.keys(fields).forEach (k) -> fields[k] = false
            ReportingState.report.updateInvalidFields(fields)


module.directive 'reportParamsTimerange', ($timeout, Utils, ReportParamsTimerangeModel) ->
    restrict: "E"
    scope:
        params: "="
        datepickerData: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-timerange">
        <header>
            <h1>What's the time range?</h1>
        </header>
        <select ng-options="x as (x.label) group by (x.group) for x in model.timeranges" ng-model="model.timerange"></select>
        <section class="custom-timerange" ng-if="model.timerange.id == 'custom'">
            <div class="overlay" ng-if="view.datepicker.isOpen"></div>
            <smart-groups-filter-time
                time-range="model"
                auto-load="false"
                is-open="view.datepicker.isOpen"
                filter="view.datepicker.selection"
                comparison="view.datepicker.comparison"
                comparison-mode="view.datepicker.comparisonMode"
                model="blackhole"
            ></smart-groups-filter-time>
        </section>
        <section class="report-params-timerange-select-x-from-now-container" ng-if="model.timerange.id == 'x-from-now'">
            <report-params-timerange-select-x-from-now model="model.xFromNowModel"></report-params-timerange-select-x-from-now>
        </section>
        <section class="report-params-timerange-options-container" ng-class="{active:view.showOptions}" ng-if="model.timerange.id != 'custom'">
            <div class="report-params-timerange-options-toggle" ng-click="view.showOptions = !view.showOptions">
                <i class="{{ view.showOptions && 'icon-down-open-mini' || 'icon-right-open-mini' }}">Additional Options...</i>
            </div>
            <ul class="report-params-timerange-options" ng-if="view.showOptions">
                <li class="report-params-timerange-option report-params-timerange-option-mode">
                    <label>
                        <span class="option-title">Comparison Mode</span>
                        <span class="option-help-text">How should we compare against the current timerange?</span>
                        <select ng-model="model.mode" ng-options="x.label for x in model.modes"></select>
                    </label>
                </li>
            </ul>
        </section>
    </article>
    """
    link: (scope) ->
        watchers = []

        scope.timerangeHashDatepicker = ->
            return null if not scope.view.datepicker.selection
            Utils.object.hash(scope.view.datepicker)

        scope.timerangeHashParam = ->
            return null if not scope.params.timerange
            Utils.object.hash(scope.params.timerange)

        scope.view = {showOptions:false}
        scope.blackhole = {}

        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            datePickerData = Utils.copy scope.datepickerData
            scope.model = new ReportParamsTimerangeModel(params, datePickerData)
            scope.model.init().then ->
                try
                    scope.view = {datepicker:{selection:{}, comparison:{}}}
                    scope.view.datepicker.selection.timestamp = Utils.copy scope.model.datepicker.selection
                    scope.view.datepicker.comparison.timestamp = Utils.copy scope.model.datepicker.comparison
                catch error
                    console.error error

                watchers.forEach (x) -> x()
                watchers = []

                scope.$watch 'timerangeHashParam()', ->
                    scope.model.updateModelFromParams()

                scope.$watch 'params.timerangeComparisonMode', ->
                    scope.model.updateModelFromParams()

                scope.$watch 'model.mode.id', ->
                    scope.model.updateParamsFromModel()

                scope.$watch 'model.timerange', ->
                    scope.model.updateParamsFromModel()
                    try
                        scope.view.datepicker.selection.timestamp  = Utils.copy scope.model.datepicker.selection
                        scope.view.datepicker.comparison.timestamp = Utils.copy scope.model.datepicker.comparison
                    catch error
                        console.error error

                scope.$watch 'timerangeHashDatepicker()', ->
                    return if not scope.view.datepicker.selection
                    scope.model.updateParamsFromModel()
                    try
                        scope.model.datepicker.selection  = Utils.copy scope.view.datepicker.selection.timestamp
                        scope.model.datepicker.comparison = Utils.copy scope.view.datepicker.comparison.timestamp
                    catch error
                        console.error


module.constant 'ReportParamsTimerangeTimeranges', [
    {id:'last-complete-week', group:'Frequently Used', label:'Last Complete Week'}
    {id:'last-complete-mtd',  group:'Frequently Used', label:'MTD – Complete Weeks'}
    {id:'last-complete-qtd',  group:'Frequently Used', label:'QTD – Complete Weeks'}
    {id:'last-complete-std',  group:'Frequently Used', label:'STD – Complete Weeks'}
    {id:'last-complete-ytd',  group:'Frequently Used', label:'YTD – Complete Weeks'}

    {id:'complete-months-qtd',  group:'Frequently Used', label:'QTD – Complete Months'}
    {id:'complete-months-std',  group:'Frequently Used', label:'STD – Complete Months'}
    {id:'complete-months-ytd',  group:'Frequently Used', label:'YTD – Complete Months'}

    {id:'custom',             group:'Frequently Used', label:'Custom Timerange'}
    {id:'x-from-now',         group:'Frequently Used', label:'X From Now'}

    {id:'yesterday',        group:'To Yesterday', label:'Yesterday'}
    {id:'wtd-complete-day', group:'To Yesterday', label:'WTY – Week To Yesterday'}

    {id:'wtd',              group:'To Date', label:'WTD – Week To Date'}
    {id:'mtd',              group:'To Date', label:'MTD – Month To Date'}
    {id:'qtd',              group:'To Date', label:'QTD – Quarter To Date'}
    {id:'std',              group:'To Date', label:'STD – Season To Date'}
    {id:'ytd',              group:'To Date', label:'YTD – Year to Date'}
    {id:'dtd',              group:'To Date', label:'DTD – Day To Date'}

    {id:'last-complete-week',    group:'Last Complete', label:'Last Complete Week'}
    {id:'last-complete-month',   group:'Last Complete', label:'Last Complete Month'}
    {id:'last-complete-quarter', group:'Last Complete', label:'Last Complete Quarter'}
    {id:'last-complete-season',  group:'Last Complete', label:'Last Complete Season'}
    {id:'last-complete-year',    group:'Last Complete', label:'Last Complete Year'}

    {id:'rolling-complete-2-week',   group:'Last Complete (Rolling)', label:'Last Complete 2 Weeks'}
    {id:'rolling-complete-3-week',   group:'Last Complete (Rolling)', label:'Last Complete 3 Weeks'}
    {id:'rolling-complete-4-week',   group:'Last Complete (Rolling)', label:'Last Complete 4 Weeks'}
    {id:'rolling-complete-6-week',   group:'Last Complete (Rolling)', label:'Last Complete 6 Weeks'}
    {id:'rolling-complete-13-week',  group:'Last Complete (Rolling)', label:'Last Complete 13 Weeks'}
    {id:'rolling-complete-26-week',  group:'Last Complete (Rolling)', label:'Last Complete 26 Weeks'}
    {id:'rolling-complete-52-week',  group:'Last Complete (Rolling)', label:'Last Complete 52 Weeks'}

    {id:'last-complete-2-week',   group:'Last Complete (Ago)', label:'2 Weeks Ago'}
    {id:'last-complete-3-week',   group:'Last Complete (Ago)', label:'3 Weeks Ago'}

    # {id:'week-from-now', group:'From Now', label:'Week from Now'}
    # {id:'month-from-now', group:'From Now', label:'Month from Now'}
    # {id:'quarter-from-now', group:'From Now', label:'Quarter from Now'}
    # {id:'season-from-now', group:'From Now', label:'Season from Now'}
    # {id:'year-from-now', group:'From Now', label:'Year from Now'}
    {id:'all', group:'All Time', label:'All Time'}
]

module.factory 'ReportParamsTimerangeXFromNowDisplay', (Utils, DateWrapper) -> class ReportParamsTimerangeXFromNowDisplay

    constructor: (@model) ->
        @update()

    isValid: ->
        return @start and @end

    update: ->
        baseFormat = "ddd, MMM Do"
        result =
            start: @_applyOffset(@model?.bounds?.end, @model?.start?.offset)
            end:   @_applyOffset(@model?.bounds?.end, @model?.end?.offset)
        @now ?= DateWrapper.CreateFromDate(@model?.bounds?.end).format(baseFormat)
        if result.start and result.end
            yearFormat = if result.start.format('YYYY') isnt result.end.format('YYYY') then 'YYYY' else ''
            for k in Object.keys(result)
                format = "#{baseFormat} #{yearFormat}".trim()
                result[k] = result[k].format(format)
        else
            result = {start:null, end:null}
        @start = result.start
        @end   = result.end
        return

    _applyOffset: (date, offset) ->
        return null if not date
        return null if not (_.isNumber(offset?.count) and offset?.unit?.selected?.id)
        date = DateWrapper.CreateFromDate(date)
        return date if offset.count is 0
        return date.subtract(offset.count, offset.unit.selected.id)

module.factory 'ReportParamsTimerangeXFromNowModel', (Utils, TimeRange) ->

    # TODO: Compute max based on time range / data bounds
    AVAILABLE_UNITS = [
        {id:"day",  label:"Days", max:365}
        {id:"week", label:"Weeks", max:52}
    ]

    class ReportParamsTimerangeXFromNowSelectorModel

        constructor: (data = {}) ->
            @reference = data.reference or "yesterday"
            @offset = @_parseOffsetFromData(data)

        isValid: ->
            count = parseInt(@offset.count)
            count = null if _.isNaN(count)
            count = null if count < 0
            return _.isNumber(count)

        serialize: ->
            return {@reference, offset:{count:@offset.count, unit:@offset.unit.selected?.id or null}}

        update: ->
            @offset = @_parseOffsetFromData(@serialize())

        _parseOffsetFromData: (data) ->
            unit = @_parseUnitFromData(data)
            count = @_parseCountFromData(data, unit)
            return {unit, count}

        _parseCountFromData: (data, unit) ->
            result = parseInt(data.offset?.count)
            return null if _.isNaN(result)
            result = Math.min(result, unit.selected.max) if unit?.selected
            result = Math.max(0, result)
            return result

        _parseUnitFromData: (data) ->
            available = Utils.copy(AVAILABLE_UNITS)
            available: available
            selected: do ->
                selectedId = do ->
                    result = data.offset?.unit
                    return null if not result
                    return result if _.isString(result)
                    return result.id
                selected = _.find available, (x) -> x.id is selectedId
                return selected or available[0]

    class ReportParamsTimerangeXFromNowModel

        constructor: (@params, bounds) ->
            @updateModelFromParams()
            @bounds = bounds or null

        init: ->
            @refresh()

        refresh: ->
            TimeRange.fetch().then (timerange) => @bounds = timerange

        isValid: ->
            @start.isValid() and @end.isValid()

        getInvalidFields: ->
            result = {}
            result['Timerange'] = not @isValid()
            return result

        updateModelFromParams: ->
            @start  = new ReportParamsTimerangeXFromNowSelectorModel(@params.timerange?.start)
            @end   ?= new ReportParamsTimerangeXFromNowSelectorModel(@params.timerange?.end)
            @end.offset.count = 0

        updateSelectorModels: ->
            @start.update()

        updateParamsFromModel: ->
            @params.timerange = do =>
                return null if not @isValid()
                start: @start.serialize()
                end:   @end.serialize()


    return ReportParamsTimerangeXFromNowModel


module.factory 'ReportParamsTimerangeModel', ($q, Utils, TimeRange, ReportParamsTimerangeTimeranges, ReportParamsTimerangeXFromNowModel) ->

    class ReportParamsTimerangeModel

        constructor: (@params, datepicker) ->
            @timeranges = Utils.copy(ReportParamsTimerangeTimeranges)
            @xFromNowModel = new ReportParamsTimerangeXFromNowModel(@params, datepicker.bounds)
            @datepicker =
                selection:  datepicker.models.selection.filter or {$gte:"2020-06-03", $lt:"2020-06-04"}
                comparison: datepicker.models.comparison.filter or {$gte:"2019-06-05", $lt:"2019-06-06"}
            @modes = [
                {id:'year',   label:'This Year vs. Last Year (default)'}
            ,   {id:'period', label:'Period over Period'}
            ,   {id:'year-2', label:'This Year vs. Two Years Ago'}
            ,   {id:'year-3', label:'This Year vs. Three Years Ago'}
            ]

            @mode = @modes[0]
            @updateModelFromParams()

        init: ->
            @refresh()

        fetch: ->
            TimeRange.fetch()


        refresh: ->
            @xFromNowModel.refresh()

        updateParamsFromModel: ->
            @timerange = @_getDefaultTimerange() if not @timerange?.id
            @params.timerangeComparisonMode = @mode.id
            if @timerange.id is 'x-from-now'
                @xFromNowModel.updateParamsFromModel()
                return
            @params.timerange = do =>
                return @timerange.id if @timerange.id isnt 'custom'
                return Utils.copy({selection:@datepicker.selection, comparison:@datepicker.comparison})


        updateModelFromParams: ->

            if not @params
                @timerange = @_getDefaultTimerange()
                @mode = @modes[0]
                return

            @mode  = _.find(@modes, (x) => x.id is @params.timerangeComparisonMode)
            @mode ?= @modes[0]

            if _.isString(@params.timerange)
                timerange = _.find @timeranges, (x) => x.id is @params.timerange
                @timerange = timerange or @_getDefaultTimerange()
                return

            if _.isObject(@params.timerange) and @params.timerange.selection
                @timerange = _.find @timeranges, (x) -> x.id is 'custom'
                try
                    @datepicker.selection = Utils.copy(@params.timerange.selection)
                    @datepicker.comparison = Utils.copy(@params.timerange.comparison)
                catch error
                    console.error("Could not parse datepicker config from `params.timerange`, falling back to time range.")
                    console.error(error)
                    @timerange = @_getDefaultTimerange()
                return

            if _.isObject(@params.timerange) and @params.timerange.start
                @timerange = _.find @timeranges, (x) -> x.id is 'x-from-now'
                @xFromNowModel.updateModelFromParams()


        _getDefaultTimerange: ->
            return _.find @timeranges, (x) -> x.id isnt 'custom'


module.directive 'reportParamsViewerHierarchy', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="report-params-hierarchy">
        <report-params-viewer-hierarchy-list list-title="Selected Properties" properties="model.selected"></report-params-hierarchy-list>
    </article>
    """

module.directive 'reportParamsHierarchy', (ReportingState) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="report-params-hierarchy" ng-class="{invalid:isInvalid}">
        <report-params-hierarchy-list class="report-params-hierarchy-list-available" list-title="Available Properties" help="{{ text }}" properties="model.properties" model="model" on-click-method="add">
            <span ng-if="model.selected.length == 0">Drag or double-click on the properties below to select them.</span>
            <button class="button-bare" ng-if="model.selected.length >= 1" ng-click="model.resetSelected()">reset</button>
        </report-params-hierarchy-list>
        <report-params-hierarchy-list class="report-params-hierarchy-list-selected" list-title="Selected Properties" properties="model.selected" model="model" on-click-method="remove">
            <button class="button-bare" ng-if="model.selected.length >= 1" ng-click="model.resetSelected()">reset</button>
        </report-params-hierarchy-list>
    </article>
    """
    link: (scope) ->
        scope.$watch 'model.selected.length', ->
            return if not scope.model
            ReportingState.report.updateInvalidFields(scope.model.getInvalidFields())

module.directive 'reportParamsHierarchyList', ($timeout, Utils) ->
    moved = false

    restrict: 'E'
    scope:
        listTitle:     '@'
        help:          '@'
        properties:    '='
        model:         '='
        onClickMethod: '@'
    transclude: true
    replace: true
    template: \
    """
    <article class="report-params-hierarchy-list">
        <header>
            <h1>{{ listTitle }}</h1>
            <h2 class="hint hint-right" ng-transclude></span>
        </header>
        <main>
            <ul class="properties"
                dnd-list="properties"
                dnd-drop="drop(event, index, item)"
                dnd-inserted="inserted(event, index, item)"
                dnd-horizontal-list="true">
                <li ng-repeat="property in properties"
                    dnd-draggable="property"
                    dnd-effect-allowed="move"
                    dnd-moved="moved($index, event)"
                    ng-double-click="onClick(property)">
                    <article class="property">
                        <span>{{ property.label }}</span>
                        <span class="button-close" ng-click="remove(property)">
                            <i class="icon-cancel"></i>
                            <i class="icon-cancel-circled button-close"></i>
                        </span>
                    </article>
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope) ->
        scope.remove = (item) ->
            index = Utils.indexOf scope.properties, (x) -> x.id is item.id
            scope.properties = Utils.removeAt(scope.properties, index)
            scope.model.properties.push(item)
        scope.add = (item) ->
            index = Utils.indexOf scope.model.properties, (x) -> x.id is item.id
            scope.model.properties = Utils.removeAt(scope.model.properties, index)
            scope.model.selected.push(item)
        scope.onClick = scope[scope.onClickMethod]
        scope.drop = (event, nextIndex, item) ->
            currentIndex = Utils.indexOf scope.properties, (x) -> x.id is item.id
            # if the property is in the list already, then we move it
            if currentIndex >= 0
                moved = true
                # The `nextIndex` that's returned by the framework will be wrong if the item is moved
                # after it's current location because it is counting the index of the placeholder element.
                nextIndex = nextIndex - 1 if nextIndex > currentIndex
                scope.properties = Utils.move(scope.properties, currentIndex, nextIndex)
            # Otherwise we just insert the element.
            else
                moved = false
                scope.properties = Utils.insertAt(scope.properties, nextIndex, item)
            return true
        scope.moved = (index) ->
            scope.properties = Utils.removeAt(scope.properties, index) if not moved
            moved = false
            return true

module.directive 'reportParamsViewerHierarchyStore', ($rootScope, $q, ReportParamsHierarchyStoreModel) ->
    restrict: 'E'
    scope: params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-viewer-hierarchy-store" ng-if="model.selected && model.selected.length > 0">
        <header>
            <h1>How should the report be broken down?</h1>
        </header>
        <main>
            <ul class="properties">
                <li class="property" ng-repeat="property in model.selected">
                    <span class="pellet">{{ property.label }}</span>
                </li>
            </ul>
        </main>
    </article>
    """
    link: (scope) ->
        currentModel = null
        watchers = []

        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            watchers.forEach (x) -> x() # unregisters the existing watchers
            watchers = []
            modelPromise = do ->
                if not scope.model
                    scope.model = new ReportParamsHierarchyStoreModel(params)
                    scope.model.init()
                else
                    scope.model.resetSelected()
                    scope.model.params = params
                    scope.model.updateModelFromParams()
                    return $q.when()
            modelPromise.then ->
                watchers.push \
                    scope.$watch 'model.selected', ((properties) ->
                        return if _.isUndefined(properties)
                        scope.model.updateParamsFromModel()
                    ), true
                watchers.push \
                    scope.$watch 'params.hierarchyStore', (->
                        scope.model.updateModelFromParams()
                    ), true
            .catch (error) ->
                console.error error
                scope.$emit 'report-params-error',
                    error: error
                    message: "Could not load store hierarchy."
                scope.error = true
                throw error

module.directive 'reportParamsHierarchyStore', ($rootScope, $q, ReportParamsHierarchyStoreModel) ->
    restrict: 'E'
    scope: params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-hierarchy-store">
        <header>
            <h1>How should the report's rows be broken down?</h1>
        </header>
        <report-params-hierarchy model="model"></report-params-hierarchy>
    </article>
    """
    link: (scope) ->
        watchers = []

        scope.hierarchyModel = $rootScope.hierarchyModel
        scope.$watch 'hierarchyModel.selected', (id) ->
            return if not id
            scope.model?.resetSelected()
            scope.model?.refresh()

        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            watchers.forEach (x) -> x() # unregisters the existing watchers
            watchers = []
            modelPromise = do ->
                if not scope.model
                    scope.model = new ReportParamsHierarchyStoreModel(params)
                    scope.model.init()
                else
                    scope.model.resetSelected()
                    scope.model.params = params
                    scope.model.updateModelFromParams()
                    return $q.when()
            modelPromise.then ->
                watchers.push \
                    scope.$watch 'model.selected', ((properties) ->
                        return if _.isUndefined(properties)
                        scope.model.updateParamsFromModel()
                    ), true
                watchers.push \
                    scope.$watch 'params.hierarchyStore', (->
                        scope.model.updateModelFromParams()
                    ), true
            .catch (error) ->
                console.error error
                scope.$emit 'report-params-error',
                    error: error
                    message: "Could not load store hierarchy."
                scope.error = true
                throw error

module.directive 'reportParamsCurrency', ($rootScope, $q, ReportParamsCurrencyModel) ->
    restrict: 'E'
    scope: params: '='
    replace: true
    template: \
    """
    <article class="report-params report-params-currency">
        <header>
            <h1>What currency do you want to see?</h1>
        </header>
        <select ng-options="x as (x.label) for x in model.available" ng-model="model.selected"></select>
    </article>
    """
    link: (scope) ->
        watchers = []

        scope.$watch 'params', (params) ->
            return if _.isUndefined(params)
            watchers.forEach (x) -> x() # unregisters the existing watchers
            watchers = []
            modelPromise = do ->
                if not scope.model
                    scope.model = new ReportParamsCurrencyModel(params)
                    scope.model.init()
                else
                    scope.model.resetSelected()
                    scope.model.params = params
                    scope.model.updateModelFromParams()
                    return $q.when()
            modelPromise.then ->
                watchers.push \
                    scope.$watch 'model.selected', ((properties) ->
                        return if _.isUndefined(properties)
                        scope.model.updateParamsFromModel()
                    ), true
                watchers.push \
                    scope.$watch 'params.currency', (->
                        scope.model.refresh()
                    ), true
            .catch (error) ->
                console.error error
                scope.$emit 'report-params-error',
                    error: error
                    message: "Could not load currencies."
                scope.error = true
                throw error

module.factory 'ReportParamsMetricSelectModel', ($q, $rootScope, Utils, QueryMetrics) ->
    class ReportParamsMetricSelectModel

        constructor: (@params) ->
            @params.metrics ?= []
            @initialized = false
            @available = []
            @selected = []

        init: ->
            @refresh()

        refresh: ->
            @_fetchMetrics().then (metrics) =>
                @available = @_getAvailableFromMetrics(metrics)
                @updateModelFromParams()

        getInvalidFields: ->
            result = {}
            result['Metrics'] = @selected.length is 0
            return result

        reset: ->
            @selected = []

        select: (item) ->
            return if @isSelected(item)
            @selected.push(item)

        remove: (item) ->
            return if not @isSelected(item)
            @selected = Utils.remove @selected, (x) -> x.id is item.id

        toggle: (item) ->
            return @remove(item) if @isSelected(item)
            return @select(item)

        selectAll: ->
            @available.forEach(@select)

        isSelected: (item) ->
            return !!_.find @selected, (x) -> x.id is item.id

        _fetchMetrics: ->
            QueryMetrics.fetch(@params.currency)

        _getAvailableFromMetrics: (metrics) ->
            seen = {}
            return metrics.reduce ((result, metric) ->
                key = "#{metric.headerGroup} - #{metric.headerName}"
                available = seen[key] or do ->
                    x = {id:key, label:metric.headerGroup, group:metric.headerGroup, metrics:[]}
                    result.push(x)
                    seen[key] = x
                    return x
                available.metrics.push(metric)
                return result
            ), []

        updateParamsFromModel: ->
            @params.metrics = _.flatten @selected.map (x) -> Utils.copy(x.metrics)

        updateModelFromParams: ->
            availableByField = _.flatten(@available).reduce ((result, available) ->
                available.metrics.forEach (x) -> result[x.field] = available
                return result
            ), {}
            seen = {}
            @selected = @params.metrics.reduce ((result, x) ->
                field = if _.isString(x) then x else x.field
                available = availableByField[field]
                return result if not available or seen[available.id]
                result.push(available)
                seen[available.id] = true
                return result
            ), []

module.factory 'ReportParamsColumnPropertiesModel', (SchedulingHierarchy) ->

    class ReportParamsColumnPropertiesModel

        constructor: (@params) ->
            @available = []
            @selected  = null

        init: ->
            @refresh()

        refresh: ->
            SchedulingHierarchy.fetch().then (properties) =>
                @selected = null
                @available = properties.filter (x) ->
                    return x.id.indexOf('calendar.') is 0 and \
                            x.id.indexOf('calendar.day_of_week_label') is -1
                @updateModelFromParams()

        updateParamsFromModel: ->
            @params.columns = _.compact([@selected?.id])

        updateModelFromParams: ->
            @selected = do =>
                id = @params?.columns?[0]
                return null if not id
                return _.find @available, (x) -> x.id is id

module.factory 'ReportParamsHierarchyModel', ($q, $log, Utils) ->
    class ReportParamsHierarchyModel
        constructor: (@params, @key, @refreshFn) ->
            @available   = []
            @properties  = []
            @selected    = []
            @initialized = false
        init: ->
            @refresh()
        refresh: ->
            $q.when(@refreshFn()).then (properties) =>
                @initialized = true
                @available = properties
                @properties = properties
                @selected = []
                @updateModelFromParams()
        resetSelected: ->
            @properties = @available
            @selected = []
            @updateParamsFromModel()
        getInvalidFields: ->
            result = {}
            result['Breakdown Properties'] = @selected.length is 0
            return result
        updateParamsFromModel: ->
            @params[@key] ?= []
            @params[@key] = @selected.map (x) -> x.id
            selectedById = _.keyBy @selected, (x) -> x.id
            @properties = @properties.filter (x) -> not selectedById[x.id]
        updateModelFromParams: ->
            availableById = _.keyBy @available, (x) -> x.id
            @selected = (@params[@key] or []).reduce ((result, id) ->
                property = availableById[id]
                $log.warn("Property `#{id}` was not found in list of available properties.") if not property
                result.push(property) if property
                return result
            ), []
            selectedById = _.keyBy @selected, (x) -> x.id
            @properties = @properties.filter (x) -> not selectedById[x.id]

module.factory 'ReportParamsCurrencyModel', ($q, $rootScope, $log, Utils, Currencies) ->
    class ReportParamsCurrencyModel
        constructor: (@params) ->
            @available   = []
            @selected    = null
            @initialized = false
        init: ->
            @refresh()
        refresh: ->
            Currencies.fetch().then (currencies) =>
                @initialized = true
                @available = currencies
                @updateModelFromParams()
        resetSelected: ->
            @updateParamsFromModel()
        updateParamsFromModel: ->
            @params.currency = @selected.id
        updateModelFromParams: ->
            selected = _.find @available, (currency) => currency.id is @params.currency
            selected ?= _.find @available, (currency) -> currency.id is $rootScope.currencyModel?.selected?.id
            @selected = selected or @available[0]


module.service 'SchedulingHierarchy', ($q, CONFIG, Hierarchy, HourProperty, CalendarProperties) ->
    fetch: ->
        $q.all([
            Hierarchy.fetch().then(({all}) -> all)
            CalendarProperties.fetch()
            HourProperty.fetch()
        ]).then ([hierarchy, calendarProperties, hourProperty]) ->
            hierarchy.push(hourProperty) if hourProperty
            return hierarchy.concat(calendarProperties or [])


module.factory 'ReportParamsHierarchyStoreModel', (Utils, ReportParamsHierarchyModel, SchedulingHierarchy) ->
    return (params) -> new ReportParamsHierarchyModel params, 'hierarchyStore', -> SchedulingHierarchy.fetch()


module.factory 'PromiseGuard', (Utils) -> return ->
    lastPromiseId = null
    return (promise) ->
        lastPromiseId = promiseId = Utils.uuid()
        promise.then (x) ->
            throw new Error('Function was called before this promise has resolved.') if lastPromiseId isnt promiseId
            return x
