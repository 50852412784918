_ = require('../../../lib/lodash-helper.ts')
moment = require 'moment'

module.exports = (Utils, CONFIG) ->
    Utils.copy(CONFIG.datepicker?.years or [
        {key:2013, start:[2013, 1, 3],  end:[2014, 1, 2]}
        {key:2014, start:[2014, 1, 2],  end:[2015, 1, 1]}
        {key:2015, start:[2015, 1, 1],  end:[2016, 0, 31]}
        {key:2016, start:[2016, 0, 31], end:[2017, 0, 30]}
        {key:2017, start:[2017, 0, 30], end:[2018, 0, 29]}
        {key:2018, start:[2018, 0, 29], end:[2019, 0, 28]}
        {key:2019, start:[2019, 0, 28], end:[2020, 0, 27]}
    ]).map (year, index, result) ->
        year.start = moment.utc(year.start)
        year.end   = moment.utc(year.end)
        year.prev  = result[index-1]
        year.next  = result[index+1]
        year.restate = year.end.diff(year.start, 'weeks') is 53
        year.restateMonth = do ->
            return if not year.restate
            restateMonth = parseInt(year.restateMonth)
            return 11 if _.isNaN(restateMonth)
            throw new Error("Calendar year `#{year.key}`'s `restateMonth` property must be between 0 and 11.") if not (0 <= restateMonth <= 11)
            return restateMonth
        return year
