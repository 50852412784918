_ = require('../../../lib/lodash-helper.ts')

module.exports = (DateSelectActionsOverrides, DateSelectAction, ComparisonModeSelectModel) -> (models, bounds) ->
    comparisonModels =
        selected: models.comparisonMode or new ComparisonModeSelectModel()
        period: new ComparisonModeSelectModel('period')

    RollingCompleteNWeeksAction = (weeks) ->
        {id:"rolling-complete-#{weeks}-weeks", label:"Last Complete <em>#{weeks} Weeks</em>", hide:true, getRange: ->
            {end} = models.selection.bounds
            selectionRange = do ->
                isCurrentWeekComplete = end.eq end.clone().endOf('week').subtract(1, 'day')
                end = end.clone().startOf('week').subtract(1, 'day') if not isCurrentWeekComplete
                start: end.clone().subtract(weeks, 'weeks').add(1, 'day')
                end:   end
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }

    completePeriodSelectionRange = (period, periodType) ->
        today = models.selection.bounds.end
        isCompletePeriod = today.eq today.endOf(periodType).subtract(1, 'day')
        today = today.startOf(periodType).subtract(1, 'day') if not isCompletePeriod
        return {start:today.startOf(period), end:today}

    overridesById = do ->
        overrides = DateSelectActionsOverrides.fetch()
        return null if not overrides
        overrides.forEach (x, i) -> x.index = i
        return _.keyBy (overrides or []), (x) -> x.id

    actions = [
        {id:'dtd', label:'<em>DTD</em> – Day to Date', getRange: ->
            today = models.selection.bounds.end
            selectionRange = {start:today.startOf('day'), end:today}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'wtd', label:'<em>WTD</em> – Week To Date', getRange: ->
            today = models.selection.bounds.end
            selectionRange = {start:today.startOf('week'), end:today}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'mtd', label:'<em>MTD</em> – Month To Date', getRange: ->
            today = models.selection.bounds.end
            selectionRange = {start:today.startOf('month'), end:today}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'qtd', label:'<em>QTD</em> – Quarter To Date', getRange: ->
            today = models.selection.bounds.end
            selectionRange = {start:today.startOf('quarter'), end:today}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'std', label:'<em>STD</em> – Season To Date', getRange: ->
            today = models.selection.bounds.end
            selectionRange = {start:today.startOf('season'), end:today}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'ytd', label:'<em>YTD</em> – Year to Date', getRange: ->
            today = models.selection.bounds.end
            selectionRange = {start:today.startOf('year'), end:today}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'last-complete-mtd', label:'<em>MTD</em> – Complete Weeks', section:true, getRange: ->
            selectionRange = completePeriodSelectionRange('month', 'week')
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'last-complete-qtd', label:'<em>QTD</em> – Complete Weeks', getRange: ->
            selectionRange = completePeriodSelectionRange('quarter', 'week')
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'last-complete-std', label:'<em>STD</em> – Complete Weeks', getRange: ->
            selectionRange = completePeriodSelectionRange('season', 'week')
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'last-complete-ytd', label:'<em>YTD</em> – Complete Weeks', getRange: ->
            selectionRange = completePeriodSelectionRange('year', 'week')
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'complete-months-qtd', label:'<em>QTD</em> – Complete Months', getRange: ->
            selectionRange = completePeriodSelectionRange('quarter', 'month')
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'complete-months-std', label:'<em>STD</em> – Complete Months', getRange: ->
            selectionRange = completePeriodSelectionRange('season', 'month')
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'complete-months-ytd', label:'<em>YTD</em> – Complete Months', getRange: ->
            selectionRange = completePeriodSelectionRange('year', 'month')
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }

        RollingCompleteNWeeksAction(4)
        RollingCompleteNWeeksAction(6)
        RollingCompleteNWeeksAction(13)
        RollingCompleteNWeeksAction(26)
        RollingCompleteNWeeksAction(52)

        {id:'last-complete-day', label:'Last Complete <em>Day</em>', getRange: ->
            {end} = models.selection.bounds
            selectionRange = do ->
                end = end.subtract(1, 'day')
                return {start:end, end}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }

        {id:'wtd-complete-day', label:'<em>WTY</em> – Week To Yesterday', getRange: ->
            {end} = models.selection.bounds
            selectionRange = do ->
                end = end.subtract(1, 'day')
                return {start:end.startOf('week'), end}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }

        {id:'last-complete-week', label:'Last Complete <em>Week</em>', section:true, getRange: ->
            {end} = models.selection.bounds
            selectionRange = do ->
                end = end.startOf('week').subtract(1, 'week') if not (end.eq end.endOf('week').subtract(1, 'day'))
                return {start:end.startOf('week'), end:end.endOf('week').subtract(1, 'day')}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }

        {id:'last-complete-month', label:'Last Complete <em>Month</em>', getRange: ->
            {end} = models.selection.bounds
            selectionRange = do ->
                end = end.startOf('month').subtract(1, 'month') if not (end.eq end.endOf('month').subtract(1, 'day'))
                return {start:end.startOf('month'), end:end.endOf('month').subtract(1, 'day')}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'last-complete-quarter', label:'Last Complete <em>Quarter</em>', getRange: ->
            {end} = models.selection.bounds
            selectionRange = do ->
                end = end.startOf('quarter').subtract(1, 'quarter') if not (end.eq end.endOf('quarter').subtract(1, 'day'))
                return {start:end.startOf('quarter'), end:end.endOf('quarter').subtract(1, 'day')}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'last-complete-season', label:'Last Complete <em>Season</em>', getRange: ->
            {end} = models.selection.bounds
            selectionRange = do ->
                end = end.startOf('season').subtract(1, 'season') if not (end.eq end.endOf('season').subtract(1, 'day'))
                return {start:end.startOf('season'), end:end.endOf('season').subtract(1, 'day')}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'last-complete-year', label:'Last Complete <em>Year</em>', getRange: ->
            {end} = models.selection.bounds
            selectionRange = do ->
                end = end.startOf('year').subtract(1, 'year') if not (end.eq end.endOf('year').subtract(1, 'day'))
                return {start:end.startOf('year'), end:end.endOf('year').subtract(1, 'day')}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'week-from-now', label:'<em>Week</em> from Now', section:true, getRange: ->
            {end} = models.selection.bounds
            selectionRange = {start:end.subtract(1, 'week').add(1, 'day'), end}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'month-from-now', label:'<em>Month</em> from Now', getRange: ->
            {end} = models.selection.bounds
            selectionRange = {start:end.subtract(1, 'month').add(1, 'day'), end}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'quarter-from-now', label:'<em>Quarter</em> from Now', getRange: ->
            {end} = models.selection.bounds
            selectionRange = {start:end.subtract(1, 'quarter').add(1, 'day'), end}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'season-from-now', label:'<em>Season</em> from Now', getRange: ->
            {end} = models.selection.bounds
            selectionRange = {start:end.subtract(1, 'season').add(1, 'day'), end}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'year-from-now', label:'<em>Year</em> from Now', getRange: ->
            {end} = models.selection.bounds
            selectionRange = {start:end.subtract(1, 'year').add(1, 'day'), end}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'same-day-ly', label:'<em>Same Day</em> Last Year', section:true, hide:false, getRange: ->
            today = models.selection.bounds.end
            lastYear = today.subtract(1, 'year')
            selectionRange = {start:lastYear.startOf('day'), end:lastYear}
            selection:  selectionRange
            comparison: comparisonModels.selected.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'last-complete-week-over-week', label:'Complete <em>Week</em> over <em>Week</em>', hide:true, getRange: ->
            {end} = models.selection.bounds
            selectionRange = do ->
                end = end.startOf('week').subtract(1, 'week') if not (end.eq end.endOf('week').subtract(1, 'day'))
                return {start:end.startOf('week'), end:end.endOf('week').subtract(1, 'day')}
            selection:  selectionRange
            comparison: comparisonModels.period.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'wtd-over-wtd', label:'<em>WTD</em> over <em>WTD</em>', hide:true, getRange: ->
            today = models.selection.bounds.end
            selectionRange = {start:today.startOf('week'), end:today}
            selection:  selectionRange
            comparison: comparisonModels.period.getComparisonTimerange(selectionRange, bounds)
        }
        {id:'all', label:'All Time', section:true, getRange: ->
            selection:  {start:models.selection.bounds.start, end:models.selection.bounds.end}
            comparison: null
        }
    ]
    actions = actions.filter (action) ->
        # If we have overrides, then we disable all the default timeranges
        return true if not overridesById
        return !!overridesById[action.id]
    actions = actions.map (action, index) ->
        override = overridesById?[action.id] or {index}
        return _.extend action, override
    actions = _.sortBy actions, (x) -> x.index
    return actions.map (action) ->
        return new DateSelectAction(action, models, bounds)
